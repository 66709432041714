import React, {Component} from 'react';
import {connect} from 'react-redux';
import './privacyterms.css';

class Terms extends Component {
   componentDidMount() {}
   render() {
      // Reference: https://www.Positive Prime.com/terms.html
      return (
         <div className="scrollGood">
            <p className="text-secondary m-0 p-3">Terms</p>
            <hr className="m-0" />
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h3 m-0">Terms</p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Terms of Use</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  Our goal is always to provide you with the most meaningful,
                  friendly, empowering, trouble-free, and enjoyable experience
                  possible.
               </p>
               <p>
                  Most of our users have the majority of their questions or
                  issues explain via our FAQ page.
               </p>
               <p className="m-0">
                  We believe in 'doing unto others, as we would wish would be
                  done unto us" and the principles of reciprocity and balance.
                  Please us let know via our Contact Page, at any time, how we
                  can improve. Suggestions are always welcome and considered
                  seriously. We are devoted to ensuring that we provide
                  exceptional value and service to thousands of people around
                  the world, and we appreciate your patience and understanding
                  as we continuously upgrade and enhance our scope of offerings
                  for your benefit.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Terms and Conditions</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  Positive Prime and its associated websites are hereafter
                  referred to, for simplicity’s sake, as Positive Prime.
               </p>
               <p>
                  These standard terms and conditions may be modified from time
                  to time, and although “legally” it is your responsibility to
                  regularly review and understand them, we realize that’s not
                  something most people think of … so we will always do our best
                  to inform you – both through on-site notices and e-mail
                  notification – of any material change in Policy.
               </p>
               <p className="m-0">
                  You must be over 18 years of age to use this site, and our
                  technology … or, if you are not 18 years of age, you must have
                  the consent of a parent or guardian to do so.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Correspondence</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  As a user of Positive Prime, you consent to receiving e-mails
                  from the Company about offers, events, and bonuses available
                  in which you might have an interest.
               </p>
               <p>
                  Of course, you’re never under any obligation to take advantage
                  of these offerings … but, since we honestly feel they may be
                  valuable in helping you achieve your goals, we just want to be
                  sure you know about them.
               </p>
               <p className="m-0">
                  And, we do have an "unsubscribe" link in the footer for all of
                  our emails. If you would like us to ensure you are removed
                  from any or all mailing lists, please click the "unsubscribe"
                  link at the bottom of every email.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Annual Club Membership and Renewals</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  An annual subscription, also known as annual membership, gives
                  you access to the i am gr8ful Club/Positive Prime for a period
                  of 12 months. For your convenience, and to minimize any
                  interruption of service, Positive Prime will invoice you on a
                  repeating annual basis. This policy quite clearly in our
                  correspondence with all annual members, and we further
                  communicate by sending out renewal notices before your
                  anniversary/renewal date to give you plenty of time to contact
                  us if you wish to cancel your membership.
               </p>
               <p>
                  We suggest that you make a note in your diary of your joining
                  date - and ensure that you have a reminder at least a week or
                  two before your renewal to check your "Subscriptions" in your
                  PayPal account.
               </p>
               <p className="m-0">
                  As an annual member, you benefit from having your membership
                  fee remain the same EVERY YEAR. In other words, no matter how
                  many times membership fees may increase, YOURS will not –
                  instead it remains the same as your very first year. (In the
                  event that membership fees ever decrease, yours would too, of
                  course.) With so much valuable new content and training being
                  added continually, this means the value of your annual
                  membership increases tremendously from year to year.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Monthly Club Membership</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  A monthly subscription, also known as a monthly membership,
                  gives you access to i am gr8ful Club/Positive Prime for a
                  period of no less than 1 calendar month. For your convenience,
                  and to minimize any interruption of service, the Company will
                  invoice you on a repeating monthly basis until you contact us
                  and let us know you wish to cancel your membership. Naturally,
                  you are free to cancel at any time through your own PayPal
                  account and we would recommend doing so.
               </p>
               <p className="m-0">
                  If you would like us to do so on your behalf, we require at
                  least five (5) business days prior to your next invoice date
                  to ensure you aren’t re-billed for the coming month. It is
                  your responsibility as a monthly subscriber/member to ensure
                  that there are sufficient funds available from the chosen
                  payment method. The Company reserves the right to adjust the
                  monthly membership fee from time to time with prior notice to
                  all monthly members/subscribers.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Cookies</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p className="m-0">
                  This website utilizes cookies – small bits of computer code,
                  shared between our computer and yours – to enhance your online
                  experience, eliminate the need for repeatedly entering the
                  same information, keep track of items placed in your shopping
                  cart, analyze which areas of our site get the most use (which
                  tells us where to put our greatest efforts, and improvements)
                  and overall to make things run more smoothly. Please ensure
                  you have cookies enabled in your web browser for
                  www.positiveprime.com … otherwise you may find certain
                  functions of this site not working as intended.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Links</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  Our websites and emails may contain links to websites not
                  owned or operated by Positive Prime. We do not accept
                  liability for the availability or content of these sites.
               </p>
               <p className="m-0">
                  Additionally, please be aware that the Company has no control
                  over, and is not responsible for, the privacy practices of
                  other websites. We respect privacy. We believe in "Liberty".
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Copyright</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p className="m-0">
                  All information contained on the Positive Prime website
                  (including but not limited to contributed articles,
                  multimedia, and briefing documents) remains the copyright of
                  Positive Prime and/or their respective authors. You are not
                  permitted under any circumstance to republish, reproduce, or
                  otherwise disperse the information contained on
                  www.positiveprime.com and all associated pages of our website
                  accessed by various different domain names, for any purpose
                  whatsoever unless you have express written permission to do
                  so. To make such an request, contact us using any of the
                  methods on the contact page. Positive Prime reserves the right
                  to cancel, without notice or refund in whole or part, the
                  membership of any member who we discover forwarding, copying
                  and pasting, republishing, distributing, or otherwise
                  breaching our copyrights, and may take further legal action.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Refund Policy</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  Positive Prime is sincerely dedicated to client and member
                  satisfaction. We offer high-value digital products and
                  services including advanced video technology, personal
                  mentoring, co-host coaching videos, guest contributor videos,
                  keynotes, training, and learning resources at a reasonable and
                  fair price.
               </p>
               <p>
                  Prospective members are given the opportunity to preview
                  Positive Prime Session for a week and numerous free coaching
                  video sessions are available. We provide these previews to
                  demonstrate the value of what we offer and allow a potential
                  client or member to experience the products and services for
                  free before making a membership decision.
               </p>
               <p>Since...</p>
               <ul>
                  <li>
                     Our Positive Prime technology is made available as a
                     digital download, and…
                  </li>
                  <li>
                     Memberships in the i am gr8ful club and Positive Prime,
                     which includes co-host and guest contributor videos, are
                     provided by online video sessions, and…
                  </li>
                  <li>
                     Due to the nature of digital downloads and online viewing
                     of videos not being returnable…
                  </li>
               </ul>
               <p>
                  Therefore, we do not refund monies paid for purchases of
                  Positive Prime Sessions or i am gr8ful Club membership. As a
                  subscriber/member, you acknowledge that the Positive Prime has
                  a “no refund” policy with respect to all Positive Prime
                  products, and the i am gr8ful Club subscriptions and
                  memberships.
               </p>
               <p className="m-0">
                  However, any physical merchandise can be returned within 30
                  days of purchase, and a full refund will be given, if you are
                  not satisfied and the merchandise is not damaged or worn. If
                  the physical merchandise is damaged in shipment, you must
                  contact us within 3 business days of receipt to inform us of
                  the damage to your purchase while in transit. Shipping fees
                  are not refundable.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Subscription Suspension Policy</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p className="m-0">
                  Monthly subscriptions are automatically suspended, or
                  cancelled, by virtue of non-payment. To re-activate a monthly
                  subscription, a new subscription must be started either within
                  the same account as before or by starting a completely new
                  account with a new email address.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Disclaimer</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  Products and services offered by the Positive Prime constitute
                  a “learning resource” … not a licensed counselling service. If
                  you feel you require medical assistance or a counselling
                  service, please seek professional assistance. The opinions and
                  information provided within this website cannot be considered
                  “personal advice” and does not take into account your personal
                  situation.
               </p>
               <p className="m-0">
                  The information on this website (www.positiveprime.com) has
                  been prepared from a wide variety of sources, which Positive
                  Prime, to the best of its knowledge and belief, considers
                  useful, beneficial and accurate. Positive Prime is not
                  responsible, and will bear no liability, for situations
                  arising from your use or misuse of this information, our
                  products, or our services.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Delivery Policy</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p className="m-0">
                  Positive Prime is wholly online and accessible to members
                  using their personal username and password to log in to the
                  site. Members may from time to time be sent email updates and
                  reminders of information that can be found via the website
                  subscription service. The information published on the website
                  can be downloaded and printed by the member for personal use
                  only.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Online Payment Security</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p className="m-0">
                  Subscribers to Positive Prime may make online subscription
                  payments using the provided third party supplier, or a valid
                  credit card. Credit card details or bank account details of
                  all subscribers are retained by PayPal which is the payment
                  gateway used by Positive Prime to process payments. Positive
                  Prime does not have access to these banking details at any
                  time.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Your Rights</p>
            </div>
            <div className="pt-3 px-4 pb-0 px-lg-5">
               <p>
                  You may terminate your membership by giving notice to us in
                  writing (such as via email). Naturally, you are also free to
                  cancel at any time through your own PayPal account by managing
                  your own subscriptions and we would recommend doing so.
               </p>
               <p className="m-0">
                  If you would like us to do so on your behalf, we require at
                  least five (5) business days prior to your next invoice date
                  to ensure you aren’t re-billed for the coming month or year.
               </p>
            </div>
            <div className="pt-5 px-4 pb-0 px-lg-5">
               <p className="h5 m-0">Limit of Our Liability</p>
            </div>
            <div className="pt-3 px-4 pb-5 px-lg-5">
               <p className="m-0">
                  You agree to indemnify and hold Positive Prime its
                  subsidiaries, affiliates, officers, agents and other partners
                  and employees, harmless from any loss, liability, claim or
                  demand, including reasonable attorneys' fees, made by any
                  third party due to, from, or arising out of your use of the
                  Positive Prime website and any of its offered products and
                  services.
               </p>
            </div>
            <div className="px-4 px-lg-5">
               <hr />
            </div>
            <div className="pt-5 px-4 pb-5 px-lg-5">
               <p className="m-0">
                  If you have any questions about this policy, the practices of
                  Positive Prime, or your experience with this web site, please
                  contact us.
               </p>
            </div>
         </div>
      );
   }
}

function mapStateToProps(state) {
   return { auth: state.auth };
}

export default connect(mapStateToProps)(Terms);

import React, { useState, useEffect } from 'react'
import clsx from 'clsx';
import { Modal, Spinner,  Card, Row, Col } from 'react-bootstrap'
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import SimpleDropZone from '../../v2Sessions/simpleDropzone';
import upload_personalize from "../../assets/icons/upload_personalize.svg";
import { API } from '../../APIAndConfig';
import WindowedSelect from 'react-windowed-select';
import { createFilter } from 'react-select';
import ReactTable from 'react-table-6'
import path from 'path';
const CreateCoachingSessions = ( props ) => {
    const [ inputField, setInputField ] = useState( {
        author: '',
        name: '',
        session_embed: '',
        category: '1',
        day: '',
        short_description: '',
        long_description: '',
    } )
    const [ messageModel, setMessageModel ] = useState( { state: false, header: "", message: "" } )

    const [ author, setAuthor ] = useState( [] )
    const [ showUploadImageModal, setShowUploadImageModel ] = useState( false )
    const [ completedCount, setCompletedCount ] = useState( [] )
    const [ thumbnail, setThumbnail ] = useState()
    const [ createCoaching, setCreateCoaching ] = useState( true )
    const [ days, setDays ] = useState( [] )
    const [ loader, setLoader ] = useState( true )
    const [ isProcessing, setisProcessing ] = useState( false );
    const [ resId, setResId ] = useState()
    const [ image, setImage ] = useState()
    const [allSessionData, setAllSessionData] = useState([])
    const [spinner, setSpinner] = useState(false)
    const [editCoachingSessions, setEditCoachingSessions] = useState(false)
    const [ change, setChange] = useState( true )
    const [editCoaching, setEditCoaching] = useState(true)
    const [currentCoachingSessions, setCurrentCoachingSessions] = useState({
        id:'',
        author: '',
        name: '',
        session_embed: '',
        category: '1',
        day: '',
        short_description: '',
        long_description: '',
        visibility:'',
        status:''
    })
    const [img, setImg] = useState()
    const [removeStatus, setRemoveStatus] = useState("")
    const [ thumbnailpath, setThumbnailPath] = useState("")
    const [uploadThumbnail, setUploadThumbnail] = useState(false)
    
    const inputsHandler = ( e ) => {
        setInputField( { ...inputField, [ e.target.name ]: e.target.value } )
    }

    const submitButton = async ( e ) => {
        e.preventDefault();
        setisProcessing( true )
        let payload = {
            author: +inputField.author,
            name: inputField.name,
            category: 1,
            session_embed: inputField.session_embed,
            day: +inputField.day,
            short_description: inputField.short_description,
            long_description: inputField.long_description,
            visibility: 'open',
            status: 'enabled'
        }
        await API.createCoachingSession( payload )
            .then(
                res => {
                    setResId( res.payload.data.id )
                    setisProcessing( false )
                    setCreateCoaching( false );
                }
            ).catch( err => {
                setisProcessing( false )
                console.log( "Error" );
            } )
    }

    const toggleUploadImageModal = () => {
        setShowUploadImageModel( false )
    }

    const updateCompletedCount = ( count ) => {
        let data = [ count ]
        setCompletedCount( data )
    }

    const onSubmitUploadImageModal = async ( e ) => {
        await API.uploadThumbnail( e[ 0 ], {
            filename: e[ 0 ].name,
            id: resId,
            mime: e[ 0 ].type,
            status: removeStatus,
            thumbnailpath: thumbnailpath
        }, updateCompletedCount )
            .then(
                res => {
                    setShowUploadImageModel(false)
                    if (res.successful) {
                        setThumbnail( e[ 0 ] )
                        setImage( process.env.REACT_APP_S3_URL_ZIP + '/coachingsessions/thumbnail/' + resId + e[ 0 ].name )
                        setImg( process.env.REACT_APP_S3_URL_ZIP + '/coachingsessions/thumbnail/' + resId + e[ 0 ].name)
                        setUploadThumbnail(true)
                    } else {
                        setMessageModel( { state: true, header: "Error", message: "Something went wrong please try again." } )
                        console.log( "Error" );
                    }
                   
                }
            )


    }

    const onUploadThumbnail = () => {
        setMessageModel( { state: true, header: "Success", message: "Coaching session created successfully" } )
        setCreateCoaching( true )
        setInputField( {
            author: "",
            name: "",
            session_embed: "",
            category: "1",
            day: "",
            short_description: "",
            long_description: ""
        } )
        setThumbnail( "" )
        setDays( days+1 )
        setResId( "" )
        setImage( "" )
    }

    const onCloseMessageModel = () => {
        setMessageModel( { state: false, header: "", message: "" } )
    }

    useEffect( () => {
        getAllDays()
        getAllUsers()
        allSession()
    }, [] )

    const getAllUsers = () => {
        API.getUsers()
            .then( ( res ) => {
                setLoader( false )
                if ( res.successful && res.payload.data ) {
                    setAuthor( res.payload.data )
                }
                else {
                    console.log( res );
                }
            } )
    }

    const setUser=( e )=> {
        setInputField({ 
            ...inputField,
            author: e.value 
        })
    }
    const setEditUser = ( e ) => {
        console.log(e.value);
        setCurrentCoachingSessions( {
            ...currentCoachingSessions,
            author: e.value
        } )
    }
    const getAllDays = async () => {
        await API.getDays()
            .then( ( res ) => {
                if ( res.payload.data ) {
                    let day = [ ...res.payload.data ]
                    let sort_day = day.sort( ( day, b ) => day - b )
                    let final_day = sort_day.at( -1 ) + 1
                    setDays( final_day )

                }
            } )
    }
    const createSession = async () => {
        setEditCoaching(true)
        setChange( true )
    }
    const allSession = async ()=>{
        await API.getAllCoachingSessions()
            .then( ( res ) => {
                if ( res.payload && res.payload.data ) {
                    res.payload.data.map( ( item ) => {
                        item.checkAuthor = item.author
                        item.checkName = item.name
                        item.checkSession_embed = item.session_embed
                        item.checkCategory = item.category
                        item.checkThumbnail = item.thumbnail
                        item.checkVisibility = item.visibility
                        item.checkStatus = item.status
                        item.checkDay = item.day
                        item.checkLong_description = item.long_description
                        item.checkShort_description = item.short_description
                        item.action = (
                            <div>
                                <button className="btn x-secondary text-white" onClick={() => {
                                    setEditCoachingSessions( true )
                                    setCurrentCoachingSessions( item )
                                    setImg( process.env.REACT_APP_S3_URL_ZIP + item.thumbnail )
                                    setResId( item.id )
                                    setRemoveStatus("remove")
                                    setThumbnailPath(item.thumbnail)

                                }}>Edit</button>
                            </div> )
                    } )

                    setAllSessionData( res.payload.data )

                }
            } )
    }
    const EditSession = async ( e ) => {
        setSpinner( true )
        setChange(false)
        setEditCoaching( false )    
    }
    const handleChange = async( e ) => {
         setCurrentCoachingSessions( {
            ...currentCoachingSessions,
            [ e.target.name ]: e.target.value
            })
         
         
    }
   const handleClose = async () =>{
       setEditCoachingSessions( false )
       setRemoveStatus("")
       setImg()
   }
    const handleSubmit = async ()=>{
        setisProcessing( true )
        if ( (currentCoachingSessions.checkSession_embed === currentCoachingSessions.session_embed )&&
             ( currentCoachingSessions.checkAuthor === currentCoachingSessions.author ) &&
             ( currentCoachingSessions.checkName === currentCoachingSessions.name ) &&
             ( currentCoachingSessions.checkDay === currentCoachingSessions.day ) &&
             ( currentCoachingSessions.checkLong_description === currentCoachingSessions.long_description ) &&
             ( currentCoachingSessions.checkShort_description === currentCoachingSessions.short_description )&&
             ( currentCoachingSessions.checkVisibility === currentCoachingSessions.visibility  ) &&
             ( currentCoachingSessions.checkStatus === currentCoachingSessions.status  ) &&
             ( uploadThumbnail === false)
             ) {
            setMessageModel( {
                    state: true,
                    message: 'No changes detected to update please try updating fields',
                    header: 'Update User Error'
                
            } )
            setRemoveStatus( "" )
            setisProcessing( false )
            setImg()
        }else{
            let payload = {
                session_embed: currentCoachingSessions.session_embed,
                id: currentCoachingSessions.id,
                author: currentCoachingSessions.author,
                name: currentCoachingSessions.name,
                day: currentCoachingSessions.day,
                long_description: currentCoachingSessions.long_description,
                short_description: currentCoachingSessions.short_description,
                visibility: currentCoachingSessions.visibility,
                status: currentCoachingSessions.status
            }
            console.log(payload);
            await API.updateCoachingSessionInfo( payload )
                .then( ( res ) => {
                    if ( res.status === 200 ) {
                        setEditCoachingSessions(false)
                        setCurrentCoachingSessions({
                            id: '',
                            author: '',
                            name: '',
                            session_embed: '',
                            category: '1',
                            day: '',
                            short_description: '',
                            long_description: '',
                            visibility: '',
                            status: ''
                        })
                        setMessageModel( {
                            state: true,
                            message: 'Sucessfully updated the user',
                            header: 'Update Successful'

                        } )
                        setisProcessing(false)
                        EditSession()
                        setRemoveStatus( "" )
                    } else {
                        setEditCoachingSessions( false )
                        setCurrentCoachingSessions( {
                            id: '',
                            author: '',
                            name: '',
                            session_embed: '',
                            category: '1',
                            day: '',
                            short_description: '',
                            long_description: '',
                            visibility: '',
                            status: ''
                        } )
                        setMessageModel( {
                            state: true,
                            message: 'Failed to update user',
                            header: 'Update Failed'

                        } )
                        setisProcessing( false )
                        setRemoveStatus( "" )
                    }
                } )
            allSession()
            setRemoveStatus("")
            setImg()
            setUploadThumbnail( false )
        }
    }

    return (
        <>
            {loader ? (
                <div className={"text-center mt-4"}>
                    <Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} />
                </div >
            ) :
                <div className="col-12">
                    <div className="d-flex align-items-center justify-items-center">
                        <h2 className="main-title m-0 p-3">Create Coaching Sessions</h2>
                        {change?(<button className="btn btn-primary ml-auto" onClick={EditSession} >Edit Session</button>):(
                        <button className="btn btn-primary ml-auto" onClick={createSession} >Create Session</button>)}
                    </div>
                    <hr className="m-0" />
                    {editCoaching === false ?(
                    <>
                        {allSessionData.length === 0 ? (
                            spinner ? (
                                <div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>
                            ) : ""
                        ) : (

                            <div>
                                <Card className="card-blur">
                                        <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>Coaching Sessions</Card.Header>
                                    <Card.Body className="p-0">
                                        <ReactTable
                                            filterable
                                            data={allSessionData}
                                            columns={[
                                                {
                                                    id: "id",
                                                    Header: 'Id',
                                                    accessor: 'id',
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'text-center'
                                                },
                                                {
                                                    id: "author",
                                                    Header: 'Author',
                                                    accessor: ( d ) => {
                                                        return d.author +" - "+ d.first_name +" "+ d.last_name
                                                    },
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'text-center'
                                                },
                                                {
                                                    id: 'name',
                                                    Header: 'Title',
                                                    accessor: 'name',
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'text-center'
                                                },
                                                {
                                                    id: 'day',
                                                    Header: 'Day',
                                                    accessor: 'day',
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'text-center'
                                                }, 
                                                {
                                                    id: 'action',
                                                    Header: 'Action',
                                                    accessor: 'action',
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'text-center',
                                                    Filter: ( { filter, onChange } ) => {
                                                        return (
                                                            <input type="text" value="" style={{ width: "100%" }} disabled />
                                                        );
                                                    }
                                                },

                                            ]}
                                                    defaultPageSize={allSessionData.length > 10 ? 10 : allSessionData.length}
                                            noDataText='No Data Found'
                                            style={{ background: "rgb(111 79 136 / 1)" }}
                                            getTrProps={( state, rowInfo, column, instance ) => {
                                                if ( typeof rowInfo !== "undefined" ) {
                                                    return {
                                                        style: {
                                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                                        },
                                                    }
                                                }
                                                else {
                                                    return {
                                                        style: {
                                                            background: 'transparent',
                                                            color: 'white'
                                                        },
                                                    }
                                                }
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                            </div>

                        )
                        }
                        </> ) : (

                    <div className="row mt-5">
                        <div className="col-md-12 p-4">
                            <div className={clsx( 'setting-card', 'card' )}>
                                <div className={clsx( 'card-header' )}>
                                    <p className={clsx( 'setting-card-header mb-0 p-4' )}>
                                        Coaching Information
                                    </p>
                                </div>
                                {createCoaching ? (
                                    <div className="card-body">
                                        <form onSubmit={submitButton}>
                                            <div className="form-group">
                                                <label htmlFor="inputSession">Author</label>
                                                <WindowedSelect
                                                    name="author"
                                                    filterOption={createFilter( { ignoreAccents: false } )}
                                                    onChange={setUser}
                                                    isDisabled={isProcessing}
                                                    styles={{
                                                        option: ( base ) => ( {
                                                            ...base,
                                                            color: "black"
                                                        } ),
                                                    }}
                                                    options={
                                                        author.map( ( obj) => {
                                                            return (
                                                                { value: obj.userId, label: obj.first_name + " " + obj.last_name + " - " + obj.userId}
                                                            )
                                                        } )
                                                    }
                                                />

                                            </div>
                                            <div className={clsx( 'form-group' )}>
                                                <label>Title</label>
                                                <input
                                                    type="text"
                                                    className={clsx( 'form-control' )}
                                                    name="name"
                                                    value={inputField.name}
                                                    onChange={inputsHandler}
                                                    disabled={isProcessing}
                                                    placeholder="Title"
                                                />
                                            </div>
                                            <div className={clsx( 'form-group' )}>
                                                <label>Session URL</label>
                                                <input
                                                    type="text"
                                                    className={clsx( 'form-control' )}
                                                    name="session_embed"
                                                    value={inputField.session_embed}
                                                    onChange={inputsHandler}
                                                    disabled={isProcessing}
                                                    placeholder="Session URL"
                                                />
                                            </div>
                                            <div className={clsx( 'form-group' )}>
                                                <label>Category</label>
                                                <input
                                                    type="text"
                                                    className={clsx( 'form-control' )}
                                                    name="category"
                                                    value={inputField.category}
                                                    onChange={inputsHandler}
                                                    disabled={true}
                                                    placeholder="Category"
                                                />
                                            </div>
                                            <div className={clsx( 'form-group' )}>
                                                <label>Day Select</label>
                                                <select className={clsx( "form-select", 'form-control' )} name="day" onChange={inputsHandler} disabled={isProcessing}>
                                                    <option disabled selected>Select Day</option>
                                                    <option value={days} name="day">{days}</option>
                                                </select>
                                            </div>
                                            <div className={clsx( 'form-group' )}>
                                                <label>Short Description</label>
                                                <textarea
                                                    rows='5'
                                                    className={clsx( 'form-control' )}
                                                    name="short_description"
                                                    value={inputField.short_description}
                                                    onChange={inputsHandler}
                                                    disabled={isProcessing}
                                                    placeholder="Short Description"
                                                />
                                            </div>
                                            <div className={clsx( 'form-group' )}>
                                                <label>Long Description</label>
                                                <textarea
                                                    rows='6'
                                                    className={clsx( 'form-control' )}
                                                    name="long_description"
                                                    value={inputField.long_description}
                                                    onChange={inputsHandler}
                                                    disabled={isProcessing}
                                                    placeholder="Long Description"
                                                />
                                            </div>
                                            <div className={clsx( 'form-group' )}>
                                                <button className={clsx( 'btn x-secondary text-white float-right' ) } style={{minWidth:"200px"}}
                                                    type="submit"
                                                    disabled={ 
                                                        !inputField.author ||
                                                        !inputField.name ||
                                                        !inputField.session_embed ||
                                                        !inputField.category ||
                                                        !inputField.day ||
                                                        !inputField.short_description ||
                                                        !inputField.long_description ||
                                                        isProcessing
                                                    }>
                                                    {isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Next: Add Coaching Thumbnail</span>}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                ) : (
                                    <div> 
                                        <div>
                                            <Modal show={showUploadImageModal} onHide={() => { setShowUploadImageModel( true ) }}>
                                                <ModalHeader>
                                                    <div className='w-100'>
                                                        <label>Upload Thumbnail</label>
                                                        <button
                                                            className="x-secondary"
                                                            style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                                                            onClick={toggleUploadImageModal}
                                                        >X</button>
                                                    </div>
                                                </ModalHeader>
                                                <SimpleDropZone
                                                    onUpload={onSubmitUploadImageModal}
                                                    completedCount={completedCount.length}
                                                    acceptTypes="image/jpg, image/gif, image/bmp, image/jpeg, image/tiff, image/png, .heic"
                                                    message="Click Here To Select Thumbnail From Your Device"
                                                    inputType="Image"
                                                    maxFiles={1}
                                                />
                                            </Modal>
                                        </div>
                                        <div className="card-body d-flex justify-content-center">
                                            <div className={clsx( 'form-group' )}>
                                                {image ? (
                                                    <div>
                                                            <img src={image} style={{ maxHeight: "300px", maxWidth: "300px", border: "3px solid", borderColor: "#B6B5B5", borderRadius: "10px", padding: "15px" }} />
                                                    </div>
                                                    ) :<div> 
                                                            <p className="d-flex justify-content-center">Add Thumbnail</p>
                                                        <button className={clsx( 'x-btn', 'btn', 'x-secondary' )} onClick={() => {
                                                        setShowUploadImageModel( true )
                                                    }}>
                                                        <img src={upload_personalize} />
                                                        <span className="ml-2">Upload Thumbnail</span>
                                                    </button>
                                                        </div>}
                                                    
                                                <br />
                                          
                                            </div>
                                        </div>

                                        <div className={clsx( 'form-group' )}>
                                            <button className={clsx( 'btn x-secondary text-white float-right mb-4 mr-3' )} disabled={thumbnail == null} onClick={onUploadThumbnail}>Submit</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        </div> )}

                </div>}
            <Modal show={messageModel.state} onHide={() => { }}>
                <Modal.Header> {messageModel.header}</Modal.Header>
                <Modal.Body>
                    {messageModel.message}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={onCloseMessageModel}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal show={editCoachingSessions} onHide={() => { }} size="lg">
                <Modal.Header>Edit User</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <div className={clsx( 'form-group' )}>
                                <label>Id</label>
                                <input
                                    type="text"
                                    className={clsx( 'form-control', 'setting-input' )}
                                    name="author"
                                    value={currentCoachingSessions.id}
                                    placeholder="Id"
                                    disabled
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="form-group">
                                <label htmlFor="inputSession">Author</label>
                                <WindowedSelect
                                    name="author"
                                    filterOption={createFilter( { ignoreAccents: false } )}
                                    onChange={setEditUser}
                                    isDisabled={isProcessing}
                                    value={{ value: currentCoachingSessions.author, 
                                        label: author.find( obj => Number( obj.userId ) === Number( currentCoachingSessions.author ) )&& author.find( obj => Number( obj.userId ) === Number( currentCoachingSessions.author ) ).first_name + " " + author.find( obj => Number( obj.userId ) === Number( currentCoachingSessions.author ) ).last_name + " - " + author.find( obj => Number( obj.userId ) === Number( currentCoachingSessions.author ) ).userId
                                        // label: author.find( obj => obj.id === currentCoachingSessions.author ).first_name +" "+ author.find( obj => obj.id === currentCoachingSessions.author ).last_name+" - "+author.find( obj => obj.id === currentCoachingSessions.author ).id
                                    }}
                                    styles={{
                                        option: ( base ) => ( {
                                            ...base,
                                            color: "black"
                                        } ),
                                    }}
                                    options={
                                        author.map( ( obj ) => {
                                            return (
                                                { value: obj.userId, label: obj.first_name + " " + obj.last_name + " - " + obj.userId }
                                            )
                                        } )
                                    }
                                />

                            </div>
                        </Col>
                        </Row>
                         <Row>
                         <Col md={6}>
                            <div className={clsx( 'form-group' )}>
                                <label>Title</label>
                                <input
                                    type="text"
                                    className={clsx( 'form-control', 'setting-input' )}
                                    name="name"
                                    value={currentCoachingSessions.name}
                                    placeholder="Title"
                                    onChange={handleChange}
                                    disabled={isProcessing}
                                />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={clsx( 'form-group' )}>
                                <label>Visibility</label>
                                <select className={clsx( "form-select", 'form-control' )} name="visibility" onChange={handleChange} disabled={isProcessing}>
                                    <option value="open" name="visibility" selected={currentCoachingSessions.visibility ==='open'}>open </option>
                                    <option value="private" name="visibility" selected={currentCoachingSessions.visibility === 'private'}>private</option>
                                </select>
                            </div>
                            {/* <div className={clsx( 'form-group' )}>
                                <label>Visibility</label>
                                <input
                                    type="text"
                                    className={clsx( 'form-control', 'setting-input' )}
                                    name="visibility"
                                    value={currentCoachingSessions.visibility}
                                    placeholder="Visibility"
                                    disabled
                                />
                            </div> */}
                        </Col>
                        </Row>
                    <Row>
                        <Col md={4}>
                            <div className={clsx( 'form-group' )}>
                                <label>Status</label>
                                <select className={clsx( "form-select", 'form-control' )} name="status" onChange={handleChange} disabled={isProcessing}>
                                    <option value="enabled" name="status" selected={currentCoachingSessions.status === 'enabled'}>enabled</option>
                                    <option value="disabled" name="status" selected={currentCoachingSessions.status === 'disabled'}>disabled</option>
                                </select>
                            </div>
                            {/* <div className={clsx( 'form-group' )}>
                                <label>Status</label>
                                <input
                                    type="text"
                                    className={clsx( 'form-control', 'setting-input' )}
                                    name="status"
                                    value={currentCoachingSessions.status}
                                    placeholder="Status"
                                    disabled
                                />
                            </div> */}
                        </Col>
                        <Col md={4}>
                            <div className={clsx( 'form-group' )}>
                                <label>Day</label>
                                <input
                                    type="text"
                                    className={clsx( 'form-control', 'setting-input' )}
                                    name="day"
                                    value={currentCoachingSessions.day}
                                    placeholder="Day"
                                    onChange={handleChange}
                                    disabled={isProcessing}
                                />
                            </div>
                        </Col>

                        <Col md={4}>
                            <div className={clsx( 'form-group' )}>
                                <label>Category</label>
                                <input
                                    type="text"
                                    className={clsx( 'form-control', 'setting-input' )}
                                    name="category"
                                    value={currentCoachingSessions.category}
                                    placeholder="Category"
                                    disabled
                                />
                            </div>
                        </Col>

                    </Row>

                            <div className={clsx( 'form-group' )}>
                                <label>Session URL</label>
                                <input
                                    type="text"
                                    className={clsx( 'form-control', 'setting-input' )}
                                    name="session_embed"
                                    value={currentCoachingSessions.session_embed}
                                    onChange={handleChange}
                                    placeholder="Session URL"
                                    disabled={isProcessing}
                                />
                            </div> 
                            <div className={clsx( 'form-group' )}>
                                <label>Long Description</label>
                                 <textarea
                                    type="text"
                                    rows='3'
                                    className={clsx( 'form-control', 'setting-input' )}
                                    name="long_description"
                                    value={currentCoachingSessions.long_description}
                                    placeholder="Long Description"
                                    onChange={handleChange}
                                    disabled={isProcessing}
                                />
                            </div>
                            <div className={clsx( 'form-group' )}>
                                <label>Short Description</label>
                                <textarea
                                    rows='2'
                                    type="text"
                                    className={clsx( 'form-control', 'setting-input' )}
                                    name="short_description"
                                    value={currentCoachingSessions.short_description}
                                    placeholder="Short Description"
                                    onChange={handleChange}
                                    disabled={isProcessing}
                                />
                            </div>
                            
                            <div className={clsx( 'form-group' )}>
                                <label>Thumbnail</label>
                                <input
                                    type="text"
                                    className={clsx( 'form-control', 'setting-input' )}
                                    name="thumbnail"
                                    value={currentCoachingSessions.thumbnail}
                                    placeholder="Thumbnail"
                                    disabled
                                />
                            </div>
                    <div className="card-body d-flex justify-content-center">
                        <div className={clsx( 'form-group' )}>
                            <div className="card-body d-flex justify-content-center">
                                <div className={clsx( 'form-group' )}>                                        
                                    <img src={img} style={{ maxHeight: "250px", maxWidth: "250px", border: "3px solid", borderColor: "#B6B5B5", borderRadius: "10px", padding: "15px" }} />                                   
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className={clsx( 'x-btn', 'btn', 'x-secondary' )} 
                                        onClick={() => {
                                            setShowUploadImageModel( true )
                                        }}
                                        disabled={isProcessing}>
                                            
                                    <img src={upload_personalize} />
                                    <span className="ml-2 " >Upload Thumbnail</span>
                                </button>
                            </div>

                            <br />

                        </div>
                    </div>
                    <div>
                        <Modal show={showUploadImageModal} onHide={() => { setShowUploadImageModel( true ) }}>
                            <ModalHeader>
                                <div className='w-100'>
                                    <label>Upload Thumbnail</label>
                                    <button
                                        className="x-secondary"
                                        style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                                        onClick={toggleUploadImageModal}
                                    >X</button>
                                </div>
                            </ModalHeader>
                            <SimpleDropZone
                                onUpload={onSubmitUploadImageModal}
                                completedCount={completedCount.length}
                                acceptTypes="image/jpg, image/gif, image/bmp, image/jpeg, image/tiff, image/png, .heic"
                                message="Click Here To Select Thumbnail From Your Device"
                                inputType="Image"
                                maxFiles={1}
                            />
                        </Modal>
                    </div>
                            
                         
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        disabled={ ""}
                        onClick={handleClose}
                    >
                        Close
                    </button>
                     <button
                        className="btn x-btn x-primary "
                        type="button"
                        onClick={handleSubmit}
                     >
                        {isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Update</span>}
                     </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateCoachingSessions
import { PayPalButton } from "react-paypal-button-v2";
import React from 'react';
export function PayPalBtn( props ) {
    const { currency, createSubscription, onApprove, catchError, onError, onCancel , onClick,} = props;

    return (
        <PayPalButton
            currency={currency}
            createSubscription={( data, details ) => createSubscription( data, details )}
            onApprove={( data, details ) => onApprove( data, details )}
            onError={( err ) => onError( err )}
            catchError={( err ) => catchError( err )}
            onCancel={( err ) => onCancel( err )}
            options={{
                clientId: process.env.LIVE_PAYPAL_CLIENT_ID,
                vault: true
            }}
            style={{
                shape: 'rect',
                color: 'gold',
                layout: 'horizontal',
                label: 'subscribe',
                height: 55
            }}
           onClick={() => onClick()}
        />
    );
}
export default PayPalBtn;
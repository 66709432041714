
import * as React from 'react';
import { connect } from "react-redux";

import { API } from '../../APIAndConfig';

// import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FiHeart } from "react-icons/fi";
import {
    faHeart
} from "@fortawesome/free-solid-svg-icons";
class PlayCoachingSessions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            videoDetails: {},
            liked: true,
            currentIndex: 0,
            likedSessions: []
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        let data = [ ...this.state.likedSessions ]

        if(this.state.liked) {
            data.push(this.state.videoDetails.id)
        }
        else {
            data.splice( data.findIndex(obj => obj.id === this.state.videoDetails.id), 1 )
        }

        this.setState((prevState) => ({
            liked: !prevState.liked,
            likedSessions : data 
        }));
        this.updateLike()
    }

    async updateLike() {
        let payload = {
            userId: this.props.auth.id,
            sessionId: this.props.location.state.session_object.id,
            sessionType: "coaching_pearl"
        }
        if (this.state.liked) {
            await API.likeSession(payload, "like").then((res) => {})
        } else {
            if (!this.state.liked) {
                await API.likeSession(payload, "dislike").then((res) => {})
            }
        }
    }
    async getAllLikes() {
        await API.getlikeSession()
            .then((res) => {
                if (res.payload.data) {
                    for (let i = 0; i < res.payload.data.length; i++) {
                        if (res.payload.data[i].userId === this.props.auth.id && res.payload.data[i].sessionId === this.props.location.state.session_object.id) {
                            this.setState({ liked: false })
                        }
                    }
                }
            })
    }
    componentDidMount() {
        if (!this.props.history.location.state || !this.props.history.location.state.session_object) {
            this.props.history.push('/coaching_pearls')   
        }
        else {
            this.getAllLikes();
            if (this.props.history.location.state) {
                console.log(this.props.history.location.state);
                this.setState({
                    videoDetails: this.props.history.location.state.session_object,
                    currentIndex: this.props.history.location.state.data.findIndex(obj => obj.id === this.props.history.location.state.session_object.id),
                    likedSessions: [...this.props.history.location.state.likedSessions]
                })
                
            }
        }
    }

    onNext() {
        let data = [ ...this.props.history.location.state.data ]
        let index = data.findIndex(obj => obj.id === this.state.videoDetails.id) >= 0 ? data.findIndex(obj => obj.id === this.state.videoDetails.id) : 0;  
        this.setState({
            videoDetails: data.filter((obj, i) => i === index-1)[0],
            currentIndex: index-1,
            liked: !this.props.history.location.state.likedSessions.includes(Number( data.filter((obj, i) => i === index-1)[0].id )),
        })
        this.props.history.push({
            pathname: "/play_coaching_session", 
            state: { 
                session_object: data.filter((obj, i) => i === index-1)[0], 
                likedSessions: [ ...this.state.likedSessions ],
                data: data,
            }
        })
    }

    onPrevious() {
        let data = [ ...this.props.history.location.state.data ]
        let index = data.findIndex(obj => obj.id === this.state.videoDetails.id) < data.length ? data.findIndex(obj => obj.id === this.state.videoDetails.id) : data.length-1; 
        this.setState((prevState) => ({
            videoDetails: data.filter((obj, i) => i === index+1)[0],
            liked: !this.props.history.location.state.likedSessions.includes(Number( data.filter((obj, i) => i === index+1)[0].id )),
            currentIndex: index+1
        }))
        this.props.history.push({
            pathname: "/play_coaching_session", 
            state: { 
                session_object: data.filter((obj, i) => i === index+1)[0], 
                likedSessions: [ ...this.state.likedSessions ],
                data: data,
            }
        })
    }

    render() {
        return (
            <>
                <div className="container">
                    <div className={'wellness-session__header'}>
                        <h2 id="header-session" className='wellness-session__header-title main-title'>
                            {this.state.videoDetails.name}
                        </h2>
                    </div>
                    <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
                        <iframe
                            src={this.state.videoDetails.session_embed}
                            frameborder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowfullscreen
                            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                            title="State of Gratitude by Anita Green">
                        </iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                    <div className="d-flex justify-content-between mt-4 mb-md-0 mb-4">
                        <button 
                            className="btn x-secondary text-white"
                            disabled={this.state.currentIndex >= this.props.history.location.state?.data.length - 1}
                            onClick={this.onPrevious.bind(this)}
                        >PREVIOUS</button>
                        <button 
                            className="btn x-secondary text-white"
                            disabled={this.state.currentIndex <= 0}
                            onClick={this.onNext.bind(this)}
                        >NEXT</button>
                    </div>
                    <div className='container wellness-session__author'>
                        <div className='wellness-session__author-text'>
                            <h4 className='wellness-session__author-title main-title main-title-3'>Day {this.state.videoDetails.day} : {this.state.videoDetails.authorName}
                                {
                                    this.state.liked ?
                                        <FiHeart
                                            className="favorite_button_hollow svg-inline--fa ml-3"
                                            color="white"
                                            onClick={this.handleClick}
                                        /> :
                                        <FontAwesomeIcon
                                            icon={faHeart}
                                            className="favorite_button far fa-heart hidden ml-3"
                                            color="red"
                                            onClick={this.handleClick}
                                        />
                                }
                            </h4>
                            <p className='wellness-session__author-description p-text' style={{ fontSize: '18px' }} >{this.state.videoDetails.long_description}</p>
                        </div>
                    </div>
                </div>

            </>
        );

    };


};


function mapStateToProps(state) {
    return {
        auth: state.auth,
    };


}

export default connect(mapStateToProps)(PlayCoachingSessions);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Firebase } from '../../firebase';

import Loader from '../common/Loader';

import '../../scss/UpgradeAccount.css';
import { faBars, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../APIAndConfig";
import { getStripe } from "../../stripe";
import { get } from "lodash";

class UpgradeAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            token: null,
            processing: false,
            vipDiscount: false
        };
    }

    componentWillMount() {
        const stripeSession = new URLSearchParams(this.props.location.search).get('stripe');
    }

    componentDidMount() {
        this.props.resetError();
        Firebase.Auth.onAuthStateChanged(async authUser => {
            if (authUser) {
                if (this.props.auth) {
                    const token = await authUser.getIdToken();
                    this.state.authToken = token;
                }
            } else {
                this.props.history.push('/login');
            }
        });
    }

    componentDidUpdate() {
        if (this.props.auth) {
        }
    }

    componentWillUnmount() {
        this.props.resetError();
    }

    onOpenNav(e) {
        document.getElementById('sidebar').classList.add('sidebarOpen');
    }

    //    onProModalClose(e) {
    //       e.preventDefault();
    //       const modal = document.getElementById('proModal');
    //       modal.style.display = 'none';
    //    }

    //    onProModalOpen(e) {
    //       e.preventDefault();
    //       const modal = document.getElementById('proModal');
    //       modal.style.display = 'block';
    //    }

    enableSpinner() {
        let spinner = document.getElementById('pp-upgrade-account-spinner');
        if (spinner) {
            spinner.parentNode.style.display = 'block';
            spinner.classList.add('spinner-enable');
        }
    }

    async onSubscribeSuccess(transactionTypeId) {
        if (this.state.token) {
            await this.props.fetchUser(this.state.token);
        }

        let statusElem = document.getElementById('subscriptionSuccess');
        if (statusElem) {
            statusElem.style.display = 'block';
        }

        let spinner = document.getElementById('pp-upgrade-account-spinner');
        if (spinner) {
            spinner.parentNode.style.display = 'none';
            spinner.classList.remove('spinner-enable');
        }

        // if (transactionTypeId && transactionTypeId === 10) {
        //    let elementPro = document.getElementById('pp-upgrade-pro');
        //    if (elementPro) {
        //       elementPro.style.display = 'none';
        //    }
        // } else if (transactionTypeId && transactionTypeId === 11) {
        //    let elementSignature = document.getElementById(
        //       'pp-upgrade-pro-signature'
        //    );
        //    if (elementSignature) {
        //       elementSignature.style.display = 'none';
        //    }
        // }
    }

    renderSubscriptionSuccess() {
        return (
            <div
                id="subscriptionSuccess"
                className="alert alert-success m-4 p-4"
                style={{ display: 'none' }}
            >
                <a
                    href="#"
                    className="close"
                    data-dismiss="alert"
                    aria-label="close"
                >
                    &times;
            </a>
                <strong>Thank you for subscribing!</strong> You have successfully
            subscribed to Positive Prime. You can cancel at anytime in the
            Settings page.
            </div>
        );
    }

    renderProYearlyArea() {
        let variant = "info";

        if (!this.props.auth) {
            return;
        }

        let textClass = "text-info";
        let btnClass = "btn-info";
        let bgClass = "bg-info";

        let buttonText = 'Subscribe';
        if (this.props.auth.role.id === 5) {
            buttonText = 'Go Premium';
        }

        let style = { opacity: 1.0 };

        if (this.props.auth.role.id === 3) {
            style = {
                boxShadow: "0 0 1em #3498DB !important"
            }
            buttonText = null;
        }

        let $elementYearly = (
            <button
                id="pp-upgrade-pro-yearly-btn"
                className={"btn btn-lg btn-block " + btnClass}
                onClick={this.handleProYearly.bind(this)}
            >
                {buttonText + " for $99.00/yr"}
            </button>
        );

        let $elementMonthly = (
            <button
                id="pp-upgrade-pro-btn"
                className="btn btn-lg btn-block btn-secondary"
                onClick={this.handleProMonthly.bind(this)}
            >
                {buttonText + " for $9.99/mo"}
            </button>
        );


        if (this.props.auth.role.id === 3 || this.props.auth.role.id === 5 || this.props.auth.role.id === 6) {
            $elementYearly = "";
        }

        if (this.props.auth.isSubscribed_membership_type === 'pro_yearly' ||
            this.props.auth.isSubscribed_membership_type === 'vip_yearly') {
            $elementMonthly = ""
        }

        if (this.props.auth.role.id === 3) {
            $elementYearly = (
                <div>
                    <p className={textClass + " font-weight-bold fontLarge text-center"}>
                        This subscription is active
               </p>
                    <p className="text-muted text-center">
                        You can cancel your subscription in the Settings Page
               </p>
                </div>
            );
            $elementMonthly = ""
        }


        return (
            <OptionCard id="pp-upgrade-pro" variant={variant} name="Premium Membership" price="99.00" frequency="yr" button={$elementYearly} altPrice="9.99" altFrequency="mo" altButton={$elementMonthly}>
                <PerkHeader>Credits - Build Your Library</PerkHeader>
                <Perk>Automatically receive enough credits every month to claim 2 new sessions of your choice from 100s of title <PerkValue>$15/ea or $360/yr</PerkValue></Perk>

                <PerkHeader>Rewards and Recognition</PerkHeader>
                <Perk>Earn credits by viewing & sharing</Perk>

                <PerkHeader>Exponential Dream Board</PerkHeader>
                <Perk>Ability to personalise individual Sessions by uploading & integrating your own photos from a private collection you create.</Perk>
                <Perk>Editing each program transforms these Sessions into powerful exercises for your Vision and Gratitude practice</Perk>
                <Perk>Priceless</Perk>
            </OptionCard>
        );
    }

    renderVIPProArea() {
        let variant = this.state.vipDiscount ? "danger" : "primary";

        // if (!this.props.auth || this.props.auth.role.id === 3) {
        //    return;
        // }

        if (!this.props.auth) {
            return;
        }

        let buttonText = 'Become a VIP!';

        let style = { opacity: 1.0 };
        let $element = (
            <button
                id="pp-upgrade-pro-btn"
                className={"btn btn-lg btn-block btn-" + variant}
                onClick={this.handleVIP.bind(this)}
            >
                {buttonText}
            </button>
        );

        if (this.props.auth.role.id === 5) {
            style = { opacity: 0.5 };
            $element = (
                <div>
                    <p className="text-success fontBold text-center">
                        You are already have an active yearly membership
                    </p>
                </div>
            );
        }

        if (this.props.auth.isSubscribed_membership_type === 'vip_yearly') {
            style = { opacity: 0.5 };
            $element = (
                <div>
                    <p className="text-success fontBold text-center">
                        This subscription is active
                    </p>
                    <p className="text-secondary text-center">
                        You can cancel your subscription in the Settings Page
                    </p>
                </div>
            );
        }

        const price = this.state.vipDiscount ? "159.00" : "359.00";

        const $bonusLink = this.state.vipDiscount ? null : <p className="mt-5">
            <a href="#" onClick={this.handleDiscount.bind(this)}>Click here if you have a discount code</a>
        </p>

        return (
            <OptionCard id="pp-upgrade-pro" name="VIP Concierge" variant={variant} price={price} frequency="yr" includes="Signature Program" button={$element}>

                <PerkHeader>Exclusive Connection</PerkHeader>
                <Perk>One hour consultation via phone or video conferencing with <b>Kim Serafini</b></Perk>

                <PerkHeader>Concierge Service</PerkHeader>
                <Perk>Once a year, on your behalf, we collect & curate images on your behalf (your bucket list
                experiences for example) and we start your Private Collection by uploading personal images
                        for you.</Perk>
                <Perk>This is especially important when you’re busy & need some tech-assistance.</Perk>
                <Perk>Allow us to do the time-consuming job of gathering & saving a range of images as we give
                        you the “done-for-you” treatment</Perk>


                {$bonusLink}
            </OptionCard>
        );
    }

    async handleCheckout(plan) {
        this.setState({ processing: true })
        const result = await API.startCheckout(plan);
        const stripe = await getStripe();

        const sessionId = get(result, 'payload.checkout.id');
        const { error } = await stripe.redirectToCheckout({
            sessionId,
        });
    }

    async handleSignatureMonthly() {
        return await this.handleCheckout("signature-monthly")
    }
    async handleSignatureYearly() {
        return await this.handleCheckout("signature-yearly")
    }
    async handleProMonthly() {
        return await this.handleCheckout("pro-monthly")
    }
    async handleProYearly() {
        return await this.handleCheckout("pro-yearly")
    }
    async handleVIP() {
        if (this.state.vipDiscount)
            return await this.handleCheckout("vip-discount")
        else
            return await this.handleCheckout("vip-yearly")
    }
    renderProSignatureArea() {
        // if (!this.props.auth || this.props.auth.role.id === 5) {
        //    return;
        // }

        let variant = "success";

        if (!this.props.auth) {
            return;
        }
        let buttonText = 'Subscribe ';
        if (this.props.auth.role.id === 3) {
            buttonText = 'Upgrade from Premium!';
        }

        let style = { opacity: 1.0 };
        let $elementYearly = (
            <button
                id="pp-upgrade-pro-signature-yearly-btn"
                className={`btn btn-lg btn-block btn-${variant}`}
                onClick={this.handleSignatureYearly.bind(this)}
            >
                {buttonText + " for $199.99/yr"}
            </button>
        );
        let $elementMonthly = (
            <button
                id="pp-upgrade-pro-signature-btn"
                className={`btn btn-lg btn-block btn-${variant}`}
                onClick={this.handleSignatureMonthly.bind(this)}
            >
                {buttonText + " for $19.99/mo"}
            </button>
        );
        if (this.props.auth.role.id === 5) {
            style = { opacity: 0.5 };
            $elementMonthly = (
                <div>
                    <p className="text-success fontBold text-center">
                        This subscription is active
               </p>
                    <p className="text-secondary text-center">
                        You can cancel your subscription in the Settings Page
               </p>
                </div>
            );
            $elementYearly = "";
        }

        if (this.props.auth.isSubscribed_membership_type === 'pro_yearly' ||
            this.props.auth.isSubscribed_membership_type === 'vip_yearly') {
            style = { opacity: 0.5 };
            $elementMonthly = (
                <div>
                    <p className="text-success fontBold text-center">
                        You are already have an active yearly membership
               </p>
                </div>
            );
            $elementYearly = "";
        }

        return (
            <OptionCard id="pp-upgrade-pro-signature" name="Signature Program" variant={variant} includes="Premium Membership" price="199.99" frequency="yr" button={$elementYearly} altPrice="19.99" altFrequency="mo" altButton={$elementMonthly}>

                <PerkHeader>Exclusive Extras</PerkHeader>
                <Perk>
                    Get a themed SuperMix added to your library every month
                  <PerkValue>$210/mo or $2,520/yr</PerkValue>
                </Perk>

                <PerkHeader>Support & World-Class Education & Guidance</PerkHeader>
                <Perk>
                    Group mentoring, masterclasses each month with Kim Serafini
                    & regular guest seminars by world-class
                    experts
              </Perk>

                <Perk>
                    Daily micro-lesson via email sharing wisdom & inspiration.
                    We have collated the very best hints, tips, suggestions, instructions & transformational ideas from the world’s most provocative “gururs” & experts who are leaders in scientifically validated suggestions or recommendations.
                    We translate them into small daily lessons for you to read from your inbox.
                  <PerkValue>$182</PerkValue>
                </Perk>

                <Perk>100s of exceptional resources exclusively to support your transformation</Perk>

                <Perk>
                    Bonus Session Support E-Guides: Receive over 20,000 pages of motivating, inspiring, empowering Flash Cards
                  <PerkValue>$240</PerkValue>
                </Perk>

                <PerkHeader>Play Together, Stay Together - Accountability Partnerships</PerkHeader>
                <Perk>Family & Friends Sharing
                Add up to 4 other people to your master account & they can enjoy the software with
                  their own private accounts. </Perk>
                <Perk>Gift your children or siblings this fantastic tool.</Perk>


            </OptionCard>
        );
    }

    handleDiscount(e) {
        e.preventDefault();

        const code = window.prompt('Please enter your discount code').toLowerCase().trim();
        if ("goldstar|friend|opportunity|million|jackie|secret".split("|").indexOf(code) >= 0) {
            this.setState({ vipDiscount: true })
            window.alert('Discount applied');
        } else {
            window.alert('Invalid code');
        }

    }
    render() {
        if (this.state.processing)
            return <Loader />
        return (
            <div className="mainWidth">
                <button
                    className="btn d-md-none"
                    onClick={this.onOpenNav.bind(this)}
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <p className="text-secondary m-0 p-3">Upgrade Account</p>
                <hr className="m-0" />
                {this.renderSubscriptionSuccess()}
                <div className="spinner-overlay">
                    <Loader id="pp-upgrade-account-spinner" />
                </div>
                <div className="card-deck m-5 justify-content-center">
                    {/* {this.renderVIPProArea()} */}
                    {this.renderProSignatureArea()}
                    {/* {this.renderProYearlyArea()} */}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return { auth: state.auth };
}

export default connect(
    mapStateToProps,
    actions
)(UpgradeAccount);

function PerkHeader(props) {
    return (
        <div className="d-flex mt-2">
            <p>{props.children}</p>
        </div>
    )
}
function Perk(props) {
    return (
        <div className="d-flex">
            <p className="mr-3"><FontAwesomeIcon icon={faCheck} /></p>
            <p className="text-dark">{props.children}</p>
        </div>
    )
}
function PerkValue(props) {
    return (
        <small className="text-muted"> &mdash; Value of {props.children}</small>
    )
}

function OptionCard(props) {

    let $price = (
        <div className="d-flex justify-content-center">
            <p className="text-white fontLarge fontBold m-0">$</p>
            <p className="text-white fontXLarge fontBold m-0">{props.price}</p>
            <p className="text-white fontLarge fontBold m-0 align-self-end">
                / {props.frequency}
            </p>
        </div>
    );

    let $header = (
        <div className={"card-header p-5 bg-" + props.variant}>
            <p className="text-white text-center fontLarge mb-5">{props.name}</p>
            {$price}
        </div>
    )

    if (props.altPrice) {
        $header = (
            <div className={"card-header pt-5 px-5 pb-4 bg-" + props.variant}>
                <p className="text-white text-center fontLarge mb-5">{props.name}</p>
                {$price}
                <div className="d-flex justify-content-center mt-1">
                    <p className="text-white fontSmall fontBold m-0">$</p>
                    <p className="text-white fontRegular fontBold m-0">{props.altPrice}</p>
                    <p className="text-white fontSmall fontBold m-0 align-self-end">
                        / {props.altFrequency}
                    </p>
                </div>
            </div>
        )
    }


    let $intro = null;

    let $altButton = null;
    if (props.altButton)
        $altButton = (
            <div className="mb-2">{props.altButton}</div>
        );

    if (props.includes)
        $intro = (
            <p className="text-secondary text-center">Membership includes ALL of the <br /> <span
                className="text-dark font-weight-bold">{props.includes}</span> benefits & advantage, <br /> plus
                ALSO INCLUDES:</p>
        )
    else
        $intro = (
            <p className="text-secondary text-center">Membership includes:</p>
        )

    return (
        <div className="card" style={{ maxWidth: '575px' }} id={props.id}>
            {$header}
            <div className={"card-body py-3 px-5 text-" + props.variant}>
                {$intro}
                {props.children}
            </div>
            <div className="card-footer p-3">
                {$altButton}
                {props.button}
            </div>
        </div>
    )
}

import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { API } from "../../APIAndConfig";
import { Firebase } from "../../firebase";

const DeleteAccountButton = ({ className }) => {
  const [showModal, setShowModal] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleDeleteAccount = async () => {
    try {
      setDeleting(true);
      setShowErrorMessage(false);

      await new Promise((res) => setTimeout(res, 2000));

      await API.deleteUser();

      setDeleting(false);

      await Firebase.Auth.signOut();
    } catch (error) {
      setShowErrorMessage(true);
      setDeleting(false);
    }
  };

  const handleToggleModalVisibility = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <Modal show={showModal} onHide={handleToggleModalVisibility}>
        <Modal.Header closeButton>
          <Modal.Title>Delete confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please note, deleting your account doesn't stop your subscription.
          </p>
          <p>
            If you would like to cancel your trial or subscription you will need
            to alert us at &nbsp;
            <a href="mailto:support@positiveprime.com">
              support@positiveprime.com
            </a>
          </p>
          {/* <p>Are you sure you want to delete this account?</p>
          {showErrorMessage && (
            <p className="text-danger">
              Something went wrong! Try again later.
            </p>
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={deleting}
            variant="secondary"
            onClick={handleToggleModalVisibility}
          >
            Close
          </Button>
          {/* <Button
            disabled={deleting}
            variant="danger"
            onClick={handleDeleteAccount}
          >
            {deleting ? "Deleting..." : "Delete"}
          </Button> */}
        </Modal.Footer>
      </Modal>

      <button
        className={className}
        type="button"
        onClick={handleToggleModalVisibility}
      >
        Delete account
      </button>
    </>
  );
};

export default DeleteAccountButton;

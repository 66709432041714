import React, { Component } from "react";
import { BsCheckCircle } from "react-icons/bs";
import "./MyStyle.css";
import { cookies } from "../../index";
import { set, get } from "lodash";
import { Firebase } from "../../firebase";
import { API, APIAxios, Config } from "../../APIAndConfig";
import validator from "validator";
import CheckoutForm from "./CheckoutForm";
import { Spinner, Alert, Form } from "react-bootstrap";
import publicIp from "public-ip";
import moment from "moment";
import axios from "axios"
import ReactPixel from 'react-facebook-pixel';
var CryptoJS = require("crypto-js");

class InviteSignup extends Component {
    constructor() {
        super();
        const referralCookie = cookies.get("referralCode");
        this.reference = React.createRef();
        this.state = {
            name: "React",
            login: false,
            signUp: true,
            isProcessing: false,
            referralCode: '',
            referralCodeCookie: referralCookie,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            setEmailErrorMessage: "",
            setFirstNameErrorMessage: "",
            setLastNameErrorMessage: "",
            setPasswordErrorMessage: "",
            token: null,
            signupStatus: false,
            checkRefferal: false,
            emailFeedback: null,
            emailIsValid: null,
            passwordFeedback: null,
            passwordIsValid: null,
            otherError: null,
            chkbox: false,
            inviteData: {},
            renderCond: 1,
            infusionsoftTags: {
                onboarding: false,
                referrer: false,
                friends: false
            }
        };
    }

    async componentDidMount() {
        ReactPixel.init(process.env.REACT_APP_FB_KEY);
        ReactPixel.track('PageView', {
            eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
        });
        if (Firebase.Analytics) {
            Firebase.Analytics.logEvent("page_view");
        }
        if (window.location.search.substr(1).split("=")[0] === 'code') {
            if (CryptoJS.AES && typeof window.location.search.substr(1).split("=")[1] !== 'undefined') {
                let data = CryptoJS.AES.decrypt(window.location.search.substr(1).split("=")[1], process.env.REACT_APP_MAIL_UUID).toString(CryptoJS.enc.Utf8);
                console.log("till here");
                try {
                    data = JSON.parse(data);
                    console.log();
                    this.setState({
                        inviteData: data,
                        renderCond: moment().diff(data.createTimestamp, 'days') > 14 || data.status === 'expired' ? 3 : 2,
                        email: data.email,
                        referralCode: data.referralCode
                    })
                } catch (error) {
                    console.log("error in json parse : ", error);
                    this.setState({
                        renderCond: 1
                    })
                }
                console.log(data);
            }
        }
    }
    handleChange(event) {
        const state = {};
        set(state, event.target.name, event.target.value);
        this.setState(state);
        switch (event.target.name) {
            case "cardNumber":
                this.validateCreditCard(event.target.value);
                console.log("CardNumber");
                break;
            case "firstName":
                this.validateFirstName(event.target.value);
                console.log("firstName");
                break;
            case "lastName":
                this.validateLastName(event.target.value);
                console.log("lastName");
                break;
            case "email":
                this.validateEmail(event.target.value);
                console.log("email");
                break;
            case "password":
                this.validatePassword(event.target.value);
                console.log("password");
                break;
            case "date":
                this.validateDate(event.target.value);
                console.log("password");
                break;
            case "cvv":
                this.validateCvv(event.target.value);
                console.log("password");
                break;
            default:
                break;
        }
        // if (event.target.name == "cardNumber") {
        //   this.validateCreditCard(event.target.value);
        //   console.log("CardNumber");
        // }
    }

    validateFirstName = (value) => {
        let state = {};
        if (validator.isEmpty(value)) {
            set(state, "setFirstNameErrorMessage", "Enter First Name!");
            this.setState(state);
            console.log(value + " " + "Else");
        } else {
            set(state, "setFirstNameErrorMessage", "");
            this.setState(state);
        }
    };

    validateLastName = (value) => {
        let state = {};
        if (validator.isEmpty(value)) {
            set(state, "setLastNameErrorMessage", "Enter Last Name!");
            this.setState(state);
            console.log(value + " " + "Else");
        } else {
            set(state, "setLastNameErrorMessage", "");
            this.setState(state);
        }
    };

    validateEmail = (value) => {
        let state = {};
        console.log(value + " " + validator.isEmail(value));
        //console.log("Hello Email")
        if (validator.isEmpty(value)) {
            set(state, "setEmailErrorMessage", "Enter Email");
            this.setState(state);
            console.log(value + " " + "Else");
        } else if (!validator.isEmail(value)) {
            set(state, "setEmailErrorMessage", "Enter Valid Email");
            this.setState(state);
            console.log(value + " " + "Else");
        } else {
            set(state, "setEmailErrorMessage", "");
            this.setState(state);
        }
    };

    validatePassword = (value) => {
        let state = {};
        let totalChar = 0;
        if (validator.isEmpty(value)) {
            set(state, "setPasswordErrorMessage", "Enter Password!");
            this.setState(state);
        } else if (
            value.length < 6
        ) {
            set(state, "setPasswordErrorMessage", "Enter a Valid Password");
            this.setState(state);
        } else {
            set(state, "setPasswordErrorMessage", "");
            this.setState(state);
        }
    };

    hideComponent(name) {
        switch (name) {
            case "login":
                console.log("login");
                this.setState({
                    login: false, //!this.state.login
                    signUp: true,
                });
                break;
            case "signUp":
                console.log("signUp");
                this.setState({
                    login: true, //!this.state.login
                    signUp: false,
                });
                break;
            default:
                break;
        }
    }

    render() {
        const { signUp } = this.state;
        console.log(document.location.search.substr(1).split('=')[0] === "code");

        return (
            <>
                {
                    this.state.renderCond === 1 ? <></> :
                        <>
                            <nav className="navbar navbar-custom  navbar-expand navbar-dark flex-column flex-md-row bd-navbar">
                                <a className="navbar-brand" href="#">
                                    <img
                                        src="/assets/images/Logo1.0.png"
                                        height="30"
                                        className="d-inline-block align-top"
                                        alt=""
                                    />
                                </a>
                            </nav>
                            {
                                this.state.renderCond === 2 ?
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <div className="col-12">
                                            <div className="row justify-content-center">
                                                <div className="col-md-5 p-0">
                                                    <h2 className="title p-4">Create account</h2>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card shadow col-md-5 pt-3">
                                            {this.signup()}
                                        </div>
                                    </div> :
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                        <div className="card shadow col-md-5 pt-3 align-items-center" style={{ height: "100px" }}>
                                            {this.renderExpire()}
                                        </div>
                                    </div>
                            }
                        </>
                }
            </>
        );
    }

    signup() {
        return (
            <div>
                <form className="card-body">
                    <div className="row justify-content-between">
                        <div className="col-6 form-group">
                            <input
                                className={"form-control myInput " + this.getClassName(this.state.firstNameIsValid)}
                                name="firstName"
                                label="First Name"
                                placeholder="Jane"
                                value={this.state.firstName}
                                onChange={this.handleChange.bind(this)}
                                feedback={this.state.firstNameFeedback}
                                disabled={this.state.isProcessing}
                            />
                            <span className="errorMessage">
                                {this.state.firstNameFeedback}
                                {this.state.setFirstNameErrorMessage}
                            </span>
                        </div>

                        <div className="col-6 form-group">
                            <input
                                className={"form-control myInput " + this.getClassName(this.state.lastNameIsValid)}
                                name="lastName"
                                label="Last Name"
                                placeholder="Smith"
                                value={this.state.lastName}
                                onChange={this.handleChange.bind(this)}
                                feedback={this.state.lastNameFeedback}
                                disabled={this.state.isProcessing}
                            />
                            <span className="errorMessage">
                                {this.state.lastNameFeedback}
                                {this.state.setLastNameErrorMessage}
                            </span>
                        </div>
                    </div>

                    <div className="form-group">
                        <input
                            className={"form-control myInput " + this.getClassName(this.state.emailIsValid)}
                            name="email"
                            type="email"
                            label="Your email address"
                            placeholder="jane.smith@example.com"
                            value={this.state.email}
                            onChange={this.handleChange.bind(this)}
                            feedback={this.state.emailFeedback}
                            disabled
                        />
                    </div>
                    <div className="form-group">
                        <input
                            className={"form-control myInput " + this.getClassName(this.state.passwordIsValid)}
                            name="password"
                            type="password"
                            label="Password"
                            placeholder="Enter your password"
                            value={this.state.password}
                            onChange={this.handleChange.bind(this)}
                            feedback={this.state.passwordFeedback}
                            disabled={this.state.isProcessing}
                        />
                        <span className="errorMessage">
                            {this.state.passwordFeedback}
                            {this.state.setPasswordErrorMessage}
                        </span>
                    </div>
                    <div className="form-group">
                        <input
                            className={"form-control myInput " + this.getClassName(this.state.referralCodeIsValid)}
                            name="referralCode"
                            type="text"
                            label="Referral Code (Optional)"
                            placeholder="Referral Code"
                            value={this.state.referralCode}
                            onChange={this.handleChange.bind(this)}
                            feedback={this.state.referralCodeFeedback}
                            disabled
                        />
                    </div>

                    <div className="form-group billingText">
                        <p>
                            If you do not provide us your personal information we may not be
                            able to provide our Services to you. See our{" "}
                            <a href="/terms" target="_blank">
                                Privacy Policy
                            </a>
                            .
                        </p>
                    </div>

                    <div className="form-group">
                        <label className="container-checkbox">Yes! I want to make sure I’m always being kept informed and up to date with the latest offerings from Positive Prime via email and other electronic communications. You can unsubscribe from our communications at any time simply by contacting us.
                            <input type="checkbox" defaultChecked={this.state.chkbox} onChange={() => this.setState({ chkbox: !this.state.chkbox })} />
                            <span class="checkmark"></span>
                        </label>
                    </div>

                    <div className="form-group">
                        <button
                            type="submit"
                            className="btn btn-block  myButton shadow"
                            disabled={
                                !(
                                    !this.state.setFirstNameErrorMessage &&
                                    !this.state.setLastNameErrorMessage &&
                                    !this.state.setEmailErrorMessage &&
                                    !this.state.setPasswordErrorMessage &&
                                    this.state.firstName &&
                                    this.state.lastName &&
                                    this.state.email &&
                                    this.state.password
                                ) || this.state.isProcessing
                            }
                            onClick={this.createUser}
                        >
                            {this.state.isProcessing ? <Spinner animation="border" size="sm" /> : <span>Continue</span>}
                        </button>
                    </div>
                </form>
            </div >
        );
    }

    renderExpire() {
        return (
            <div className="card-body">
                <p>The Link you followed has expired</p>
            </div>
        )
    }

    createUser = async (e) => {
        e.preventDefault();
        await this.setState({ isProcessing: true });
        let agent = navigator.userAgent
        let Ip = await publicIp.v4({
            fallbackUrls: ["https://ifconfig.co/ip"]
        })
        let user = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            referral_code: this.state.referralCode || "epic",
            ipaddress: Ip,
            user_agent: agent,
            http_referrer: document.referrer,
            url: window.location.origin + window.location.pathname

        }
        await API.createUserAbandoned(user)
        await this.handleSubmit()
    }

    handleSubmit = async () => {
        await this.setState({ isProcessing: true });
        console.log(this.state.isProcessing);
        const params = {
            email: this.state.email || "",
            firstName: this.state.firstName || "",
            lastName: this.state.lastName || "",
            password: this.state.password || "",
            confirmPassword: this.state.password || "",
            referralCode: this.state.referralCode || "epic",
            inviteStatus: 'INVITED',
            invitingUser: this.state.inviteData.referrerFullName
        };

        ReactPixel.track('Lead', {
            content_name: 'Sign Up',
            content_category: 'Meditation > wellbeing',
            content_ids: ['1234'],
            content_type: 'product',
            value: 0.00,
            currency: 'USD',
            eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
        });

        if (Firebase.Analytics)
            Firebase.Analytics.logEvent('complete_order')

        let result = await API.createUser(params);
        if (result.successful && result.status === 201) {
            let payload = {
                email: this.state.email,
                subscriptionMembership: this.state.inviteData.subscriptionMembership,
                subscriptionType: this.state.inviteData.subscriptionType,
                role: this.state.inviteData.roleId,
                referralCode: this.state.referralCode,
                userId: result.payload.user.id,
                timestamp: this.state.inviteData.createTimestamp,
                signupId: this.state.inviteData.signupId,
                senderId: this.state.inviteData.senderId
            }
            await API.updateInviteRoles(payload)
            await Firebase.Auth.signInWithEmailAndPassword(
                this.state.email || "",
                this.state.password || ""
            );

            this.props.history.push({pathname : "/dashboard", state: {
                path : "from_login",
                infusion : true,
                referralCode : this.state.referralCode,
                emailPermissionStatus: this.state.chkbox,
                fromInvite:true
            }})
            cookies.remove("referralCode");
        } else {
            let state = {
                isProcessing: false,
                firstNameIsValid: null,
                firstNameFeedback: null,
                lastNameIsValid: null,
                lastNameFeedback: null,
                emailIsValid: null,
                emailFeedback: null,
                passwordIsValid: null,
                passwordFeedback: null,
                confirmPasswordIsValid: null,
                confirmPasswordFeedback: null,
                referralCodeIsValid: null,
                referralCodeFeedback: null,
            };

            let error;
            let errors = get(result.payload, "errors", []);
            for (error of errors) {
                set(state, `${error.param}IsValid`, false);
                set(state, `${error.param}Feedback`, error.detail);
            }
            await this.setState(state);
        }
    };

    getClassName(valid) {

        if (valid === null || valid == undefined) {
            return undefined;
        }

        return "is-" + (valid ? "valid" : "invalid");
    }

    renderError() {
        if (!this.state.otherError)
            return;

        return (
            <Alert variant="danger">
                {this.state.otherError}
            </Alert>
        )
    }
}
export default InviteSignup;

import React from 'react';
import Loader from '../common/Loader';
import './image.css';

export default props => {
   let $defaultElem = (
      <Loader
         size="large"
         style={{
            display: 'block',
            position: 'absolute',
            top: 'calc(50% - 50px)',
            left: 'calc(50% - 50px)'
         }}
      />
   );

   let percent = 0;
   if (props.progressBar) {
      percent = props.progressBar;
   }

   let $progressElem = (
      <div className="progress" style={{ borderRadius: 0 }}>
         <div
            className="progress-bar"
            role="progressbar"
            aria-valuenow={percent}
            valuemin="0"
            aria-valuemax="100"
            style={{ width: `${percent}%` }}
         />
      </div>
   );

   if (props.imageData) {
      // $defaultElem = (
      //    <img
      //       alt={props.name}
      //       src={process.env.REACT_APP_S3_URL + '/' + props.imageData.media_url}
      //    />
      // );
      $defaultElem = '';
      $progressElem = '';
   }

   return (
      <div id="mainImagePlayerImage" style={{height: "100%"}}>
         {/* <div className="image-title-detail white">
               {props.imageIndex + '. ' + props.imageData.photo_tags[0].title}
            </div> */}

         {$defaultElem}
         {$progressElem}

         <img alt={props.name} img-idx={0} />

         <img alt={props.name} img-idx={1} className="pp-img-disable" />

         <img alt={props.name} img-idx={2} className="pp-img-disable" />

         <img alt={props.name} img-idx={3} className="pp-img-disable" />

         <img alt={props.name} img-idx={4} className="pp-img-disable" />
      </div>
   );
};

import React, { useRef, useEffect } from "react";
import {Row, Col } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import PayPalBtn from "./PayPalBtn"

import CryptoJS from "crypto-js"
export default function Paypal(props) {
  
  // const [ planId, setPlanId ] = useState({
  //     plan_id: 'P-79L97173M4273220YMHTZAQA',
  //     plan: 'monthly'
  // })
  const [ planId, setPlanId ] = useState({
      plan_id: '',
      plan:''  
  })

  
  // useEffect(()=>{
  //   retrievePlanKey("Signature10 Yearly Free Trial")
  // },[])

  useEffect(()=>{
    if(props.page === "coachingsignup"){
      // console.log("Using Coaching signup");
      retrievePlanKey("Signature Coaching Recurring")
    }
    else{
      // console.log("Using SignupB");
      retrievePlanKey("Signature10 Yearly Free Trial")
    }
  },[])
 
  const paypalSubscribe = ( data, actions ) => {
   
    return actions.subscription.create( {
      'plan_id': planId.plan_id,
    } );
  };
  //console.log("checkplan=======>",planId.plan,"==",planId.plan_id)
  const paypalOnError = ( err ) => {
    console.log( "Error: ",err )
  }
  const paypalOnApprove = ( data, detail ) => {
    // call the backend api to store transaction details
    // console.log("In PaypalOnApprove");
    // console.log("Deatils in paypalOnApprove: ",detail);
    // console.log("Data in paypalOnApprove: ",data);
    let paymentBy = 'paypal'
    // console.log("Plan: ",planId.plan);
    props.paypalProps( data.orderID, data.subscriptionID, planId.plan, paymentBy);

  };
  const yearlyButton = () => {}
  const retrievePlanKey =async (plan) =>{
    if(plan){
     await axios.get(`${process.env.REACT_APP_API}/get_plan_key/${plan}`)
                
     .then(function (response) {
      // handle success
      //console.log("response",response);
      //console.log("message is here",response.data.message);
      //console.log("env=",process.env.REACT_APP_PAYPAL_PLAN_ID_SECRET_KEY);
      let bytes  =  CryptoJS.AES.decrypt(response.data.message, process.env.REACT_APP_PAYPAL_PLAN_ID_SECRET_KEY);
      let originalKey = bytes.toString(CryptoJS.enc.Utf8);
      
      // console.log("message is here",bytes,originalKey);
      setPlanId({ plan_id: originalKey, plan: plan})
      // console.log("decrypted_id=======>",planId.plan,"==",planId.plan_id)
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    })
    }
  }
 
  return (
    <div>
      
        {props.page =="coachingsignup" ? (<><Col>
          <div className="d-flex align-items-center">
            <input type="radio" id="plan" name="plan_name" className="check_radio" defaultChecked />
            <label htmlFor="plan" className='ml-2 mb-0'>Freedom Coaching Program $199 per year</label>
          
          </div>
        </Col></>) : 
              <>
                  <Row>
                  <Col className="col-8 font-weight-bold">Select Option</Col>
                  {/* <Col className="col-4 col d-flex justify-content-end font-weight-bold">Price</Col> */}
                  </Row>
                  <hr />
                  <Row>
                  <Col>
                    <div className="d-flex align-items-center">
                      <input type="radio" id="yearly_plan" name="plan_name" onClick={( e ) => retrievePlanKey("Signature10 Yearly Free Trial")
                      
                        // setPlanId( { plan_id:'P-95724698C2059125EMHTZBQY', plan: 'yearly'} )
                      } className="check_radio" defaultChecked />
                      <label htmlFor="yearly_plan" className='ml-2 mb-0'>FREE for 10 days then $199 per year</label>
                    
                    </div>

                    <div className="d-flex align-items-center mt-3">
                      
                      <input type="radio" id="monthly_plan" name="plan_name" onClick={( e ) => 
                        retrievePlanKey("Signature10 Monthly Free Trial")
                        // setPlanId({ plan_id: 'P-79L97173M4273220YMHTZAQA', plan: 'monthly'})
                        } className="check_radio" />
                      <label htmlFor="monthly_plan" className='ml-2 mb-0' >FREE for 10 days then $19 per month</label>
                      {/* <label for="plan" className='ml-auto mb-0'>FREE Trial</label> */}
                      
                    </div>

                  </Col>
                  </Row> 
              </>
        }
      

      <br />
      <PayPalBtn
        currency="USD"
        onClick ={yearlyButton}
        createSubscription={paypalSubscribe}
        onApprove={paypalOnApprove}
        catchError={paypalOnError}
        onError={paypalOnError}
        onCancel={paypalOnError}
      />
      <br />
    </div>
  );
}
import { set } from "lodash";
import moment from "moment";
import React from "react";
import { Container, Spinner, Card, Row, Col, Form } from "react-bootstrap";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { APIActions } from "../../api/APIActions";
import { API } from "../../APIAndConfig";
import { FormInput } from "../FormInput";
import { TextField } from "@material-ui/core";
import { format, parseISO } from "date-fns";
import Select from "react-select";

interface stateinterface {
  tableData: any;
  paidLedgerData: any;
  userID: any;
  description: any;
  amount: any;
  userIdList: any;
  type: any;
  errAmount: any;
  errDesp: any;
  amountMessage: any;
  descriptionMessage: any;
  payoutData: any;
  searchInput: string;
  searchInput1: string;
  pendingPayoutData: any;
  filteredData: any;
  payoutTransactionDate: string;
}

export class PayoutLedgerComponent extends React.Component<
  any,
  stateinterface
> {
  ledgerEntryFormRef: any;
  origTableData: any;
  origPaidLedgerData: any;
  origUserID: any;
  origUserIdList: any;

  constructor(props: any) {
    super(props);

    this.ledgerEntryFormRef = React.createRef();

    this.state = {
      tableData: null,
      paidLedgerData: null,
      userID: null,
      description: "",
      amount: null,
      errAmount: null,
      errDesp: null,
      userIdList: [],
      type: "Generic (default)",
      amountMessage: null,
      descriptionMessage: null,
      payoutData: null,
      searchInput: "",
      searchInput1: "",
      pendingPayoutData: [],
      filteredData: [],
      // need to follow yyyy-MM-ddTHH:mm
      payoutTransactionDate: format(new Date(), "yyyy-MM-dd HH:mm").replace(
        " ",
        "T"
      ),
    };
  }

  async componentWillMount() {
    const ledgerRecord: any = await API.getLedgerPayoutData();
    const pendingPayoutData = ledgerRecord.payload.data;
    pendingPayoutData.forEach(
      (d: { payoutdate: any }) => (d.payoutdate = moment(d.payoutdate).local())
    );
    this.setState({
      tableData: pendingPayoutData || [],
      userIdList: ledgerRecord.payload.userId || [],
      userID: ledgerRecord.payload.userId[0].id,
      paidLedgerData: ledgerRecord.payload.paidLedger || [],
    });
    this.origTableData = pendingPayoutData || [];
    this.origUserIdList = ledgerRecord.payload.userId || [];
    this.origUserID = ledgerRecord.payload.userId[0].id;
    this.origPaidLedgerData = ledgerRecord.payload.paidLedger || [];
    console.log("---------------------");
    if (this.props.selectedUserId) {
      this.setState({
        tableData:
          this.state.tableData.filter(
            (x: any) => x.id.toString() === this.props.selectedUserId
          ) || [],
        userIdList:
          this.state.userIdList.filter(
            (x: any) => x.id.toString() === this.props.selectedUserId
          ) || [],
        userID: this.state.userIdList.filter(
          (x: any) => x.id.toString() === this.props.selectedUserId
        )[0]?.id,
        paidLedgerData:
          this.state.paidLedgerData.filter(
            (x: any) => x.id.toString() === this.props.selectedUserId
          ) || [],
      });
    } else if (!this.state.tableData) {
      this.setState({
        tableData: this.origTableData,
        userIdList: this.origUserIdList,
        userID: this.origUserID,
        paidLedgerData: this.origPaidLedgerData,
      });
    }
  }

  getColumnWidth(rows: any, accessor: any, headerText: any) {
    const maxWidth = 400;
    const magicSpacing = 10;
    const cellLength = Math.max(
      ...rows.map((row: any) => (`${row[accessor]}` || "").length),
      headerText.length
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }

  handleChange(e: any) {
    let state = { ...this.state };

    if (e.target.name === "userid") {
      state.userID = e.target.value;
    } else if (e.target.name === "description") {
      state.description = e.target.value;
    } else if (e.target.name === "amount") {
      state.amount = e.target.value;
    } else if (e.target.name === "type") {
      state.type = e.target.value;
    }
    this.setState(state);
  }

  setUser(e: any) {
    console.log("event", e);
    this.setState({ userID: e.value });
  }

  async setPayout(e: any) {
    const formattedDate = moment(e.original.payoutdate)
      .local()
      .format("YYYY-MM-DD");

    this.setState({
      payoutData: e.original,
      userID: e.original.id,
      description: "",
      amount: e.original.amount,
      // need to follow yyyy-MM-ddTHH:mm
      payoutTransactionDate: format(
        new Date(formattedDate),
        "yyyy-MM-dd HH:mm"
      ).replace(" ", "T"),
    });
    //   e.preventDefault()

    this.ledgerEntryFormRef.current.scrollIntoView();
  }

  async viewTrans(e: any) {
    let queryString =
      "userId=" +
      e.original.id +
      "&payoutdate=" +
      moment(e.original.payoutdate).format("YYYY-DD-MM");
    this.props.props.history.push(`/ledger?${queryString}`);
    //   e.preventDefault()
  }

  async handleClick(e: any) {
    e.preventDefault();

    let num = new RegExp("[-]?d+(.)?(d+)?");

    if (this.state.amount !== null && this.state.amount.match(num) !== null) {
      this.setState({
        errAmount: false,
        amountMessage: "amount can only be number",
      });
    }

    if (
      this.state.userID !== null &&
      this.state.type != null &&
      this.state.amount !== null &&
      this.state.description !== null
    ) {
      await API.postLedgerPayoutData({
        id: this.state.userID,
        description: this.state.description,
        amount: this.state.amount,
        type: this.state.type,
        payoutTransactionDate: parseISO(this.state.payoutTransactionDate),
      })
        .then(async (res: any) => {
          if (res.payload.message === "Successfully saved") {
            this.setState({
              tableData: null,
              userIdList: [],
              userID: null,
              paidLedgerData: null,
            });
            const ledgerRecord: any = await API.getLedgerPayoutData();
            alert("Saved Successfully");

            this.setState({
              tableData: ledgerRecord.payload.data || [],
              userIdList: ledgerRecord.payload.userId || [],
              userID: ledgerRecord.payload.userId[0].id,
              paidLedgerData: ledgerRecord.payload.paidLedger || [],
            });

            this.origTableData = ledgerRecord.payload.data || [];
            this.origUserIdList = ledgerRecord.payload.userId || [];
            this.origUserID = ledgerRecord.payload.userId[0].id;
            this.origPaidLedgerData = ledgerRecord.payload.paidLedger || [];

            let data = { ...this.state };
            data.userID = data.userIdList[0].id;
            data.type = "Generic (default)";
            data.description = null;
            data.amount = null;
            data.errAmount = null;
            data.errDesp = null;
            data.amountMessage = null;
            data.descriptionMessage = null;
            this.setState(data);
          }
        })
        .catch((err) => {});
    } else {
      if (this.state.amount === null) {
        this.setState({
          errAmount: false,
          amountMessage: "amount cannot be empty",
        });
      }

      if (this.state.description === null) {
        this.setState({
          errDesp: false,
          descriptionMessage: "description cannot be empty",
        });
      }
    }
  }
  searchChange1 = (event: any) => {
    this.setState({ searchInput1: event.target.value }, () => {
      this.globalSearch1();
    });
  };

  globalSearch1 = () => {
    let { searchInput1, tableData } = this.state;
    let pendingPayoutData = tableData.filter((value: any) => {
      return (
        value.id.toString() === searchInput1.toLowerCase() ||
        value.first_name.toLowerCase().includes(searchInput1.toLowerCase()) ||
        value.last_name.toLowerCase().includes(searchInput1.toLowerCase()) ||
        value.email.toLowerCase() === searchInput1.toLowerCase() ||
        searchInput1
          .toLowerCase()
          .split(" ")
          .findIndex((x) => x === value.first_name.toLowerCase()) > -1 ||
        searchInput1
          .toLowerCase()
          .split(" ")
          .findIndex((x) => x === value.last_name.toLowerCase()) > -1
      );
    });
    this.setState({ pendingPayoutData });
  };

  searchChange = (event: any) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, paidLedgerData } = this.state;
    let filteredData = paidLedgerData.filter((value: any) => {
      return (
        value.id.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
        value.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.email.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  handlePayoutTransactionDateChange(e: any) {
    const target = e.target as HTMLInputElement;
    this.setState({ payoutTransactionDate: target.value });
  }

  showLedger() {
    if (!this.state.tableData)
      return (
        <div className={"text-center"}>
          <Spinner
            animation={"grow"}
            variant={"primary"}
            style={{ width: "5em", height: "5em" }}
          />
        </div>
      );

    return (
      <div>
        <Card border="dark">
          <Card.Header
            className="text-white font-weight-bold "
            style={{ background: "rgb(111 79 136 / 1)" }}
          >
            {" "}
            <div>
              <span>Pending Payouts</span>
              <input
                style={{ float: "right" }}
                name="searchInput"
                value={this.state.searchInput1 || ""}
                onChange={this.searchChange1}
                placeholder="Search"
              />
            </div>
          </Card.Header>
          <Card.Body className="p-0">
            <ReactTable
              data={
                this.state.pendingPayoutData &&
                this.state.pendingPayoutData.length
                  ? this.state.pendingPayoutData
                  : this.state.tableData
              }
              columns={[
                {
                  Header: "User Id",
                  accessor: "id",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "First Name",
                  accessor: "first_name",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "Last Name",
                  accessor: "last_name",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
                {
                  id: "email",
                  Header: "Email",
                  accessor: "email",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                  width: this.getColumnWidth(
                    this.state.tableData,
                    "email",
                    "Email"
                  ),
                },
                {
                  id: "payoutdate",
                  Header: "Payout Date",
                  accessor: (d: any) => {
                    return moment(d.payoutdate).local().format("YYYY-DD-MM");
                  },
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "Payout",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                  Cell: (data: any) => (
                    <button
                      className="btn btn-primary btn-block p-auto m-auto"
                      onClick={() => this.setPayout(data)}
                    >
                      Payout
                    </button>
                  ),
                },
                {
                  Header: "Transaction",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                  Cell: (data: any) => (
                    <button
                      className="btn btn-primary btn-block p-auto m-auto"
                      onClick={() => this.viewTrans(data)}
                    >
                      View
                    </button>
                  ),
                },
              ].slice(0, this.props.isLedger ? 8 : 6)}
              style={{ background: "rgb(111 79 136 / 1)" }}
              defaultPageSize={10}
              noDataText="No Data Found"
              getTrProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    style: {
                      background:
                        rowInfo.index % 2 === 0
                          ? "transparent"
                          : "rgb(96 64 125 / 1)",
                      color: rowInfo.index % 2 === 0 ? "white" : "white",
                    },
                  };
                } else {
                  return {
                    style: {
                      background: "transparent",
                      color: "white",
                    },
                  };
                }
              }}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
  paidLedger() {
    if (!this.state.paidLedgerData)
      return (
        <div className={"text-center"}>
          <Spinner
            animation={"grow"}
            variant={"primary"}
            style={{ width: "5em", height: "5em" }}
          />
        </div>
      );

    return (
      <div>
        <Card border="dark">
          <Card.Header
            className="text-white font-weight-bold "
            style={{ background: "rgb(111 79 136 / 1)" }}
          >
            <div>
              <span>Payout Ledger</span>
              <input
                style={{ float: "right" }}
                name="searchInput"
                value={this.state.searchInput || ""}
                onChange={this.searchChange}
                placeholder="Search"
              />
            </div>
          </Card.Header>
          <Card.Body className="p-0">
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.paidLedgerData
              }
              columns={[
                {
                  Header: "User Id",
                  accessor: "id",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "First Name",
                  accessor: "first_name",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "Last Name",
                  accessor: "last_name",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
                {
                  id: "email",
                  Header: "Email",
                  accessor: "email",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                  width: this.getColumnWidth(
                    this.state.paidLedgerData,
                    "email",
                    "Email"
                  ),
                },
                {
                  id: "entrystamp",
                  Header: "Entry Date",
                  accessor: (d: any) => {
                    return moment(d.entrytimestamp)
                      .local()
                      .format("YYYY-DD-MM");
                  },
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
                {
                  id: "transactionDate",
                  Header: "Transaction Date",
                  accessor: (d: any) => {
                    return d.transactionDate
                      ? moment(d.transactionDate).local().format("YYYY-DD-MM")
                      : "";
                  },
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                  headerClassName: "p-3 text-light",
                  className: "text-center",
                },
              ]}
              defaultPageSize={10}
              noDataText="No Data Found"
              style={{ background: "rgb(111 79 136 / 1)" }}
              getTrProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    style: {
                      background:
                        rowInfo.index % 2 === 0
                          ? "transparent"
                          : "rgb(96 64 125 / 1)",
                      color: rowInfo.index % 2 === 0 ? "white" : "white",
                    },
                  };
                } else {
                  return {
                    style: {
                      background: "transparent",
                      color: "white",
                    },
                  };
                }
              }}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }

  renderSelectUser() {
    let label = "";
    if (this.state.userID) {
      let index = this.state.userIdList.findIndex(
        (x: any) => x.id === this.state.userID
      );
      let obj = this.state.userIdList[index];
      label = obj.first_name + " " + obj.last_name + " - " + obj.id;
    }
    return (
      <>
        <Select
          onChange={this.setUser.bind(this)}
          name="userid"
          styles={{
            option: (base: any) => ({
              ...base,
              color: "black",
            }),
          }}
          value={{
            value: this.state.userID || "",
            label: label,
          }}
          options={this.state.userIdList.map((obj: any) => {
            return {
              value: obj.id,
              label: obj.first_name + " " + obj.last_name + " - " + obj.id,
            };
          })}
        />
      </>
    );
  }

  render() {
    return (
      <div className="p-0">
        <Row className="mb-3 mt-5">
          <Col>{this.paidLedger()}</Col>
        </Row>
        <Row className="mb-3 mt-5">
          <Col>{this.showLedger()}</Col>
        </Row>

        {this.props.isLedger && (
          <>
            <div className="row m-0">
              <div className="col-12 pt-5 px-4 pb-0 px-lg-5">
                <p className="h4 m-0">Add Payout Entry</p>
              </div>
            </div>
            <Row className="mt-3 mb-5" ref={this.ledgerEntryFormRef}>
              <Col>
                <div className="bg-white shadow p-4 mb-5">
                  <form>
                    <div className="form-group">
                      <label htmlFor="inputSession">User ID</label>
                      {this.renderSelectUser()}
                    </div>
                    {this.state.type === "payout-adjustment" ? (
                      <h6 style={{ color: "grey" }}>Transaction Date</h6>
                    ) : (
                      <h6 style={{ color: "grey" }}>Payout Date</h6>
                    )}
                    <TextField
                      id="date"
                      type="datetime-local"
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "0.25rem",
                        padding: "0.375rem 0.75rem",
                        width: "100%",
                      }}
                      value={this.state.payoutTransactionDate}
                      onChange={(e) =>
                        this.handlePayoutTransactionDateChange(e)
                      }
                    />
                    <FormInput
                      name="description"
                      type="text"
                      label="Description"
                      placeholder="Description"
                      value={this.state.description}
                      valid={this.state.errDesp}
                      feedback={this.state.descriptionMessage}
                      onChange={(e) => this.handleChange(e)}
                    />
                    <div className="form-group">
                      <label htmlFor="inputSession">Type</label>
                      <select
                        id="dashboard-session-select"
                        value={this.state.type}
                        onChange={this.handleChange.bind(this)}
                        className="custom-select"
                        name="type"
                      >
                        {[
                          "Generic (default)",
                          "payout-adjustment",
                          "pending-payout-adjustment",
                        ].map((obj: any, value: any) => {
                          return (
                            <option key={`option-item-${value}`} value={obj}>
                              {obj}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <FormInput
                      name="amount"
                      type="text"
                      label="Amount"
                      placeholder="Amount"
                      value={this.state.amount}
                      valid={this.state.errAmount}
                      feedback={this.state.amountMessage}
                      onChange={(e) => this.handleChange(e)}
                    />
                    <div className="mt-4">
                      <button
                        className="btn btn-primary btn-block p-auto m-auto"
                        onClick={(e) => {
                          this.handleClick(e);
                        }}
                        // disabled={
                        //   this.state.payoutData == null ||
                        //   this.state.description == ""
                        //     ? true
                        //     : false
                        // }
                      >
                        Add Payout Entry
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

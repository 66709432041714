import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop( { history } ) {
    useEffect( () => {
        // const unlisten = history.listen( () => {
        //     window.scrollTo( 0, 0 );
        // } );
        // return () => {
        //     unlisten();
        // }
        
        document.querySelector(".mainContainer").scrollTo(0,0)
    });

    return ( null );
}

export default withRouter( ScrollToTop );
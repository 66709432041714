import {USER_SELECTED_CATEGORIES} from '../actions/types';

export default function(state = [], action) {
   //console.log(action);
   switch (action.type) {
      case USER_SELECTED_CATEGORIES:
         return action.payload || [];
      default:
         return state;
   }
}

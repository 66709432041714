import React, { useEffect, useState } from 'react';
import { Media_URL } from '../../APIAndConfig';
import "../../scss/session_images.scss"
import Axios from 'axios';
import { FaQuestionCircle, FaRegQuestionCircle } from 'react-icons/fa';

function SessionImages(props) {

    const [resolutionStr, setResolutionStr] = useState("")
    const [imageSize, setImageSize] = useState(0)

    const getImageSizeInBytes = async (imgURL, id) => {
        await Axios.head(imgURL).then(res => {
            if (props.setImageSize) {
                props.setImageSize(id, Number(res.headers['content-length']), "Size")
            }
            setImageSize(Number(res.headers['content-length']))
        }).catch(err => {
            console.log(err);
        })
    }

    const getMeta = async (url) => {
        var img = new Image();
        img.src = url;
        img.onload = function () {
            if (props.setImageSize) {
                props.setImageSize(props.id, this.width + " x " + this.height, "Resolution")
            }
            setResolutionStr(this.width + " x " + this.height)
        };
    }

    useEffect(() => {
        getMeta(Media_URL + "/" + props.image)
        getImageSizeInBytes(Media_URL + "/" + props.image, props.id)
    }, []);

    return (
        <div key={props.id} className="mb-2" >
            {
                props.openDetailsModal ? 
                <div className="view_details_icon" style={{ cursor : "pointer" }} onClick={() => {
                    props.openDetailsModal(props.id, props.item, `${imageSize / 1000} kb`, resolutionStr)
                }}>
                    <FaQuestionCircle className="question_mark" size={24} />
                </div>
                :
                <></>
            }
            {
                props.status === "delete" ?
                    <input type="checkbox" id={`cb${props.id}`} onChange={(e) => {
                        props.onCheck(props.id, e)
                    }}
                        checked={props.checked} /> : <></>
            }
            <label htmlFor={`cb${props.id}`} className="session_images_label" onClick={() => {
                if (props.status === "view" || props.status === "used" || props.status === "unused") {
                    props.onClickImage(Media_URL + "/" + props.image)
                }
            }}>
                <img src={Media_URL + "/" + props.image} />
            </label>
            <div style={{ marginLeft: "11px" }}>
                <div className="font-weight-bolder">Size : {imageSize / 1000} kb</div>
                <div className="font-weight-bolder">Resolution : {resolutionStr}</div>
            </div>
        </div>

    )
}

export default SessionImages;
import { Col, Row, Form, Button, Spinner, Alert } from "react-bootstrap";
import Loader from "../common/Loader";
import React, { ChangeEvent } from "react";
import { IntroWrap } from "./IntroWrap";
import { Link } from "react-router-dom";
import { Firebase } from "../../firebase";
import { set } from "lodash";
import { FormInput } from "../FormInput";
import { cookies } from "../../index";
import { API } from "../../APIAndConfig";

export interface LoginState {
    isProcessing: boolean
    email: string;
    emailIsValid: boolean | null;
    emailFeedback: string | null;
    password: string;
    passwordIsValid: boolean | null;
    passwordFeedback: string | null;
    otherError: string | null;
}

export class Login extends React.Component<any, LoginState> {

    constructor(props: any) {
        super(props);
        this.state = {
            email: cookies.get("email"),
            password: '',
            isProcessing: false,
            emailFeedback: null,
            emailIsValid: null,
            passwordFeedback: null,
            passwordIsValid: null,
            otherError: null
        }
    }

    async handleLogin() {

        let state: Partial<LoginState> = {
            isProcessing: this.state.email && this.state.password ? true : false,
            emailFeedback: null,
            emailIsValid: null,
            passwordFeedback: null,
            passwordIsValid: null,
            otherError: null
        }
        if (!this.state.email) {
            state.emailIsValid = false;
            state.emailFeedback = "Please enter an email address";
        }
        if (!this.state.password) {
            state.passwordIsValid = false;
            state.passwordFeedback = "Please enter your password";
        }
        await this.setState(state as object);
        if (!this.state.email || !this.state.password)
            return;

        try {
            const result = await Firebase.Auth.signInWithEmailAndPassword(this.state.email, this.state.password)
            await API.updateLastLogin(this.state.email);
            if (document.location.href === "/login")
                document.location.href = "/"
            else
                document.location.reload();
        } catch (error) {
            switch (error.code) {
                case "auth/user-not-found":
                    state.emailIsValid = false;
                    state.emailFeedback = "This email address is not registered"
                    break;

                case "auth/wrong-password":
                    state.passwordIsValid = false;
                    state.passwordFeedback = "Incorrect password";

                    break;

                default:
                    console.error(error.code);
                    state.otherError = error.message || "Unknown Error";
            }
            state.isProcessing = false;
        } finally {
            await this.setState(state as object);
        }
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        const state = {};
        set(state, event.target.id, event.target.value);
        this.setState(state);
    }

    render() {
        return (
            <IntroWrap
                processing={this.state.isProcessing}
                heading="Welcome Back!"
                text="Login to Positive Prime using your email address and password below"
            >
                <div className="loginForm">
                    {this.renderError()}

                    <FormInput
                        name="email"
                        type="email"
                        label="Email Address"
                        placeholder="Your email address"
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                        valid={this.state.emailIsValid}
                        feedback={this.state.emailFeedback}
                        disabled={this.state.isProcessing}
                    />

                    <div className="small text-right float-right">
                        <Link className="link-muted" to="/forgetPassword" onClick={this.handleForgotPassword.bind(this)}>Forgot Password?</Link>
                    </div>

                    <FormInput
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Your password"
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                        valid={this.state.passwordIsValid}
                        feedback={this.state.passwordFeedback}
                        disabled={this.state.isProcessing}
                    />

                    <div className="form-group d-flex justify-content-between align-items-center mb-4">

                    </div>

                    <button className="btn btn-primary btn-block py-2" type="submit" onClick={this.handleLogin.bind(this)}>Login</button>

                    <div className="my-5 text-center text-success">
                        <p className="mb-2 small">Don’t have an account?</p>
                        <Link className="btn btn-success px-5 py-2 ml-3" to="/signup7">Sign Up for Positive Prime</Link>
                    </div>

                </div>
            </IntroWrap>
        );
    }

    renderError() {
        if (!this.state.otherError)
            return;

        return (
            <Alert variant="danger">
                {this.state.otherError}
            </Alert>
        )
    }
    handleForgotPassword() {
        cookies.set("email", this.state.email);
    }
}
export default Login;

import React, { Component } from "react";
import { connect } from "react-redux";
// import {Firebase} from"../../firebase";
// import fixOrientation from "fix-orientation";
import { withRouter } from "react-router-dom";
import * as actions from "../actions";
import Pagination from "react-js-pagination";
import map from "lodash/map";
import _ from "lodash";
import EditImageCard from '../components/cards/EditImageCard';
import clsx from 'clsx';
import UploadIcon from '../assets/icons/upload-icon.svg';
import { makePlayerImageUrl } from "../APIAndConfig";
import { ROUTES } from '../Routers/DashboardRouter';
import "./sessions.css";
import Loader from "../components/common/Loader";
import CreateStatement from "./CreateStatement";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import BasicModal from "../components/Modals/BasicModal";
import {
  Modal,
  Spinner,
  ProgressBar,
  Row,
  Col,
  ModalBody,
  ModalFooter
} from 'react-bootstrap'
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import SimpleDropZone from "./simpleDropzone";
import { FiTrash2 } from "react-icons/fi";

import deselect_personalize from "../assets/icons/deselect_personalize.svg";
import upload_personalize from "../assets/icons/upload_personalize.svg";
import select_personalize from "../assets/icons/select_personalize.svg";
import play_personalize from "../assets/icons/play_personalize.svg";

function arrayRemove(array, value) {
  for (let i = 0; i < array.length; i++) {
    if (array[i] === value) {
      array.splice(i, 1);
    }
  }
}

const imageArray = [{}, {}, {}, {}, {}];
// const demoUserImages = [
//   {
//     id: 11,
//     name: 'ii',
//     image: userImage1
//   },
//   {
//     id: 12,
//     name: 'ii',
//     image: userImage2
//   },
//   {
//     id: 13,
//     name: 'ii',
//     image: userImage3

//   },
//   {
//     id: 14,
//     name: 'ii',
//     image: userImage4
//   },
//   {
//     id: 15,
//     name: 'ii',
//     image: userImage5
//   },
//   {
//     id: 16,
//     name: 'ii',
//     image: userImage6
//   }
// ]
class EditSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //authUser: null,
      session: null,
      personalImages: null,
      newName: null,
      selectedItems: [],
      selectedImages: [],
      retrievedImage: true,
      error: null,
      currentPage: 1,
      imagesPerPage: 50,
      activePage: 15,
      showUploadImageModal: false,
      completedCount: [],
      checkedStatus: false,
      deleteModal: false,
      isProcessing: false
    };

    this.handlePageNumberClick = this.handlePageNumberClick.bind(this);
    this.initImagesCheckbox = this.initImagesCheckbox.bind(this);
    // this.handleClick = this.handleClick.bind(this);
  }

  // handlePageNumberClick(event) {
  //   this.setState({
  //     currentPage: Number(event.target.id)
  //   });
  // }
  style = {
    position: "relative",
    overflow: "hidden",
  };

  style2 = {
    position: "absolute",
    transform: "none",
    top: "44%",
    bottom: "0",
    left: "0",
    right: "0",
    height: "fit-content",
  };

  overlay = {
    background: "#000000",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    height: "100%",
    borderRadius: "10px 10px 0 0",
  };
  handlePageNumberClick(pageNumber) {

    const { personalImages, imagesPerPage, selectedImages } = this.state;

    // Logic for displaying current images
    const indexOfLastImage = Number(pageNumber) * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = personalImages ? personalImages.slice(
      indexOfFirstImage,
      indexOfLastImage
    ) : [];

    this.setState({
      currentPage: Number(pageNumber),
      checkedStatus : currentImages.every(i => selectedImages.includes(i.id))
    });
  }

  componentDidMount() {
    this.props.resetError();
    let session_id = this.props.match.params.sessionId
    console.log("session_id", session_id)

    if (isNaN(session_id)) {
      this.props.history.push("/notFound");
      return;
    }

    if (this.props.auth && !this.state.session) {
      const lookup = async function () {
        let data = await this.props.fetchSessionDetailById(
          session_id
        );

        //console.log(data);
        this.setState({ session: data });
        this.state.session = data;
      }.bind(this);

      lookup(this);

    }
  }

  async componentDidUpdate() {

    let session_id = this.props.match.params.sessionId

    if (isNaN(session_id)) {
      this.props.history.push("/notFound");
      return;
    }

    if (this.props.auth && !this.state.session) {
      let data = await this.props.fetchSessionDetailById(
        session_id
      );

      //console.log(data);
      this.setState({ session: data });
      this.state.session = data;
    }

    if (this.props.auth && !this.state.personalImages) {
      let imageData = await this.props.fetchUserUploadedImages();

      this.setState({ personalImages: imageData });
      this.initImagesCheckbox();
    }
  }

  componentWillUnmount() {
    //this.props.resetError();
    this.setState({
      //authUser: null,
      session: null,
      newName: null,
      selectedItems: [],
      error: null
    });
  }

  onOpenNav(e) {
    document.getElementById("sidebar").classList.add("sidebarOpen");
  }

  async onSelectAllClicked(e) {
    const { personalImages, currentPage, imagesPerPage } = this.state;

    // Logic for displaying current images
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = personalImages.slice( indexOfFirstImage, indexOfLastImage );

    map(currentImages, userImage => {
      let id = `image-input-${userImage.id}`;
      let elem = document.getElementById(id);

      if (!elem.checked) {
        elem.click();
      }
    });
  }

  async onDeselectAllClicked(e) {
    const { personalImages, currentPage, imagesPerPage } = this.state;

    // Logic for displaying current images
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = personalImages.slice(
      indexOfFirstImage,
      indexOfLastImage
    );

    map(currentImages, userImage => {
      let id = `image-input-${userImage.id}`;
      let elem = document.getElementById(id);

      if (elem.checked) {
        elem.click();
      }
    });
  }

  async onSaveClicked(e) {
    e.preventDefault();

    console.log("Click save current state", this.state);
    let result = await this.props.editSessionDetails(
      this.props.auth.id,
      this.state.session.id,
      this.state.newName,
      this.state.selectedImages
    );

    //console.log(result);
    // if (result.error) {
    //   alert("There was a problem with saving your change.");
    // } else {
    //   alert("Your change was saved");
    // }


    if (result && result.error) {

    } if (this.props.auth.role.id === 2) {
      this.props.history.push(`/upgradeAccount`);

    } else {
      this.props.history.replace(ROUTES.CATEGORY + '/' + this.state.session.category + '/play/' + this.state.session.id);
    }
  }

  onChangeName(e) {
    e.preventDefault();
    //console.log('Changing name', e.target.value);
    this.setState({ newName: e.target.value });
  }

  /**
   * Begin User images logic
   */

  initImagesCheckbox() {
    const { session } = this.state;

    this.state.selectedImages = [];
    if (session && session.personal_images) {
      for (let i = 0; i < session.personal_images.length; ++i) {
        let image = session.personal_images[i];

        let id = `image-input-${image.id}`;
        let elem = document.getElementById(id);

        // if (elem) {
        //    elem.checked = true;
        // }

        this.state.selectedImages.push(parseInt(image.id));
      }
    }
  }

  async onSubmitUpload(e) {
    e.preventDefault();
    const modal = document.getElementById("uploadModalContainer");
    const modalLoader = document.getElementById("modalLoader");
    const imagePreview = document.getElementById("imagePreview");
    imagePreview.style.display = "none";
    if (modalLoader) {
      modalLoader.style.display = "block";
    }
    const inputElem = document.getElementById("upload-new-img");

    //console.log('onSubmitUpload - uploading file object:', this.state.file);
    // TODO: move the modal, modalLoader, and imagePreview out of the submitImage
    if (this.state.files && this.state.files.length > 0) {
      let data = await this.props.submitImage(
        this.props.auth,
        this.state.files,
        modal,
        this,
        imagePreview,
        modalLoader,
        inputElem
      );

      this.setState({ personalImages: data });
    }
  }

  onOpenAddImage(e) {
    e.preventDefault();
    const modal = document.getElementById("uploadModalContainer");
    modal.style.display = "block";
  }

  onClose(e) {
    e.preventDefault();
    const modal = document.getElementById("uploadModalContainer");
    modal.style.display = "none";
    // const inputElem = document.getElementById("upload-new-img");
    // inputElem.value = "";

    // const imagePreview = document.getElementById("imagePreview");
    // imagePreview.innerHTML = "";
  }

  onFileChange(e) {
    e.preventDefault();
    //this.setState({ file: event.target.files[0] });
    //console.log("onFileChange - event object:", e.target.files);

    let fileList = [];
    let readerList = [];
    for (let i = 0; i < e.target.files.length; ++i) {
      let reader = new FileReader();
      let file = e.target.files[i];

      reader.onloadend = () => {
        fileList.push(file);
        readerList.push(reader.result);
        //console.log('Setting states for file and imagePreviewUrl', this.state);
        this.setState({
          files: fileList,
          uploadImgPreviewList: readerList
        });
      };
      reader.readAsDataURL(file);
    }
    this.setState({
      files: fileList,
      uploadImgPreviewList: readerList
    });

    const modal = document.getElementById("uploadModalContainer");
    modal.style.display = "block";

    //console.log("onFileChange state", this.state);
  }

  async onTrashClick(e) {
    //console.log("Which click onTrash", e.target.nodeName);
    let value = parseInt(e.target.getAttribute("name"));
    if (e.target.nodeName === "path") {
      value = parseInt(e.target.parentElement.getAttribute("name"));
    }

    // Why is this returning NaN?
    //console.log("onTrashClick - event image index = ", value);
    if (isNaN(value)) {
      return;
    }

    // Send delete message
    let data = await this.props.deleteImage(value);
    this.setState({ personalImages: data });
  }

  onImageCheckboxClick(e) {
    const value = parseInt(e.target.name);
    //console.log("onImageCheckboxClick - event image index = ", value);
    //console.log("checkbox state - ", e.target.checked);
    let newData = this.state.selectedImages

    if (!e.target.checked) {
      if (this.state.session && this.state.session.personal_images) {
        for (let i = 0; i < this.state.session.personal_images.length; ++i) {
          let image = this.state.session.personal_images[i];
          if (image.id === value) {
            this.state.session.personal_images.splice(i, 1);
          }
        }
      }
      _.remove(newData, n => {
        if (n === value) return n;
      });
    }

    if (e.target.checked) {
      newData.push(value);
      this.setState({ error: null });
    }

    const { personalImages, currentPage, imagesPerPage } = this.state;

    // Logic for displaying current images
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = personalImages ? personalImages.slice(
      indexOfFirstImage,
      indexOfLastImage
    ) : [];

    console.log(newData);
    console.log(currentImages);
      console.log(currentImages.every(i => newData.includes(i)));

    this.setState({
      selectedImages: newData,
      checkedStatus : currentImages.every(i => newData.includes(i.id))
    })
    //console.log('Selected images - ', this.state.selectedImages);
  }

  onCreateStatement(e) {
    e.preventDefault();
    //alert('NOTE: Not implemented yet');
    const modal = document.getElementById("stmtModalContainer");
    modal.style.display = "block";
  }

  onCloseStatement(e) {
    e.preventDefault();
    const modal = document.getElementById("stmtModalContainer");
    modal.style.display = "none";
  }

  renderSelectedImages() {
    const { personalImages, currentPage, imagesPerPage } = this.state;

    // Logic for displaying current images
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = personalImages ? personalImages.slice(
      indexOfFirstImage,
      indexOfLastImage
    ) : [];
  }

  renderError() {
    if (this.state.error) {
      return (
        <p className="font-italic text-danger text-center font-weight-bold">
          {this.state.error}
        </p>
      );
    }

    if (this.props.error) {
      return (
        <p className="font-italic text-danger text-center font-weight-bold">
          {this.props.error}
        </p>
      );
    }
  }

  renderUserImages() {
    if (
      !this.props.auth ||
      this.props.auth === undefined ||
      !this.state.personalImages
    ) {
      // console.log("NO AUTH");  scvbn mfds
      return <div />;
    } else {
      //console.log("YES AUTH");
    }

    const { personalImages, currentPage, imagesPerPage, selectedImages } = this.state;

    // Logic for displaying current images
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = personalImages ? personalImages.slice(
      indexOfFirstImage,
      indexOfLastImage
    ) : [];

    const { session } = this.state;

    return map(currentImages, userImage => {
      const fullpath =
        process.env.REACT_APP_CDN + '/' +
        userImage.object.url;

      let checkImage = false;
      if (session && session.personal_images) {
        for (let i = 0; i < session.personal_images.length; ++i) {
          let image = session.personal_images[i];

          if (image.id === userImage.id) {
            checkImage = true;
          }
        }
      }

      if ( selectedImages.includes( userImage.id ) ) {
        checkImage = true
      }

      return (

        <EditImageCard
          key={userImage.id}
          id={userImage.id}
          name={userImage.name}
          image={fullpath}
          onDelete={this.onTrashClick.bind(this)}
          checked={checkImage}
          onCheckboxChange={this.onImageCheckboxClick.bind(this)}
        />
        /* <div key={userImage.id} className="m-2 p-2 bg-white border shadow">
          <div className="pp-img-container">
            <div className="pp-action-container">
              <div
                className="pp-img-trash-icon"
                name={userImage.id}
                onClick={this.onTrashClick.bind(this)}
              >
                <FontAwesomeIcon icon={faTrashAlt} name={userImage.id} />
              </div>
              <input
                type="checkbox"
                name={userImage.id}
                id={`image-input-${userImage.id}`}
                className="selectImgCheckbox"
                onClick={this.onImageCheckboxClick.bind(this)}
                defaultChecked={checkImage}
              />
            </div>

            <img
              id={`image-${userImage.id}`}
              alt={userImage.name}
              src={fullpath}
              className="userImageCard"
            />
          </div>
        </div> */
      );
    });
  }

  renderPageNumbers() {
    if (
      !this.props.auth ||
      this.props.auth === undefined ||
      !this.state.personalImages
    ) {
      return <div />;
    } else {

    }

    const { personalImages, currentPage, imagesPerPage } = this.state;
    // Logic for displaying page numbers
    const pageNumbers = [];
    const totalPageCount = personalImages.length;

    return (
      <div>
        <Pagination
          itemClass="page-item"
          linkClass="page-link"
          prevPageText='prev'
          nextPageText='next'
          firstPageText='first'
          lastPageText='last'
          activePage={currentPage}
          itemsCountPerPage={imagesPerPage}
          totalItemsCount={totalPageCount}
          pageRangeDisplayed={10}
          onChange={this.handlePageNumberClick}
        />
      </div>
    );
  }

  renderModal() {
    let { files, uploadImgPreviewList } = this.state;
    let $imagePreview = null;

    if (uploadImgPreviewList && uploadImgPreviewList.length > 0) {
      $imagePreview = _.map(uploadImgPreviewList, (value, index) => {
        let $img = (
          <div
            key={index}
            style={{
              width: "fit-content",
              display: "inline-block"
            }}
          >
            <img alt={files[index].name} src={value} />{" "}
          </div>
        );
        return $img;
      });
    }

    return (
      <div id="uploadModalContainer" className="modalContainer ">
        <div className="uploadModalContent pp-animate-bottom modal-lg">
          <span className="close text-dark font-weight-bold " onClick={this.onClose.bind(this)}>
            &times;
            </span>
          {/* <p>Select images from your computer to upload.</p> */}
          {/* <input
            id="sessionInputFile"
            onChange={this.onFileChange.bind(this)}
            type="file"
            name="imageUpload"
            accept="image/*"
            multiple
          /> */}
          <div id="imagePreview" className="imgPreview">
            {$imagePreview}
          </div>
          <Loader />
          {this.renderError()}
          <button
            className="btn btn-primary"
            style={{ margin: "10px 0px" }}
            type="submit"
            onClick={this.onSubmitUpload.bind(this)}
          >
            Upload Now
          </button>
        </div>
      </div>
    );
  }

  renderCreateStatement() {
    return (
      <div id="stmtModalContainer" className="modalContainer">
        <div className="stmtModalContent pp-animate-bottom">
          <span className="close" onClick={this.onCloseStatement.bind(this)}>
            &times;
          </span>
          <CreateStatement auth={this.props.auth} />
        </div>
      </div>
    );
  }

  // handleClick(e) {
  //   this.refs.fileUploader.click();
  // }

  /**
   * End User images logic
   */

  toggleUploadImageModal() {
    this.setState({
      showUploadImageModal: !this.state.showUploadImageModal
    })
  }

  successCount(count) {
    let data = [...this.state.completedCount]
    data.push(count)
    this.setState({
      completedCount: data
    })
  }

  async onSubmitUploadImageModal(files) {
    if (files.length === 0) {
      this.setState({
        showUploadImageModal: !this.state.showUploadImageModal
      })
    } else {
      const modal = document.getElementById("uploadModalContainer");
      const modalLoader = document.getElementById("modalLoader");
      const imagePreview = document.getElementById("imagePreview");
      imagePreview.style.display = "none";
      if (modalLoader) {
        modalLoader.style.display = "block";
      }
      const inputElem = document.getElementById("upload-new-img");

      //console.log('onSubmitUpload - uploading file object:', this.state.file);
      // TODO: move the modal, modalLoader, and imagePreview out of the submitImage
      if (files && files.length > 0) {
        let data = await this.props.submitImage(
          this.props.auth,
          files,
          modal,
          this,
          imagePreview,
          modalLoader,
          inputElem,
          this.successCount.bind(this)
        );

        this.setState({ personalImages: data, showUploadImageModal: !this.state.showUploadImageModal, completedCount: [] });
      }
    }
  }

  uploadModal() {
    return (
      <>
        <Modal show={this.state.showUploadImageModal} onHide={() => { this.setState({ showUploadImageModal: true }) }}>
          <ModalHeader>
            <div className='w-100'>
              <label>Upload Images</label>
              <button
                className="x-secondary"
                style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                onClick={this.toggleUploadImageModal.bind(this)}
              >X</button>
            </div>
          </ModalHeader>
          <SimpleDropZone
            onUpload={this.onSubmitUploadImageModal.bind(this)}
            completedCount={this.state.completedCount.length}
            acceptTypes="image/jpg, image/gif, image/bmp, image/jpeg, image/tiff, image/png, .heic"
            message="Click Here To Select Images From Your Device"
            inputType="Image"
            maxFiles={50}
          />
        </Modal>
      </>
    )
  }

  async handleDelete() {
    // Send delete message
    let data = await this.props.deleteMultipleImages(this.state.selectedImages, this.props.auth.id);
    this.setState({ personalImages: data, isProcessing: false, deleteModal: false, selectedImages: [] });
  }

  render() {


    return (
      <div className={clsx("container", "dashboardViewContainer")}>
        <div className={clsx("container", "sessionCoverContainer px-0")} style={this.style}>
          {
            (this.state.session?.images && !this.state.session?.images[1]) && !this.state.session?.thumbnail ?
              <img
                src={'https://www.positiveprime.com/wp-content/uploads/2021/04/Positive-Prime-logowhite.png'}
                alt={this.state.session?.name}
                className={"sessionCoverContainer__img"}
                style={{
                  height: "100%",
                  width: "100%"
                }}
              />
              :
              <img
                src={
                  this.state.session?.images && this.state.session?.images[1] ?
                    makePlayerImageUrl(this.state.session?.images[1].media_url) : this.state.session?.thumbnail
                }
                alt={this.state.session?.name}
                className={"sessionCoverContainer__img"}
                style={{
                  height: "fit-content",
                }}
              />
          }
          <div style={this.overlay} className="overlay"></div>
          <div className={"sessionCoverContainer__textContainer"} style={this.style2}>
            <h3
              className={clsx(
                "sessionCoverContainer__textContainer-title",
                "main-title",
                "main-title-2"
              )}
            >
              {this.state.session?.name}
            </h3>
            <p
              className={clsx(
                "sessionCoverContainer__textContainer-title",
                "p-text"
              )}
            >
              {this.state.session?.descriptions?.short_description}
            </p>
            {/* <button
              onClick={props.onClickPersonalize}
              className={clsx(
                "sessionCoverContainer__textContainer-button",
                "btn",
                "x-btn",
                "x-secondary",
                "personalize__btn"
              )}
            >
              Personalize
        </button>
            <button
              onClick={props.onClickPlay}
              className={clsx(
                "sessionCoverContainer__textContainer-button",
                "btn",
                "x-btn",
                "x-secondary"
              )}
              style={{ marginLeft: "20px" }}
            >
              Play Session
        </button> */}
          </div>
        </div>
        <div className={"dashboardViewContainer__header", "mt-4"}>
          <h2
            className={clsx("dashboardViewContainer__header-title", "main-title")}
          >
            Personalize Your Session
        </h2>
          <p className={clsx('x-modal__header-subtitle p-text')}>Customize your session by uploading and selecting photos that bring you positive emotions, are based on goals you want to manifest or fit the theme of the session.</p>
          <hr />
        </div>

        {(!this.props.auth || this.state.session === null) ?
          <div className={"text-center mt-4"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>
          :
          <div className={'ps-Modal'}>
            <div className={'ps-Modal__selectImages'}>
              <div className={clsx('ps-Modal__selectImages-header')}>
                <Row className="w-100 m-0 d-flex align-items-center justify-content-center">
                  <Col className="col-md-auto col-12 pl-0 pr-0 align-items-center justify-content-center d-flex">
                    <h2 className={clsx('main-title-3', 'ps-Modal__selectImages-title text-white text-center')}>Select Images To Play in this Session</h2>
                  </Col>
                  <Col className="col-md-auto col-12 mt-2 mt-lg-0 ml-sm-auto mt-auto pl-1 pr-1">
                    <input type={'file'} style={{ display: "none" }} id={'upload-new-img'} ref="fileUploader" onChange={this.onFileChange.bind(this)} multiple />
                    {/* <button className={clsx('x-btn', 'btn', 'x-secondary')} onClick={this.handleClick}  >
                    Upload New Images
                    </button> */}

                    <button className={clsx('x-btn', 'btn', 'x-secondary', 'w-100', 'd-flex', 'justify-content-center', 'align-items-center')} onClick={() => {
                      this.setState({ showUploadImageModal: true })
                    }}  >
                      <img src={upload_personalize} />
                      <span className="ml-2">Upload</span>
                    </button>
                  </Col>
                  {
                    this.state.checkedStatus ?
                      <Col className="col-md-auto col-12 mt-2 mt-lg-0 pl-1 pr-1">
                        <button className={clsx('x-btn', 'btn', 'x-secondary', 'w-100', 'd-flex', 'justify-content-center', 'align-items-center')} onClick={this.onDeselectAllClicked.bind(this)}>
                          <img src={deselect_personalize} />
                          <span className="ml-2">Deselect All</span>
                        </button>
                      </Col>
                      :
                      <Col className="col-md-auto col-12 mt-2 mt-lg-0 pl-1 pr-1">
                        <button className={clsx('x-btn', 'btn', 'x-secondary', 'w-100', 'd-flex', 'justify-content-center', 'align-items-center')} onClick={this.onSelectAllClicked.bind(this)}>
                          <img src={select_personalize} />
                          <span className="ml-2">Select All</span>
                        </button>
                      </Col>
                  }
                  {/* <Col className="col-md-auto col-12 mt-2 mt-lg-0 pl-1 pr-1">
                    <button className={clsx('x-btn', 'btn float-right', 'x-secondary', 'w-100', 'd-flex', 'justify-content-center', 'align-items-center')} disabled={this.state.selectedImages.length > 0 ? false : true} onClick={() => { this.setState({ deleteModal: true }) }}>
                      <FiTrash2 size={23} ></FiTrash2>
                      <span className="ml-2">Delete Selected</span>
                    </button>
                  </Col> */}
                  <Col className="col-md-auto col-12 mt-2 mt-lg-0 pl-1 pr-1">
                    <button className={clsx('x-btn', 'btn float-right', 'x-secondary', 'w-100', 'd-flex', 'justify-content-center', 'align-items-center')} onClick={this.onSaveClicked.bind(this)}>
                      <img src={play_personalize} />
                      <span className="ml-2">Play Session</span>
                    </button>
                  </Col>
                </Row>
              </div>
              <div className={clsx('ps-Modal__selectImages-container', 'justify-content-center')}>
                {this.renderUserImages()}
                {/* {demoUserImages.map((userImageProps) => {
                  return (
                    <EditImageCard
                      {...userImageProps}
                    />
                  )
                })} */}
              </div>
              <div className="my-4 p-4 d-flex justify-content-center ">
                {this.renderPageNumbers()}
              </div>
            </div>
            <div className={'ps-Modal__footer'}>
              <button className={clsx('x-btn', 'btn', 'x-secondary')} onClick={this.onSaveClicked.bind(this)}>
                Play Session
                </button>
            </div>
            {this.renderModal()}
            {this.renderCreateStatement()}
            {this.renderError()}
            {this.uploadModal()}
            <Modal show={this.state.deleteModal} onHide={() => { this.setState({ deleteModal: false, isProcessing: false }) }}>
              <ModalHeader>Confirm Image Deletion</ModalHeader>
              <ModalBody>{`Are you sure you want to delete ${this.state.selectedImages.length} images?`}</ModalBody>
              <ModalFooter>
                <button
                  className="btn x-btn x-secondary "
                  type="button"
                  onClick={() => {
                    this.setState({ deleteModal: false, isProcessing: false })
                  }}
                >
                  Cancel
                    </button>
                <button
                  className="btn x-btn x-primary "
                  type="button"
                  onClick={() => {
                    this.setState({ isProcessing: true })
                    this.handleDelete()
                  }}
                >
                  {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Confirm</span>}
                </button>
              </ModalFooter>
            </Modal>
          </div>
        }

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    categories: state.categories,
    userUploadedImages: state.userUploadedImages,
    error: state.error
  };
}

export default connect(mapStateToProps, actions)(withRouter(EditSession));

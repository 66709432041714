// import external modules
import React from "react";
import { Route, Redirect } from "react-router-dom";

const UpgradeRoute = ({ requiredRoles, requiredSubscriptionType, user, ...rest }) => {
    const userHasRequiredRole = requiredRoles.includes(user.role.id)
    const userHasRequiredSubscription = requiredSubscriptionType.includes(user.subscriptionType)
    const invite = user.invite_parent
    if (userHasRequiredRole || userHasRequiredSubscription || invite) {
        return <Route {...rest} />
    } else {
        return <Redirect to="/404" />
    }
};

export default UpgradeRoute;

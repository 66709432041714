import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import '../scss/tutorial.scss'

class Tutorial extends Component {
    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        return (
            <div className="tutorial_div">
                <Row className="top_row">
                    <Col>
                        <Container className="pt-3 pb-3">
                            <Row className="pt-5 pb-5">
                                <Col className="d-flex align-items-center flex-column justify-content-center col-12 col-lg-5">
                                    <h1 className="pl-4 pr-4 pt-4">Welcome to the global family of Positive Prime!</h1>
                                    <p className="p-4">We love our Positive Primers and are grateful you are here.</p>
                                </Col>
                                <Col className="col-12 col-lg-7" >
                                    <img 
                                        // src="https://www.positiveprime.com/wp-content/uploads/2021/02/xgroup-min-1.png.pagespeed.ic.GOGcQqaelZ.webp" 
                                        src="/assets/images/tutorial_circles.gif"
                                        className="w-100 p-4"    
                                    ></img>

                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
                <Row className="middle_row">
                    <Col className="pt-4">
                        <Container className="pt-5">
                            <h2 className="text-center mb-5" >Watch This Tutorial Videos To Get Started:</h2>
                            <Row>
                                <Col className="col-12 p-4 col-md-6">
                                    <iframe
                                        title="Kim Serafini, Founder and CEO of Positive Prime"
                                        src="https://www.youtube.com/embed/ALDJjjkujWQ?autoplay=1&amp;amp;feature=oembed"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen=""
                                        name="fitvid0"
                                        className="h-100 w-100"
                                        style={{ minHeight: "299px" }}
                                    ></iframe>
                                </Col>
                                <Col className="col-12 p-4 col-md-6">
                                    <iframe
                                        title="Harnessing Your “Change Genius Within by using Kim Serafini’s Master Formula"
                                        src="https://www.youtube.com/embed/oUAh_VgWHzk?autoplay=0&amp;amp;feature=oembed"
                                        frameborder="0"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen=""
                                        name="fitvid1"
                                        className="h-100 w-100"
                                        style={{ minHeight: "299px" }}
                                    ></iframe>
                                </Col>
                            </Row>
                            <a href="https://positiveprime.com/wp-content/uploads/2021/07/Tutorial-Guide.pdf" target="_blank">
                                <div className="mt-4" style={{ backgroundColor: '#F58181', borderRadius: "20px" }} >
                                    <p
                                        className="text-center p-4"
                                        style={{ fontSize: "40px", fontWeight: "bold", textDecoration: "underline", cursor: 'pointer', color: 'white' }}
                                    >
                                        {">> Download Your Free PDF Guide <<"} </p>
                                </div>
                            </a>
                            <h2 className="pl-2 pr-2 text-center mt-5" style={{ fontSize: '26px', lineHeight: '1.5', fontWeight: '600' }} >Watching a Positive Prime session results in 6-8 hours of more positive moods, attitudes and energy and teaches you to automatically think in terms of what can go right.</h2>
                            <h1 className="text-center pt-4 pb-4 font-weight-bold" style={{ color: "black", fontSize: '40px' }} >Watch your First Positive Prime Session Now:</h1>
                            <div style={{ border: '5px dashed #6058df' }} >
                                <p className="pt-4 pb-4 pl-5 pr-5 text-center m-0" style={{ color: "#141f54", fontSize: '18px' }} >Wait for the player to load. Click player to unhide menu and press the Play icon. Hide the menu by moving your cursor or tapping your finger outside the player window. Enjoy!!!</p>
                            </div>
                            <div class="embed-parent pt-5 pb-5">
                                <div class='embed-container'>
                                    <iframe
                                        src='https://experience.positiveprime.com/embed/YvGhutrXxg9zBvhFi5uh4QSmlSgw7OK2'
                                        style={{ border: 0 }}
                                        scrolling="no"
                                        allowfullscreen
                                    ></iframe>
                                </div>
                            </div>
                        </Container>
                    </Col>
                </Row>

            </div>
        );
    }
}
export default Tutorial;

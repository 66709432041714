import React, { useEffect, useState, } from 'react';
import { useParams } from 'react-router-dom'
import clsx from 'clsx';
import { useDispatch, useSelector } from "react-redux";
import SessionCard from '../../components/cards/SessionCard';
import axios from "axios";
import { Firebase } from '../../firebase';
import { Config, API, APIAxios } from "../../APIAndConfig";
import ImagePlayer from '../../components/players/ImagePlayer/ImagePlayer';
import { ROUTES } from '../../Routers/DashboardRouter';
import { CATEGORY_ROUTES } from '../Category';
import { Media_URL } from '../../APIAndConfig.ts';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../../actions";
import { fetchOwnerSessionForUser, fetchPurchasesByUserId, fetchUserSession } from "../../actions";
import { Spinner } from 'react-bootstrap';
import { set } from 'lodash';
import { getOfflineSession } from '../../helpers/offlineSession';


function Play(props) {
    const state = useSelector(state => state)
    const networkStatus = useSelector(state => state.networkStatus)
    const dispatch = useDispatch()

    const sessionId = useParams().sessionId;
    const categoryId = useParams().categoryId;

    const [product, setProduct] = useState({})
    const [suggetions, setSuggetions] = useState([])
    const [author_name, setAuthor_name] = useState()
    const [isRender, setIsRender] = useState(false)
    const [checkPathObject, setCheckPathObject] = useState(null)
    const [favorites, setFavorites] = useState("Processing")
    const handleSessionCardClick = (session) => {
        let category = session.category ? session.category : "private"
        props.history.push(ROUTES.CATEGORY + '/' + category + CATEGORY_ROUTES.SESSION + '/' + session.id);
    }

    const fetchProduct = async () => {
        const downloadedSession = await getOfflineSession(sessionId)
        if (downloadedSession && downloadedSession.downloadState === 'FINISHED') {
            setAuthor_name(`${downloadedSession.author?.firstName} ${downloadedSession.author?.lastName}`)
            if (checkPathObject) {
                downloadedSession.duration = checkPathObject.duration
            }

            setProduct(downloadedSession)

            if (categoryId !== "private" && categoryId !== null) {
                setSuggetions(downloadedSession.suggestedData || [])
            }

            return false
        }
        
        const token = await Firebase.Auth.currentUser.getIdToken();
        const header = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token
        }
        const result = await axios.get(
            `${Config.APIUrl}/session/${sessionId}`,
            { headers: header }
        ).then((res) => {
            let result = res.data
            setAuthor_name( result.data.author?result.data.author.firstName + " " + result.data.author.lastName:"")

            if (checkPathObject) {
                result.data.duration = checkPathObject.duration
            }

            setProduct(result.data)
            console.log("Result duration is : ", result.data);

            if (categoryId !== "private" && categoryId !== null) {
                setSuggetions(result.suggestedData)
            }
        }).catch( async (err) => {
            switch (err.response.data) {
                case "SESSION_EXPIRED":
                    const token = await Firebase.Auth.currentUser?.getIdToken();
                    if (token) {
                        await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
                        window.location.reload()
                    }
                    break;
                case "BAD_CONNECTION":
                case "UNCAUGHT_ERROR":
                case "DATABASE_LIMIT_EXCEEDED":
                    localStorage.setItem('service_unavailable_status', "failed");
                    await Firebase.Auth.signOut();
                    window.location.href = "/error"
                    break;
                default:
                    console.log(err)
                    break;
            }
        })
    }

    const randomSessions = async (arr) => {
        var n = 3
        var result = [],
            len = arr.length,
            taken = [];
        if (n > len) n = len;
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }

        var flags = [], output = [], l = result.length, i;
        for (i = 0; i < l; i++) {
            if (flags[result[i].id]) continue;
            flags[result[i].id] = true;
            output.push(result[i]);
        }
        return result;

        // if (arr.length < 8) { n = arr.length }
        // var object = {}
        // var taken = []
        // while (Object.keys(object).length < n) {
        //     var x = Math.floor(Math.random() * arr.length);
        //     if (taken.includes(x)) continue
        //     if (Object.keys(object).includes(arr[x].id)) continue
        //     taken.push(x)
        //     object[arr[x].id] = arr[x]
        // }
        // return Object.values(object)
    }

    const renderSelectSession = async () => {
        let sessionIds = [];
        let sessionsArray = [];

        if (state.userSessions) {
            if (state.userSessions === null) return <div />;
            sessionsArray = state.userSessions;
        }

        if (state.ownerSessionForUser) {
            if (state.ownerSessionForUser === null) return <div />;
            sessionsArray = sessionsArray.concat(state.ownerSessionForUser);
        }

        if (state.purchasedSessions) {
            if (state.purchasedSessions === null) return <div />;
            sessionsArray = sessionsArray.concat(state.purchasedSessions);
        }
        if (state.userSessions !== null && state.ownerSessionForUser !== null && state.purchasedSessions !== null) {
            const sessions = Array.from(new Set(sessionsArray.map((a) => a.id))).map(
                (id) => {
                    return sessionsArray.find((a) => a.id === id);
                }
            );

            sessions.sort((a, b) => (a.name > b.name ? 1 : -1));
            let allowedSession = sessions.filter(obj => obj.id === Number(sessionId))

            console.log(allowedSession);

            if (state.auth.role.id === 2 && ![ "1000", "1282" ].includes(sessionId) && allowedSession.length === 0) {
                setProduct({})
                props.history.push('/upgradeAccount')
            }

            if (categoryId === "private" || categoryId === null) {
                setSuggetions(await randomSessions(sessionsArray))
            }
            setIsRender(true)
        }

        if (!networkStatus.connected) {
            setIsRender(true)
            setFavorites(false)
        }
    }

    const getUserFavorites = async () => {
        await API.getUserFavorites(state.auth.id)
            .then((res) => {
                let favorite = res.payload.data.find((obj) => obj.sessionId === Number(sessionId) && obj.sessionType !== 'coaching_pearl')
                setFavorites(favorite ? true : false)
            }).catch((err) => {
                console.log("Error in fetch favorites", err);
            })
    }

    useEffect(() => {
        if (networkStatus.connected) {
            console.log(props.history.location.state);
            if (props.history.location.state && props.history.location.state.duration) {
                console.log(props.history.location.state.duration);
                setCheckPathObject(props.history.location.state)
                props.history.replace(props.location.pathname, null);
            }
            dispatch(fetchUserSession(state.auth || {}))
            dispatch(fetchOwnerSessionForUser(state.auth || {}))
            dispatch(fetchPurchasesByUserId(state.auth.id || ""))
            getUserFavorites()
        }
    }, [sessionId, categoryId])

    useEffect(() => {
        renderSelectSession()
        fetchProduct()
    }, [state.userSessions, state.purchasedSessions, state.ownerSessionForUser])
    return (
        !isRender || favorites === "Processing" ?
            <div className={"text-center mt-4"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div> :
            <div className={clsx('container', 'wellness-play')}>
                <div className={'wellness-play__header'}>
                    <h2 className={clsx('wellness-play__header-title', 'main-title')}>
                        {product.name}
                    </h2>
                    <p className={clsx('wellness-play__header-subtitle', 'x-card-subtitle')}>{author_name}</p>
                </div>

                <div className={clsx('wellness-play__main')}>

                    <div className={clsx('wellness-play__main-imagePlayer', 'container')}>
                        <ImagePlayer
                            sessionId={sessionId}
                            runtime={product.duration}
                            favorite={favorites}
                        />
                    </div>

                    {/* <div className={clsx('wellness-play__main-personalizeButton')}>
                    <button className={'btn x-btn x-primary'}>Personalize Screen</button>
                </div> */}
                    {
                        suggetions.length > 0 ?
                            <div className={clsx('wellness-play__main-upNextContainer')}>
                                <h2 className={clsx('wellness-play__header-title', 'main-title')}>
                                    View More..
                                </h2>
                                <div className={clsx('row', 'wellness-play__main-upnextList', 'justify-content-center', 'mt-4')}>
                                    {suggetions.map((session, index) => {
                                        return (
                                            <div className={'col-lg-4 col-sm-12'} key={index}>
                                                <SessionCard
                                                    onClick={() => handleSessionCardClick(session)}
                                                    id={session.id}
                                                    title={session.name}
                                                    author={session.author.fullName}
                                                    img={
                                                        session.thumbnail ? process.env.REACT_APP_CDN + '/' + session.thumbnail :
                                                            session.placeholder_image ? Media_URL + '/' + session.placeholder_image :
                                                                session.images && session.images[0] && session.images[0].media_url ? Media_URL + '/' + session.images[0].media_url :
                                                                    'https://www.positiveprime.com/wp-content/uploads/2021/04/Positive-Prime-logowhite.png'
                                                    }
                                                    direction="play"
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div> : <></>
                    }
                </div>
            </div>
    )
}



function mapStateToProps(state) {
    return {
        auth: state.auth,
        userSessions: state.userSessions,
        ownerSessionForUser: state.ownerSessionForUser,
        purchasedSessions: state.purchasedSessions,
    };
}


export default connect(mapStateToProps, actions)(withRouter(Play));

import {FETCH_OWNER_SESSION_FOR_USER} from '../actions/types';

export default function(state = null, action) {
   //console.log(action);
   switch (action.type) {
      case FETCH_OWNER_SESSION_FOR_USER:
         return action.payload || [];
      default:
         return state;
   }
}

import {MODE_DEFAULT, MODE_LOGIN, MODE_SIGNUP} from '../actions/types';

export default function(state = null, action) {
   //console.log(action);
   switch (action.type) {
      case MODE_DEFAULT:
      case MODE_LOGIN:
      case MODE_SIGNUP:
         return action.payload;
      default:
         return state;
   }
}

import React, { Component } from 'react';
import { ProgressBar } from "react-bootstrap";
import "../../scss/create_session.scss"

class ImageUploadProgressBar extends Component {
    constructor(props) {
        super(props)
        console.log(props);
    }

    render() {
        return (
            <div style={{ background: "#e9ecef", width: "60%", minHeight: "96px", borderRadius: "5px", position: "relative" }} className="mx-auto">
                <ProgressBar className="progress_bar_custom py-5" striped animated
                    style={{ width: (this.props.done) + "%", background: "#e98461", transition: 'width 1s', position: "absolute" }} />
                <div className="w-100 h-100 d-flex justify-content-center align-items-center" style={{ position: "absolute" }} >
                    <p style={{ color: "#260a43", fontSize: "25px", fontWeight: "bolder" }}>Please Be Patient While We Upload Your Images</p>
                </div>
            </div>
        );
    }
}

export default ImageUploadProgressBar

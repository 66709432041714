import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import PPTable2 from '../common/PPTable2';
import { makeThumbImageUrl } from "../../APIAndConfig";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columns = [
   {
      Header: () => <div className="text-left" />,
      Cell: row => {
         //console.log(row);
         let pathSrc = makeThumbImageUrl(row.original.product.icon_image_url);
         return (
            <div>
               <img height={75} width={75} src={pathSrc} />
            </div>
         );
      },
      accessor: 'product.icon_image_url',
      width: 85,
      filterable: false,
      sortable: false
   },
   {
      Header: () => <div className="text-left">Product</div>,
      accessor: 'product.title'
   },
   {
      Header: () => <div className="text-left">Author</div>,
      accessor: 'product.author_full_name'
   },
   {
      Header: () => <div className="text-left">Description</div>,
      accessor: 'product.short_description'
   },
   {
      Header: () => <div className="text-left">Cost (Credits)</div>,
      accessor: 'product.cost',
      filterable: false
   }
];
class ShopSearchResults extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: null,
         queryValue: null,
         optionValue: 'All'
      };
   }

   async componentDidMount() {
      this.props.resetError();

      const params = new URLSearchParams(this.props.location.search);
      const queryValue = params.get('query');
      const optionValue = params.get('option');

      this.setState({ queryValue });

      if (queryValue && queryValue.length > 0) {
         await this.props.fetchProductsBySearch(queryValue);

      } else {
         await this.props.fetchProducts();

      }

      let option = document.getElementById('productResultSearchOption');
      option.value = optionValue;
   }

   async componentDidUpdate() {
      if (!this.props.products) {
         const params = new URLSearchParams(this.props.location.search);
         const queryValue = params.get('query');
         const optionValue = params.get('option');

         this.setState({ queryValue });

         if (queryValue && queryValue.length > 0) {
            await this.props.fetchProductsBySearch(queryValue);

         } else {
            await this.props.fetchProducts();

         }

         let option = document.getElementById('productResultSearchOption');
         option.value = optionValue;
      }
   }

   componentWillUnmount() {
      this.props.resetError();
   }

   onOpenNav(e) {
      document.getElementById('sidebar').classList.add('sidebarOpen');
   }

   async onSearchClick(e) {
      e.preventDefault();

      let search = document.getElementById('productResultSearchBar');

      let queryValue = search.value;

      if (queryValue && queryValue.length > 0) {
         await this.props.fetchProductsBySearch(queryValue);

      } else {
         await this.props.fetchProducts();

      }
   }

   onProductClick(rowInfo) {
      //onsole.log('Passed in function!!!', rowInfo);
      const { original } = rowInfo;
      //console.log(original);
      //this.props.setSelectedSession(parseInt(original.id), original.name);

      this.props.history.push(`/productDetail/${original.product.id}`);
   }

   render() {
      return (
         <div className="mainWidth h-100">
            <div className="d-flex flex-column h-100">
               <div>
                  <div className="d-flex">
                     <button
                        className="btn bg-transparent d-md-none"
                        onClick={this.onOpenNav.bind(this)}
                     >
                        <FontAwesomeIcon icon={faBars} />
                     </button>
                     <p className="text-secondary m-0 px-0 py-3 px-md-3">
                        Shop Sessions
                     </p>
                  </div>
                  <hr className="m-0" />
                  <div className="px-4 pt-5 px-lg-5">
                     <div className="input-group">
                        <div className="input-group-prepend">
                           <select
                              className="custom-select searchAll bg-transparent"
                              id="productResultSearchOption"
                           >
                              <option defaultValue>All</option>
                              {/* <option>Category</option> */}
                           </select>
                        </div>
                        <input
                           type="text"
                           id="productResultSearchBar"
                           className="form-control"
                           placeholder="Search"
                           aria-label="Text input with dropdown button"
                           defaultValue={this.state.queryValue}
                        />
                        <div className="input-group-append">
                           <button
                              id="productResultSearchBtn"
                              className="btn btn-primary"
                              type="button"
                              onClick={this.onSearchClick.bind(this)}
                           >
                              Search
                           </button>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="px-3 px-sm-5 pt-5">
                  <p className="h4 m-0">Search Results</p>
                  <p className="text-secondary">Here's what we found</p>
               </div>
               <div className="col-12 px-3 px-sm-5">
                  <PPTable2
                     list={this.props.products}
                     columns={columns}
                     noDataText={'No Items Found'}
                     onSelectRow={this.onProductClick.bind(this)}
                  />
               </div>
            </div>
         </div>
      );
   }
}

function mapStateToProps(state) {
   return { auth: state.auth, products: state.products };
}

export default connect(
   mapStateToProps,
   actions
)(ShopSearchResults);

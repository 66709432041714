import React, { SyntheticEvent } from "react";
import { TopUpProps, TopUpState } from "./TopUp";
import {
  Col,
  Jumbotron,
  Row,
  Card,
  Spinner,
  Alert,
  Table,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { LedgerComponent } from '../UserManagement/ledgerComponent';
import * as actions from "../../actions";
import { connect } from "react-redux";
import { faBars, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../APIAndConfig";
import { get } from "lodash";
import moment from "moment";
import ReactTable from "react-table-6";
import clsx from "clsx";
import { CSVLink } from "react-csv";
import { AiOutlineDownload } from "react-icons/ai";
import { CircularProgress, TextField } from "@material-ui/core";
import { MultiSelect } from "react-multi-select-component";
import "../../scss/affiliate_multi_select_override.css";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Tree, TreeNode } from "react-organizational-chart";
import ScrollToTop from "../../Routers/ScrollToTop";
import { PayoutLedgerComponent } from '../UserManagement/payoutLedgerComponent';


export interface AffiliateProps extends RouteComponentProps {
  auth: any;
}
export interface UserList {
  id: string;
  userId: string;
  title: string;
  first_name: string;
  last_name: string;
}
export interface AffiliateState {
  referralData: any;
  chartData: any;
  payoutDate: any;
  runningBalance: number;
  payoutAmount: number;
  ledgerData: any;
  paidLedgerData: any;
  searchInput: string;
  filteredData: any;
  userState: boolean;
  referralCode: string;
  userList: UserList[];
  loading: boolean[];
  tier: string;
  loadingReferrals: boolean;
  affiliateColumnOptions: any;
  selectedAffilateColumns: any;
  loggedUserId: string;
  showModal: boolean;
  modalProps: {
    header: string;
    message: string[];
  };
  hideNoDownTier1: boolean;
  qtyNoDownTier1: number;
  showChartIdList: number[];
  tableRowCount: Number;
  pageNumber: Number;
  currentSelectedId: any;
  refresh: boolean;
}

const tiers = [
  { id: 1, value: 1, label: "Tier 1" },
  { id: 2, value: 2, label: "Tier 2" },
  { id: 3, value: 3, label: "Tier 3" },
];

const countDirectDownlines = (data: any, id: string) => {
  let downlinesCount = 0;
  data.map((line: any) => {
    if (line.referrer === id) {
      downlinesCount += 1;
    }
  });
  return downlinesCount;
};

export class AffiliatesV2Component extends React.Component<
  AffiliateProps,
  AffiliateState
> {
  currentUserRole: number;
  currentSelected: UserList = {
    id: "",
    userId: "",
    title: "",
    first_name: "",
    last_name: "",
  };
  allUsers: UserList[] = [];
  constructor(props: AffiliateProps) {
    super(props);
    this.state = {
      referralData: null,
      chartData: null,
      payoutDate: null,
      runningBalance: 0,
      payoutAmount: 0,
      ledgerData: null,
      paidLedgerData: null,
      searchInput: "",
      filteredData: [],
      userState: false,
      referralCode: "",
      userList: [],
      loading: [false],
      tier: "",
      loadingReferrals: false,
      loggedUserId: this.props.auth.id.toString(),
      affiliateColumnOptions: [
        { label: "Total active downline", value: "totalActiveDownlines" },
        { label: "Total inactive downline", value: "totalInactiveDownlines" },
        { label: "Total sales", value: "totalSales" },
        { label: "Average sales / month", value: "avgSalesPerMonth" },
        { label: "Current month sales", value: "currentMonthSales" },
        { label: "Total commission", value: "totalCommissionProvided" },
        { label: "Average commission / month", value: "avgCommissionPerMonth" },
        { label: "Current month commission", value: "currentMonthCommission" },
      ],
      selectedAffilateColumns: [],
      showModal: false,
      modalProps: {
        header: "",
        message: [],
      },
      hideNoDownTier1: true,
      qtyNoDownTier1: 0,
      showChartIdList: [],
      tableRowCount: 10,
      pageNumber: 0,
      currentSelectedId: null,
      refresh: true
    };
    this.currentUserRole = props.auth.role.id;
  }

  onOpenNav(e: React.MouseEvent<HTMLButtonElement>) {
    const sidebar = document.getElementById("sidebar");
    if (sidebar) sidebar.classList.add("sidebarOpen");
  }

  async componentDidMount(): Promise<void> {
    const countNoDownTier1 = (details: any = []) => {
      let newCount = 0;
      details.forEach(function (line: any) {
        if (
          line.tier === "1" &&
          countDirectDownlines(details, line.userId) === 0
        ) {
          newCount += 1;
        }
      });
      this.setState({ qtyNoDownTier1: newCount });
      const newState = newCount > 10;
      this.setState({ hideNoDownTier1: newState });
    };

    let data;
    let chartData;
    if (
      this.props.location.search &&
      this.props.location.search.split("?")[1] &&
      this.props.location.search.split("?")[1].split("=")[0] ===
        "admin_user_override" &&
      this.props.auth.id === 4395
    ) {
      data = await API.getUserReferrals(
        this.props.location.search.split("?")[1].split("=")[1]
      );
      chartData = await API.getUserChartReferrals(
        this.props.location.search.split("?")[1].split("=")[1]
      );
      this.setState({
        userState: true,
      });
    } else {
      data = await API.getUserReferrals(this.currentSelected.id !== ""? this.currentSelected.id : this.props.auth.id);
      chartData = await API.getUserChartReferrals();
      this.setState({
        userState: false,
      });
    }

    this.setState({
      referralData: data,
      chartData: chartData,
      payoutDate: get(data, "payload.payoutDate.payoutdate", 0),
      runningBalance: get(data, "payload.runningBalance", 0) || 0,
      payoutAmount: get(data, "payload.payoutAmount.payoutAmount", 0) || 0,
      ledgerData: get(data, "payload.ledgerData"),
      paidLedgerData: get(data, "payload.paidLedger"),
      referralCode: get(data, "payload.referral_code"),
    });
    this.allUsers = ((await API.getUsers()) as any).payload.data;
    countNoDownTier1(this.state.chartData.payload.referrals);
  }

  // const boxTotalSignup = get(this.state.referralData, 'payload.totalSignups', 0) || 0
  // return (
  //     <>
  //     <ScrollToTop/>
  //     <div className="">
  //         <h2 className="main-title m-0 p-3">Referrals / Affiliates</h2>
  //         <hr className="m-0" />
  render() {
    const boxTotalSignup =
      get(this.state.referralData, "payload.totalSignups", 0) || 0;
    return (
      <div className="">
        <Container>
          <Row>
            <Col md={4}>
              <h2 className="main-title m-0 p-3">Referrals / Affiliates</h2>
            </Col>
            <Col md={{ span: 4, offset: 4 }}>
              {this.currentUserRole === 1 && (
                <Autocomplete
                  id="combo-box-demo"
                  options={this.state.userList}
                  getOptionLabel={(option) => {
                    this.currentSelected = option;
                    return option.title;
                  }}
                  onChange={async (e, v) => {
                    const recountNoDownTier1 = (details: any) => {
                      let newCount = 0;
                      details.payload.referrals.forEach(function (line: any) {
                        if (
                          line.tier === "1" &&
                          countDirectDownlines(
                            details.payload.referrals,
                            line.userId
                          ) === 0
                        ) {
                          newCount += 1;
                        }
                      });
                      this.setState({ qtyNoDownTier1: newCount });
                      const newState = newCount > 10;
                      this.setState({ hideNoDownTier1: newState });
                    };
                    let data;
                    let chartData;
                    if (!v) {
                      data = await API.getUserReferrals();
                      chartData = await API.getUserChartReferrals();
                      this.currentSelected = {
                        id: "",
                        first_name: "",
                        last_name: "",
                        title: "",
                        userId: "",
                      };
                      this.setState({
                        currentSelectedId: null,
                        loggedUserId: this.props.auth.id.toString()
                      })
                    } else {
                      data = await API.getUserReferrals(
                        this.currentSelected.userId
                      );
                      chartData = await API.getUserChartReferrals(
                        this.currentSelected.userId
                      );
                      this.setState({
                        currentSelectedId: this.currentSelected.id
                      })
                    }
                    // console.log(this.state.userList);
                    this.setState({
                      referralData: data,
                      chartData: chartData,
                      payoutDate: get(data, "payload.payoutDate.payoutdate", 0),
                      runningBalance:
                        get(data, "payload.runningBalance", 0) || 0,
                      payoutAmount:
                        get(data, "payload.payoutAmount.payoutAmount", 0) || 0,
                      ledgerData: get(data, "payload.ledgerData"),
                      paidLedgerData: get(data, "payload.paidLedger"),
                      referralCode: get(data, "payload.referral_code"),
                      userState: false,
                      loggedUserId: this.currentSelected.id !== ""? this.currentSelected.id : this.props.auth.id.toString(),
                      refresh: false
                    });
                    this.setState({
                      refresh: true
                    })
                    recountNoDownTier1(chartData);
                  }}
                  style={{
                    width: 300,
                    backgroundColor: "white",
                    borderRadius: "4px",
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search User Here"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loading[0] ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                  onInputChange={async (e) => {
                    try {
                      const filteredData = (data: any, searchKey: string) => {
                        return JSON.parse(JSON.stringify(data)).filter(
                          (x: any) =>
                            (x.first_name || "")
                              .toLowerCase()
                              .includes(searchKey) ||
                            (x.last_name || "")
                              .toLowerCase()
                              .includes(searchKey) ||
                            ((x.first_name || "") + " " + (x.last_name || ""))
                              .toLowerCase()
                              .includes(searchKey)
                        );
                      };
                      let searchString = (
                        (e.target as HTMLInputElement).value || ""
                      ).toLowerCase();

                      this.state.userList.length = 0;
                      if (searchString === "") {
                        // this.state.userList.splice(0, this.state.userList.length);
                        // this.setState(this.state);
                        return;
                      }

                      if (
                        filteredData(this.allUsers, searchString).length == 0
                      ) {
                        this.state.loading[0] = true;
                        this.allUsers = (
                          (await API.getUsers()) as any
                        ).payload.data;
                      }
                      filteredData(this.allUsers, searchString).map(
                        (x: any) => {
                          this.state.userList.push({
                            id: x.userId.toString(),
                            userId: x.userId.toString(),
                            title: x.first_name + " " + x.last_name,
                            first_name: x.first_name,
                            last_name: x.last_name,
                          });
                        }
                      );
                      this.state.userList.sort((a: any, b: any) => {
                          const result = a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase());
                          return result !== 0 ? result : a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase());
                      });
                      this.state.loading[0] = false;
                      this.setState(this.state);
                    } catch (e) {
                      console.error(e);
                    }
                  }}
                />
              )}
            </Col>
          </Row>
        </Container>
        <hr className="m-0" />

        <Container fluid className="p-3">
          {boxTotalSignup > 0 ? (
            <></>
          ) : (
            <Row className="mb-1">
              <Col>
                <Jumbotron className={clsx("card-blur", "shadow text-center")}>
                  <h1 className="mb-5 text-white">
                    We love our new Affiliate program. We trust you will too!{" "}
                  </h1>
                  <h3 className="text-light">
                    For more information, please visit:{" "}
                    <a
                      target="_blank"
                      href="https://www.positiveprime.com/affiliates/"
                    >
                      positiveprime.com/affiliates
                    </a>{" "}
                    <br />
                    Now you can earn bonuses and commissions
                  </h3>
                </Jumbotron>
              </Col>
            </Row>
          )}

          {this.renderUpgradeWarning()}

          <Row className="text-center mb-5">
            <Col className="mb-3">
              <Card className={clsx("card-blur")}>
                <Card.Header className=" text-white">
                  Your Unique Referral Link
                </Card.Header>
                <Card.Body style={{ height: 150 }}>
                  <h2 className="p-3">
                    <a
                      target="_blank"
                      className=" text-white"
                      href={`https://pospri.me/${this.state.referralCode}`}
                    >
                      pospri.me/{this.state.referralCode}
                    </a>
                  </h2>
                </Card.Body>
              </Card>
            </Col>

            <Col className="mb-3">
              <Card className={clsx("card-blur")}>
                <Card.Header className=" text-white">
                  Account Information
                </Card.Header>
                <Card.Body style={{ height: 150 }}>
                  <div className="p-3">
                    <h4 className="">
                      Current Balance : ${this.state.runningBalance}{" "}
                    </h4>
                    <h4 className="">
                      {" "}
                      Payouts ( Expected :{" "}
                      {!this.state.payoutDate || this.state.payoutDate === null
                        ? "Not Available"
                        : moment(this.state.payoutDate).format("DD MMM")}{" "}
                      ) : ${this.state.payoutAmount}
                    </h4>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {this.renderAffiliateCounts()}

          {/* <div style={{ textAlign: "center", marginTop: 50 }} >
                        <p>*Note* Data on this screen commences from 1st September 2020 for Stripe transactions only.</p>
                    </div> */}

          <Row className="my-5">
            <Col>{this.renderPayoutLedger()}</Col>
          </Row>
          <Row className="my-5">
            <Col>{this.renderLedgerData()}</Col>
          </Row>

        </Container>
      </div>
    );
  }

  searchChange = (event: any) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, referralData } = this.state;
    let filteredData = get(referralData, "payload.referrals").filter(
      (value: any) => {
        return (
          value.emailHash.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.referred.toLowerCase().includes(searchInput.toLowerCase()) ||
          value.userId
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())
        );
      }
    );
    this.setState({ filteredData });
  };

  // </Container>
  // </div>
  // </>
  async handleTierChange(e: any) {
    const tier = e.target.value;

    this.setState({ loadingReferrals: true });

    const referralData = await API.getUserReferrals(
      this.currentSelected.id === "" ? "current" : this.currentSelected.id,
      tier
    );

    this.setState({ tier, referralData, loadingReferrals: false });
  }

  paidLedger() {
    if (!this.state.paidLedgerData)
      return (
        <div className={"text-center"}>
          <Spinner
            animation={"grow"}
            variant={"primary"}
            style={{ width: "5em", height: "5em" }}
          />
        </div>
      );

    return (
      <div>
        <Card border="dark" className={clsx("card-blur")}>
          <Card.Header
            className={clsx("text-white font-weight-bold")}
            style={{ background: "rgb(111 79 136 / 1)" }}
          >
            Payouts
          </Card.Header>
          <Card.Body className="p-0">
            <ReactTable
              data={this.state.paidLedgerData}
              // className={clsx('card-blur')}
              columns={[
                {
                  Header: "User Id",
                  accessor: "id",
                  headerClassName: " p-3 text-light",
                  className: "text-center ",
                },
                {
                  Header: "First Name",
                  accessor: "first_name",
                  headerClassName: " p-3 text-light",
                  className: "text-center ",
                },
                {
                  Header: "Last Name",
                  accessor: "last_name",
                  headerClassName: " p-3 text-light",
                  className: "text-center ",
                },
                {
                  id: "email",
                  Header: "Email",
                  accessor: "email",
                  headerClassName: " p-3 text-light",
                  className: "text-center ",
                  width: this.getColumnWidth(
                    this.state.paidLedgerData,
                    "email",
                    "Email"
                  ),
                },
                {
                  id: "entrystamp",
                  Header: "Payment Date",
                  accessor: (d: any) => {
                    return moment(d.entrytimestamp)
                      .local()
                      .format("YYYY-DD-MM");
                  },
                  headerClassName: " p-3 text-light",
                  className: "text-center ",
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                  headerClassName: " p-3 text-light",
                  className: "text-center ",
                },
              ]}
              style={{ background: "rgb(111 79 136 / 1)" }}
              defaultPageSize={5}
              noDataText="No Data Found"
              getTrProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    style: {
                      background:
                        rowInfo.index % 2 === 0
                          ? "transparent"
                          : "rgb(96 64 125 / 1)",
                      color: rowInfo.index % 2 === 0 ? "white" : "white",
                    },
                  };
                } else {
                  return {
                    style: {
                      background: "transparent",
                      color: "white",
                    },
                  };
                }
              }}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }

  renderLedgerData() {
    if (!this.state.ledgerData)
      return (
        <div className={"text-center"}>
          <Spinner
            animation={"grow"}
            variant={"primary"}
            style={{ width: "5em", height: "5em" }}
          />
        </div>
      );

    if (!this.state.referralData.successful)
      return <Alert variant="danger">Error fetching referral data</Alert>;

    const total = get(this.state.referralData, "payload.totals.all", 0);
    if (total < 1) return <p>No referrals yet</p>;

    let rows = [];
    const columns = [
      {
        Header: "Ledger Id",
        accessor: "ledgerID",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Timestamp",
        headerClassName: "p-3 text-light",
        accessor: "entrytimestamp",
        className: "text-center",
      },
      {
        Header: "User Id",
        accessor: "userID",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Description",
        accessor: "description",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Referee Id",
        accessor: "refereeID",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Status",
        accessor: "paymentstatus",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Payout Date",
        accessor: "payoutdate",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Payout Id",
        accessor: "payoutID",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Amount",
        accessor: "amount",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
    ];

    let row: any;
    let data = this.state.ledgerData;

    if(this.currentSelected.id !== ""){
      data = data.filter((x: any) => x.userID === this.currentSelected.id);
    }

    for (row of data) {
      let ledgerID = get(row, "ledgerID", "");
      let entrytimestamp = (get(row, "entrytimestamp") || "").split("T")[0];
      let userID = get(row, "userID");
      let description = get(row, "description", "") || "";
      let refereeID = get(row, "refereeID", "");
      let paymentstatus = get(row, "paymentstatus", "");
      let payoutmaindate = get(row, "payoutdate");
      let payoutdate;
      if (payoutmaindate) {
        let date = payoutmaindate.split("T")[0];
        let hours = payoutmaindate.split("T")[1].slice(0, 5);
        payoutdate = date + " " + hours;
      } else {
        payoutdate = "NA";
      }

      let payoutID = get(row, "payoutID", "");
      let amount = get(row, "amount", "");

      // console.log(level + "\t" + date + "\t" + name + "\t" + email)
      let dataObj: any = {
        ledgerID,
        entrytimestamp,
        userID,
        description,
        refereeID,
        paymentstatus,
        payoutdate,
        payoutID,
        amount,
      };
      rows.push(dataObj);
    }
    // console.log(this.state.referralData);
    // console.log(this.state.chartData);
    return (
      <div>
        {/* <Card border="dark" className={clsx("card-blur")}>
          <Card.Header
            className="text-white font-weight-bold "
            style={{ background: "rgb(111 79 136 / 1)" }}
          >
            Sales Ledger
          </Card.Header>
          <Card.Body className="p-0">
            <ReactTable
              data={rows}
              columns={columns}
              defaultPageSize={10}
              noDataText="No Data Found"
              style={{ background: "rgb(111 79 136 / 1)" }}
              getTrProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    style: {
                      background:
                        rowInfo.index % 2 === 0
                          ? "transparent"
                          : "rgb(96 64 125 / 1)",
                      color: rowInfo.index % 2 === 0 ? "white" : "white",
                    },
                  };
                } else {
                  return {
                    style: {
                      background: "transparent",
                      color: "white",
                    },
                  };
                }
              }}
            />
          </Card.Body>
        </Card> */}

        <Row className="my-5" id="paymentLedgerTable" >
          <Col>
            {
              this.state.refresh && 
              <LedgerComponent props={this.props} isLedger={false} currentUser={``} loggedUserId={this.state.loggedUserId} />
            }
            {/* ${this.props.auth.firstName} ${this.props.auth.lastName} */}
          </Col>
        </Row>
        <Row className="my-5">
          <Col>{this.renderAffiliateData()}</Col>
        </Row>

        <Row className="my-5">
          <Col>{this.renderAffiliateChart()}</Col>
        </Row>

      </div>
    );
  }

  renderAffiliateCounts() {
    const totalSignup =
      get(this.state.referralData, "payload.totalSignups", 0) || 0;
    const totalEarning =
      get(this.state.referralData, "payload.totalEarning", 0) || 0;
    const tier1Count =
      get(this.state.referralData, "payload.tier1.count", 0) || 0;
    const tier1Sum =
      get(this.state.referralData, "payload.tier1.tier1Earning", 0) || 0;
    const tier2Count =
      get(this.state.referralData, "payload.tier2.count", 0) || 0;
    const tier2Sum =
      get(this.state.referralData, "payload.tier2.tier2Earning", 0) || 0;
    const tier3Count =
      get(this.state.referralData, "payload.tier3.count", 0) || 0;
    const tier3Sum =
      get(this.state.referralData, "payload.tier3.tier3Earning", 0) || 0;

    if (!this.state.referralData)
      return (
        <div className={"text-center"}>
          <Spinner
            animation={"grow"}
            variant={"primary"}
            style={{ width: "5em", height: "5em" }}
          />
        </div>
      );

    if (!this.state.referralData.successful)
      return <Alert variant="danger">Error fetching referral data</Alert>;

    return (
      <Row>
        <Col className="mb-3">
          <Card
            className={clsx(
              "card-blur h-100",
              "bg-gradient-primary text-white"
            )}
          >
            <Card.Body className="text-center mt-3 mb-3">
              <h3>{totalSignup + " ( $" + totalEarning + " )"}</h3>
              <h4>Total Signups</h4>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mb-3">
          <Card
            className={clsx(
              "card-blur h-100",
              "bg-gradient-success text-white"
            )}
          >
            <Card.Body className="text-center mt-3 mb-3">
              <h3>{tier1Count + " ( $" + tier1Sum + " )"}</h3>
              <h4>Tier 1</h4>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mb-3">
          <Card
            className={clsx(
              "card-blur h-100",
              "bg-gradient-warning text-white"
            )}
          >
            <Card.Body className="text-center mt-3 mb-3">
              <h3>{tier2Count + " ( $" + tier2Sum + " )"}</h3>
              <h4>Tier 2</h4>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mb-3">
          <Card
            className={clsx("card-blur h-100", "bg-gradient-info text-white")}
          >
            <Card.Body className="text-center mt-3 mb-3">
              <h3>{tier3Count + " ( $" + tier3Sum + " )"}</h3>
              <h4>Tier 3</h4>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

  renderPayoutLedger(){
    return (
      <div className=" mb-5 pb-5" >
          <h2 className="main-title m-0 p-3">Payout Ledger</h2>
          <hr className="m-0" />
          <div className="ml-3 mr-3">
            {
              this.state.currentSelectedId && <PayoutLedgerComponent props={this.props} isLedger={false} selectedUserId={this.state.currentSelectedId} />
            }
            {
              !this.state.currentSelectedId && <PayoutLedgerComponent props={this.props} isLedger={false} selectedUserId={this.props.auth.id.toString()} />
            }
          </div>
      </div>
    );
  }

  handleColumnChange(selected: any) {
    this.setState({ selectedAffilateColumns: selected });
  }

  handleAffiliateValueRenderer(selected: any, _options: any) {
    return selected.length
      ? selected.map(({ label }: any) => label)
      : "No Items Selected";
  }

  getColumnWidth(rows: any, accessor: any, headerText: any) {
    const maxWidth = 400;
    const magicSpacing = 10;
    const cellLength = Math.max(
      ...rows.map((row: any) => (`${row[accessor]}` || "").length),
      headerText.length
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }
  renderAffiliateData() {
    if (!this.state.referralData)
      return (
        <div className={"text-center"}>
          <Spinner
            animation={"grow"}
            variant={"primary"}
            style={{ width: "5em", height: "5em" }}
          />
        </div>
      );

    if (!this.state.referralData.successful)
      return <Alert variant="danger">Error fetching referral data</Alert>;

    const total = get(this.state.referralData, "payload.totals.all", 0);
    // if (total < 1)
    //     return <p>No referrals yet</p>

    let rows: any = [];

    const selectedOptions = this.state.selectedAffilateColumns.map((f: any) => {
      return {
        Header: f.label,
        accessor: f.value,
        headerClassName: "p-3 text-light",
        className: "text-center",
        Footer: () => {
          let amountSum = 0;
          //this.state.tableRowCount
          for (
            let i =
              Number(this.state.pageNumber) * Number(this.state.tableRowCount);
            i <
            Number(this.state.pageNumber) * Number(this.state.tableRowCount) +
              Number(this.state.tableRowCount);
            i++
          ) {
            try {
              amountSum += Number(
                !!this.state.filteredData.length
                  ? this.state.filteredData[i][String(f.value)]
                  : get(this.state.referralData, "payload.referrals")[i][String(f.value)]
              );
            } catch {
              console.log("No more data");
            }
          }
          return (
            "Total: " +
            amountSum.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          );
        },
      };
    });

    const columns = [
      {
        Header: "Level",
        accessor: "level",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Referral Date",
        headerClassName: "p-3 text-light",
        accessor: "date",
        className: "text-center",
      },
      {
        Header: "Name",
        accessor: "name",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Email",
        accessor: "email",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Tier",
        accessor: "tier",
        Cell: (row: any) => `Tier ${row.original.tier}`,
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      ...selectedOptions,
    ];

    let row: any;
    let data;
    if (this.state.filteredData.length > 0) {
      data = this.state.filteredData;
    } else {
      data = get(this.state.referralData, "payload.referrals", []);
    }

    const levelMap: { [key: string]: string } = {
      user: "Free User",
      coach: "Leader",
    };

    for (row of data) {
      const levelKey = get(row, "userRole", "").toLowerCase();
      let level = levelMap[levelKey] ?? get(row, "userRole", "");
      let levelClass = level === "User" ? "text-muted" : undefined;
      let date = (get(row, "referred") || "").split("T")[0];
      let id = get(row, "userId");
      let name = get(row, "name", "") || 0;
      let email = get(row, "emailHash", "");
      let tier = get(row, "tier", "");
      let totalActiveDownlines = get(row, "totalActiveDownlines", "");
      let totalInactiveDownlines = get(row, "totalInactiveDownlines", "");
      let totalSales = get(row, "totalSales", "");
      let currentMonthSales = get(row, "currentMonthSales", "");
      let avgCommissionPerMonth = get(row, "avgCommissionPerMonth", "");
      let avgSalesPerMonth = get(row, "avgSalesPerMonth", "");
      let currentMonthCommission = get(row, "currentMonthCommission", "");
      let totalCommissionProvided = get(row, "totalCommissionProvided", "");
      let userStatus = get(row, "userStatus", "");

      // console.log(level + "\t" + date + "\t" + name + "\t" + email)
      let dataObj: any = {
        level: level,
        date: date,
        name: name,
        email: email,
        tier,
        totalActiveDownlines,
        totalInactiveDownlines,
        totalSales,
        currentMonthSales,
        avgCommissionPerMonth,
        avgSalesPerMonth,
        currentMonthCommission,
        totalCommissionProvided,
        userStatus,
      };
      const dataObjKeys = Object.keys(dataObj);
      dataObjKeys.forEach((key) => {
        if (
          key === "level" ||
          key === "date" ||
          key === "id" ||
          key === "name" ||
          key === "email" ||
          key === "tier"
        ) {
          return;
        } else if (
          this.state.selectedAffilateColumns.findIndex(
            (x: any) => x.value === key
          ) === -1
        ) {
          delete dataObj[key];
        }
      });
      // console.log(dataObj);
      rows.push(dataObj);

      // rows.push(<tr>
      //     <td className={levelClass}>{level}</td>
      //     <td>{date}</td>
      //     <td>{name}</td>
      //     <td>{email}</td>
      // </tr>);
    }

    let tableBox = document.getElementById("referralTable")!;

    return (
      <div>
        <Card border="dark" className={clsx("card-blur")}>
          <Card.Header
            className="text-white font-weight-bold "
            style={{ background: "rgb(111 79 136 / 1)" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <span>Current Referrals: &nbsp; {total}</span>

              <div className="d-flex">
                <div style={{ width: "250px" }} className="mr-4">
                  <MultiSelect
                    options={this.state.affiliateColumnOptions}
                    value={this.state.selectedAffilateColumns}
                    onChange={this.handleColumnChange.bind(this)}
                    labelledBy="Select"
                    valueRenderer={this.handleAffiliateValueRenderer.bind(this)}
                  />
                </div>

                <select
                  id="dashboard-session-select"
                  value={this.state.tier}
                  onChange={this.handleTierChange.bind(this)}
                  className="custom-select mr-4"
                  name="type"
                >
                  <option key="option-item-0" value={"All"}>
                    All
                  </option>
                  {tiers.map((t) => {
                    return (
                      <option key={`option-item-${t.id}`} value={t.value}>
                        {t.label}
                      </option>
                    );
                  })}
                </select>

                <button
                  className="btn btn-sm ml-auto mr-4 dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <AiOutlineDownload color="#fff" size={25} />
                </button>

                <div className="dropdown-menu">
                  <CSVLink
                    data={rows}
                    filename={"Current-Referrals.csv"}
                    className="dropdown-item"
                  >
                    Download CSV
                  </CSVLink>
                </div>

                <input
                  style={{ float: "right" }}
                  name="searchInput"
                  value={this.state.searchInput || ""}
                  onChange={this.searchChange}
                  placeholder="Search"
                />
              </div>
            </div>
          </Card.Header>
          <Card.Body className="p-0" id="referralTable">
            {/* <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Level</th>
                                    <th>Referral Date</th>
                                    <th>Name</th>
                                    <th>Email MD5 Hash</th>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </Table> */}

            <ReactTable
              data={rows}
              columns={columns}
              defaultPageSize={10}
              noDataText="No Data Found"
              onPageChange={() => {
                if(tableBox){
                  let pageBox = tableBox!.getElementsByClassName("-pageJump");
                  this.setState({
                  pageNumber:
                    Number(
                      (pageBox[0].childNodes[0] as HTMLInputElement).value
                    ) - 1,
                })}
              }
              }
              onPageSizeChange={() => {
                if(tableBox){
                  let tableBody = tableBox!.getElementsByClassName("rt-tr-group");
                  this.setState({ tableRowCount: tableBody.length })
                }
               }  
              }
              loading={this.state.loadingReferrals}
              style={{ background: "rgb(111 79 136 / 1)" }}
              getTrProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    style: {
                      background:
                        rowInfo.index % 2 === 0
                          ? "transparent"
                          : "rgb(96 64 125 / 1)",
                      color: rowInfo.index % 2 === 0 ? "white" : "white",
                    },
                  };
                } else {
                  return {
                    style: {
                      background: "transparent",
                      color: "white",
                    },
                  };
                }
              }}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }

  renderAffiliateChart() {
    const onModalClose = () => {
      this.setState({ showModal: false });
    };

    const onModalOpen = (chartDetails: any) => {
      this.setState({ showModal: true });
      this.state.referralData.payload.referrals.map((line: any) => {
        if (line.nameHash === chartDetails.nameHash) {
          this.setState({
            modalProps: {
              header: chartDetails.name + "'s details",
              message: [
                "User ID: " + String(chartDetails.userId || "NA"),
                "Joined Date: " + String(chartDetails.referred || "NA"),
                "Tier: " + String(chartDetails.tier || "NA"),
                "Total number of active downline: " +
                  String(line.totalActiveDownlines || "NA"),
                "Total number of inactive downline: " +
                  String(line.totalInactiveDownlines || "NA"),
                "Total sales: " + line.totalSales || "NA",
                "Average sales per month: " +
                  String(line.avgSalesPerMonth || "NA"),
                "Total sale in current calendar month: " +
                  String(line.currentMonthSales || "NA"),
                "Total commission from this user: " +
                  String(line.totalComissionProvided || "NA"),
                "Average commission per month: " +
                  String(line.avgComissionPerMonth || "NA"),
                "Total commission in current calendar monthn: " +
                  String(line.currentMonthComission || "NA"),
                "Active: " + String(chartDetails.status || "NA"),
                "Phone Number: " + String(chartDetails.phone || "NA"),
                "Email: " + String(chartDetails.emailHash || "NA"),
              ],
            },
          });
        }
      });
    };

    const countIndirectDownlines = (data: any, id: string) => {
      let indirectDownlinesCount = 0;
      data.map((line: any) => {
        if (line.referrer === id) {
          indirectDownlinesCount += countDirectDownlines(data, line.userId);
        }
      });
      return indirectDownlinesCount;
    };

    const showHideNode = (id: number) => {
      const newList = [...this.state.showChartIdList];
      if (newList.includes(id)) {
        newList.splice(newList.indexOf(id), newList.indexOf(id) + 1);
      } else {
        newList.push(id);
      }
      this.setState({ showChartIdList: newList });
    };

    const chartNodeButton = (line: any) => {
      return (
        <div style={{ width: "6rem", margin: "auto" }}>
          <button
            onClick={() => onModalOpen(line)}
            style={{ width: "5rem", margin: "auto", wordWrap: "break-word" }}
          >
            Tier {line.tier}
            {":\n"}
            {line.name}
          </button>
          {countDirectDownlines(
            this.state.chartData.payload.referrals,
            line.userId
          ) > 0 && (
            <button
              style={{ background: "white" }}
              onClick={() => showHideNode(line.userId)}
            >
              <FontAwesomeIcon icon={faUser}/>{" "}
              {countDirectDownlines(
                this.state.chartData.payload.referrals,
                line.userId
              )}
              {" + "}
              {countIndirectDownlines(
                this.state.chartData.payload.referrals,
                line.userId
              )}
            </button>
          )}
        </div>
      );
    };

    const renderTier3 = (data: any, id: string) => {
      return data.map((line: any) => {
        if (line.referrer === id && line.tier === "3") {
          return (
            <TreeNode
              label={
                <div>
                  <button onClick={() => onModalOpen(line)}>
                    Tier {line.tier}
                    {":\n"}
                    {line.name}{" "}
                    <FontAwesomeIcon icon={faUser}/>
                    {" ("}
                    {countDirectDownlines(data, line.userId)}
                    {")"}
                  </button>
                </div>
              }
            ></TreeNode>
          );
        }
      });
    };

    const renderTier2 = (data: any, id: string) => {
      return data.map((line: any) => {
        if (line.referrer === id && line.tier === "2") {
          if (
            countDirectDownlines(data, line.userId) > 0 &&
            this.state.showChartIdList.includes(Number(line.userId))
          ) {
            return (
              <TreeNode label={chartNodeButton(line)}>
                {renderTier3(data, line.userId)}
              </TreeNode>
            );
          } else {
            return <TreeNode label={chartNodeButton(line)}></TreeNode>;
          }
        }
      });
    };

    const changeHideNoDownTier1 = () => {
      const newState = !this.state.hideNoDownTier1;
      this.setState({
        hideNoDownTier1: newState,
      });
    };

    return (
      <div>
        <Modal show={this.state.showModal}>
          <ModalHeader>{this.state.modalProps.header}</ModalHeader>
          <ModalBody>
            {this.state.modalProps.message.map((line: string) => {
              return (
                <div>
                  <p>{line}</p>
                </div>
              );
            })}
          </ModalBody>
          <ModalFooter>
            <button
              className="btn x-btn x-secondary "
              type="button"
              onClick={onModalClose}
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
            <input
              style={{
                float: "left",
                marginTop: "0.5rem",
              }}
              type="checkbox"
              checked={this.state.hideNoDownTier1}
              onChange={changeHideNoDownTier1}
            />
            <p
              className="mb-1"
              style={{
                float: "left",
              }}
            >
              Hide {this.state.qtyNoDownTier1} Tier 1 referees with No Downlines
            </p>
            <br/>
            {/* {this.state.showChartIdList.length > 0 && (
              <p
                style={{
                  float: "left",
                  marginLeft: "3rem",
                }}
              >
                Showing Downline from Users:{" "}
                {String(
                  this.state.showChartIdList.map((id: any) => {
                    return " " + id;
                  })
                )}
              </p>
            )} */}
        
            <div
              className="mt-1 mr-0 ml-0"
              style={{
                padding: "2rem",
                border: "solid",
                overflowX: "scroll",
                width: "100vw",
              }}
            >
              <Tree
                label={
                  <div>
                    <FontAwesomeIcon icon={faUser}/>
                  </div>
                }
              >
                {this.state.hideNoDownTier1 && (
                  <TreeNode
                    label={
                      <button onClick={changeHideNoDownTier1}>
                        <FontAwesomeIcon icon={faUser}/> {this.state.qtyNoDownTier1} Tier 1 referees
                      </button>
                    }
                  ></TreeNode>
                )}
                {this.state.chartData.payload.referrals.map((line: any) => {
                  if (line.tier === "1") {
                    if (
                      countDirectDownlines(
                        this.state.chartData.payload.referrals,
                        line.userId
                      ) > 0
                    ) {
                      if (
                        this.state.showChartIdList.includes(Number(line.userId))
                      ) {
                        return (
                          <TreeNode label={chartNodeButton(line)}>
                            {renderTier2(
                              this.state.chartData.payload.referrals,
                              line.userId
                            )}
                          </TreeNode>
                        );
                      } else {
                        return <TreeNode label={chartNodeButton(line)}></TreeNode>;
                      }
                    } else {
                      if (!this.state.hideNoDownTier1) {
                        return <TreeNode label={chartNodeButton(line)}></TreeNode>;
                      }
                    }
                  }
                })}
              </Tree>
            </div>
            <br/>
            <h6 className="mt-1">* Click referee's name to see member's details</h6>
            <h6>* Click <FontAwesomeIcon icon={faUser}/> to view downlines</h6>
      </div>
    );
  }

  renderUpgradeWarning() {
    const roleId = get(this.props.auth, "role.id", 0);
    if ([4, 5, 6, 1].indexOf(roleId) >= 0) return;

    return (
      <Row className="my-3">
        <Col>
          <Alert variant="warning" className="p-3">
            <Alert.Heading>
              You are NOT CURRENTLY ELIGIBLE for the Affiliate program.
            </Alert.Heading>
            <p>
              You must be a VIP or SIGNATURE member to take advantage. You will
              only earn commission on transactions and purchases made AFTER
              you've upgraded your account.
            </p>
            <Alert.Link>
              <Link to="/upgradeAccount">
                Click here to upgrade your account now
              </Link>
            </Alert.Link>
          </Alert>
        </Col>
      </Row>
    );
  }
}
function mapStateToProps(state: any) {
  return { auth: state.auth, createdSessions: state.userSessions };
}
export const Affiliate = connect(
  mapStateToProps,
  actions
)(withRouter(AffiliatesV2Component));

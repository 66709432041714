import { Spinner } from "react-bootstrap";
import React from "react";
import "../scss/loader.css"

export class Splash extends React.Component<any, any> {
    render() {
        return (
            <div className="min-vh-100 text-white d-flex align-items-center text-center loader-background">
                <Spinner animation="border" style={{ margin: "0 auto" }} />
            </div>
        );
    }

}




export const ReferalDefaults = {
    ranges: {
        today: {
            label: "Today",
            collate: "hourly",
        },
        yesterday: {
            label: "Yesterday",
            collate: "hourly"
        },
        last7days: {
            label: "Last 7 Days",
            collate: "daily",
        },
        last14days: {
            label: "Last 14 Days",
            collate: "daily",
        },
        last28days: {
            label: "Last 28 Days",
            collate: "daily",
        },
        thisWeek: {
            label: "This Week",
            collate: "daily",
        },
        lastWeek: {
            label: "Last Week",
            collate: "daily",
        },
        thisMonth: {
            label: "This Month",
            collate: "daily",
        },
        lastMonth: {
            label: "Last Month",
            collate: "daily",
        },
        thisYear: {
            label: "This Year",
            collate: "monthly",
        },
        lastYear: {
            label: "Last Year",
            collate: "monthly",
        }
    }
}
import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/remote-config";
import "firebase/analytics";

import {omitBy, isNil} from "lodash";


const config = {
   apiKey: process.env.REACT_APP_FIREBASE_KEY,
   authDomain: process.env.REACT_APP_FIREBASE_DOMAIN || `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || `https://${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER,
   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT,
   appId: process.env.REACT_APP_FIREBASE_APP
};

if (!firebase.apps.length) {
   firebase.initializeApp(omitBy(config, isNil));
}

// export const firebaseLoginCall = async (email, password) => {
//    try {
//       const data = await firebase
//          .auth()
//          .signInWithEmailAndPassword(email, password);

//       const token = await Firebase.Auth.currentUser.getIdToken();
//       console.log('Successfully logged in and got token:', token);
//       return token;
//    } catch (error) {
//       console.log('Error logging in to firebase', error);
//       return null;
//    }
// };

// async function testCall() {
//    const token = await firebaseLoginCall('mightguydev@gmail.com', 'aaAA22@@');
// }

//testCall();

//setup email auth
const auth = firebase.auth();

//setup facebookAuth
const facebookAuth = new firebase.auth.FacebookAuthProvider();
facebookAuth.addScope("email");
facebookAuth.addScope("user_age_range");
facebookAuth.addScope("user_birthday");
facebookAuth.addScope("user_gender");
facebookAuth.addScope("user_location");
facebookAuth.addScope("user_hometown");
//firstName,lastName,middle_name,name,name_format,picture,short_name,email");
const googleAuth = new firebase.auth.GoogleAuthProvider();


//setup remote config
const remoteConfig = config.appId ? firebase.remoteConfig() : null;

// Only set analytics if in production
const analytics = config.appId ? firebase.analytics() : null;

export const Firebase = {
   Auth: auth,
   Analytics: analytics,
   FacebookAuth: facebookAuth,
   GoogleAuth: googleAuth,
   RemoteConfig: remoteConfig,
}

export async function GetCurrentFirebaseToken(forceRefresh: boolean = false): Promise<string | null> {
   if (auth && auth.currentUser)
       return await auth.currentUser.getIdToken(forceRefresh);

   return null;
}

import {Config, API, APIAxios} from "../APIAndConfig";
import axios from "axios";
// import { FETCH_USER, ERROR_MESSAGE } from './types';
import {FETCH_USER} from "./types";
import {Firebase} from"../firebase";
import set from "date-fns/set";

export const handleToken = (
  token,
  auth,
  amount,
  credit,
  description,
  transactionTypeId
) => async dispatch => {
  const res = await axios.post(Config.APIUrl + "/api/stripe", {
    token: token,
    auth: auth,
    amount,
    credit,
    description,
    transaction_type_id: transactionTypeId
  });

  //get back current user model with updated credits
  dispatch({ type: FETCH_USER, payload: res.data });
};

export const handleSubscription = (
  token,
  auth,
  amount,
  credit,
  transactionTypeId
) => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.post(
    Config.APIUrl+"/api/stripe/subscription",
    {
      token: token,
      auth: auth,
      amount,
      credit,
      transaction_type_id: transactionTypeId
    },
    { headers: header }
  ).then((res) => {
    dispatch({ type: FETCH_USER, payload: res.data });
  }).catch( async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
        await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

  //get back current user model with updated credits
  // dispatch({ type: FETCH_USER, payload: res.data });
};

export const handleProSubscription = (
  token,
  auth,
  amount,
  credit,
  transactionTypeId
) => async dispatch => {
  const fbtoken = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + fbtoken
  };

  await axios.post(
    Config.APIUrl+"/api/stripe/subscription_pro",
    {
      token: token,
      auth: auth,
      amount,
      credit,
      transaction_type_id: transactionTypeId
    },
    { headers: header }
  ).then((res) => {
    dispatch({ type: FETCH_USER, payload: res.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

  //get back current user model with updated credits
  // dispatch({ type: FETCH_USER, payload: res.data });
};

export const cancelSubscription = auth => async dispatch => {
  const fbtoken = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + fbtoken
  };

  await axios.post(
    Config.APIUrl+"/api/stripe/cancel_subscription",
    {
      auth: auth
    },
    { headers: header }
  ).then((res) => {
    dispatch({ type: FETCH_USER, payload: res.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

  //get back current user model with updated credits
  // dispatch({ type: FETCH_USER, payload: res.data });
};

export const cancelProSubscription = auth => async dispatch => {
  console.log("cancelProSubscription");
  const fbtoken = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + fbtoken
  };

  await axios.post(
    Config.APIUrl+"/api/stripe/cancel_pro_subscription",
    {
      auth: auth
    },
    { headers: header }
  ).then((res) => {
    dispatch({ type: FETCH_USER, payload: res.data });
  }).catch(async (err) => {
    switch (err.response.data) {
      case "SESSION_EXPIRED":
        const token = await Firebase.Auth.currentUser?.getIdToken();
        if (token) {
          await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
          window.location.reload()
        }
        break;
      case "BAD_CONNECTION":
      case "UNCAUGHT_ERROR":
      case "DATABASE_LIMIT_EXCEEDED":
        localStorage.setItem('service_unavailable_status', "failed");
await Firebase.Auth.signOut();
        window.location.href = "/error"
        break;
      default:
        console.log("Error", err);
        break;
    }
  });

  //get back current user model with updated credits
  // dispatch({ type: FETCH_USER, payload: res.data });
};

import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class NotFound extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: null
      };
   }

   componentDidMount() {
      //this.hideSideBar();
   }

   componentDidUpdate() {
      //this.hideSideBar();
   }

   componentWillUnmount() {
      //this.showSideBar();
   }

   hideSideBar() {
      if (document.getElementById('sidebar')) {
         document.getElementById('sidebar').style.display('none');
      }
   }

   showSideBar() {
      if (document.getElementById('sidebar')) {
         document.getElementById('sidebar').style.display('block');
      }
   }

   render() {
      return (
         <div className="mainWidth">
            <center>
               <p>404 Page Not Found</p>
            </center>
            <center>
               <Link to="/">Return to Home Page</Link>
            </center>
         </div>
      );
   }
}

export default NotFound;

export const readAsBase64 = async (url) => {
  // Fetch the photo, read as a blob, then convert to base64 format
  try {
    const response = await fetch(url);
    const blob = await response.blob();
  
    return await convertBlobToBase64(blob);
  }
  catch (err) {
    console.error(err);
    return null;
  }
};

const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { Firebase } from "../../firebase";

import Loader from "../common/Loader";

import "../../scss/UpgradeAccount.css";
import "../../scss/upgrade_account.scss";
import {
  faBars,
  faCheck,
  faBrands,
  faApplePay,
  faApple,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "../../APIAndConfig";
import { getStripe } from "../../stripe";
import { get } from "lodash";
import { Card, Col, Container, Row, Spinner } from "react-bootstrap";
import PayPalBtn from "../login/PayPalBtn";
import axios from "axios";
import CryptoJS from "crypto-js";
import { BrandingWatermarkSharp } from "@material-ui/icons";
import { FaApplePay } from "react-icons/fa";
import {
  InAppPurchase2 as iap,
  IAPProduct,
} from "@ionic-native/in-app-purchase-2";
import { Capacitor } from "@capacitor/core";

class UpgradeAccountV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      token: null,
      processing: false,
      vipDiscount: false,
      isProcessingYear: false,
      isProcessingYearIOS: false,
      isProcessingYearPaypal: false,
      isProcessingMonth: false,
      planId: null,
      plan: null,
      productPrice: "",
      product: [],
    };
  }

  //if on an ios or android device, then get product info
  initInAppPurchase = () => {
    const isPlatformMobile = Capacitor.getPlatform();
    if (isPlatformMobile === "ios" || isPlatformMobile === "android") {
      iap.verbosity = iap.DEBUG;

      const productId = "signatureyearly4";

      iap.register({
        //com.positiveprime.app.signatureyearly
        id: productId,
        type: iap.PAID_SUBSCRIPTION,
      });

      iap.ready(() => {
        let product = iap.get(productId);
        this.setState({ productPrice: product.price, product: product });
      });

      iap.refresh();

      // Called when store.order("signatureyearly") is executed. The user can
      // still cancel after this has been called.
      iap.when(productId).initiated((product) => {
        // Write a function that identifies this product ID as having been
        // initiated to purchase.
        console.log("Initiated");
      });

      iap.when(productId).requested((product) => {
        console.log("Requested: " + JSON.stringify(product));
      });

      iap.when(productId).invalid((product) => {
        console.log("INVALID: " + JSON.stringify(product));
      });

      // Register event handlers for the specific product
      iap.when(productId).registered((product) => {
        console.log("Registered: " + JSON.stringify(product));
      });

      // Upon approval, verify the receipt.
      iap.when(productId).approved((product) => {
        console.log("approved");
        product.verify();
      });

      // Upon receipt validation, mark the subscription as owned.
      iap.when(productId).verified((product) => {
        console.log("verified");
        product.finish();
      });

      iap.when(productId).cancelled((product) => {
        console.log("cancelled");
        console.error("Purchase was Cancelled");
      });

      iap.when(productId).updated((product) => {
        if (product.owned) {
          console.log("Product Owned:" + product.id);
          // continue subs
        } else {
          console.log(
            "ID:" +
              product.id +
              "Price:" +
              product.price +
              "/" +
              product.priceMicros
          );
          console.log("Description:" + product.description);
          // expired subs
          this.setState({
            isProcessingYearIOS: !this.state.isProcessingYearIOS,
          });
        }
      });

      // User closed the native purchase dialog
      iap.when(productId).cancelled((product) => {
        console.log("cancelled");
        console.error("Purchase was Cancelled");
      });

      // Track all store errors
      iap.error((err) => {
        console.log("error");
        console.log("Store Error", JSON.stringify(err));
        this.setState({ isProcessingYearIOS: !this.state.isProcessingYearIOS });
      });
    }
  };

  //if user clicks purchase button
  purchaseProduct = () => {
    if (this.state.product.owned) {
      alert("Product already owned");
    } else {
      iap.order("signatureyearly4").then(() => {
        //com.positiveprime.app.signatureyearly ?
        iap.when("signatureyearly4").approved((p) => {
          //store product
          p.verify();
          p.finish();
        });
      });
      iap.refresh();
    }
  };

  purchaseProductMonthly = () => {
    // TODO: Monthly Purchase here
  };

  //if user clicks retore or promo code button
  restore = () => {
    //com.positiveprime.app.signatureyearly ?
    iap.when("signatureyearly4").owned((p) => {
      if (this.state.product.owned) {
        //store product
      } else {
        alert("You have not purchased this product before.");
      }
    });
    iap.refresh();
  };

  componentWillMount() {
    console.log("vlaus", this.props.auth.role.id, Date.now());
    if (
      Date.now() < new Date("Fri, 23 Apr 2021 23:59:59 +0000").getTime() &&
      this.props.auth.role.id == 2
    ) {
      console.log("enter");
      this.props.history.push("/upgradeAccountLife");
    }
    const stripeSession = new URLSearchParams(this.props.location.search).get(
      "stripe"
    );
    // const init = async () => {
    //   await this.initInAppPurchase();
    // };
    // init();
  }

  componentDidMount() {
    this.initInAppPurchase();
    this.props.resetError();
    Firebase.Auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        if (this.props.auth) {
          const token = await authUser.getIdToken();
          this.state.authToken = token;
        }
      } else {
        this.props.history.push("/login");
      }
    });
  }

  componentDidUpdate() {
    if (this.props.auth) {
    }
  }

  componentWillUnmount() {
    this.props.resetError();
  }

  onOpenNav(e) {
    document.getElementById("sidebar").classList.add("sidebarOpen");
  }

  async handleCheckout(plan) {
    this.setState({ processing: true });
    const result = await API.startCheckout(plan);
    const stripe = await getStripe();

    const sessionId = get(result, "payload.checkout.id");
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
  }

  async handleSignatureMonthly() {
    return await this.handleCheckout("signature-monthly");
  }
  async handleSignatureYearly() {
    return await this.handleCheckout("signature-yearly");
  }

  // async handleSignatureYearlyIOS() {
  //     const store = InAppPurchase2
  //     store.order('signatureyearly')
  // }

  para(props) {
    return <p className="text-dark">{props.children}</p>;
  }

  points(props) {
    return <li className="text-dark">{props.children}</li>;
  }

  paypalSubscribe = (data, actions) => {
    // console.log("planid",this.state.planId)
    return actions.subscription.create({
      plan_id: this.state.planId,
    });
  };

  paypalOnApprove = (data, detail) => {
    // call the backend api to store transaction details
    //console.log("In PaypalOnApprove");
    //console.log("Deatils in paypalOnApprove: ",detail);
    //console.log("Data in paypalOnApprove: ",data);
    //console.log("get plan",this.state.plan)
    this.paypalPayment(data, this.state.plan);
  };

  paypalOnError = (err) => {
    console.log("Error: ", err);
  };

  yearlyButton = async () => {
    this.setState({
      plan: "Signature Yearly",
    });

    await axios
      .get(`${process.env.REACT_APP_API}/get_plan_key/${this.state.plan}`)
      .then((response) => {
        // handle success
        //console.log("response",response);
        //console.log("message is here",response.data.message);
        //console.log("env=",process.env.REACT_APP_PAYPAL_PLAN_ID_SECRET_KEY);
        let bytes = CryptoJS.AES.decrypt(
          response.data.message,
          process.env.REACT_APP_PAYPAL_PLAN_ID_SECRET_KEY
        );
        let originalKey = bytes.toString(CryptoJS.enc.Utf8);

        //console.log("message",originalKey);
        this.setState({
          planId: originalKey,
        });
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  monthlyButton = async () => {
    this.setState({
      plan: "Signature Monthly",
    });
    await axios
      .get(`${process.env.REACT_APP_API}/get_plan_key/${this.state.plan}`)
      .then((response) => {
        // handle success
        //console.log("response",response);
        //console.log("message is here",response.data.message);
        // console.log("env=",process.env.REACT_APP_PAYPAL_PLAN_ID_SECRET_KEY);
        let bytes = CryptoJS.AES.decrypt(
          response.data.message,
          process.env.REACT_APP_PAYPAL_PLAN_ID_SECRET_KEY
        );
        let originalKey = bytes.toString(CryptoJS.enc.Utf8);

        //console.log("message ",originalKey);
        this.setState({
          planId: originalKey,
        });
      })
      .catch((error) => {
        // handle error
        console.log(error);
      });
  };

  async paypalPayment(data, customer_plan) {
    //console.log("paypalrequest data",data)
    await API.trialPaypalCheckout(customer_plan, {
      user: this.props.auth,
      orderID: data.orderID,
      subscription_Id: data.subscriptionID,
    });
  }

  render() {
    if (this.state.processing) return <Loader />;
    return (
      <div className="main_div">
        <Container>
          <div className="d-flex flex-column jutify-content-center align-items-center">
            <div className="mt-5" style={{ color: "black" }}>
              SIGNATURE PROGRAM
            </div>
            {Capacitor.getPlatform() === "ios" ? (
              <h1 className="main-title plan_title">$199.99usd /yr</h1>
            ) : (
              <h1 className="main-title plan_title">$199.00usd /yr</h1>
            )}
            <div className="membership_subtitle">
              Membership includes ALL of the following:
            </div>
            <Row className="mt-4 d-flex justify-content-center flex-md-row flex-column">
              <Col className="col-12 col-md-4">
                <Card
                  className="main_card h-100 pt-4 pb-4"
                  style={{
                    backgroundImage: `url(/assets/images/upgrade_acccount_block.jpg)`,
                  }}
                >
                  <Card.Body style={{ color: `white` }}>
                    <Card.Title className="ml-3 mb-4 font-weight-bold">
                      Positive Prime Full Access
                    </Card.Title>
                    <ul>
                      <li>
                        Access to all 100+ Positive Prime Sessions created by
                        world class coaches, including Lisa Barnett, Dana Wilde
                        and Joe Vitale
                      </li>
                      <li>
                        New Sessions added weekly on topics like finding wealth,
                        beating anxiety and discovering daily happiness
                      </li>
                      <li>
                        <span className="font-weight-bold">
                          Personalise your experience
                        </span>{" "}
                        by uploading & integrating your own photos into your
                        Sessions
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-12 col-md-4 mt-4 mt-md-1">
                <Card className="h-100 pt-4 pb-4 main_card">
                  <Card.Body style={{ color: `black` }}>
                    <Card.Title className="ml-3 mb-4 font-weight-bold">
                      Exclusive Extras
                    </Card.Title>
                    <ul>
                      <this.points>
                        <span className="font-weight-bold">
                          A Brand New SuperMix Session added
                        </span>{" "}
                        every month – SuperMixes combine multiple sessions for
                        supercharging visual hypnosis and discovering a deeper
                        state of Positive Priming.
                      </this.points>
                      <this.points>
                        <span className="font-weight-bold">
                          Bonus Session Support E-Guides:
                        </span>{" "}
                        including empowerment Flash Cards
                      </this.points>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-12 col-md-4 mt-4 mt-md-1">
                <Card className="h-100 pt-4 pb-4 main_card">
                  <Card.Body style={{ color: `black` }}>
                    <Card.Title className="ml-3 mb-4 font-weight-bold">
                      Bonus Gifts
                    </Card.Title>
                    <ul>
                      <this.points>
                        Positive Prime Exclusive{" "}
                        <span className="font-weight-bold">
                          7 Magnificent Days of Think and Grow Rich
                        </span>{" "}
                        Mini-Course
                      </this.points>
                      <this.points>
                        <span className="font-weight-bold">
                          6x Positive Prime Bonus Gifts
                        </span>{" "}
                        from our community of coaches to empower you to have a
                        happier and healthier life both at home and in the
                        workplace. And more to come!
                      </this.points>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4 d-flex justify-content-center flex-md-row flex-column mb-4">
              <Col className="col-12 col-md-6">
                <Card className="h-100 pt-4 pb-4 main_card">
                  <Card.Body className="pl-4 pr-4" style={{ color: `black` }}>
                    <Card.Title className="font-weight-bold">
                      5 for the price of 1
                    </Card.Title>
                    <Card.Title
                      className="mb-4 font-weight-bold"
                      style={{ color: "#333" }}
                    >
                      Family & Friends Sharing - Celebrate Together
                    </Card.Title>
                    <Card.Subtitle className="mb-4 font-italic font-weight-bold text-dark">
                      Help each other be accountable
                    </Card.Subtitle>
                    <this.para>
                      For your Family, Friends, and Clients:{" "}
                      <span className="font-weight-bold">
                        Gift a full (equal) subscription
                      </span>{" "}
                      to Positive Prime by inviting up to 4 other people.
                    </this.para>
                    <this.para>
                      Whilst the primary account holder continues with an active
                      subscription, those who are invited to share will have
                      full access (ie. a free account that is a gift) therefore,
                      there’s no charge for the 4 others related to the main
                      account.
                    </this.para>
                    {Capacitor.getPlatform() === "ios" ? (
                      <this.para>
                        Activate the invitations & send to your four favourite
                        people from within your account.
                      </this.para>
                    ) : (
                      <this.para>
                        Activate the invitations & send to your four favourite
                        people from within your account, even during your first
                        10-days on your free trial. You can all try at the same
                        time!
                      </this.para>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-12 col-md-6 mt-4 mt-md-1">
                <Card
                  className="h-100 pt-4 pb-4"
                  style={{
                    borderRadius: "15px",
                    lineHeight: "26px",
                    maxWidth: "338px",
                  }}
                >
                  <Card.Body style={{ color: `black` }}>
                    <Card.Title className="ml-3 mb-4 font-weight-bold">
                      Support, Education & Guidance
                    </Card.Title>
                    <ul>
                      <this.points>
                        Monthly group mentoring calls with founder and creator
                        of Positive Prime – Kim Serafini
                      </this.points>
                      <this.points>
                        Weekly micro-lessons via email – Sharing wisdom,
                        inspiration and the most effective ways to get the best
                        out of your Positive Prime Sessions.
                      </this.points>
                      <this.points>
                        100s of exceptional resources to support your journey
                        and much more.
                      </this.points>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="w-100 mb-5 d-flex justify-content-center">
              <Col className="col-md-6 col-10">
                <Card
                  className="h-100 pt-4 pb-4"
                  style={{
                    borderRadius: "15px",
                    background:
                      "linear-gradient(87.95deg,#857FF1 -36.33%,#171717 107.87%)",
                  }}
                >
                  <Card.Body
                    style={{ color: `white` }}
                    className="d-flex flex-column jutify-content-center align-items-center"
                  >
                    <h1
                      className="main-title text-center"
                      style={{ fontSize: "45px", fontWeight: "bold" }}
                    >
                      Monthly
                    </h1>
                    <p
                      style={{ fontSize: "22px" }}
                      className="d-flex flex-column justidy-content-center align-items-center mt-1"
                    >
                      {Capacitor.getPlatform() === "ios" ? (
                        <span>US$19.99 billed monthly,</span>
                      ) : (
                        <span>US$19.00 billed monthly,</span>
                      )}
                      <span>cancel anytime</span>
                    </p>
                    {/* IOS monthly button */}
                    {Capacitor.getPlatform() === "ios" && (
                      <button
                        // className="firstbutton mt-2"
                        className={
                          this.props.auth.subscriptionType ===
                            "signature-yearly" ||
                          this.props.auth.subscriptionType ===
                            "signature-monthly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Yearly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Monthly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Coaching Recurring" ||
                          this.props.auth.subscriptionType ===
                            "SignatureCoachingR"
                            ? "firstbutton mt-2 button-grey-2"
                            : "firstbutton mt-2 button-white-2"
                        }
                        onClick={() => {
                          this.setState({
                            isProcessingMonth: !this.state.isProcessingMonth,
                          });
                          this.purchaseProductMonthly();
                        }}
                        disabled={
                          this.props.auth.subscriptionType ===
                            "signature-yearly" ||
                          this.props.auth.subscriptionType ===
                            "signature-monthly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Yearly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Monthly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Coaching Recurring" ||
                          this.props.auth.subscriptionType ===
                            "SignatureCoachingR"
                        }
                      >
                        {this.state.isProcessingMonth ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="p-2 ml-5 mr-5"
                          />
                        ) : (
                          <span className="p-2" style={{ color: "#4c4b4b" }}>
                            Subscribe for US$19.99/month
                          </span>
                        )}
                      </button>
                    )}
                    {/* stripe monthly button  */}
                    {Capacitor.getPlatform() !== "ios" && (
                      <button
                        // className="firstbutton mt-2"
                        className={
                          this.props.auth.subscriptionType ===
                            "signature-yearly" ||
                          this.props.auth.subscriptionType ===
                            "signature-monthly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Yearly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Monthly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Coaching Recurring" ||
                          this.props.auth.subscriptionType ===
                            "SignatureCoachingR"
                            ? "firstbutton mt-2 button-grey-2"
                            : "firstbutton mt-2"
                        }
                        onClick={() => {
                          this.setState({
                            isProcessingMonth: !this.state.isProcessingMonth,
                          });
                          this.handleSignatureMonthly();
                        }}
                        disabled={
                          this.props.auth.subscriptionType ===
                            "signature-yearly" ||
                          this.props.auth.subscriptionType ===
                            "signature-monthly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Yearly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Monthly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Coaching Recurring" ||
                          this.props.auth.subscriptionType ===
                            "SignatureCoachingR"
                        }
                      >
                        {this.state.isProcessingMonth ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="p-2 ml-5 mr-5"
                          />
                        ) : (
                          <span className="p-2">Subscribe</span>
                        )}
                      </button>
                    )}
                    <br />
                    {/* paypal monthly button  */}
                    {Capacitor.getPlatform() !== "ios" && (
                      <>
                        {this.props.auth.subscriptionType ===
                          "Signature Yearly" ||
                        this.props.auth.subscriptionType ===
                          "Signature Monthly" ||
                        this.props.auth.subscriptionType ===
                          "signature-yearly" ||
                        this.props.auth.subscriptionType ===
                          "signature-monthly" ||
                        this.props.auth.subscriptionType ===
                          "Signature Coaching Recurring" ||
                        this.props.auth.subscriptionType ===
                          "SignatureCoachingR" ? (
                          <button
                            className="secondbutton mt-2 "
                            onClick={() => {
                              this.setState({
                                isProcessingMonth:
                                  !this.state.isProcessingMonth,
                              });
                              //this.handleSignatureMonthly()
                            }}
                            disabled={
                              this.props.auth.subscriptionType ===
                                "signature-yearly" ||
                              this.props.auth.subscriptionType ===
                                "Signature Yearly" ||
                              this.props.auth.subscriptionType ===
                                "Signature Coaching Recurring" ||
                              this.props.auth.subscriptionType ===
                                "SignatureCoachingR"
                            }
                          >
                            {this.state.isProcessingMonth ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                className="p-2 ml-5 mr-5"
                              />
                            ) : (
                              <span className="p-2">
                                <i>PayPal</i>
                              </span>
                            )}
                          </button>
                        ) : (
                          <PayPalBtn
                            onClick={this.monthlyButton}
                            currency="USD"
                            createSubscription={this.paypalSubscribe}
                            onApprove={this.paypalOnApprove}
                            catchError={this.paypalOnError}
                            onError={this.paypalOnError}
                            onCancel={this.paypalOnError}
                          />
                        )}
                      </>
                    )}
                    <a
                      href="https://www.positiveprime.com/privacy-policy/"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      Privacy Policy
                    </a>
                    <a href="https://app.positiveprime.com/terms">
                      Terms and Conditions
                    </a>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-md-6 col-10 mt-4 mt-md-1">
                <Card
                  className="h-100 pt-4 pb-4"
                  style={{
                    borderRadius: "15px",
                    background:
                      "linear-gradient(87.95deg,#857FF1 -36.33%,#171717 107.87%)",
                  }}
                >
                  <Card.Body
                    style={{ color: `white` }}
                    className="d-flex flex-column jutify-content-center align-items-center"
                  >
                    <h1
                      className="main-title text-center"
                      style={{ fontSize: "45px", fontWeight: "bold" }}
                    >
                      Discounted Yearly
                    </h1>
                    <p
                      style={{ fontSize: "22px" }}
                      className="d-flex flex-column justidy-content-center align-items-center mt-1"
                    >
                      {Capacitor.getPlatform() === "ios" ? (
                        <span>US$199.99 billed monthly,</span>
                      ) : (
                        <span>US$199.00 billed monthly,</span>
                      )}
                      <span>cancel anytime</span>
                    </p>
                    {/* Stripe yearly button */}
                    {Capacitor.getPlatform() !== "ios" && (
                      <button
                        //className="secondbutton mt-2"
                        className={
                          this.props.auth.subscriptionType ===
                            "Signature Yearly" ||
                          this.props.auth.subscriptionType ===
                            "signature-yearly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Coaching Recurring" ||
                          this.props.auth.subscriptionType ===
                            "SignatureCoachingR"
                            ? "secondbutton mt-2 button-grey-2"
                            : "secondbutton mt-2"
                        }
                        onClick={() => {
                          this.setState({
                            isProcessingYear: !this.state.isProcessingYear,
                          });
                          this.handleSignatureYearly();
                        }}
                        disabled={
                          this.props.auth.subscriptionType ===
                            "Signature Yearly" ||
                          this.props.auth.subscriptionType ===
                            "signature-yearly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Coaching Recurring" ||
                          this.props.auth.subscriptionType ===
                            "SignatureCoachingR"
                        }
                      >
                        {this.state.isProcessingYear ? (
                          <Spinner
                            animation="border"
                            size="sm"
                            className="p-2 ml-5 mr-5"
                          />
                        ) : (
                          <span className="p-2">Credit Card</span>
                        )}
                      </button>
                    )}
                    <br />
                    {/* IOS yearly button */}
                    {Capacitor.getPlatform() === "ios" && (
                      <button
                        className={
                          this.props.auth.subscriptionType ===
                            "Signature Yearly" ||
                          this.props.auth.subscriptionType ===
                            "signature-yearly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Coaching Recurring" ||
                          this.props.auth.subscriptionType ===
                            "SignatureCoachingR"
                            ? "firstbutton mt-2 button-grey-2"
                            : "firstbutton mt-2 button-white-2"
                        }
                        onClick={() => {
                          this.setState({
                            isProcessingYearIOS:
                              !this.state.isProcessingYearIOS,
                          });
                          this.purchaseProduct();
                        }}
                        disabled={
                          this.props.auth.subscriptionType ===
                            "Signature Yearly" ||
                          this.props.auth.subscriptionType ===
                            "signature-yearly" ||
                          this.props.auth.subscriptionType ===
                            "Signature Coaching Recurring" ||
                          this.props.auth.subscriptionType ===
                            "SignatureCoachingR"
                        }
                      >
                        {this.state.isProcessingYearIOS ? (
                          <Spinner
                            variant="dark"
                            animation="border"
                            size="sm"
                            className="p-2 ml-5 mr-5"
                          />
                        ) : (
                          <span className="p-2" style={{ color: "#4c4b4b" }}>
                            Subscribe for US$199.99/year
                          </span>
                        )}
                      </button>
                    )}
                    <br />
                    {/* paypal yearly button  */}
                    {Capacitor.getPlatform() !== "ios" && (
                      <>
                        {this.props.auth.subscriptionType ===
                          "Signature Yearly" ||
                        this.props.auth.subscriptionType ===
                          "signature-yearly" ||
                        this.props.auth.subscriptionType ===
                          "Signature Coaching Recurring" ||
                        this.props.auth.subscriptionType ===
                          "SignatureCoachingR" ? (
                          <button
                            className="secondbutton mt-2 button-grey"
                            onClick={() => {
                              this.setState({
                                isProcessingYearPayPal:
                                  !this.state.isProcessingYearPayPal,
                              });
                            }}
                            disabled={
                              this.props.auth.subscriptionType ===
                                "Signature Yearly" ||
                              this.props.auth.subscriptionType ===
                                "signature-yearly" ||
                              this.props.auth.subscriptionType ===
                                "Signature Coaching Recurring" ||
                              this.props.auth.subscriptionType ===
                                "SignatureCoachingR"
                            }
                          >
                            {this.state.isProcessingYearPayPal ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                className="p-2 ml-5 mr-5"
                              />
                            ) : (
                              <span className="p-2">
                                <i>PayPal</i>
                              </span>
                            )}
                          </button>
                        ) : (
                          <PayPalBtn
                            onClick={this.yearlyButton}
                            currency="USD"
                            createSubscription={this.paypalSubscribe}
                            onApprove={this.paypalOnApprove}
                            catchError={this.paypalOnError}
                            onError={this.paypalOnError}
                            onCancel={this.paypalOnError}
                          />
                        )}
                      </>
                    )}
                    <a
                      href="https://www.positiveprime.com/privacy-policy/"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      Privacy Policy
                    </a>
                    <a href="https://app.positiveprime.com/terms">
                      Terms and Conditions
                    </a>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps, actions)(UpgradeAccountV3);

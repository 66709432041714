import React from 'react';
import {
    Container,
    Row,
    Col
} from 'react-bootstrap';
import ReferalRangePicker from './ReferalRangePicker'
import * as actions from "../../actions";
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from "@material-ui/core";
import { API } from "../../APIAndConfig";
import { User } from '@sentry/browser';

export interface UserList {
    id: string,
    userId: string,
    title: string,
    first_name: string,
    last_name: string,
    loading: boolean[]
}
class ReferalAnalysisV2 extends React.Component {
    state = {
        userList: [] as UserList[],
        loading: [false],
        currentUserRole: [-1],
        userId: [""]
    }
    
    currentSelected: UserList = {
        id: "",
        userId: "",
        title: "",
        first_name: "",
        last_name: "",
        loading: []
    };

    allUsers: UserList[] = [];

    componentDidMount(){
        try{

            let userpilotUser: any = JSON.parse(localStorage.getItem('userpilotUser') as string);
            API.getUser(userpilotUser.userid).then((result: any) => {
                this.state.currentUserRole[0] = result.payload.user.role.id;
                this.setState(this.state);
            });
            (async()=> {
                this.allUsers = ((await API.getUsers() as any).payload).data;
            })();
        } catch(e){

        }
    }
    render() {
        return <div className="">
            <Container>
                <Row>
                    <Col>
                        <h2 className="main-title m-0 p-3">Referral Analysis</h2>
                    </Col>
                    <Col xs={4}></Col>
                    <Col>
                        {this.state.currentUserRole[0] &&<Autocomplete
                            id="combo-box-demo"
                            options={this.state.userList}
                            getOptionLabel={(option) => {
                                    this.currentSelected = option;
                                    return option.title
                                }
                            }
                            onChange={async e => {
                                this.state.userId[0] = this.currentSelected.userId;
                                this.setState(this.state);
                            }}
                            style={{ width: 300, backgroundColor: "white", borderRadius: "4px" }}
                            renderInput={(params) => <TextField 
                                {...params} 
                                label="Search User Here" 
                                variant="outlined" 
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                        <React.Fragment>
                                            {this.state.loading[0] ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                        ),
                                    }}
                                />}
                            onInputChange={async e => {
                                    try{
                                        const filteredData = (data: any, searchKey: string) => {
                                            return JSON.parse(JSON.stringify(data)).filter((x: any) => 
                                            (x.first_name || "").toLowerCase().includes(searchKey) || 
                                            (x.last_name || "").toLowerCase().includes(searchKey) ||  
                                            ((x.first_name || "") + " " + (x.last_name || "")).toLowerCase().includes(searchKey))
                                        }
                                        let searchString = ((e.target as HTMLInputElement).value || "").toLowerCase();
                                        
                                        if(searchString === ""){
                                            this.state.userList.splice(0, this.state.userList.length);
                                            this.setState(this.state);
                                            return;
                                        }
                                        
                                        if(filteredData(this.allUsers, searchString).length == 0){
                                            this.state.loading[0] = true;
                                            this.allUsers = ((await API.getUsers() as any).payload).data;
                                        }
                                        let response = filteredData(this.allUsers, searchString);
                                        this.state.loading[0] = false;
                                        this.state.userList.splice(0, this.state.userList.length);
                                        response.forEach((x: any) => {
                                            this.state.userList.push({
                                                id: x.userId.toString(),
                                                userId: x.userId.toString(),
                                                title: x.first_name + " " + x.last_name,
                                                first_name: x.first_name,
                                                last_name: x.last_name
                                            } as UserList);
                                        });
                                        this.state.userList.sort((a: any, b: any) => {
                                            const result = a.first_name.toLowerCase().localeCompare(b.first_name.toLowerCase());
                                            return result !== 0 ? result : a.last_name.toLowerCase().localeCompare(b.last_name.toLowerCase());
                                        });
                                        this.setState(this.state)
                                    } catch(e){
                                        console.error(e)
                                    }
                                }
                            }
                        />}
                    </Col>
                </Row>
            </Container>
            <hr className="m-0" />

            <div >
                <Container fluid={true}>
                    <Row >
                        <Col className="text-center">
                            <ReferalRangePicker temp="" userId={this.state.userId[0]} />
                        </Col>
                    </Row>
                </Container>
            </div>

        </div>
    }
}

export default ReferalAnalysisV2
import { Col, Row, Form, Button, Spinner, Alert } from "react-bootstrap";
import Loader from "../common/Loader";
import React, { ChangeEvent } from "react";
// import { IntroWrap } from "./IntroWrapV2";
import { Link } from "react-router-dom";
import { Firebase } from "../../firebase";
import { get, set } from "lodash";
import { cookies } from "../../index";
import { API } from "../../APIAndConfig";
import '../../scss/loginV2.css'
import { createBrowserHistory } from "history";
import * as firebase from "firebase/app";
export interface LoginState {
    isProcessing: boolean
    email: string;
    emailIsValid: boolean | null;
    emailFeedback: string | null;
    password: string;
    passwordIsValid: boolean | null;
    passwordFeedback: string | null;
    otherError: string | null;
    firebaseRes: any
}

export class LoginV3 extends React.Component<any, LoginState> {

    constructor(props: any) {
        super(props);
        this.state = {
            // email: cookies.get("email") && cookies.get("email") !== "undefined" && cookies.get("email") !== "null" ? cookies.get("email") : "",
            email: '',
            password: '',
            isProcessing: false,
            emailFeedback: null,
            emailIsValid: null,
            passwordFeedback: null,
            passwordIsValid: null,
            otherError: null,
            firebaseRes:null
        }
    }

    componentDidMount() {
        if ( localStorage.getItem('session_status') === "expired") {
            this.setState({ otherError : "Session expired please re-login" })
            localStorage.removeItem('session_status')
        }
    }

    componentDidUpdate(){
        if(this.state.firebaseRes){
            createBrowserHistory({forceRefresh:true}).push({pathname : "/dashboard", state: {
                path : "from_login"
            }})
        }
    }

    async handleLogin() {
        let state: Partial<LoginState> = {
            isProcessing: this.state.email && this.state.password ? true : false,
            emailFeedback: null,
            emailIsValid: null,
            passwordFeedback: null,
            passwordIsValid: null,
            otherError: null
        }
        if (!this.state.email) {
            state.emailIsValid = false;
            state.emailFeedback = "Please enter an email address";
        }
        if (!this.state.password) {
            state.passwordIsValid = false;
            state.passwordFeedback = "Please enter your password";
        }
        await this.setState(state as object);
        if (!this.state.email || !this.state.password)
            return;

        await Firebase.Auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        Firebase.Auth.signInWithEmailAndPassword(this.state.email, this.state.password)
        .then(res => {
            this.setState({firebaseRes:res})
            console.log("FIREBASE_CHECK_RESPONSE : ",this.state.firebaseRes);
            // this.props.history.push({pathname : "/dashboard", state: {
            //     path : "from_login"
            // }})
        }).catch((error) => {
            switch (error.code) {
                case "auth/user-not-found":
                    state.emailIsValid = false;
                    state.emailFeedback = "This email address is not registered"
                    break;

                case "auth/wrong-password":
                    state.passwordIsValid = false;
                    state.passwordFeedback = "Incorrect password";

                    break;

                default:
                    console.error(error.code);
                    state.otherError = error.message || "Unknown Error";
            }
            state.isProcessing = false;
        }).finally(async () => {
            await this.setState(state as object);
        })

        // try {
        //     await Firebase.Auth.signInWithEmailAndPassword(this.state.email, this.state.password)
            
        //     // this.props.history.push('/v2/dashboard')
        //     this.props.history.push({pathname : "/dashboard", state: {
        //         path : "from_login"
        //     }})
        // } catch (error) {
        //     switch (error.code) {
        //         case "auth/user-not-found":
        //             state.emailIsValid = false;
        //             state.emailFeedback = "This email address is not registered"
        //             break;

        //         case "auth/wrong-password":
        //             state.passwordIsValid = false;
        //             state.passwordFeedback = "Incorrect password";

        //             break;

        //         default:
        //             console.error(error.code);
        //             state.otherError = error.message || "Unknown Error";
        //     }
        //     state.isProcessing = false;
        // } finally {
        //     await this.setState(state as object);
        // }
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        const state = {};
        set(state, event.target.name, event.target.value);
        this.setState(state);
    }

    handleKeypress(e: any) {
        let unicode = e.keyCode ? e.keyCode : e.charCode
        if (unicode === 13) {
            this.handleLogin();
        }
    };
    render() {
        return (
            // <IntroWrap processing={this.state.isProcessing}>
            <div className="container-fluid justify-content-center login-background3 h-100">
                <div className="row justify-content-center d-flex p-2 mt-3">

                    <div className="card col-md-4 col-sm-12 p-5 border-0" style={{ background: "transparent" }}>
                        {this.renderError()}

                        <div className="row mb-4">
                            <div className="d-flex flex-column justify-content-center align-items-center col-12">
                                <a className="loginForm text-center logo-prime" href="https://positiveprime.com">
                                    <img src="/assets/images/Logo1.0.png" alt="Positive Prime" style={{ maxWidth: "250px" }} />
                                </a>
                            </div>
                        </div>

                        <div className="form-group my-4">
                            <label htmlFor="email" className="custom-input-label">Email address</label>
                            <input
                                type="email"
                                className={"form-control custom-input " + this.getClassName(this.state.emailIsValid)}
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange.bind(this)}
                                onKeyPress={this.handleKeypress.bind(this)}
                                disabled={this.state.isProcessing}
                            />
                            <span className="errorMessage">
                                {this.state.emailFeedback}
                            </span>
                        </div>
                        {/* <div className="small text-right float-right">
                            <Link className="link-muted text-warning" to="/forgetPassword" onClick={this.handleForgotPassword.bind(this)}>Forgot Password?</Link>
                        </div> */}

                        <div className="form-group my-4">
                            <label htmlFor="password" className="custom-input-label">Password</label>
                            <input
                                type="password"
                                name="password"
                                className={"form-control custom-input " + this.getClassName(this.state.passwordIsValid)}
                                value={this.state.password}
                                onChange={this.handleChange.bind(this)}
                                onKeyPress={this.handleKeypress.bind(this)}
                                disabled={this.state.isProcessing}
                            />
                            <span className="errorMessage">
                                {this.state.passwordFeedback}
                            </span>
                        </div>

                        <div className="form-group d-flex justify-content-between align-items-center my-4">
                            <button className="btn btn-primary btn-block py-2 custom-button" type="submit" onClick={this.handleLogin.bind(this)}>Login</button>
                        </div>

                        <div className="my-5 text-success form-group  row">
                            <div className="col-md-6 text-center">
                                <span className="bottom-text mb-2 d-inline float-md-left">Don’t have an account?
                        <Link className="ml-1 d-md-inline bottom-text text-warning" to="/signup">Sign Up</Link>
                                </span>
                            </div>
                            <div className="col-md-6 text-center">
                                <span>
                                    <Link className="d-md-inline float-md-right mb-2 bottom-text" to="/forgetPassword">Forgot Password ?</Link>
                                </span>
                            </div>

                        </div>
                        <div className="u-login-form text-muted py-3 mt-auto text-center">
                            If you are having trouble please contact <a href="mailto:support@positiveprime.com">support@positiveprime.com</a>
                        </div>
                    </div>
                    <div className="d-none d-lg-flex flex-column align-items-center justify-content-center text-white " />
                </div>

            </div>
            // </IntroWrap>
        );
    }

    renderError() {
        if (!this.state.otherError)
            return;

        return (
            <Alert variant="danger">
                {this.state.otherError}
            </Alert>
        )
    }
    handleForgotPassword() {
        cookies.set("email", this.state.email);
    }
    getClassName(valid: boolean | null | undefined) {

        if (valid === null || valid == undefined) {
            return undefined;
        }

        return "is-" + (valid ? "valid" : "invalid");
    }
}

export default LoginV3;

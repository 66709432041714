import React, { Component } from "react";
import { BsCheckCircle } from "react-icons/bs";
import "./MyStyle.css";
import { cookies } from "../../index";
import { set, get } from "lodash";
import { Firebase } from "../../firebase";
import { API, APIAxios, Config } from "../../APIAndConfig";
import validator from "validator";
import CheckoutForm from "./CheckoutFormB";
import { Spinner, Alert, Form, Row, Col } from "react-bootstrap";
import publicIp from "public-ip";
import moment from "moment";
import axios from "axios"
import ReactPixel from 'react-facebook-pixel';
import '../../scss/sign_up_b.scss'
import { faBars, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaCheck } from "react-icons/fa";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";

class SignUpBn extends Component {
    constructor() {
        super();
        const referralCookie = cookies.get("referralCode");
        this.reference = React.createRef();
        this.state = {
            signUp: true,
            isProcessing: false,
            referralCode: '',
            referralCodeCookie: referralCookie,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            setEmailErrorMessage: "",
            setFirstNameErrorMessage: "",
            setLastNameErrorMessage: "",
            setPasswordErrorMessage: "",
            token: null,
            signupStatus: false,
            checkRefferal: false,
            emailFeedback: null,
            emailIsValid: null,
            passwordFeedback: null,
            passwordIsValid: null,
            otherError: null,
            chkbox: true,
            showCardInfo: false,
            form_show_check: true,
            selectedPlan: 'monthly',
            fbIframe: false

        };
        this.handler = this.handler.bind(this)
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
        const referralCode = params.get('referralCode');
        const ref = params.get('ref');
        ReactPixel.init(process.env.REACT_APP_FB_KEY);
        ReactPixel.track('PageView', {
            eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
        });
        ReactPixel.track('AddToCart', {
            eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
        });
        if (Firebase.Analytics) {
            Firebase.Analytics.logEvent("page_view");
        }
        if (ref || referralCode) {
            this.setState({ referralCode: ref || referralCode, checkRefferal: true })
        }
    }
    handleChange(event) {
        console.log("event", event.target);
        const state = {};
        set(state, event.target.name, event.target.value);
        this.setState(state);
        switch (event.target.name) {
            case "cardNumber":
                this.validateCreditCard(event.target.value);
                console.log("CardNumber");
                break;
            case "firstName":
                this.validateFirstName(event.target.value);
                console.log("firstName");
                break;
            case "lastName":
                this.validateLastName(event.target.value);
                console.log("lastName");
                break;
            case "email":
                this.validateEmail(event.target.value);
                console.log("email");
                break;
            case "password":
                this.validatePassword(event.target.value);
                console.log("password");
                break;
            case "date":
                this.validateDate(event.target.value);
                console.log("password");
                break;
            case "cvv":
                this.validateCvv(event.target.value);
                console.log("password");
                break;
            default:
                break;
        }
        // if (event.target.name == "cardNumber") {
        //   this.validateCreditCard(event.target.value);
        //   console.log("CardNumber");
        // }
    }

    validateFirstName = (value) => {
        let state = {};
        if (validator.isEmpty(value)) {
            set(state, "setFirstNameErrorMessage", "Enter First Name!");
            this.setState(state);
            console.log(value + " " + "Else");
        } else {
            set(state, "setFirstNameErrorMessage", "");
            this.setState(state);
        }
    };

    validateLastName = (value) => {
        let state = {};
        if (validator.isEmpty(value)) {
            set(state, "setLastNameErrorMessage", "Enter Last Name!");
            this.setState(state);
            console.log(value + " " + "Else");
        } else {
            set(state, "setLastNameErrorMessage", "");
            this.setState(state);
        }
    };

    validateEmail = (value) => {
        let state = {};
        console.log(value + " " + validator.isEmail(value));
        //console.log("Hello Email")
        if (validator.isEmpty(value)) {
            set(state, "setEmailErrorMessage", "Enter Email");
            this.setState(state);
            console.log(value + " " + "Else");
        } else if (!validator.isEmail(value)) {
            set(state, "setEmailErrorMessage", "Enter Valid Email");
            this.setState(state);
            console.log(value + " " + "Else");
        } else {
            set(state, "setEmailErrorMessage", "");
            this.setState(state);
        }
    };

    validatePassword = (value) => {
        let state = {};
        let totalChar = 0;
        if (validator.isEmpty(value)) {
            set(state, "setPasswordErrorMessage", "Enter Password!");
            this.setState(state);
        } else if (
            value.length < 6
        ) {
            set(state, "setPasswordErrorMessage", "Enter a Valid Password");
            this.setState(state);
        } else {
            set(state, "setPasswordErrorMessage", "");
            this.setState(state);
        }
    };

    handleSubmit = async () => {

        ReactPixel.track('Lead', {
            content_name: 'Sign Up',
            content_category: 'Meditation > wellbeing',
            content_ids: ['1234'],
            content_type: 'product',
            value: this.state.selectedPlan === 'monthly' ? 19.00 : 199.99,
            currency: 'USD',
            eventID: (Math.floor(Math.random() * 10) + 1) * new Date().getTime()
        });

        if (Firebase.Analytics)
            Firebase.Analytics.logEvent('complete_order')

        await this.setState({ isProcessing: true, fbIframe: true });
        const params = {
            email: this.state.email || "",
            firstName: this.state.firstName || "",
            lastName: this.state.lastName || "",
            password: this.state.password || "",
            confirmPassword: this.state.password || "",
            referralCode: this.state.referralCode || "epic",
        };

        let result = await API.createUser(params);
        if (result.successful && result.status === 201) {

            if (this.state.selectedPlan === 'monthly') {
                await API.trialCheckout("signature-monthly-free10trial", {
                    user: result.payload.user,
                    token: this.state.token,
                });
            } else {
                await API.trialCheckout("signature-yearly-free10trial", {
                    user: result.payload.user,
                    token: this.state.token,
                });
            }

            await Firebase.Auth.signInWithEmailAndPassword(
                this.state.email || "",
                this.state.password || ""
            );

            this.props.history.push({pathname : "/dashboard", state: {
                path : "from_login",
                infusion : true,
                referralCode : this.state.referralCode,
                emailPermissionStatus: this.state.chkbox
            }})
            cookies.remove("referralCode");
        } else {
            let state = {
                isProcessing: false,
                firstNameIsValid: null,
                firstNameFeedback: null,
                lastNameIsValid: null,
                lastNameFeedback: null,
                emailIsValid: null,
                emailFeedback: null,
                passwordIsValid: null,
                passwordFeedback: null,
                confirmPasswordIsValid: null,
                confirmPasswordFeedback: null,
                referralCodeIsValid: null,
                referralCodeFeedback: null,
            };

            let error;
            let errors = get(result.payload, "errors", []);
            for (error of errors) {
                //console.log("Error")
                set(state, `${error.param}IsValid`, false);
                set(state, `${error.param}Feedback`, error.detail);
            }
            await this.setState(state);
        }

    };

    PerkHeader(props) {
        return (
            <div className="d-flex mt-2">
                <p>{props.children}</p>
            </div>
        )
    }

    Perk(props) {
        return (
            <div className="d-flex">
                <p className="mr-3"><FaCheck color="#4b3861" size={12} /></p>
                <p className="text-black" style={{ fontSize: '16px' }}>{props.children}</p>
            </div>
        )
    }

    render() {
        return (
            <>
                <nav className="navbar navbar-custom  navbar-expand navbar-dark">
                    <a className="navbar-brand" href="#">
                        <img
                            src="/assets/images/Logo1.0.png"
                            height="30"
                            className="d-inline-block align-top"
                            alt=""
                        />
                    </a>
                </nav>
                <div style={{ background: "#6A62F9" }}>
                    <div className="container" style={{ fontSize: "16px" }}>
                        <Row className="bg-white main_row m-0">
                            <Col className="p-3 col-12 col-sm-7 bg-white">
                                <div className="row p-0 bg-white">
                                    <div className="p-0">
                                        <h2 className="title p-4" style={{ color: '#4b3861' }}>Try Positive Prime FREE for 10 Days</h2>
                                    </div>
                                </div>
                                <hr />
                                <Row className="align-items-center m-auto">
                                    <Col className="col-1 p-0">
                                        <img src="/assets/images/signupbn_tick.jpg" className="h-100 w-100" ></img>
                                    </Col>
                                    <Col>
                                    {
                                        this.props.location.pathname === "/signupbna" ?
                                        <p className="m-0">YES please send me the $392 Success Shortcut package & Positive Prime test drive</p> : 
                                        <p className="m-0">Yes! I want to start my FREE 10-Day Positive Prime Test Drive and receive my 7 bonus gifts worth $392</p>
                                    }
                                    </Col>
                                </Row>
                                {/* <Row className="ml-1 mr-1">
                                    <Col className="col-8 font-weight-bold">ITEM</Col>
                                    <Col className="col-4 col d-flex justify-content-end font-weight-bold">$ USD</Col>
                                </Row>
                                <hr className="mr-1 mt-2 ml-2" />
                                <Row className="ml-1 mr-1 mt-2">
                                    <Col className="col-8">Positive Prime Free Trial</Col>
                                    <Col className="col-4 col d-flex justify-content-end">$ 0.00</Col>
                                </Row>

                                <div className="form-group mt-4 mb-4">
                                    <label className="container-checkbox">Yes! I want to Positive Prime My Mind!
                                        <input type="checkbox" checked />
                                        <span class="checkmark"></span>
                                    </label>
                                </div> */}

                                {/* <div className="outer_div_box mt-5">
                                    <p className="outer_div_box_heading">SUMMARY</p>
                                <p class="text-black" style={{ fontSize: '12px', paddingLeft: '15px', paddingRight:'15px' }}>Congratulations! Your Free 7-Day Trial of the Full Positive Prime Experience has begun. If you decide to keep the full-membership, your subscription will be only $19 a month. We’ll send a reminder email before each payment so you have enough time to modify or cancel your plan. Whether or not you keep the full membership, you’ll always have access to “Being Happier” and any sessions you were gifted.</p>
                                </div> */}

                                {
                                    this.renderForm()
                                }

                            </Col>

                            <Col className="col-sm-5 col-12 p-0">
                                <div className="card" style={{ border: "none", background: "#f2f2f2" }}>
                                    <div className="outer_div_box1 m-4">
                                        <p className="outer_div_box_heading1">WHATS INCLUDED</p>
                                        <div className="pl-4 pr-4 pt-3 pb-3 text-justify">
                                            <p className="text-center" style={{ fontSize: '20px' }}>Membership includes ALL of the following:</p>
                                            <this.Perk><b className="bold_perk_text">Access to all 100+ Positive Prime Sessions</b> created by world class coaches, including Lisa Barnett, Dana Wilde and Joe Vitale</this.Perk>
                                            <this.Perk><b className="bold_perk_text">New Sessions added weekly</b> on topics like finding wealth, beating anxiety and discovering daily happiness</this.Perk>
                                            <this.Perk><b className="bold_perk_text">Personalise your experience</b> by uploading &amp; integrating your own photos into your Sessions</this.Perk>
                                            <this.PerkHeader><b className="bold_perk_text">Exclusive Extras</b></this.PerkHeader>
                                            <this.Perk><b className="bold_perk_text">A Brand New SuperMix Session</b> added every month - SuperMixes combine multiple sessions for supercharging visual hypnosis and discovering a deeper state of Positive Priming.</this.Perk>
                                            <this.Perk>Positive Prime Exclusive <b className="bold_perk_text">7 Magnificent Days of Think and Grow Rich Mini-Course</b></this.Perk>
                                            <this.Perk><b className="bold_perk_text">6x Positive Prime Bonus Gifts</b> from our community of coaches to empower you to have a happier and healthier life both at home and in the workplace. And more to come!</this.Perk>
                                            <this.Perk><b className="bold_perk_text">Bonus Session Support E-Guides:</b> including empowerment Flash Cards</this.Perk>
                                            <this.Perk>For your Family, Friends and Clients: <b className="bold_perk_text">Gift a full (equal) subscription</b> to Positive Prime by inviting up to 4 other people</this.Perk>
                                            <this.PerkHeader><b className="bold_perk_text">Support, Education &amp; Guidance</b></this.PerkHeader>
                                            <this.Perk>Monthly group mentoring calls with founder and creator of Positive Prime - Kim Serafini</this.Perk>
                                            <this.Perk>Weekly micro-lessons via email - Sharing wisdom, inspiration and the most effective ways to get the best out of your Positive Prime Sessions.</this.Perk>
                                            <this.Perk>100s of exceptional resources to support your journey and much more :)</this.Perk>
                                        </div>
                                    </div>

                                    <div className="outer_div_box m-4">
                                        <p className="outer_div_box_heading">WHAT OTHERS SAY</p>
                                        <div className="mt-5" ></div>
                                        <div className="m-4 d-flex justify-content-center text-center text-sm-justify">
                                            <div className="d-flex justify-content-center"><ImQuotesLeft color="red" style={{ marginTop: '-15px', marginRight: '15px' }}></ImQuotesLeft></div>
                                            <Row>
                                                <Col className="col-12 order-md-1 order-2">
                                                    <p><span>This brand new approach will help millions of people live their lives to the fullest. The scale of the Positive Prime solution is unimaginable.</span><br /><br />
                                                        <span className="font-weight-bold">Dr. Robi Ludwig, Psychotherapist & Award-Winning Reporter</span></p>
                                                </Col>
                                                <Col className="d-flex justify-content-center align-items-center p-0 col-12 order-md-2 order-1 ">
                                                    <img src='/assets/images/dr_robi_ludwig.jpg' style={{ height: "100%", maxHeight: '170px', maxWidth: '170px' }} />
                                                </Col>
                                            </Row>
                                            <div className="d-flex justify-content-center align-self-end mb-1"><ImQuotesRight color="red" style={{ marginTop: '5px', marginLeft: '15px' }}></ImQuotesRight></div>
                                        </div>
                                        <div className="m-4 d-flex justify-content-center text-center text-sm-justify">
                                            <div className="d-flex justify-content-center"><ImQuotesLeft color="#6b65f8" style={{ marginTop: '-15px', marginRight: '15px' }}></ImQuotesLeft></div>
                                            <Row>
                                                <Col className="d-flex justify-content-center align-items-center p-0 col-12 ">
                                                    <img src='/assets/images/sherry_gaba.jpg' style={{ height: "100%", maxHeight: '170px', maxWidth: '170px' }} />
                                                </Col>
                                                <Col className="col-12">
                                                    <p><span>I recommend Positive Prime to all my clients. It takes meditation, positive affirmations, and the use of a vision board to a whole different level of mastery, mindfulness, and motivation.</span><br /><br />
                                                        <span className="font-weight-bold">Sherry Gaba, Psychotherapist, Life Coach & Author</span></p>
                                                </Col>
                                            </Row>
                                            <div className="d-flex justify-content-center align-self-end mb-1"><ImQuotesRight color="#6b65f8" style={{ marginTop: '5px', marginLeft: '15px' }}></ImQuotesRight></div>
                                        </div>
                                        <div className="m-4 d-flex justify-content-center text-center text-sm-justify">
                                            <div className="d-flex justify-content-center"><ImQuotesLeft color="red" style={{ marginTop: '-15px', marginRight: '15px' }}></ImQuotesLeft></div>
                                            <Row>
                                                <Col className="col-12 order-md-1 order-2">
                                                    <p><span>From the minute I was introduced to Positive Prime, the solid principles of neuroscience and positive psychology as its foundation were evident. I was hooked. It put the powerful ability of these fields right where it belongs: in the hands of clients.</span><br /><br />
                                                        <span className="font-weight-bold">Dr. Diane Shelton Author, Brain Trainer & Host</span></p>
                                                </Col>
                                                <Col className="d-flex justify-content-center align-items-center p-0 col-12 order-md-2 order-1 ">
                                                    <img src='/assets/images/Diane_Shelton.jpg' style={{ height: "100%", maxHeight: '170px', maxWidth: '170px' }} />
                                                </Col>
                                            </Row>
                                            <div className="d-flex justify-content-center align-self-end mb-1"><ImQuotesRight color="red" style={{ marginTop: '5px', marginLeft: '15px' }}></ImQuotesRight></div>
                                        </div>
                                        <div className="m-4 d-flex justify-content-center text-center text-sm-justify">
                                            <div className="d-flex justify-content-center"><ImQuotesLeft color="#6b65f8" style={{ marginTop: '-15px', marginRight: '15px' }}></ImQuotesLeft></div>
                                            <Row>
                                                <Col className="d-flex justify-content-center align-items-center p-0 col-12 ">
                                                    <img src='/assets/images/david_wolfe.jpg' style={{ height: "100%", maxHeight: '170px', maxWidth: '170px' }} />
                                                </Col>
                                                <Col className="col-12">
                                                    <p><span>This has changed my life. I have a passion for goal setting. Breakthrough technology has finally got us to this point. I love to pass along this innovative tool & pay it forward, It bypasses the monkey mind that wants to stop us. All you gotta do is just watch it! Positive Prime is here to stay. It helps us develop that ABSOLUTE CERTAINTY.</span><br /><br />
                                                        <span className="font-weight-bold">David Wolfe, Author, Spokesman & Influencer</span></p>
                                                </Col>
                                            </Row>
                                            <div className="d-flex justify-content-center align-self-end mb-1"><ImQuotesRight color="#6b65f8" style={{ marginTop: '5px', marginLeft: '15px' }}></ImQuotesRight></div>
                                        </div>
                                        <div className="m-4 d-flex justify-content-center text-center text-sm-justify">
                                            <div className="d-flex justify-content-center"><ImQuotesLeft color="red" style={{ marginTop: '-15px', marginRight: '15px' }}></ImQuotesLeft></div>
                                            <Row>
                                                <Col className="col-12 order-md-1 order-2">
                                                    <p><span>Kim Serafini has created a wicked smart tool with Positive Prime! She has incorporated all the best that brain science and positive psychology have to offer to create change in your life in three minutes a day. In a word: Awesome!</span><br /><br />
                                                        <span className="font-weight-bold">Dana Wilde Bestselling Author, Brain Trainer, Speaker & Host</span></p>
                                                </Col>
                                                <Col className="d-flex justify-content-center align-items-center p-0 col-12 order-md-2 order-1 ">
                                                    <img src='/assets/images/dana_wilde.jpg' style={{ height: "100%", maxHeight: '170px', maxWidth: '170px' }} />
                                                </Col>
                                            </Row>
                                            <div className="d-flex justify-content-center align-self-end mb-1"><ImQuotesRight color="red" style={{ marginTop: '5px', marginLeft: '15px' }}></ImQuotesRight></div>
                                        </div>
                                        <div className="m-4 d-flex justify-content-center text-center text-sm-justify">
                                            <div className="d-flex justify-content-center"><ImQuotesLeft color="#6b65f8" style={{ marginTop: '-15px', marginRight: '15px' }}></ImQuotesLeft></div>
                                            <Row>
                                                <Col className="d-flex justify-content-center align-items-center p-0 col-12 ">
                                                    <img src='/assets/images/shawn_achor.jpg' style={{ height: "100%", maxHeight: '170px', maxWidth: '170px' }} />
                                                </Col>
                                                <Col className="col-12">
                                                    <p><span>A masterful practice that shows you how to relieve stress and obtain the mindset for success faster than you ever dreamed possible.</span><br /><br />
                                                        <span className="font-weight-bold">Shawn Achor `Bestselling Author & Speaker</span></p>
                                                </Col>
                                            </Row>
                                            <div className="d-flex justify-content-center align-self-end mb-1"><ImQuotesRight color="#6b65f8" style={{ marginTop: '5px', marginLeft: '15px' }}></ImQuotesRight></div>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* <Row className="d-flex pt-4 pb-3 main_row m-sm-0 mt-n1 mr-0 ml-0 mb-0 justify-content-end bg-white">
                            <Col className="col-sm-7 col-12">
                                <table className="table-borderless">
                                    <tbody>
                                        <tr className="d-flex">
                                            <td className="float-text">
                                                <BsCheckCircle color="#4b3861" size={27} />
                                            </td>
                                            <td>
                                                <p className="text-left pl-3 moneyBack orderGuaranteeText">
                                                    <span className="orderGuarantee">
                                                        <b style={{ color: "#4b3861" }}>30-Day Money Back Guarantee</b>
                                                    </span>
                                                    <br />
                                                    Available if you are dissatisfied with one of our products and email us within 30 days of date of subscription. See{" "}
                                                    <a href="https://www.positiveprime.com/privacy-policy/" target="_blank">
                                                        <u> Terms & Conditions{" "} </u>
                                                    </a>
                                                    for details
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="row justify-content-center">
                                    <img
                                        className="mr-4"
                                        src="/assets/images/norton.png"
                                        height="40px"
                                        alt="norton"
                                    />
                                    <img
                                        className="ml-4"
                                        src="/assets/images/bbb.png"
                                        height="40px"
                                        alt="bbb"
                                    />
                                </div>
                            </Col>
                            <Col className="d-flex flex-column col-sm-5 col-12 mt-5 mt-sm-0 align-items-center justify-content-center">
                                <div>TOTAL : </div>
                                <h2>$0.00</h2>
                            </Col>
                            <Col className="col-sm-3 mt-5 mt-sm-0 col-12">
                                <button
                                    type="submit"
                                    className="btn btn-block rounded-end myButton"
                                    onClick={this.handleSubmit}
                                    style={{ background: "#11142f" }}
                                    disabled={!this.state.token || !this.state.signupStatus || this.state.isProcessing}
                                >
                                    {this.state.isProcessing ? <Spinner animation="border" size="sm" /> : <span>Complete Order</span>}

                                </button> 
                            </Col> 
                        </Row>
                     */}
                    </div>
                </div>
                {
                    this.state.fbIframe ?
                        <>
                            <iframe
                                src="https://video.positiveprime.com/wp-admin/admin-ajax.php?action=abtrk&eid=861"
                                style={{
                                    position: "absolute",
                                    width: 0,
                                    height: 0,
                                    border: 0
                                }}
                            ></iframe>
                            <iframe 
                                src="https://video.positiveprime.com/wp-admin/admin-ajax.php?action=abtrk&eid=1119" 
                                style={{
                                    position: "absolute",
                                    width:0,
                                    height:0,
                                    border:0
                                }}></iframe>
                        </>  
                        :
                        <></>
                }
            </>
        );
    }

    renderForm() {
        return (
            <div className="card-body">

                <hr />
                <Row className="shadow bg-white" style={{ border: "0.5px solid #e0e0e4" }}>
                    <Col className={"d-flex align-items-center " + (this.state.form_show_check ? "column_border" : "")}
                        style={{ height: "70px" }}>
                        <div style={{ marginRight: "10px" }} >
                            <h6 className={(this.state.form_show_check ? "text_color" : "")} style={{ fontSize: "18px", fontWeight: 'bold' }}>1.</h6>
                        </div>
                        <div style={{ paddingTop: "10px" }} >
                            <h6 className={(this.state.form_show_check ? "text_color" : "")} style={{ margin: 0, fontSize: "15px", fontWeight: "bold" }} >Info</h6>
                            <p style={{ fontSize: '12px' }} >Personal Details</p>
                        </div>
                    </Col>
                    <Col className={"d-flex align-items-center " + (!this.state.form_show_check ? "column_border" : "")}
                        style={{ height: "70px" }}>
                        <div style={{ marginRight: "10px" }} >
                            <h6 className={(!this.state.form_show_check ? "text_color" : "")} style={{ fontSize: "18px", fontWeight: 'bold' }}>2.</h6>
                        </div>
                        <div style={{ paddingTop: "10px" }} >
                            <h6 className={(!this.state.form_show_check ? "text_color" : "")} style={{ margin: 0, fontSize: "15px", fontWeight: "bold" }} >Card Info</h6>
                            <p style={{ fontSize: '12px' }} >Payment Details</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col className="p-0">
                        <div className={"shadow" + (this.state.form_show_check ? ' arrow-left' : ' arrow-right')}></div>
                    </Col>
                </Row>

                {
                    this.state.form_show_check ? this.personalDetails() : this.billing()
                }
                
                <Row className="mb-4">
                    <Col className="px-4">
                        <p className="text-center">
                            <span><b>Why we ask for a credit card when it’s free for 10 days:</b></span>  
                            Positive Prime are conducting the largest scientific study ever conducted on the positive effects of meditation. In order to ensure the integrity of the study we need to be able to verify each person is a legitimate user. 
                            <span><b>But we will not charge your credit card unless you want to become a long term user after 10 days.</b></span>
                        </p>
                    </Col>
                </Row>

                <div className="d-flex align-items-center justify-content-center">
                    <img
                        src="/assets/images/satisfaction.png"
                        height="120"
                        className="d-inline-block align-top"
                        alt=""
                    />
                </div>
                <div className="d-flex align-items-center justify-content-center mt-4">
                    <img
                        src="/assets/images/payment.png"
                        height="100"
                        className="d-inline-block align-top"
                        alt=""
                    />
                </div>
            </div >
        );
    }

    personalDetails() {
        return (
            <form className="pt-2 pl-2 pr-2 pb-5">
                <div className="form-group">
                    <label className="font-weight-bold">First Name</label>
                    <input
                        className={"form-control myInput " + this.getClassName(this.state.firstNameIsValid)}
                        name="firstName"
                        label="First Name"
                        placeholder="Jane"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange.bind(this)}
                        feedback={this.state.firstNameFeedback}
                        disabled={this.state.isProcessing || this.state.showCardInfo}
                        style={{ background: "white" }}

                    />
                    <span className="errorMessage">
                        {this.state.firstNameFeedback}
                        {this.state.setFirstNameErrorMessage}
                    </span>
                </div>
                <div className="form-group">
                    <label className="font-weight-bold">Last Name</label>
                    <input
                        className={"form-control myInput " + this.getClassName(this.state.lastNameIsValid)}
                        name="lastName"
                        label="Last Name"
                        placeholder="Smith"
                        value={this.state.lastName}
                        onChange={this.handleChange.bind(this)}
                        feedback={this.state.lastNameFeedback}
                        disabled={this.state.isProcessing || this.state.showCardInfo}
                        style={{ background: "white" }}
                    />
                    <span className="errorMessage">
                        {this.state.lastNameFeedback}
                        {this.state.setLastNameErrorMessage}
                    </span>
                </div>
                <div className="form-group">
                    <label className="font-weight-bold">Email Address</label>
                    <input
                        className={"form-control myInput " + this.getClassName(this.state.emailIsValid)}
                        name="email"
                        type="email"
                        label="Your email address"
                        placeholder="jane.smith@example.com"
                        value={this.state.email}
                        onChange={this.handleChange.bind(this)}
                        feedback={this.state.emailFeedback}
                        disabled={this.state.isProcessing || this.state.showCardInfo}
                        style={{ background: "white" }}
                    />

                    <span className="errorMessage">
                        {/* {this.state.emailFeedback} */}
                        {this.state.setEmailErrorMessage}
                    </span>
                </div>
                <div className="form-group">
                    <label className="font-weight-bold">Password</label>
                    <input
                        className={"form-control myInput " + this.getClassName(this.state.passwordIsValid)}
                        name="password"
                        type="password"
                        label="Password"
                        placeholder="Enter your password"
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                        feedback={this.state.passwordFeedback}
                        disabled={this.state.isProcessing || this.state.showCardInfo}
                        style={{ background: "white" }}
                    />
                    <span className="errorMessage">
                        {this.state.passwordFeedback}
                        {this.state.setPasswordErrorMessage}
                    </span>
                </div>
                {!this.state.checkRefferal ? <div className="form-group">
                    <label className="font-weight-bold">Referral Code</label>
                    <input
                        className={"form-control myInput " + this.getClassName(this.state.referralCodeIsValid)}
                        name="referralCode"
                        type="text"
                        label="Referral Code (Optional)"
                        placeholder="Referral Code"
                        value={this.state.referralCode}
                        onChange={this.handleChange.bind(this)}
                        feedback={this.state.referralCodeFeedback}
                        disabled={this.state.isProcessing || this.state.showCardInfo}
                        style={{ background: "white" }}
                    />
                    <span className="errorMessage">
                        {this.state.referralCodeFeedback}
                    </span>
                </div> : <></>}
                <div className="form-group billingText">
                    <p>
                        If you do not provide us your personal information we may not be
                        able to provide our Services to you. See our{" "}
                        <a href="https://www.positiveprime.com/privacy-policy/" target="_blank">
                            Privacy Policy
                        </a>
                        .
                    </p>
                </div>

                <div className="form-group">
                    <button
                        type="submit"
                        className="btn btn-block signup-button-css shadow"
                        style={{ background: "#11142f" }}
                        disabled={
                            !(
                                !this.state.setFirstNameErrorMessage &&
                                !this.state.setLastNameErrorMessage &&
                                !this.state.setEmailErrorMessage &&
                                !this.state.setPasswordErrorMessage &&
                                this.state.firstName &&
                                this.state.lastName &&
                                this.state.email &&
                                this.state.password
                            ) || this.state.isProcessing
                        }
                        onClick={this.createUser}
                    >
                        {this.state.isProcessing ? <Spinner animation="border" size="sm" /> : <span>Next</span>}
                    </button>
                </div>
            </form>
        )
    }

    billing() {
        return (
            <div className="pt-2 pl-2 pr-2 pb-5">
                <Row className="mr-2 ml-2">
                    <Col className="col-8 font-weight-bold">Item</Col>
                    <Col className="col-4 col d-flex justify-content-end font-weight-bold text-right">Price</Col>
                </Row>
                <hr />
                <Row className="mr-2 ml-2">
                    <Col className="p-0">
                        <div className="d-flex align-items-center">
                            <input type="radio" id="plan" name="plan_name" onClick={(e) => { this.setState({ selectedPlan: 'monthly' }) }} className="check_radio" defaultChecked />
                            <label for="plan" className='ml-2 mb-0' >FREE for 10 days then $19 per month</label>
                            <label for="plan" className='ml-auto mb-0 text-right'>FREE Trial</label>
                        </div>

                        <div className="d-flex align-items-center mt-3">
                            <input type="radio" id="plan" name="plan_name" onClick={(e) => { this.setState({ selectedPlan: 'yearly' }) }} className="check_radio" />
                            <label for="plan" className='ml-2 mb-0'>FREE for 10 days then $199 per year</label>
                            <label for="plan" className='ml-auto mb-0 text-right'>FREE Trial</label>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <CheckoutForm handler={this.handler} status={this.state.signupStatus} processing={this.state.isProcessing} page={true} />
                    </Col>
                </Row>
            </div>

        )
    }

    handler(token,value) {
        this.setState({ token: token, isProcessing: true, chkbox: value }, () => {
            this.handleSubmit()
        })
    }

    createUser = async (e) => {
        e.preventDefault();
        await this.setState({ isProcessing: true });
        let agent = navigator.userAgent
        let Ip = await publicIp.v4({
            fallbackUrls: ["https://ifconfig.co/ip"]
        })
        let user = {
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            email: this.state.email,
            referral_code: this.state.referralCode || "epic",
            ipaddress: Ip,
            user_agent: agent,
            http_referrer: document.referrer,
            url: window.location.href

        }
        await API.createUserAbandoned(user)
        .then((res)=>{
            console.log("User Abundendand Response: ",res);
            if(res.status ===201){
                this.setState({ signupStatus: true, showCardInfo: true, form_show_check : false, isProcessing: false })
            }else{
                if(res.status === 409){
                    this.setState({
                        "setEmailErrorMessage": "Email Already Exists"
                    });
                }
                this.setState({ isProcessing: false });
            }
        })
    }

    getClassName(valid) {

        if (valid === null || valid == undefined) {
            return undefined;
        }

        return "is-" + (valid ? "valid" : "invalid");
    }

    renderError() {
        if (!this.state.otherError)
            return;

        return (
            <Alert variant="danger">
                {this.state.otherError}
            </Alert>
        )
    }
}
export default SignUpBn;

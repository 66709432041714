import React, { Component } from "react";
import {
    Col, Row, Card, Spinner, Alert, Table, Container, DropdownButton,
    Dropdown,
    Modal,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import { API, Config } from "../../APIAndConfig";
import ReactTable from 'react-table-6'


export class MembershipTypeReports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            membershipTypeReportData: []
        }
    }

    async componentDidMount() {
        await this.membershipReport()
    }

    async membershipReport() {
        await API.getMembershipTypeReport()
            .then((res) => {
                if (res.payload && res.payload.data) {
                    // console.log("Membership Type Reports",res.payload.data);
                    this.setState({ membershipTypeReportData : res.payload.data })
                }
            })
    }
    render() {
        if (this.state.membershipTypeReportData.length === 0)
            return (<div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        return (
           
            <div>
                <h2 className="main-title m-0 p-3">Membership Types Report</h2>
                <hr className="m-0" />    
                <Card className="card-blur my-5">
                    <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>Membership Types Report</Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                        defaultPageSize = {10}
                        data={this.state.membershipTypeReportData}
                            columns={[
                                {
                                    id: "count",
                                    Header: 'Count',
                                    accessor: 'count',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'subscribed_membership_type',
                                    Header: 'Subscribed Membership Type',
                                    accessor: 'subscribed_membership_type',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'role_id',
                                    Header: 'Role_Id',
                                    accessor: 'role_id',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'name',
                                    Header: 'Name',
                                    accessor: 'name',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                            ]}
                        />
                    </Card.Body>
                </Card>
                    
                    
                </div>
        )
    }

}


export default MembershipTypeReports
import {FETCH_CURRENT_EMBED_SESSION} from "../actions/types";

export default function(state = [], action) {
  switch (action.type) {
    case FETCH_CURRENT_EMBED_SESSION:
      return action.payload || [];
    default:
      return state;
  }
}

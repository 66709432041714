import { Col, Row, Form, Button, Spinner } from "react-bootstrap";
import Loader from "../common/Loader";
import React, { ChangeEvent } from "react";
import { IntroWrap } from "./IntroWrap";
import { Link } from "react-router-dom";
import { cookies } from "../../index";
import { set, get } from "lodash";
import { FormInput } from "../FormInput";
import { Firebase } from "../../firebase";
import { CreateUserParams } from "../../api/APIParams";
import { API } from "../../APIAndConfig";

interface SignUpState {
    isProcessing: boolean,
    referralCodeCookie?: string,

    firstName: string,
    firstNameIsValid?: boolean | null,
    firstNameFeedback?: string | null

    lastName: string,
    lastNameIsValid?: boolean | null,
    lastNameFeedback?: string | null,

    email: string,
    emailIsValid?: boolean | null,
    emailFeedback?: string | null,

    password: string,
    passwordIsValid?: boolean | null,
    passwordFeedback?: string | null,

    confirmPassword: string
    confirmPasswordIsValid?: boolean | null,
    confirmPasswordFeedback?: string | null,

    referralCode: string,
    referralCodeIsValid?: boolean | null,
    referralCodeFeedback?: string | null,

}

export class SignUp extends React.Component<any, SignUpState> {

    constructor(props: any) {
        super(props);
        const referralCookie = cookies.get("referralCode");
        this.state = {
            isProcessing: false,
            referralCode: referralCookie,
            referralCodeCookie: referralCookie,
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            confirmPassword: ''
        }
    }
    handleChange(event: ChangeEvent<HTMLInputElement>) {
        const state = {};
        set(state, event.target.id, event.target.value);
        this.setState(state);
    }

    render() {
        return (
            <IntroWrap
                processing={this.state.isProcessing}
                heading="Join Positive Prime!"
                text="Enter your details below to join Positive Prime"
            >
                <Form.Group className="loginForm min-w-75">
                    <Form.Row>
                        <Col md={6}>
                            <FormInput
                                name="firstName"
                                label="First Name"
                                placeholder="Jane"
                                value={this.state.firstName}
                                onChange={this.handleChange.bind(this)}
                                valid={this.state.firstNameIsValid}
                                feedback={this.state.firstNameFeedback}
                                disabled={this.state.isProcessing}
                            />
                        </Col>
                        <Col md={6}>
                            <FormInput
                                name="lastName"
                                label="Last Name"
                                placeholder="Smith"
                                value={this.state.lastName}
                                onChange={this.handleChange.bind(this)}
                                valid={this.state.lastNameIsValid}
                                feedback={this.state.lastNameFeedback}
                                disabled={this.state.isProcessing}
                            />
                        </Col>
                    </Form.Row>

                    <FormInput
                        name="email"
                        type="email"
                        label="Your email address"
                        placeholder="jane.smith@example.com"
                        value={this.state.email}
                        valid={this.state.emailIsValid}
                        feedback={this.state.emailFeedback}
                        onChange={this.handleChange.bind(this)}
                        disabled={this.state.isProcessing}
                    />

                    <Form.Row>
                        <Col md={6}>
                            <FormInput
                                name="password"
                                type="password"
                                label="Password"
                                placeholder="Enter your password"
                                value={this.state.password}
                                valid={this.state.passwordIsValid}
                                feedback={this.state.passwordFeedback}
                                onChange={this.handleChange.bind(this)}
                                disabled={this.state.isProcessing}
                            />
                        </Col>
                        <Col md={6}>
                            <FormInput
                                name="confirmPassword"
                                type="password"
                                label="Confirm Password"
                                placeholder="Confirm your password"
                                value={this.state.confirmPassword}
                                valid={this.state.confirmPasswordIsValid}
                                feedback={this.state.confirmPasswordFeedback}
                                onChange={this.handleChange.bind(this)}
                                disabled={this.state.isProcessing}
                            />
                        </Col>
                    </Form.Row>

                    <FormInput
                        name="referralCode"
                        type="text"
                        label="Referral Code (Optional)"
                        placeholder="If you've been given a referral code, please enter code here"
                        value={this.state.referralCode}
                        valid={this.state.referralCodeIsValid}
                        feedback={this.state.referralCodeFeedback}
                        onChange={this.handleChange.bind(this)}
                        disabled={this.state.isProcessing}
                    />
                    {this.renderButton()}

                    <div className="text-center small mt-2">
                        By registering with Positive Prime, you are agreeing to our <a href="/terms" target="_blank">Terms and Conditions</a> and <a href="/privacy" target="_blank">Privacy Policy</a>
                    </div>

                    <p className="text-center mt-5">
                        Already have an account? <Link to="/login">Login here</Link>
                    </p>
                </Form.Group>
            </IntroWrap>
        );
    }

    renderButton() {
        return (
            <Button block disabled={this.state.isProcessing} type="submit" onClick={this.handleSubmit.bind(this)}>Sign Up</Button>
        )
    }

    async handleSubmit() {
        await this.setState({ isProcessing: true });

        const params: CreateUserParams = {
            email: this.state.email || '',
            firstName: this.state.firstName || '',
            lastName: this.state.lastName || '',
            password: this.state.password || '',
            confirmPassword: this.state.confirmPassword || '',
            referralCode: this.state.referralCode || 'epic'
        };

        const result = await API.createUser(params);

        if (result.successful && result.status === 201) {
            await Firebase.Auth.signInWithEmailAndPassword(this.state.email || '', this.state.password || '');
            /*Firebase.Analytics.logEvent("sign_up",{
                method:"form"
            });*/
            cookies.remove("referralCode");
        } else {
            let state = {
                isProcessing: false,
                firstNameIsValid: null,
                firstNameFeedback: null,
                lastNameIsValid: null,
                lastNameFeedback: null,
                emailIsValid: null,
                emailFeedback: null,
                passwordIsValid: null,
                passwordFeedback: null,
                confirmPasswordIsValid: null,
                confirmPasswordFeedback: null,
                referralCodeIsValid: null,
                referralCodeFeedback: null
            }
            type errorType = {
                code: string,
                param: string,
                detail: string
            };
            let error: errorType;
            let errors: errorType[] = get(result.payload, 'errors', [])
            for (error of errors) {
                set(state, `${error.param}IsValid`, false);
                set(state, `${error.param}Feedback`, error.detail);
            }
            await this.setState(state);
        }
    }
}
export default SignUp;

import React from "react";
import { Moment } from "moment";
import Chart from "react-google-charts";
import { WatchAnalyticsParams } from "../../api/APIParams";
import { Alert, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import numeral from "numeral";
import { get } from "lodash";
import { ReactGoogleChartEvent } from "react-google-charts/dist/types";
import { API } from "../../APIAndConfig";

export type ChartValue = boolean | string | number | Date | number[] | null | undefined;
export interface WatchGraphProps {
    sessionId?: number | number[]
    params?: WatchAnalyticsParams,
    onSelect?: (column: ChartValue, row: ChartValue, value?: ChartValue) => any
}
export interface WatchGraphState {
    data?: object | null;
    startDate?: Moment | null,
    endDate?: Moment | null,
    params?: WatchAnalyticsParams,
    isLoading: boolean;
}



export class WatchGraph extends React.Component<WatchGraphProps, WatchGraphState> {
    constructor(props: WatchGraphProps) {
        super(props);
        this.state = {
            isLoading: true
        };
    }

    componentDidUpdate(prevProps: Readonly<WatchGraphProps>, prevState: Readonly<WatchGraphState>, snapshot?: any): void {

        let needsFetching: string[] = []

        if (this.props.params?.start !== this.state.params?.start)
            needsFetching.push("startDate")

        if (this.props.params?.end !== this.state.params?.end)
            needsFetching.push("endDate");

        if (this.props.params?.collate !== this.state.params?.collate)
            needsFetching.push("collate")

        if (this.props.params?.filter !== this.state.params?.filter)
            needsFetching.push("filter")

        if (needsFetching.length) {
            this.fetchData().catch((err) => {
                console.error(err);
            })
        }
    }

    async fetchData() {
        await this.setState({
            isLoading: true,
            params: this.props.params
        });

        if (!this.props.params?.start || !this.props.params?.end)
            return this.setState({ data: null, isLoading: false });

        const data = await API.getWatchAnalytics(Object.assign(this.props.params || {}, {
            format: "graph"
        }));

        if (data.successful && typeof data.payload === "object") {
            this.setState({
                data: data.payload,
                isLoading: false
            })
        }

    }

    render() {

        const $loading = <div className={"text-center"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>;

        if (!this.props.params?.start || !this.props.params?.end)
            return (
                <p>&nbsp;</p>
            )

        if (!this.state.data || this.state.isLoading)
            return (
                <Container fluid>
                    <div className="text-center py-5">
                        {$loading}
                    </div>
                </Container>
            );

        let chartEvents: ReactGoogleChartEvent[] = [];

        if (this.props.onSelect) {
            let onSelect = this.props.onSelect;
            chartEvents.push({
                eventName: 'select',
                callback: function (args) {
                    /*const chart = args.chartWrapper.getChart();
                    const selected = chart.getSelection()[0];
                    const dataTable = args.chartWrapper.getDataTable();
                    const row = dataTable?.getValue(selected.row, 0)
                    const column = dataTable?.getColumnLabel(selected.column);
                    const value = dataTable?.getValue(selected.row, selected.column);
                    onSelect(row, column, value);*/
                }
            });
        }

        let graphData = get(this.state.data, 'graph', []);

        let $chart: any = null;
        if (graphData.length > 1)
            $chart = (<Card className="shadow">
                <Card.Body>
                    <Chart
                        height={"400px"}
                        options={{ colors: ['#3498DB', '#18BC9C', '#4a57a5'] }}
                        chartType={graphData.length > 2 ? 'Line' : 'Bar'}
                        chartEvents={chartEvents}
                        loader={$loading}
                        data={graphData}
                    /></Card.Body>
            </Card>)
        else
            $chart = <Alert variant="danger" className="m-5">No data for this period</Alert>

        return (
            <Container fluid={true} className="p-3">
                <Row className={"mb-3 text-center"}>
                    <Col className="px-3">
                        <Card className="bg-gradient-primary shadow-sm">
                            <Card.Body>
                                <h3 className="text-white">{formatNumber(get(this.state.data, 'total', 0))}</h3>
                                <h4 className="text-light">Total</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="px-3">
                        <Card className="bg-gradient-success shadow-sm">
                            <Card.Body>
                                <h3 className="text-white">{formatNumber(get(this.state.data, 'unique', 0))}</h3>
                                <h4 className="text-light">Unique</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="px-3" >
                        <Card className="bg-gradient-info shadow-sm">
                            <Card.Body>
                                <h3 className="text-white">{formatNumber(get(this.state.data, 'complete', 0))}</h3>
                                <h4 className="text-light">Completed</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>

                        {$chart}

                    </Col>
                </Row>

            </Container>
        )

    }

}

function formatNumber(n: number): string {
    return numeral(n).format('0.[00]a');
};

import React from 'react'
import clsx from 'clsx';
import BasicModal from './BasicModal';
import userImage1 from '../../assets/userImages/1.png';
import userImage2 from '../../assets/userImages/2.png';
import userImage3 from '../../assets/userImages/3.png';
import userImage4 from '../../assets/userImages/4.png';
import userImage5 from '../../assets/userImages/5.png';
import userImage6 from '../../assets/userImages/6.png';
import EditSession from '../../v2Sessions/EditSession';
import Loader from '../common/Loader';


const PersonalizeSessions = props => {
  return (
    <>

      {/* <BasicModal
        show={props.show}
        title={'Personalize Your Session'}
        subtitle={'Customize your session by uploading and selecting photos that bring you positive emotions, are based on goals you want to manifest or fit the theme of the session.'}
        footer={null}
        onClose={props.onClose}
        modalProps={{
          size: 'xl'
        }}
        body={
          
        }
      /> */}



      {props.show && (
        <>
          <div id="modalLoader">
            <Loader />
          </div>
          <EditSession onClose={props.onClose} onClickPlay={props.onClickPlay} />
        </>
      )}
    </>
  )
}

export default PersonalizeSessions

PersonalizeSessions.displayName = "PersonalizeSessions"
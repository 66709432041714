import {Config, API, APIAxios} from "../APIAndConfig";
import axios from "axios";
import {FETCH_DEFAULT_EMBED_IMAGES, FETCH_DEFAULT_IMAGES} from "./types";
import {Firebase} from"../firebase";
import set from "date-fns/set";
import { getOfflineDefaultRequiredImages } from "../helpers/offlineSession";

export const fetchDefaultImages = () => async dispatch => {
  const defaultRequiredImages = await getOfflineDefaultRequiredImages()
  if (defaultRequiredImages && Object.keys(defaultRequiredImages).length > 0) {
    dispatch({ type: FETCH_DEFAULT_IMAGES, payload: defaultRequiredImages });

    return false
  }

  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  await axios.get(
    `${Config.APIUrl}/api/default_required_images?set_name=set_2`,
    { headers: header }
  ).then((images) => {
    dispatch({ type: FETCH_DEFAULT_IMAGES, payload: images.data });
  }).catch(
    async (err) => {
      switch (err.response.data) {
        case "SESSION_EXPIRED":
          const token = await Firebase.Auth.currentUser?.getIdToken();
          if (token) {
            await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
            window.location.reload()
        }
          break;
        case "BAD_CONNECTION":
          case "UNCAUGHT_ERROR":
        case "DATABASE_LIMIT_EXCEEDED":
          localStorage.setItem('service_unavailable_status', "failed");
          await Firebase.Auth.signOut();
          window.location.href = "/error"
          break;
        default:
          console.log("Error", err);
          break;
      }
  });

};

import React, { useEffect, useState } from "react";
import "./uploadModalStyle.css";
import Dropzone, { Preview } from 'react-dropzone-uploader'
import 'react-dropzone-uploader/dist/styles.css'
import { ModalBody, ModalFooter, ProgressBar } from "react-bootstrap";
export default function SimpleDropZone(props) {

  const getUploadParams = ({ meta }) => { return { url: 'https://httpbin.org/post' } }
  const [allFiles, setAllFiles] = useState([])
  const [heicUrls, setHeicUrls] = useState([])
  const [flag, setFlag] = useState(true)
  var flag_1 = 0
  const [isProcessing, setIsProcessing] = useState(false)
  const [filesLength, setFilesLength] = useState(0)
  const handleChangeStatus = ({ file, remove, restart }, status) => {
    if (status === "headers_received" || status === "error_validation" || status === "error_file_size") {
      allFiles.push(file)
      setFilesLength(allFiles.length)
      if (allFiles.length === flag_1) {
        setFlag(false)
      }
      else {
        setFlag(true)
      }
    }
    if (status === "error_validation" || status === "error_file_size") {
      remove()
    }
    if (status === "exception_upload") {
      restart()
    }
    else if (status === "removed") {
      allFiles.splice(allFiles.findIndex((e) => e.lastModified === file.lastModified), 1);
      setFilesLength(allFiles.length)
    }
  }

  const handleError = (p) => {
    if (p && p[0]) {
      p[0].props.files.forEach(
        (e) => {
          if (e.meta.type === "audio/mpeg" || e.meta.type === "audio/wav" || e.meta.type === "audio/mp3" ) {
            e.meta.previewUrl = "/assets/images/play_placeholder.png"
          }
          else if (e.meta.type === "image/heif" || e.meta.type === "image/heic") {
            if (e.meta.previewUrl && e.meta.previewUrl.startsWith("blob:http")) {
              heicUrls.push({ id: e.file.lastModified, url: e.meta.previewUrl })
            }
            e.meta.previewUrl = "/assets/images/session_placeholder.jpg"
          }
        })
    }
    return p
  }

  const Layout = ({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) => {
    if (isProcessing) {
      input.props.withFilesContent = `Please Be Patient While We Upload Your ${ props.inputType }`
    }
    else if (previews[0] && flag) {
      let num = previews[0].props.files.length - previews[0].props.files.filter(obj => obj.meta.status !== "done").length + 1
      input.props.withFilesContent = `Preparing ${num <= previews[0].props.files.length ? num : previews[0].props.files.length} of ${previews[0].props.files.length}`
    }
    else {
      input.props.withFilesContent = `Click to Add More ${ props.inputType }`
    }

    if (files.length === maxFiles && !flag) {
      input.props.withFilesContent = `Limit Reached - ${ props.maxFiles } ${ props.inputType } Allowed Per Upload`
    }
    return (
      <div>
        <div className="card sticky-top w-100 p-0 m-0">
          <div {...dropzoneProps}>
            {files.length <= maxFiles && input}
          </div>
        </div>
        <div>
          {handleError(previews)}
        </div>
      </div>
    )
  }

  const validate = ({ file }) => {
    const fileUsed = allFiles.filter(obj => obj.lastModified === file.lastModified)
    if (fileUsed.length > 0) {
      return "File already uploaded"
    } else {
      return false
    }
  }

  useEffect(() => {
    var el = document.getElementsByClassName('dzu-inputLabel');
    for (var i = 0; i < el.length; i++) {
      el[i].click();
    }
  }, [])

  return (
    <>
      <ModalBody className="p-0">
        <div className="App">
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            LayoutComponent={Layout}
            maxFiles={Number( props.maxFiles )}
            inputContent={(files, extra) => (extra.reject ? `${ props.inputType } files only` : props.message)}
            accept={props.acceptTypes}
            validate={validate}
            disabled={files => files.some(f => {
              flag_1 = files.length
              return ['preparing', 'getting_upload_params', 'uploading'].includes(f.meta.status) || files.length === Number( props.maxFiles ) ? true : false
            })}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        {
          isProcessing ?
            <div className="w-100" style={{ background: "#e9ecef" }}>
              <ProgressBar className="progress_bar_custom" striped animated
                style={{ width: (( Number(props.completedCount) / filesLength) * 100) + "%", background: "#35294f", transition: 'width 1s' }} />
            </div>
            :
            <button
              className={`btn x-btn x-primary ${filesLength === 0 ? "d-none" : ""}`}
              type="button"
              disabled={allFiles.length > 0 && !flag ? false : true}
              onClick={() => {
                setIsProcessing(true)
                props.onUpload(allFiles)
              }}
            >
              {filesLength > 0 ? <span>{`Upload ${filesLength} Images`}</span> : <span>Upload</span>}
            </button>
        }
      </ModalFooter>
    </>

  );
}

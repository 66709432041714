import clsx from "clsx";
import { get } from "lodash";
import React, { Component } from "react";
import { Alert, Card, Col, Modal, Row, Spinner } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { createFilter } from "react-select";
import ReactTable from "react-table-6";
import WindowedSelect from "react-windowed-select";
import { API } from "../../APIAndConfig";
import { BsInfoCircle } from "react-icons/bs";
export interface AffiliatePlanProps extends RouteComponentProps {
  auth: any;
}
export interface AffiliatePlanState {
  affiliatePlanData: any;
  editAffiliatePlanData: any;
  loader: boolean;
  addAffiliatePlan: boolean;
  isProcessing: boolean;
  tableRows: Array<any>;
  editAffiliatePlan: boolean;
  statusOptions: Array<any>;
  confirmationModel: {
    state: boolean;
    header: string;
    message: string;
  };
  messageModel: {
    state: boolean;
    header: string;
    message: string;
  };
  inputField: any;
  infoModel: boolean;
}

export default class PaymentPlans extends Component<
  AffiliatePlanProps,
  AffiliatePlanState
> {
  constructor(props: AffiliatePlanProps) {
    super(props);
    this.state = {
      affiliatePlanData: null,
      editAffiliatePlanData: "",
      loader: true,
      addAffiliatePlan: false,
      editAffiliatePlan: false,
      isProcessing: false,
      tableRows: [],
      inputField: "",
      statusOptions: ["enabled", "disabled"],
      confirmationModel: {
        state: false,
        header: "",
        message: "",
      },
      messageModel: {
        state: false,
        header: "",
        message: "",
      },
      infoModel: false,
    };
    this.addAffiliateSubmit = this.addAffiliateSubmit.bind(this);
    this.updateAffiliateSubmit = this.updateAffiliateSubmit.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const affiliatePlanData = await API.getAffiliatePlansList();

    this.setState({ affiliatePlanData: affiliatePlanData, loader: false });
    this.renderAffiliatePlanData();
  }

  renderAffiliatePlanData() {
    if (!this.state.affiliatePlanData)
      return (
        <div className={"text-center"}>
          <Spinner
            animation={"grow"}
            variant={"primary"}
            style={{ width: "5em", height: "5em" }}
          />
        </div>
      );

    if (!this.state.affiliatePlanData.successful)
      return <Alert variant="danger">Error fetching referral data</Alert>;

    const planData = get(this.state.affiliatePlanData, "payload.data", []);
    let rows = [];

    let row: any;
    for (row of planData) {
      let source = get(row, "source");
      let affiliate_payout = get(row, "affiliate_payout");
      let insertdate = get(row, "insertdate");
      let live_planID = get(row, "live_planID");
      let live_planName = get(row, "live_planName");
      let test_planID = get(row, "test_planID");
      let test_planName = get(row, "test_planName");
      let status = get(row, "status");
      let id = get(row, "id");
      let amount = get(row, "amount");
      let frequency = get(row, "frequency");
      let product = get(row, "product");

      let dataObj: any = {
        id,
        source,
        affiliate_payout,
        insertdate,
        live_planID,
        live_planName,
        test_planID,
        test_planName,
        status,
        amount,
        frequency,
        product,
      };
      rows.push(dataObj);
    }
    this.setState({ tableRows: rows });
  }

  inputsHandler(e: any) {
    this.setState({
      inputField: {
        ...this.state.inputField,
        [e.target.name]: e.target.value,
      },
    });
  }

  setCreateStatus(e: any) {
    if (e.value === "disabled") {
      this.setState({
        confirmationModel: {
          state: true,
          header: "warning",
          message:
            "Please be aware, disabling a plan will affect affiliates recording commissions if there are any active sales on this plan. Do you still wish to continue?",
        },
      });
    } else {
      this.setState({
        inputField: { ...this.state.inputField, status: e.value },
      });
    }
  }

  setEditStatus(e: any) {
    if (e.value === "disabled") {
      this.setState({
        confirmationModel: {
          state: true,
          header: "warning",
          message:
            "Please be aware, disabling a plan will affect affiliates recording commissions if there are any active sales on this plan. Do you still wish to continue?",
        },
        editAffiliatePlanData: {
          ...this.state.editAffiliatePlanData,
          status: e.value,
        },
      });
    } else {
      this.setState({
        editAffiliatePlanData: {
          ...this.state.editAffiliatePlanData,
          status: e.value,
        },
      });
    }
  }

  editInputsHandler(e: any) {
    this.setState({
      editAffiliatePlanData: {
        ...this.state.editAffiliatePlanData,
        [e.target.name]: e.target.value,
      },
    });
  }

  async addAffiliateSubmit() {
    this.setState({ isProcessing: true });
    let payload = {
      source: this.state.inputField.source,
      affiliate_payout: this.state.inputField.affiliate_payout,
      live_planName: this.state.inputField.live_planName,
      live_planID: this.state.inputField.live_planID,
      test_planName: this.state.inputField.test_planName,
      test_planID: this.state.inputField.test_planID,
      status: this.state.inputField.status,
      amount: this.state.inputField.amount,
      frequency: this.state.inputField.frequency,
      product: this.state.inputField.product,
    };
    await API.createAffiliatePlan(payload)
      .then((res: any) => {
        this.setState({
          isProcessing: false,
          addAffiliatePlan: false,
          tableRows: [...this.state.tableRows, res.payload.data],
          inputField: {
            source: "",
            affiliate_payout: "",
            live_planName: "",
            live_planID: "",
            test_planName: "",
            test_planID: "",
            status: "",
            amount: "",
            frequency: "",
            product: "",
          },
          messageModel: {
            state: true,
            header: "Success",
            message: "Payment plan created successfully",
          },
        });
      })
      .catch((err) => {
        this.setState({
          isProcessing: false,
          addAffiliatePlan: false,
          inputField: {
            source: "",
            affiliate_payout: "",
            live_planName: "",
            live_planID: "",
            test_planName: "",
            test_planID: "",
            status: "",
            amount: "",
            frequency: "",
            product: "",
          },
          messageModel: {
            state: true,
            header: "Error",
            message: "Something went wrong please try again.",
          },
        });
        console.log(err);
      });
  }

  async updateAffiliateSubmit() {
    this.setState({ isProcessing: true });
    await API.updateAffiliatePlan(this.state.editAffiliatePlanData)
      .then((res: any) => {
        const rowIndex = this.state.tableRows.findIndex(
          (obj) => obj.id === res.payload.data.id
        );
        this.state.tableRows[rowIndex] = res.payload.data;
        this.setState({
          isProcessing: false,
          editAffiliatePlan: false,
          messageModel: {
            state: true,
            header: "Success",
            message: "Payment plan updated successfully.",
          },
          inputField: {
            status: "",
          },
        });
      })
      .catch((err) => {
        this.setState({
          isProcessing: false,
          editAffiliatePlan: false,
          messageModel: {
            state: true,
            header: "Error",
            message: "Something went wrong please try again.",
          },
        });
        console.log(err);
      });
  }

  render() {
    return (
      <>
        {this.state.loader ? (
          <div className={"text-center mt-4"}>
            <Spinner
              animation={"grow"}
              variant={"primary"}
              style={{ width: "5em", height: "5em" }}
            />
          </div>
        ) : (
          <div>
            <div className="d-flex align-items-center justify-items-center">
              <h2 className="main-title m-0 p-3">Payment Plans</h2>
              <button
                className="btn btn-primary ml-auto"
                onClick={() => {
                  this.setState({ addAffiliatePlan: true });
                }}
              >
                Add Plan
              </button>
            </div>
            <hr className="m-0" />
            <Row className="my-5">
              <Col>
                <div>
                  <Card border="dark" className={clsx("card-blur")}>
                    <Card.Header
                      className="text-white font-weight-bold "
                      style={{ background: "rgb(111 79 136 / 1)" }}
                    >
                      <div>
                        <span>Payment Plans</span>
                        {/* <input
                                style={{ float: "right" }}
                                name="searchInput"
                                value={this.state.searchInput || ""}
                                onChange={this.searchChange}
                                placeholder="Search"
                            /> */}
                      </div>
                    </Card.Header>
                    <Card.Body className="p-0">
                      <ReactTable
                        data={this.state.tableRows}
                        columns={[
                          {
                            Header: "Source",
                            accessor: "source",
                            headerClassName: "p-3 text-light",
                            className: "text-center",
                          },
                          {
                            Header: "Live Plan ID",
                            headerClassName: "p-3 text-light",
                            accessor: "live_planID",
                            className: "text-center",
                          },
                          {
                            Header: "Live Plan Name",
                            headerClassName: "p-3 text-light",
                            accessor: "live_planName",
                            className: "text-center",
                          },
                          {
                            Header: "Test Plan ID",
                            accessor: "test_planID",
                            headerClassName: "p-3 text-light",
                            className: "text-center",
                          },
                          {
                            Header: "Test Plan Name",
                            accessor: "test_planName",
                            headerClassName: "p-3 text-light",
                            className: "text-center",
                          },
                          {
                            Header: "Status",
                            accessor: "status",
                            headerClassName: "p-3 text-light",
                            className: "text-center",
                          },
                          {
                            Header: "Affiliate Payout",
                            accessor: "affiliate_payout",
                            headerClassName: "p-3 text-light",
                            className: "text-center",
                          },
                          {
                            Header: "Amount",
                            accessor: "amount",
                            headerClassName: "p-3 text-light",
                            className: "text-center",
                          },
                          {
                            Header: "Frequency",
                            accessor: "frequency",
                            headerClassName: "p-3 text-light",
                            className: "text-center",
                          },
                          {
                            Header: "Product",
                            accessor: "product",
                            headerClassName: "p-3 text-light",
                            className: "text-center",
                          },
                          {
                            Header: "Action",
                            headerClassName: "p-3 text-light",
                            className: "text-center",
                            Cell: (data: any) => (
                              <>
                                <BsInfoCircle
                                  size={22}
                                  className="mr-2"
                                  onClick={() => {
                                    this.setState({
                                      infoModel: true,
                                      editAffiliatePlanData: data.original,
                                    });
                                  }}
                                ></BsInfoCircle>
                                <button
                                  className="btn x-secondary text-white"
                                  onClick={() => {
                                    this.setState({
                                      editAffiliatePlan: true,
                                      editAffiliatePlanData: data.original,
                                    });
                                  }}
                                >
                                  Edit
                                </button>
                              </>
                            ),
                          },
                        ]}
                        defaultPageSize={10}
                        noDataText="No Data Found"
                        style={{ background: "rgb(111 79 136 / 1)" }}
                        getTrProps={(
                          state: any,
                          rowInfo: any,
                          column: any,
                          instance: any
                        ) => {
                          if (typeof rowInfo !== "undefined") {
                            return {
                              style: {
                                background:
                                  rowInfo.index % 2 === 0
                                    ? "transparent"
                                    : "rgb(96 64 125 / 1)",
                                color:
                                  rowInfo.index % 2 === 0 ? "white" : "white",
                              },
                            };
                          } else {
                            return {
                              style: {
                                background: "transparent",
                                color: "white",
                              },
                            };
                          }
                        }}
                      />
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        )}
        <Modal show={this.state.addAffiliatePlan} onHide={() => {}} size="lg">
          <Modal.Header>Add Payment Plan</Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Source</label>
                  <select
                    className={clsx("form-select", "form-control")}
                    name="source"
                    onChange={this.inputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  >
                    <option disabled selected>
                      Select Option
                    </option>
                    <option value="stripe">Stripe</option>
                    <option value="paypal">Paypal</option>
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="inputSession">Affiliate Payout</label>
                  <select
                    className={clsx("form-select", "form-control")}
                    name="affiliate_payout"
                    onChange={this.inputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  >
                    <option disabled selected>
                      Select Option
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Live Plan Id</label>
                  <input
                    type="text"
                    className={clsx("form-control", "setting-input")}
                    name="live_planID"
                    value={this.state.inputField.live_planID}
                    placeholder="Live Plan ID"
                    onChange={this.inputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Live Plan Name</label>
                  <input
                    type="text"
                    className={clsx("form-control", "setting-input")}
                    name="live_planName"
                    value={this.state.inputField.live_planName}
                    placeholder="Live Plan Name"
                    onChange={this.inputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Test Plan ID</label>
                  <input
                    type="text"
                    className={clsx("form-control", "setting-input")}
                    name="test_planID"
                    value={this.state.inputField.test_planID}
                    placeholder="Test Plan ID"
                    onChange={this.inputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Test Plan Name</label>
                  <input
                    type="text"
                    className={clsx("form-control", "setting-input")}
                    name="test_planName"
                    value={this.state.inputField.test_planName}
                    placeholder="Test Plan Name"
                    onChange={this.inputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Status</label>
                  <WindowedSelect
                    name="status"
                    filterOption={createFilter({ ignoreAccents: false })}
                    onChange={this.setCreateStatus.bind(this)}
                    isDisabled={this.state.isProcessing}
                    styles={{
                      option: (base: any) => ({
                        ...base,
                        color: "black",
                      }),
                    }}
                    value={{
                      value: this.state.inputField.status
                        ? this.state.inputField.status
                        : "",
                      label: this.state.inputField.status
                        ? this.state.inputField.status
                        : "Select",
                    }}
                    options={this.state.statusOptions.map((obj) => {
                      return { value: obj, label: obj };
                    })}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Amount</label>
                  <input
                    type="number"
                    className={clsx("form-control", "setting-input")}
                    name="amount"
                    value={this.state.inputField.amount}
                    placeholder="Amount"
                    onChange={this.inputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Frequency</label>
                  <select
                    className={clsx("form-select", "form-control")}
                    name="frequency"
                    onChange={this.inputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  >
                    <option disabled selected>
                      Select Option
                    </option>
                    <option value="one-time">One-time</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Product</label>
                  <input
                    type="text"
                    className={clsx("form-control", "setting-input")}
                    name="product"
                    value={this.state.inputField.product}
                    placeholder="Product"
                    onChange={this.inputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn x-btn x-secondary "
              type="button"
              onClick={() => {
                this.setState({ addAffiliatePlan: false });
              }}
            >
              Close
            </button>
            <button
              className="btn x-btn x-primary "
              type="button"
              onClick={this.addAffiliateSubmit}
              disabled={
                !this.state.inputField.affiliate_payout ||
                !this.state.inputField.live_planID ||
                !this.state.inputField.live_planName ||
                !this.state.inputField.source ||
                !this.state.inputField.status ||
                !this.state.inputField.test_planID ||
                !this.state.inputField.test_planName ||
                !this.state.inputField.amount ||
                !this.state.inputField.frequency ||
                !this.state.inputField.product
              }
            >
              {this.state.isProcessing ? (
                <Spinner animation="border" size="sm" className="p-2" />
              ) : (
                <span className="p-2">Add</span>
              )}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.editAffiliatePlan} onHide={() => {}} size="lg">
          <Modal.Header>Edit Payment Plan</Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Source</label>
                  <select
                    className={clsx("form-select", "form-control")}
                    name="source"
                    onChange={this.editInputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  >
                    <option disabled selected>
                      Select Option
                    </option>
                    <option
                      value="stripe"
                      selected={
                        this.state.editAffiliatePlanData.source === "stripe"
                      }
                    >
                      Stripe
                    </option>
                    <option
                      value="paypal"
                      selected={
                        this.state.editAffiliatePlanData.source === "paypal"
                      }
                    >
                      Paypal
                    </option>
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className="form-group">
                  <label htmlFor="inputSession">Affiliate Payout</label>
                  <select
                    className={clsx("form-select", "form-control")}
                    name="affiliate_payout"
                    onChange={this.editInputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  >
                    <option disabled selected>
                      Select Option
                    </option>
                    <option
                      value="yes"
                      selected={
                        this.state.editAffiliatePlanData.affiliate_payout ===
                        "yes"
                      }
                    >
                      Yes
                    </option>
                    <option
                      value="no"
                      selected={
                        this.state.editAffiliatePlanData.affiliate_payout ===
                        "no"
                      }
                    >
                      No
                    </option>
                  </select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Live Plan Id</label>
                  <input
                    type="text"
                    className={clsx("form-control", "setting-input")}
                    name="live_planID"
                    value={this.state.editAffiliatePlanData.live_planID}
                    placeholder="Live Plan ID"
                    onChange={this.editInputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Live Plan Name</label>
                  <input
                    type="text"
                    className={clsx("form-control", "setting-input")}
                    name="live_planName"
                    value={this.state.editAffiliatePlanData.live_planName}
                    placeholder="Live Plan Name"
                    onChange={this.editInputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Test Plan ID</label>
                  <input
                    type="text"
                    className={clsx("form-control", "setting-input")}
                    name="test_planID"
                    value={this.state.editAffiliatePlanData.test_planID}
                    placeholder="Test Plan ID"
                    onChange={this.editInputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Test Plan Name</label>
                  <input
                    type="text"
                    className={clsx("form-control", "setting-input")}
                    name="test_planName"
                    value={this.state.editAffiliatePlanData.test_planName}
                    placeholder="Test Plan Name"
                    onChange={this.editInputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Status</label>
                  <WindowedSelect
                    name="status"
                    filterOption={createFilter({ ignoreAccents: false })}
                    onChange={this.setEditStatus.bind(this)}
                    isDisabled={this.state.isProcessing}
                    styles={{
                      option: (base: any) => ({
                        ...base,
                        color: "black",
                      }),
                    }}
                    value={{
                      value: this.state.editAffiliatePlanData.status
                        ? this.state.editAffiliatePlanData.status
                        : "",
                      label: this.state.editAffiliatePlanData.status
                        ? this.state.editAffiliatePlanData.status
                        : "Select",
                    }}
                    options={this.state.statusOptions.map((obj) => {
                      return { value: obj, label: obj };
                    })}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Amount</label>
                  <input
                    type="number"
                    className={clsx("form-control", "setting-input")}
                    name="amount"
                    value={this.state.editAffiliatePlanData.amount}
                    placeholder="Amount"
                    onChange={this.editInputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Frequency</label>
                  <select
                    className={clsx("form-select", "form-control")}
                    name="frequency"
                    onChange={this.editInputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  >
                    <option disabled selected>
                      Select Option
                    </option>
                    <option
                      value="one-time"
                      selected={
                        this.state.editAffiliatePlanData.frequency ===
                        "one-time"
                      }
                    >
                      One-time
                    </option>
                    <option
                      value="weekly"
                      selected={
                        this.state.editAffiliatePlanData.frequency === "weekly"
                      }
                    >
                      Weekly
                    </option>
                    <option
                      value="monthly"
                      selected={
                        this.state.editAffiliatePlanData.frequency === "monthly"
                      }
                    >
                      Monthly
                    </option>
                    <option
                      value="yearly"
                      selected={
                        this.state.editAffiliatePlanData.frequency === "yearly"
                      }
                    >
                      Yearly
                    </option>
                  </select>
                </div>
              </Col>
              <Col md={6}>
                <div className={clsx("form-group")}>
                  <label>Product</label>
                  <input
                    type="text"
                    className={clsx("form-control", "setting-input")}
                    name="product"
                    value={this.state.editAffiliatePlanData.product}
                    placeholder="Product"
                    onChange={this.editInputsHandler.bind(this)}
                    disabled={this.state.isProcessing}
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn x-btn x-secondary "
              type="button"
              onClick={() => {
                this.setState({ editAffiliatePlan: false });
              }}
            >
              Close
            </button>
            <button
              className="btn x-btn x-primary "
              type="button"
              onClick={this.updateAffiliateSubmit}
              disabled={
                !this.state.editAffiliatePlanData.affiliate_payout ||
                !this.state.editAffiliatePlanData.live_planID ||
                !this.state.editAffiliatePlanData.live_planName ||
                !this.state.editAffiliatePlanData.source ||
                !this.state.editAffiliatePlanData.status ||
                !this.state.editAffiliatePlanData.test_planID ||
                !this.state.editAffiliatePlanData.test_planName ||
                !this.state.editAffiliatePlanData.amount ||
                !this.state.editAffiliatePlanData.frequency ||
                !this.state.editAffiliatePlanData.product
              }
            >
              {this.state.isProcessing ? (
                <Spinner animation="border" size="sm" className="p-2" />
              ) : (
                <span className="p-2">Update</span>
              )}
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.messageModel.state} onHide={() => {}}>
          <Modal.Header> {this.state.messageModel.header}</Modal.Header>
          <Modal.Body>{this.state.messageModel.message}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn x-btn x-secondary "
              type="button"
              onClick={() => {
                this.setState({
                  messageModel: { state: false, header: "", message: "" },
                });
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.confirmationModel.state} onHide={() => {}}>
          <Modal.Header> {this.state.confirmationModel.header}</Modal.Header>
          <Modal.Body>{this.state.confirmationModel.message}</Modal.Body>
          <Modal.Footer>
            <button
              className="btn x-btn x-secondary "
              type="button"
              onClick={() => {
                this.setState({
                  confirmationModel: { state: false, header: "", message: "" },
                  inputField: { ...this.state.inputField, status: "disabled" },
                });
              }}
            >
              Yes
            </button>
            <button
              className="btn x-btn x-secondary "
              type="button"
              onClick={() => {
                this.setCreateStatus({ value: "enabled" });
                this.setEditStatus({ value: "enabled" });
                this.setState({
                  confirmationModel: { state: false, header: "", message: "" },
                  // editAffiliatePlanData: { ...this.state.editAffiliatePlanData, status: "enabled" }
                });
              }}
            >
              No
            </button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.infoModel} onHide={() => {}} size="lg">
          <Modal.Header>
            <h4>Payment Plan Information</h4>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <span className="font-weight-bold">Source: </span>
              </Col>
              <Col md={6}>
                <span>{this.state.editAffiliatePlanData.source}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Live Plan ID: </span>
              </Col>
              <Col md={6}>
                <span>{this.state.editAffiliatePlanData.live_planID}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Live Plan Name: </span>
              </Col>
              <Col md={6}>
                <span>{this.state.editAffiliatePlanData.live_planName}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Test Plan ID: </span>
              </Col>
              <Col md={6}>
                <span>{this.state.editAffiliatePlanData.test_planID}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Test Plan Name:</span>
              </Col>
              <Col md={6}>
                <span>{this.state.editAffiliatePlanData.test_planName}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Status: </span>
              </Col>
              <Col md={6}>
                <span>{this.state.editAffiliatePlanData.status}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Affiliate Payout: </span>
              </Col>
              <Col md={6}>
                <span>{this.state.editAffiliatePlanData.affiliate_payout}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Amount: </span>
              </Col>
              <Col md={6}>
                <span>{this.state.editAffiliatePlanData.amount}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Frequency: </span>
              </Col>
              <Col md={6}>
                <span>{this.state.editAffiliatePlanData.frequency}</span>
              </Col>
              <Col md={6}>
                <span className="font-weight-bold">Product: </span>
              </Col>
              <Col md={6}>
                <span>{this.state.editAffiliatePlanData.product}</span>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn x-btn x-secondary "
              type="button"
              onClick={() => {
                this.setState({ infoModel: false });
              }}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

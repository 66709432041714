import React, { Component } from 'react';
// Import React Table
import ReactTable from 'react-table';
import './react-table.css';

// Structure:
// {
// 	"id": 2,
// 	"uuid": "YcmvvSL3woVPSyh27A3uUxdb9NA2",
// 	"role.id": 2,
// 	"firstName": "Jordon",
// 	"lastName": "Pierce ",
// 	"email": "test5@test.com",
// 	"date_of_birth": null,
// 	"sex": null,
// 	"credits": 2620,
// 	"referral_code": null,
// 	"created": "2019-01-03T12:13:39.000Z",
// 	"modified": "2019-01-03T12:13:39.000Z"
// }
// TODO: Add more fields to this...

class PPTable2 extends Component {
   state = { selected: -1 };
   tableRowProps(state, rowInfo) {
      if (rowInfo && rowInfo.row) {
         //console.log(rowInfo);
         let bgColor = 'rgba(0, 0, 0, 0.03)';
         if (rowInfo.index !== 0 && rowInfo.index % 2 !== 0) {
            bgColor = 'rgba(0, 0, 0, 0.02)';
         }

         return {
            // TODO: Add click handler from props
            onClick: e => {
               const { original } = rowInfo; // original data passed in...
               //console.log('Clicked on', original);
               //console.log(e.target.parentElement);

               // Handle user's click
               if (this.props.onSelectRow) {
                  this.props.onSelectRow(rowInfo);
               }
            },
            onMouseOver: e => {
               e.target.parentElement.style.background = 'rgba(0, 0, 0, 0.5)';
               e.target.parentElement.style.color = 'white';
            },
            onMouseOut: e => {
               this.setState({ selected: -1 });
               // if (this.state.selected > -1) {
               //    e.target.parentElement.style.background = '#00afec';
               // } else {
               e.target.parentElement.style.background = bgColor;
               e.target.parentElement.style.color = 'white';
               // }
            },
            style: {
               background:
                  rowInfo.index === this.state.selected ? '#00afec' : bgColor,
               color: rowInfo.index === this.state.selected ? 'black' : 'white',
               cursor: 'pointer'
            }
         };
      } else {
         return {};
      }
   }

   render() {
      const { list, columns, noDataText } = this.props;
      return (
         <ReactTable
            filterable
            data={list}
            columns={columns}
            defaultPageSize={10}
            className=""
            noDataText={noDataText}
            getTrProps={this.tableRowProps.bind(this)}
         />
      );
   }
}

export default PPTable2;

import moment from "moment";
import React, { Component } from "react";
import { Card, Dropdown, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { FaTrashAlt } from "react-icons/fa";
import { MdFileDownload } from "react-icons/md";
import ReactTable from "react-table-6";
import { API, Media_URL } from "../../APIAndConfig";
import Pagination from "react-js-pagination";

export class GeneratedZips extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            sortedData: [],
            loader: false,
            deleteZip: {},
            showDeleteModal: false,
            isProcessing: false,
            messageModal: {
                state: false,
                header: '',
                message: ''
            },
        }
    }

    async componentDidMount() {
        await this.getData()
    }

    async getData() {
        await API.getGeneratedZips().then(
            res => {
                if (res.successful && res.payload) {
                    this.setState({
                        data: res.payload.data,
                        sortedData: res.payload.data,
                        loader: true,
                    })
                } else {
                    console.log("Failed to fetch generated zips");
                }
            }
        )
    }

    async removeZipFile() {
        this.setState({ isProcessing: true })
        let payload = {
            ids: this.state.deleteZip.id,
            paths: [this.state.deleteZip.zip_name]
        }

        await API.removeGeneratedZips(payload).then(
            res => {
                if (res.successful) {
                    this.setState({
                        showDeleteModal: false,
                        deleteZip: {},
                        messageModal: {
                            header: 'Successful',
                            message: 'Successfully removed the zip file',
                            state: true
                        },
                        isProcessing: false
                    })
                    this.getData()
                } else {
                    this.setState({
                        showDeleteModal: false,
                        deleteZip: {},
                        messageModal: {
                            header: 'Failed',
                            message: 'Failed to remove zip file',
                            state: true
                        },
                        isProcessing: false
                    })
                }
            }
        )
    }

    renderMessageModal() {
        return (
            <Modal show={this.state.messageModal.state} >
                <Modal.Header> {this.state.messageModal.header}</Modal.Header>
                <Modal.Body>
                    {this.state.messageModal.message}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            this.setState({
                                messageModal: {
                                    header: '',
                                    message: '',
                                    state: false
                                }
                            })
                        }}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (
            !this.state.loader ?
                <div className={"text-center mt-4"}>
                    <Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} />
                </div > :
                <>
                    <div className="row">
                        <div className='col-6 my-4'>
                            <h2 className='main-title'>
                                Session Exports
                            </h2>
                        </div>
                        <div className='col-6 my-4 d-flex justify-content-end align-items-center'>
                            <Dropdown>
                                <Dropdown.Toggle variant="danger" id="dropdown-basic">
                                    Sort
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            let data = [...this.state.data]
                                            this.setState({
                                                sortedData: data
                                            })
                                        }}
                                    >View All</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            let data = [...this.state.data]
                                            this.setState({
                                                sortedData: data.filter(obj => obj.status === "done")
                                            })
                                        }}
                                    >View Completed</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            let data = [...this.state.data]
                                            this.setState({
                                                sortedData: data.filter(obj => obj.status === "InProgress")
                                            })
                                        }}
                                    >View In-Progress</Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            let data = [...this.state.data]
                                            this.setState({
                                                sortedData: data.filter(obj => obj.status === "created"),
                                            })
                                        }}
                                    >View Created</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    {
                        this.state.sortedData.length > 0 ?
                            <div>
                                <Card border="dark">
                                    <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>Session Exports</Card.Header>
                                    <Card.Body className="p-0">
                                        <ReactTable
                                            filterable
                                            data={this.state.sortedData}
                                            columns={[
                                                {
                                                    id: "image",
                                                    Header: 'Session Image',
                                                    accessor: (d) => {
                                                        return (
                                                            <div style={{ minWidth: '100px' }}>
                                                                {
                                                                    !d.thumbnail_url && !d.placeholder_url ?
                                                                        <div style={{
                                                                            minHeight: '100px', borderRadius: '20px', background: '#4a3862', maxWidth: '100px', minWidth: '100px', border: '0'
                                                                        }} className="d-flex justify-content-center align-items-center ml-auto mr-auto" >
                                                                            <img
                                                                                src={'https://www.positiveprime.com/wp-content/uploads/2021/04/Positive-Prime-logowhite.png'}
                                                                                className="sessionCardContainer__imgContainer-img sessionCard-rounded h-100 p-3"
                                                                            />
                                                                        </div>
                                                                        :
                                                                        <img
                                                                            src={d.thumbnail_url ? process.env.REACT_APP_CDN + '/' + d.thumbnail_url : Media_URL + '/' + d.placeholder_url}
                                                                            style={{ width: '100%', border: "0", minWidth: '100px', maxWidth: '100px', minHeight: '100px', maxHeight: "100px" }}
                                                                            className="sessionCardContainer__imgContainer-img sessionCard-rounded"
                                                                        />
                                                                }
                                                            </div>
                                                        )
                                                    },
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'd-flex justify-content-center align-items-center',
                                                    style: { minWidth: "110px" }
                                                },
                                                {
                                                    id: "session_id",
                                                    Header: 'Session Id',
                                                    accessor: 'session_id',
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'd-flex justify-content-center align-items-center'
                                                },
                                                {

                                                    id: "session_name",
                                                    Header: 'Session Name',
                                                    accessor: (d) => {
                                                        return (
                                                            <div className="w-100" style={{ whiteSpace : "pre-wrap" }}>
                                                                {d.session_name}
                                                            </div>
                                                        )
                                                    },
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'd-flex justify-content-center align-items-center'

                                                },
                                                {
                                                    id: "created_at",
                                                    Header: 'Created At',
                                                    accessor: (d) => {
                                                        return (
                                                            <div className="w-100" style={{ whiteSpace : "pre-wrap" }}>
                                                                {moment(d.created_at).format("DD-MM-YYYY HH:mm:ss")}
                                                            </div>
                                                        )
                                                    },
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'd-flex justify-content-center align-items-center'
                                                },
                                                {
                                                    id: "user_id",
                                                    Header: 'Requested By',
                                                    accessor: 'user_id',
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'd-flex justify-content-center align-items-center'
                                                },
                                                {

                                                    id: "file_name",
                                                    Header: 'File Name',
                                                    accessor: (d) => {
                                                        return (
                                                            <div className="w-100" style={{ whiteSpace : "pre-wrap" }}>
                                                                {d.file_name ? d.file_name : "-"}
                                                            </div>
                                                        )
                                                    },
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'd-flex justify-content-center align-items-center',

                                                },
                                                {
                                                    id: "status",
                                                    Header: 'File Status',
                                                    accessor: (d) => {
                                                        return d.status === "done" ? "Completed" : d.status === "created" ? "Created" : "In-Progress"
                                                    },
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'd-flex justify-content-center align-items-center'
                                                },
                                                {
                                                    id: "size",
                                                    Header: 'File Size',
                                                    accessor: (d) => {
                                                        return d.status === "done" ?
                                                            parseFloat((Number(d.size) / 1000000).toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) + " mb" : " - "
                                                    },
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'd-flex justify-content-center align-items-center',

                                                },
                                                {
                                                    id: "total_images",
                                                    Header: 'Total Images',
                                                    accessor: (d) => {
                                                        return d.total_images ? d.total_images : " - "
                                                    },
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'd-flex justify-content-center align-items-center'
                                                },
                                                {
                                                    id: "action",
                                                    Header: 'Action',
                                                    accessor: (d) => {
                                                        return (
                                                            <>
                                                                <button className="btn x-secondary text-white"
                                                                    disabled={d.status !== "done"}
                                                                    onClick={() => {
                                                                        window.location.href = process.env.REACT_APP_S3_URL_ZIP + "/" + d.zip_name
                                                                    }}
                                                                >
                                                                    <MdFileDownload size={18} />
                                                                </button>
                                                                <button className="btn x-secondary text-white ml-2"
                                                                    disabled={d.status !== "done"}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            deleteZip: d,
                                                                            showDeleteModal: true
                                                                        })
                                                                    }}
                                                                >
                                                                    <FaTrashAlt size={18} />
                                                                </button>
                                                            </>
                                                        )
                                                    },
                                                    headerClassName: 'p-3 text-light',
                                                    className: 'd-flex justify-content-center align-items-center'
                                                }
                                            ]}
                                            defaultPageSize={10}
                                            style={{ background: "rgb(111 79 136 / 1)" }}
                                            noDataText='No Data Found'
                                            getTrProps={(state, rowInfo, column, instance) => {
                                                if (typeof rowInfo !== "undefined") {
                                                    return {
                                                        style: {
                                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                                        },
                                                    }
                                                }
                                                else {
                                                    return {
                                                        style: {
                                                            background: 'transparent',
                                                            color: 'white'
                                                        },
                                                    }
                                                }
                                            }}
                                        />
                                    </Card.Body>
                                </Card>
                            </div>
                            :
                            < div className="text-center mt-5 pt-5" style={{ fontSize: '30px' }} > No Session Exported</div >
                    }
                    {this.renderMessageModal()}
                    <Modal show={this.state.showDeleteModal} >
                        <Modal.Header>
                            <div className='w-100'>
                                <label>Delete Zip</label>
                                <button
                                    className="x-secondary"
                                    style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                                    onClick={() => {
                                        this.setState({
                                            showDeleteModal: false,
                                            deleteZip: {}
                                        })
                                    }}
                                >X</button>
                            </div>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete the zip file ?
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="btn x-btn x-secondary "
                                type="button"
                                onClick={this.removeZipFile.bind(this)}
                            >
                                {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Delete</span>}
                            </button>
                        </Modal.Footer>
                    </Modal>
                </>
        )
    }
}


export default GeneratedZips
import React from 'react';
import './survey.css';
import {faFrown} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMeh, faSmile} from "@fortawesome/free-solid-svg-icons";

const SurveyAfter = ({ onAfterModalClose, onSelect, onDone, onSkip }) => {
   return (
      <div id="afterModal" className="surveyModal newSurveyModal">
         <div className="d-flex align-items-center justify-content-center h-100">
            <div className="surveyModalContent pp-animate-bottom bg-white p-4 shadow">
               <p
                  className="modalClose text-right m-0"
                  onClick={onAfterModalClose}
               >
                  &times;
               </p>
               <div className="py-4">
                  <p className="h4 m-0 px-2" style={{color: '#200E32', fontWeight: '300'}}>After this session</p>
                  <p className="h4 m-0 px-2" style={{color: '#30374D', fontWeight: '300'}}>How are you feeling now?</p>
               </div>
               <div className="row m-0 pb-4">
                  <div className="col-4 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded border"
                        name="not-better"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faFrown} className="ppEmoji ppBlue" />
                        </p>
                     </div>
                     <p className="fontSmall text-center m-0">
                        Not Better
                     </p>
                  </div>
                  <div className="col-4 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded border"
                        name="same"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faMeh} className="ppEmoji ppGray" />
                        </p>
                     </div>
                     <p className="fontSmall text-center m-0">Same</p>
                  </div>
                  <div className="col-4 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded border"
                        name="better"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faSmile} className="ppEmoji ppGreen" />
                        </p>
                     </div>
                     <p className="fontSmall text-center m-0">
                        Better
                     </p>
                  </div>
               </div>
               <div className="pb-4" align="center">
                  <button className="btn btn-primary" id="done-btn" onClick={onDone}>
                     <p>Done</p>
                  </button>
               </div>
               <div className="pb-4" align="center">
                  <a href="#" onClick={onSkip}
                  style={{color: '#30374D', fontSize: '18px'}}>
                     Skip
                  </a>
               </div>
               {/* <center>
                        <button
                           id="addBtn"
                           className="btn btn-primary"
                           style={{ margin: '10px 0px' }}
                           type="submit"
                        >
                           Ok
                        </button>
                     </center> */}
            </div>
         </div>
      </div>
   );
};

export default SurveyAfter;

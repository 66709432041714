import React from 'react';
import clsx from 'clsx';

function StatsCard(props){
    return(
        <div className={clsx('container','stats-card','primary-bg')}>
            <div className={'stats-card__text'}>
                <p className={clsx('stats-card__text-title','x-card-title')}>{props.title}</p>
                <p className={clsx('stats-card__text-subtitle','x-card-subtitle')}>{props.subtitle}</p>
            </div>
            <div className={'stats-card__main'}>
                <img src={props.icon} alt={'stats-card__main-image'} className={'stats-card__main-image'} />
                <span className={'stats-card__main-value'}>{props.value}</span>
                <span className={'stats-card__main-unit'}>{props.unit}</span>
            </div>
        </div>
    )
}

export default StatsCard;
import React from "react";
import clsx from "clsx";
import SessionCardButton from "./SessionCardButton";

function SessionCover(props) {
  const overlay = {
    // background: "#000000",
    // position: "absolute",
    // top: "0",
    // bottom: "0",
    // left: "0",
    // right: "0",
    // height: "100%",
    borderRadius: "10px 10px 0 0",
    backgroundImage:
      "linear-gradient(0deg, rgb(13 9 12 / 61%), rgb(40 40 40 / 30%)), url(" +
      props.img +
      ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    minHeight: "300px",
  };

  return (
    <div className={clsx("container-fluid justify-content-center")}>
      {/* <div style={overlay} className="overlay row" >
        <div className={"col-12 "}>
          <img
            src={makePlayerImageUrl(props.img)}
            alt={props.title}
            // className={"sessionCoverContainer__img,"}
            style={{
              // height: "fit-content",
              width: "100%",
              visibility: "hidden"
            }} /></div>
      </div> */}

      <div
        className={("sessionCoverContainer__textContainer", "row", "m-0")}
        style={overlay}
      >
        <div className={"col-12 p-5"}>
          {/* 
          <img
            src={makePlayerImageUrl(props.img)}
            alt={props.title}
            // className={"sessionCoverContainer__img,"}
            style={{
              // height: "fit-content",
              visibility: "hidden"
            }} /> */}
          <h3
            className={clsx(
              "sessionCoverContainer__textContainer-title",
              "main-title",
              "main-title-2",
              "mt-5"
            )}
          >
            {props.title}
          </h3>
          <p
            className={clsx(
              "sessionCoverContainer__textContainer-title",
              "p-text"
            )}
          >
            {props.desc}
          </p>
          <button
            onClick={props.onClickPersonalize}
            className={clsx(
              "sessionCoverContainer__textContainer-button",
              "btn",
              "x-btn",
              "x-secondary",
              "personalize__btn"
            )}
          >
            Personalize
          </button>
          <button
            onClick={props.onClickPlay}
            className={clsx(
              "sessionCoverContainer__textContainer-button",
              "btn",
              "x-btn",
              "x-secondary"
            )}
            style={{ marginLeft: "20px" }}
          >
            Play Session
          </button>
        </div>
      </div>
      <SessionCardButton id={props.id} />
    </div>
  );
}

export default SessionCover;

import {FETCH_IMAGE} from '../actions/types';

export default function(state = {}, action) {
   switch (action.type) {
      case FETCH_IMAGE:
         //console.log('IMAGE PAYLOAD', action.payload);
         return action.payload.data || [];
      default:
         return state;
   }
}

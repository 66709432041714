import { Col, Row, Form, Button, Alert, Card } from "react-bootstrap";
import Loader from "../common/Loader";
import React, { ChangeEvent } from "react";
import { IntroWrap } from "./IntroWrap";
import { Link } from "react-router-dom";
import { FormInput } from "../FormInput";
import { cookies } from "../../index";
import { Firebase } from "../../firebase";
import { GoInfo } from "react-icons/go";

export interface ForgetPasswordState {
    isProcessing: boolean
    email: string;
    emailIsValid: boolean | null;
    emailFeedback: string | null;
    otherError: string | null;
    showDoneAlert: boolean;
}
export class ForgetPasswordV2 extends React.Component<any, ForgetPasswordState> {


    constructor(props: any) {
        super(props);
        this.state = {
            isProcessing: false,
            // email: cookies.get("email") && cookies.get("email") !== "undefined" && cookies.get("email") !== "null" ? cookies.get("email") : '',
            email: '',
            emailFeedback: null,
            emailIsValid: null,
            otherError: null,
            showDoneAlert: false
        }
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.id === "email")
            this.setState({
                email: event.target.value,
                emailIsValid: null,
                emailFeedback: null
            })
    }
    async handleReset() {
        await this.setState({ isProcessing: true, showDoneAlert: true });
        cookies.set("email", this.state.email);
        try {
            const result = await Firebase.Auth.sendPasswordResetEmail(this.state.email);
            await this.setState({
                isProcessing: false,
                emailIsValid: true,
                emailFeedback: "An email has been sent to this address"
            });
        } catch (err) {
            let message: string = err.message;
            if (err.code === "auth/user-not-found")
                message = "This email address is not registered";

            await this.setState({
                isProcessing: false,
                emailIsValid: false,
                emailFeedback: message,
                otherError: message
            });
        }

    }
    renderError() {
        if (!this.state.otherError)
            return;

        return (
            <Alert variant="danger">
                {this.state.otherError}
            </Alert>
        )
    }
    render() {
        return (
            <div className="container-fluid justify-content-center login-background3 h-100">
                <div className="row justify-content-center d-flex p-2 mt-3">
                    <div className="card col-md-4 col-sm-12 p-5 border-0" style={{ background: "transparent" }}>
                        {this.renderError()}

                        <div className="row mb-4">
                            <div className="d-flex flex-column justify-content-center align-items-center col-12">
                                <a className="loginForm text-center logo-prime" href="https://positiveprime.com">
                                    <img src="/assets/images/Logo1.0.png" alt="Positive Prime" style={{ maxWidth: "250px" }} />
                                </a>
                            </div>
                        </div>
                        <div className="d-flex flex-column align-items-center m-4">
                            <h1 className="main-title text-light">Forgotten your password?</h1>
                            <p style={{
                                fontFamily : 'sofia-pro, sans-serif', fontSize : '14px', color : 'white'
                            }} >Enter your email below to receive a link to reset your password</p>
                        </div>
                        {
                            !this.state.showDoneAlert ?
                                <>
                                    <div className="form-group my-4">
                                        <label htmlFor="email" style={{
                                            fontFamily : 'sofia-pro, sans-serif', fontSize : '14px', lineHeight : '13px', letterSpacing : '0.19em',
                                            color : 'white', opacity : '0.75'
                                        }}>Email Address</label>
                                        <input
                                            type="email"
                                            className={"form-control custom-input " + this.getClassName(this.state.emailIsValid)}
                                            name="email"
                                            id="email"
                                            value={this.state.email}
                                            onChange={this.handleChange.bind(this)}
                                            disabled={this.state.isProcessing}
                                        />
                                        {/* <span className="errorMessage">
                                {this.state.emailFeedback}
                            </span> */}
                                    </div>
                                    <div className="form-group d-flex justify-content-between align-items-center my-4">
                                        <button
                                            className="btn btn-primary btn-block py-2 custom-button"
                                            disabled={this.state.emailIsValid || this.state.isProcessing}
                                            type="submit"
                                            onClick={this.handleReset.bind(this)}
                                        >Reset Password</button>
                                    </div>
                                </> :
                                <div className="card shadow text-light" style={{ background: "linear-gradient( 104.21deg, #857ff1 -27.11%, #cdaab1 114.9% )" }}>
                                    <Row className="p-4">
                                        <Col className="col-1 d-flex align-items-center">
                                            <GoInfo size={22} ></GoInfo>
                                        </Col>
                                        <Col>
                                            If you have a valid email address with us, instructions to reset your password have been emailed and will arrive in your inbox shortly.
                                            <br/>
                                            <br/>
                                            In the instance you have not received the notification within a few minutes, please ensure you check your Spam/Junk folders within your email account.
                                    </Col>
                                    </Row>
                                </div>
                        }

                        <div className="my-5 text-success form-group  row">
                            <div className="col-md-6 text-center">
                                <span>
                                    <Link className="d-md-inline float-md-left mb-2" style={{
                                fontFamily : 'sofia-pro, sans-serif', fontSize : '14px', color : 'white'
                            }} to="/login">Back to Login</Link>
                                </span>
                            </div>
                            <div className="col-md-6 text-center">
                                <span>
                                    <Link className="d-md-inline float-md-right mb-2" style={{
                                fontFamily : 'sofia-pro, sans-serif', fontSize : '14px', color : 'white'
                            }} to="/signup7">Sign Up</Link>
                                </span>
                            </div>
                        </div>
                        <div className="u-login-form text-muted py-3 mt-auto text-center">
                            If you are having trouble please contact <a href="mailto:support@positiveprime.com">support@positiveprime.com</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    getClassName(valid: boolean | null | undefined) {

        if (valid === null || valid == undefined) {
            return undefined;
        }

        return "is-" + (valid ? "valid" : "invalid");
    }

}
export default ForgetPasswordV2;

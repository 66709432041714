import { set } from "lodash";
import moment from "moment";
import React from "react";
import {
  Container,
  Spinner,
  Card,
  Row,
  Col,
  Form,
  InputGroup,
  Modal,
  Button,
} from "react-bootstrap";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { APIActions } from "../../api/APIActions";
import { API } from "../../APIAndConfig";
import { FormInput } from "../FormInput";
import { createFilter } from "react-select";
import WindowedSelect from "react-windowed-select";
import { CSVLink } from "react-csv";
import _ from "lodash";
import { DataUsageSharp } from "@material-ui/icons";
import { format, parseISO } from "date-fns";
import { IconButton, TextField } from "@material-ui/core";
import { FaTrashAlt } from "react-icons/fa";
import Select from "react-select";

interface stateinterface {
  tableData: any;
  userID: any;
  description: any;
  amount: any;
  userIdList: any;
  type: any;
  searchInput: string;
  errAmount: any;
  errDesp: any;
  amountMessage: any;
  descriptionMessage: any;
  filteredData: any;
  eligibleBox: any;
  tableRowCount: Number;
  pageNumber: Number;
  entryDate: string;
  transactionDate2: string;
  showConfirmationDeleteModal: boolean;
  selectedLedgerId: number | null;
  loggedUserId: any;
}

export class LedgerComponent extends React.Component<any, stateinterface> {
  constructor(props: any) {
    super(props);

    this.state = {
      tableData: null,
      filteredData: [],
      userID: null,
      description: null,
      amount: null,
      errAmount: null,
      errDesp: null,
      userIdList: [],
      type: "Generic (default)",
      amountMessage: null,
      descriptionMessage: null,
      searchInput: "",
      eligibleBox: true,
      tableRowCount: 10,
      pageNumber: 0,
      // need to follow yyyy-MM-ddTHH:mm
      entryDate: format(new Date(), "yyyy-MM-dd HH:mm").replace(" ", "T"),
      transactionDate2: format(new Date(), "yyyy-MM-dd HH:mm").replace(
        " ",
        "T"
      ),
      showConfirmationDeleteModal: false,
      selectedLedgerId: null,
      loggedUserId: props.loggedUserId,
    };
  }

  async componentWillMount() {
    await this.loadTable();
  }

  async loadTable() {
    const params = new URLSearchParams(this.props.props.location.search);
    const payoutdate = params.get("payoutdate");
    const userId = params.get("userId");
    const ledgerRecord: any = await API.getLedgerData(
      userId,
      Number(this.state.eligibleBox),
      String(payoutdate)
    );
    const day = Number(payoutdate?.split("-")[1]) + 2;
    const formattedpayoutdate =
      payoutdate?.split("-")[0] + "-" + payoutdate?.split("-")[2] + "-" + day;
    const currentUser = this.props.currentUser;
    const origTableData = payoutdate
      ? ledgerRecord.payload.data.filter(
          (x: any) =>
            new Date(x.payoutdate) < new Date(formattedpayoutdate) &&
            new Date(x.payoutdate) >
              new Date(
                payoutdate?.split("-")[0] +
                  "-" +
                  payoutdate?.split("-")[2] +
                  "-01"
              )
        )
      : ledgerRecord.payload.data;

    console.log("Date: ", formattedpayoutdate);
    console.log("Date Filtered Data: ", origTableData);

    if (this.state.loggedUserId) {
      this.setState({
        filteredData: origTableData.filter(
          (x: any) => x.userID === this.props.loggedUserId.toString()
        ),
      });
    }
    this.setState({
      tableData: origTableData || [],
      userIdList: ledgerRecord.payload.userId || [],
      userID: userId,
      searchInput: currentUser,
    });
  }

  getUserLedger = async (data: any) => {
    this.props.props.history.push("/ledger?userId=" + data.original.userID);
    this.setState({
      tableData: null,
      userIdList: [],
      userID: data.original.userID,
    });
    const ledgerRecord: any = await API.getLedgerData(
      data.original.userID,
      Number(this.state.eligibleBox)
    );
    this.setState({
      tableData: ledgerRecord.payload.data || [],
      userIdList: ledgerRecord.payload.userId || [],
      userID: ledgerRecord.payload.userId[0].id,
    });
  };

  globalSearch = () => {
    let { searchInput, tableData } = this.state;
    let filteredData = tableData.filter((value: any) => {
      return (
        (value.amount &&
          value.amount.toLowerCase().includes(searchInput.toLowerCase())) ||
        (value.description &&
          value.description
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (value.entrytimestamp &&
          value.entrytimestamp
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (value.ledgerID &&
          value.ledgerID.toLowerCase().includes(searchInput.toLowerCase())) ||
        (value.firstName &&
          value.firstName.toLowerCase().includes(searchInput.toLowerCase())) ||
        (value.lastName &&
          value.lastName.toLowerCase().includes(searchInput.toLowerCase())) ||
        (value.refereeFirstName &&
          value.refereeFirstName
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (value.refereeLastName &&
          value.refereeLastName
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (value.payoutdate &&
          value.payoutdate.toLowerCase().includes(searchInput.toLowerCase())) ||
        (value.paymentstatus &&
          value.paymentstatus.toLowerCase().includes(searchInput.toLowerCase()))
      );
    });
    if (this.state.loggedUserId) {
      filteredData = filteredData.filter(
        (x: any) => x.userID === this.props.loggedUserId.toString()
      );
    }
    this.setState({ filteredData });
  };

  searchChange = (event: any) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  eligibleBoxChange = async () => {
    let newState = !this.state.eligibleBox;
    const params = new URLSearchParams(this.props.props.location.search);
    const userId = params.get("userId");

    const ledgerRecord: any = await API.getLedgerData(
      this.state.userID || userId,
      Number(newState)
    );
    this.setState({
      tableData: ledgerRecord.payload.data,
      eligibleBox: newState,
    });
  };

  handleChange(e: any) {
    let state = { ...this.state };

    if (e.target.name === "userid") {
      state.userID = e.target.value;
    } else if (e.target.name === "description") {
      state.description = e.target.value;
    } else if (e.target.name === "amount") {
      state.amount = e.target.value;
    } else if (e.target.name === "type") {
      state.type = e.target.value;
    }
    this.setState(state);
  }

  setUser(e: any) {
    console.log("event", e);
    this.setState({ userID: e.value });
  }

  async handleClick(e: any) {
    e.preventDefault();

    let num = new RegExp("[-]?d+(.)?(d+)?");

    if (this.state.amount !== null && this.state.amount.match(num) !== null) {
      this.setState({
        errAmount: false,
        amountMessage: "amount can only be number",
      });
    }

    if (
      this.state.userID !== null &&
      this.state.type != null &&
      this.state.amount !== null &&
      this.state.description !== null
    ) {
      await API.postLedgerData({
        userID: this.state.userID,
        description: this.state.description,
        amount: this.state.amount,
        type: this.state.type,
        entryDate: parseISO(this.state.entryDate),
        transactionDate2: parseISO(this.state.transactionDate2),
      })
        .then((res: any) => {
          if (res.payload.message === "Successfully saved") {
            alert("Saved Successfully");

            let data = { ...this.state };
            data.userID = data.userIdList[0].id;
            data.type = "Generic (default)";
            data.description = null;
            data.amount = null;
            data.errAmount = null;
            data.errDesp = null;
            data.amountMessage = null;
            data.descriptionMessage = null;
            this.setState(data);
            this.loadTable();
          }
        })
        .catch((err) => {});
    } else {
      if (this.state.amount === null) {
        this.setState({
          errAmount: false,
          amountMessage: "amount cannot be empty",
        });
      }

      if (this.state.description === null) {
        this.setState({
          errDesp: false,
          descriptionMessage: "description cannot be empty",
        });
      }
    }
  }

  handleDateChange(e: any) {
    const target = e.target as HTMLInputElement;
    if (e.target.name === "transaction_date") {
      this.setState({ transactionDate2: target.value });
      return;
    }
    this.setState({ entryDate: target.value });
  }

  async handleDeleteLedgerClick() {
    const ledgerId = this.state.selectedLedgerId ?? 0;
    const tableData = [...this.state.tableData];
    const filteredData = [...this.state.filteredData];

    await API.deleteLedgerData(ledgerId);

    const tableDataIndex = tableData.findIndex((t) => t.ledgerID === ledgerId);
    if (tableDataIndex > -1) {
      tableData.splice(tableDataIndex, 1);
    }

    const filteredDataIndex = filteredData.findIndex(
      (t) => t.ledgerID === ledgerId
    );
    if (filteredDataIndex > -1) {
      filteredData.splice(filteredDataIndex, 1);
    }

    this.setState({
      tableData,
      filteredData,
      showConfirmationDeleteModal: false,
    });
  }

  handleToggleConfirmationDeleteModal() {
    this.setState({
      showConfirmationDeleteModal: !this.state.showConfirmationDeleteModal,
    });
  }

  showLedger() {
    const columns = [
      //   {
      //     Header: "Ledger Id",
      //     accessor: "ledgerID",
      //     headerClassName: "p-3 text-light",
      //     className: "text-center",
      //   },
      {
        id: "entrytimestamp",
        Header: "Timestamp",
        headerClassName: "p-3 text-light",
        accessor: (d: any) => {
          return moment(d.entrytimestamp).local().format("YYYY-DD-MM");
        },
        className: "text-center",
      },
      {
        Header: "User",
        headerClassName: "p-3 text-light",
        className: "text-center",
        Cell: (data: any) => (
          <a
            style={{ color: "white", cursor: "pointer" }}
            onClick={() => this.getUserLedger(data)}
          >
            {data.original.firstName} {data.original.lastName}
          </a>
          //
          // <button className="btn btn-primary btn-block p-auto m-auto" onClick={() => this.setPayout(data)} >
          //     Payout
          // </button>
        ),
      },
      {
        Header: "Description",
        accessor: "description",
        headerClassName: "p-3 text-light",
        className: "text-center",
        Cell: (data: any) => (
          <p>
            {data.original.description} for ${data.original.amount}
            {data.original.subscribedMembershipType === "NA" ||
            data.original.subscribedMembershipType === "none" ||
            !data.original.subscribedMembershipType
              ? ""
              : ", " +
                data.original.subscribedMembershipType.replace(/[-]/g, " ") +
                " membership"}
          </p>
        ),
      },
      {
        Header: "Referee",
        headerClassName: "p-3 text-light",
        className: "text-center",
        Cell: (data: any) =>
          data.original.refereeFirstName === "NA" ? (
            "NA"
          ) : (
            <p>
              {data.original.refereeFirstName} {data.original.refereeLastName}
            </p>
          ),
      },
      {
        Header: "Payout Date",
        id: "payoutdate",
        accessor: (d: any) => {
          if (d.payoutdate !== "NA") {
            return moment(d.payoutdate).local().format("YYYY-DD-MM");
          } else if (d.transactiondate !== "NA") {
            return (
              moment(d.transactiondate).local().format("YYYY-DD-MM") +
              " (TRANSACTION)"
            );
          } else {
            return (
              moment(d.entrytimestamp).local().format("YYYY-DD-MM") + " (ENTRY)"
            );
          }
        },
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Amount",
        accessor: "amount",
        id: "amount",
        headerClassName: "p-3 text-light",
        className: "text-center",
        Footer: () => {
          let amountSum = 0;
          //this.state.tableRowCount
          for (
            let i =
              Number(this.state.pageNumber) * Number(this.state.tableRowCount);
            i <
            Number(this.state.pageNumber) * Number(this.state.tableRowCount) +
              Number(this.state.tableRowCount);
            i++
          ) {
            try {
              amountSum += Number(
                !!this.state.filteredData.length
                  ? this.state.filteredData[i].amount
                  : this.state.tableData[i].amount
              );
            } catch {
              console.log("No more data");
            }
          }
          return (
            "Total: " +
            amountSum.toLocaleString("en", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          );
        },
      },
      {
        Header: "Status",
        accessor: "paymentstatus",
        headerClassName: "p-3 text-light",
        className: "text-center",
      },
      {
        Header: "Action",
        headerClassName: "p-3 text-light",
        className: "text-center",
        Cell: (data: any) => (
          <IconButton
            size="small"
            aria-label="delete entry"
            component="span"
            onClick={() => {
              this.setState({ selectedLedgerId: data.original.ledgerID });
              this.handleToggleConfirmationDeleteModal();
            }}
          >
            <FaTrashAlt color="d21404" />
          </IconButton>
        ),
      },
    ];

    let filteredColumns = columns;
    if (this.state.eligibleBox) {
      filteredColumns = filteredColumns.filter(
        (f) => f.accessor !== "paymentstatus"
      );
    }

    if (!this.state.tableData)
      return (
        <div className={"text-center"}>
          <Spinner
            animation={"grow"}
            variant={"primary"}
            style={{ width: "5em", height: "5em" }}
          />
        </div>
      );

    let tableBox = document.getElementById("paymentLedgerTable")!;
    let tableBody = tableBox!.getElementsByClassName("rt-tr-group");
    let pageBox = tableBox!.getElementsByClassName("-pageJump");

    return (
      <div>
        <Modal
          show={this.state.showConfirmationDeleteModal}
          onHide={this.handleToggleConfirmationDeleteModal.bind(this)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this record?</Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={this.handleToggleConfirmationDeleteModal.bind(this)}
            >
              Close
            </Button>
            <Button
              variant="danger"
              onClick={this.handleDeleteLedgerClick.bind(this)}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        <Card border="dark">
          <Card.Header
            className="text-white font-weight-bold "
            style={{ background: "rgb(111 79 136 / 1)" }}
          >
            {" "}
            <div>
              <span>Sales Ledger</span>
              <div
                style={{
                  float: "right",
                  marginLeft: "0.5rem",
                  backgroundColor: "white",
                }}
              >
                <CSVLink
                  data={
                    !!this.state.filteredData.length || this.props.loggedUserId
                      ? this.state.filteredData
                      : this.state.tableData
                  }
                  filename={"Current-Referrals.csv"}
                  className="dropdown-item"
                >
                  Download CSV
                </CSVLink>
              </div>
              <input
                style={{
                  float: "right",
                  marginLeft: "0.5rem",
                  marginTop: "0.5rem",
                }}
                type="checkbox"
                checked={this.state.eligibleBox}
                onChange={this.eligibleBoxChange}
              />
              <p
                style={{
                  float: "right",
                  marginLeft: "0.5rem",
                  marginTop: "0.1rem",
                }}
              >
                Hide Ineligible
              </p>
              <input
                style={{ float: "right" }}
                name="searchInput"
                value={this.state.searchInput || ""}
                onChange={this.searchChange}
                placeholder="Search"
              />
            </div>
          </Card.Header>
          <Card.Body className="p-0" id="paymentLedgerTable">
            <ReactTable
              data={
                !!this.state.filteredData.length || this.props.loggedUserId
                  ? this.state.filteredData
                  : this.state.tableData
              }
              columns={filteredColumns}
              defaultPageSize={10}
              noDataText="No Data Found"
              onPageChange={() =>
                this.setState({
                  pageNumber:
                    Number(
                      (pageBox[0].childNodes[0] as HTMLInputElement).value
                    ) - 1,
                })
              }
              onPageSizeChange={() =>
                this.setState({ tableRowCount: tableBody.length })
              }
              style={{ background: "rgb(111 79 136 / 1)" }}
              getTrProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    style: {
                      background:
                        rowInfo.index % 2 === 0
                          ? "transparent"
                          : "rgb(96 64 125 / 1)",
                      color: rowInfo.index % 2 === 0 ? "white" : "white",
                    },
                  };
                } else {
                  return {
                    style: {
                      background: "transparent",
                      color: "white",
                    },
                  };
                }
              }}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }

  renderSelectUser() {
    return (
      <>
        <Select
          onChange={this.setUser.bind(this)}
          styles={{
            option: (base) => ({
              ...base,
              color: "black",
            }),
          }}
          options={this.state.userIdList.map((obj: any) => {
            return {
              value: obj.id,
              label: obj.first_name + " " + obj.last_name + " - " + obj.id,
            };
          })}
        />
        {/* <WindowedSelect
          name="userID"
          filterOption={createFilter({ ignoreAccents: false })}
          onChange={this.setUser.bind(this)}
          styles={{
            option: (base) => ({
              ...base,
              color: "black",
            }),
          }}
          options={this.state.userIdList.map((obj: any) => {
            return {
              value: obj.id,
              label: obj.first_name + " " + obj.last_name + " - " + obj.id,
            };
          })}
        /> */}
      </>
    );
  }

  render() {
    return (
      <div className="p-0">
        {this.props.isLedger ? (
          <Row id="paymentLedgerTable" className="ml-3 mr-3 mb-3 mt-5">
            <Col>{this.showLedger()}</Col>
          </Row>
        ) : (
          this.showLedger()
        )}

        {this.props.isLedger && (
          <>
            <div className="row m-0">
              <div className="col-12 pt-5 px-4 pb-0 px-lg-5">
                <p className="h4 m-0">Add Sales Entry</p>
              </div>
            </div>

            <Row className="mt-3 ml-3 mr-3 mb-5">
              <Col>
                <div className="bg-white shadow p-4 mb-5">
                  <form>
                    <div className="form-group">
                      <label htmlFor="inputSession">User ID</label>
                      {this.renderSelectUser()}
                    </div>
                    <h6 style={{ color: "grey" }}>Entry Date</h6>
                    <TextField
                      id="date"
                      type="datetime-local"
                      disabled
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "0.25rem",
                        padding: "0.375rem 0.75rem",
                        width: "100%",
                      }}
                      value={this.state.entryDate}
                      onChange={(e) => this.handleDateChange(e)}
                    />

                    <FormInput
                      name="description"
                      type="text"
                      label="Description"
                      placeholder="Description"
                      value={this.state.description}
                      valid={this.state.errDesp}
                      feedback={this.state.descriptionMessage}
                      onChange={(e) => this.handleChange(e)}
                    ></FormInput>

                    <div className="form-group">
                      <label htmlFor="inputSession">Type</label>
                      <select
                        id="dashboard-session-select"
                        value={this.state.type}
                        onChange={this.handleChange.bind(this)}
                        className="custom-select"
                        name="type"
                      >
                        {[
                          "Generic (default)",
                          "Product Sale with Affiliates",
                          "Product Sale No Affiliates",
                          "sales-adjustment",
                        ].map((obj: any, value: any) => {
                          return (
                            <option key={`option-item-${value}`} value={obj}>
                              {obj}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <FormInput
                      name="amount"
                      type="text"
                      label="Amount"
                      placeholder="Amount"
                      value={this.state.amount}
                      valid={this.state.errAmount}
                      feedback={this.state.amountMessage}
                      onChange={(e) => this.handleChange(e)}
                    ></FormInput>
                    <h6 style={{ color: "grey" }}>Transaction Date</h6>
                    <TextField
                      id="transaction_date"
                      type="datetime-local"
                      name="transaction_date"
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: "0.25rem",
                        padding: "0.375rem 0.75rem",
                        width: "100%",
                      }}
                      value={this.state.transactionDate2}
                      onChange={(e) => this.handleDateChange(e)}
                    />
                    <div className="mt-4">
                      <button
                        className="btn btn-primary btn-block p-auto m-auto"
                        onClick={(e) => {
                          this.handleClick(e);
                        }}
                      >
                        Add Sales Entry
                      </button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
            <Row className="mb-5"></Row>
          </>
        )}
      </div>
    );
  }
}

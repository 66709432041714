import {FETCH_CURRENT_SESSION} from "../actions/types";

export default function(state = [], action) {
  //console.log(action);
  switch (action.type) {
    case FETCH_CURRENT_SESSION:
      return action.payload || [];
    default:
      return state;
  }
}

import React, { useEffect, useRef, useState, useContext } from "react";
import { Spinner } from "react-bootstrap";
import DeleteIcon from "../../assets/icons/delete-icon.svg";
import DownloadIcon from "../../assets/icons/download.svg";
import PauseIcon from "../../assets/icons/pause.svg";
import {
  getOfflineSessionImages,
  getOfflineSessionImageIdsForSession,
  getOfflineSession,
  DOWNLOAD_STATE,
} from "../../helpers/offlineSession";
import SessionContext from "../../contexts/SessionContext";


function SessionCardButton(props) {
  const { download, pause, remove, downloads } = useContext(
    SessionContext.Context
  );

  const [isProcessing, setIsProcessing] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const imgRef = useRef(null);

  const loadOfflineSession = async (id) => {
    const offlineSession = await getOfflineSession(id);
    if (!offlineSession) return;
    const imageIds = await getOfflineSessionImageIdsForSession(props.id);
    const images = (await getOfflineSessionImages(props.id)) || 0;
    const imageLength = images.length;
    offlineSession.downloadPercentage = Math.floor(
      (imageIds.length / imageLength) * 100
    );

    if (offlineSession.downloadState === DOWNLOAD_STATE.FINISHED) {
      if (offlineSession.downloadPercentage < 100) downloadSession();
    }

    if (
      offlineSession &&
      offlineSession.downloadState === DOWNLOAD_STATE.DOWNLOADING
    )
      downloadSession();

    if (
      offlineSession &&
      offlineSession.downloadState === DOWNLOAD_STATE.REMOVING
    )
      removeSession();
  };

  useEffect(() => {
    if (props.imgHeight) {
      imgRef.current.style.height = props.imgHeight;
    }
    loadOfflineSession(props.id);
  }, []);

  const downloadSession = async () => {
    setIsProcessing(true);
    await download(props.id);
    setIsProcessing(false);
  };

  const removeSession = async () => {
    setIsProcessing(true);
    setIsRemoving(true);
    await remove(props.id);
    setIsProcessing(false);
    setIsRemoving(false);
  };

  return (
    <div style={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
      <>
        {downloads[props.id] &&
          (downloads[props.id].state === DOWNLOAD_STATE.PAUSED ||
            downloads[props.id].state === DOWNLOAD_STATE.DOWNLOADING) && (
            <div>
              Download
              {downloads[props.id].state === DOWNLOAD_STATE.FINISHED
                ? " complete"
                : ` progress: ${
                    downloads[props.id].progress
                      ? `${Math.floor(downloads[props.id].progress)}%`
                      : "calculating..."
                  }`}
            </div>
          )}
      </>

      {/* PROGRESS BAR */}
      <>
        {!isRemoving &&
          downloads[props.id] &&
          (downloads[props.id].state === DOWNLOAD_STATE.PAUSED ||
            downloads[props.id].state === DOWNLOAD_STATE.DOWNLOADING) && (
            <div
              className="mb-1"
              style={{
                width: "100%",
                height: "6px",
                backgroundColor: "#ccc",
                borderRadius: "6px",
              }}
            >
              <div
                style={{
                  height: "inherit",
                  backgroundColor: "#00B7EB",
                  width: `${downloads[props.id].progress}%`,
                  borderRadius: "6px",
                }}
              />
            </div>
          )}
      </>

      <>
        {downloads[props.id] &&
          downloads[props.id].state !== DOWNLOAD_STATE.DOWNLOADING &&
          downloads[props.id].state !== DOWNLOAD_STATE.NONE && (
            <button
              type="button"
              className="btn btn-primary btn-sm mr-1"
              onClick={removeSession}
              disabled={
                downloads[props.id].state === DOWNLOAD_STATE.REMOVING ||
                isProcessing
              }
            >
              {downloads[props.id].state === DOWNLOAD_STATE.REMOVING ? (
                <Spinner
                  animation={"border"}
                  variant={"secondary"}
                  style={{ width: "16px", height: "16px" }}
                  className="mr-1"
                />
              ) : (
                <img
                  alt="delete-icon"
                  className="mr-1"
                  src={DeleteIcon}
                  style={{
                    width: "14px",
                    height: "14px",
                    top: "-2px",
                    position: "relative",
                  }}
                />
              )}{" "}
              Remove
            </button>
          )}
      </>

      <>
        {downloads[props.id] &&
          downloads[props.id].state === DOWNLOAD_STATE.DOWNLOADING && (
            <button
              type="button"
              className="btn btn-primary btn-sm mr-1"
              onClick={() => pause(props.id)}
              disabled={isProcessing}
            >
              <img
                alt="delete-icon"
                className="mr-1"
                src={PauseIcon}
                style={{
                  width: "14px",
                  height: "14px",
                  top: "-2px",
                  position: "relative",
                }}
              />{" "}
              Pause download
            </button>
          )}
      </>

      <>
        {downloads[props.id] &&
          downloads[props.id].state === DOWNLOAD_STATE.PAUSED && (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => downloadSession()}
              disabled={isProcessing}
            >
              {isProcessing && !isRemoving ? (
                <Spinner
                  animation={"border"}
                  variant={"secondary"}
                  style={{ width: "16px", height: "16px" }}
                  className="mr-1"
                />
              ) : (
                <img
                  alt="download-icon"
                  className="mr-1"
                  src={DownloadIcon}
                  style={{
                    width: "16px",
                    height: "216px0px",
                    top: "-2px",
                    position: "relative",
                  }}
                />
              )}
              Resume download
            </button>
          )}
      </>

      <>
        {(!downloads[props.id] ||
          downloads[props.id].state === DOWNLOAD_STATE.NONE) && (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => downloadSession()}
            disabled={isProcessing}
          >
            {isProcessing ? (
              <Spinner
                animation={"border"}
                variant={"secondary"}
                style={{ width: "16px", height: "16px" }}
                className="mr-1"
              />
            ) : (
              <img
                alt="download-icon"
                className="mr-1"
                src={DownloadIcon}
                style={{
                  width: "16px",
                  height: "216px0px",
                  top: "-2px",
                  position: "relative",
                }}
              />
            )}
            Download
          </button>
        )}
      </>
    </div>
  );
}

export default SessionCardButton;

import {
  default as Axios,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import { GetCurrentFirebaseToken } from "./firebase";
import { get } from "lodash";
import { Firebase } from "./firebase";
import { APIActions } from "./api/APIActions";

const pckg = require("../package.json");

export const api = Axios.create();

class Configuration {
  Environment: string =
    process.env.ENVIRONMENT || process.env.NODE_ENV || "development";
  Release: string = `${pckg.name}@${pckg.version}`;
  Version: string = pckg.version;
  SentryDSN?: string = process.env.SENTRY_DSN;
  UserpilotAppToken?: string = process.env.REACT_APP_USERPILOT_APP_TOKEN;
  APIUrl?: string = process.env.REACT_APP_API_URL;
  BucketUrl?: string = process.env.REACT_APP_BUCKET_URL;
  MediaUrl?: string = process.env.REACT_APP_MEDIA_URL;
  CDNUrl?: string = process.env.REACT_APP_CDN_URL;
  StripePublicKey?: string = process.env.STRIPE_PUBLISHABLE_KEY;

  async fetchFromAPI(): Promise<boolean> {
    if (!Config.APIUrl) return false;

    const request = await API.getConfig();

    if (!request.successful) return false;

    if (!request.payload) return false;

    const config = get(request, "payload.config", {});

    this.StripePublicKey = get(config, "stripePublicKey", this.StripePublicKey);
    this.APIUrl = get(config, "apiUrl", this.APIUrl);
    this.MediaUrl = get(config, "mediaUrl", this.MediaUrl);
    this.CDNUrl = get(config, "cdnUrl", this.CDNUrl);
    this.BucketUrl = get(config, "bucketUrl", this.BucketUrl);
    return true;
  }

  parseFirebase() {
	// DO NOT OVERRIDE. SET ENV VAR `REACT_APP_API_URL` TO YOUR LOCAL, ie. http://localhost:5001
	let apiUrl: string = this.APIUrl || 'https://api.positiveprime.net';

	// Only use remote config api if environment variable is not set
    if (Firebase.RemoteConfig && !process.env.REACT_APP_API_URL)
	  apiUrl = Firebase.RemoteConfig.getString('api');


    if (apiUrl) {
      this.APIUrl = apiUrl;
      APIAxios.defaults.baseURL = apiUrl;
      //APIAxios.defaults.baseURL = "http://localhost:5001"
    }
  }
}

export const Config = new Configuration();

export function makeAPIUrl(endpoint: string) {
  return removeDoubleSlash(`${Config.APIUrl}/${endpoint}`);
}

export function makeAssetsUrl(suffix: string) {
  if (suffix.startsWith('data')) return suffix;
  return makeLegacyCDNUrl(`/a/${suffix}`);
}
export function makeProductImageUrl(suffix: string) {
  if (suffix.startsWith('data')) return suffix;
  return makeLegacyCDNUrl(`/v/product/${suffix}`);
}
export function makeProfileImageUrl(suffix: string) {
  if (suffix.startsWith('data')) return suffix;
  return makeLegacyCDNUrl(`/v/profile/${suffix}`);
}
export function makeThumbImageUrl(suffix: string) {
  if (suffix.startsWith('data')) return suffix;
  return makeLegacyCDNUrl(`/v/thumb/${suffix}`);
}
export function makePlayerImageUrl(suffix: string) {
  if (!suffix) return suffix;
  if (suffix.startsWith('data')) return suffix;
  return makeLegacyCDNUrl(`/v/player/${suffix}`);
}
export function makeBucketUrl(file: string) {
  return removeDoubleSlash(`${Config.BucketUrl}/${file}`);
}
export function makeLegacyCDNUrl(suffix: string) {
  // -----------------------------------------------------
  const base = Config.CDNUrl || Config.MediaUrl;
  return removeDoubleSlash(`${base}/${suffix}`);
}

function removeDoubleSlash(url: string): string {
  const protoAndPath = url.split("://");
  const fix = (protoAndPath.pop() || "").split("//").join("/");
  protoAndPath.push(fix);
  return protoAndPath.join("://");
}

export const APIAxios: AxiosInstance = Axios.create({
  baseURL: Config.APIUrl,
  headers: {
    accept: "application/json",
    "x-client": Config.Release,
    "x-timezone": Intl
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : "Unknown",
    "x-timezone-offset": new Date().getTimezoneOffset(),
  },
});

export const API = new APIActions(APIAxios);

export const Media_URL = "https://cdn.positiveprime.com/v/player";
export const CDN_URL = "https://cdn.positiveprime.com";

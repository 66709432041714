// import external modules
import React from "react";
import { Route, Redirect } from "react-router-dom";

const AdminProRoute = ({ requiredRoles, user, ...rest }) => {
    const userHasRequiredRole = requiredRoles.includes(user.role.id)
    if (userHasRequiredRole) {
        return <Route {...rest} />
    } else {
        return <Redirect to="/404" />
    }
};

export default AdminProRoute;

import React, { Component } from "react";
import clsx from 'clsx';
import { Modal, Spinner } from "react-bootstrap";
import { API, CDN_URL } from "../../APIAndConfig";
import { MdUpdate } from "react-icons/md";
import { createFilter } from 'react-select';
import WindowedSelect from 'react-windowed-select';
import { isNull } from "util";

class SessionMusicDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            session : this.props.session,
            playUrl : this.props.session ? CDN_URL + '/b' + this.props.session.defaultAudioURL : '',
            updateAudio : {
                status: false,
                selectedAudio: null,
                selectedAudioName: '',
                data: []
            },
            isProcessing : false
        }
    }

    async getAllAudios() {
        await API.getAudioList()
            .then(res => {
                if (res.successful && res.payload) {
                    let arr = []
                    res.payload.data.map(item => {
                        let obj = { value: item, label: item.file_name }
                        arr.push(obj)
                    })
                    let tempData = { ...this.state.updateAudio }
                    tempData.data = arr
                    this.setState({
                        updateAudio : tempData 
                    })
                }
            })
    }

    componentDidMount() {
        this.getAllAudios()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.session) {
            if ((prevProps.session && prevProps.session.id) !== this.props.session.id) {
                this.setState({
                    session : this.props.session,
                    playUrl : CDN_URL + '/b' + this.props.session.defaultAudioURL
                })
            }
        }
    }

    async updateSessionAudio() {
        let payload = {
            sessionId : this.state.session.id,
            audioUrl : this.state.updateAudio.selectedAudio
        }

        await API.updateAudio(payload).then(
            res => {
                if (res.successful) {
                    this.props.displatMessage(
                        'Success',
                        'Audio for session updated successfully',
                        true
                    )
                    this.setState({
                        isProcessing : false,
                        playUrl : CDN_URL + '/b' + this.state.updateAudio.selectedAudio,
                        session : {
                            ...this.state.session,
                            fileName : this.state.updateAudio.selectedAudioName
                        },
                        updateAudio : {
                            ...this.state.updateAudio,
                            status: false,
                            selectedAudio: null,
                            selectedAudioName: ''
                        }
                    })
                }
                else {
                    this.props.displatMessage(
                        'Failed',
                        'Failed to update audio for session',
                        true
                    )
                    this.setState({
                        isProcessing : false,
                        updateAudio : {
                            ...this.state.updateAudio,
                            status: false,
                            selectedAudio: null,
                            selectedAudioName: ''
                        }
                    })
                }
            }
        )

    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className={clsx('col-12 my-4')}>
                        <div className="card card-blur">
                            <div className="card-header d-flex align-items-center">
                                <div>Session Music</div>
                                <div className="ml-auto">
                                    <button className={clsx('btn', 'x-secondary', 'col-md-auto', 'col-12', 'mt-md-0', 'mt-2')} 
                                        style={{ borderRadius: '10px' }}
                                        disabled={!this.state.session}
                                        onClick={() => {
                                            this.setState({ updateAudio : {
                                                ...this.state.updateAudio,
                                                status : true
                                            }})
                                        }}
                                    >
                                        <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                            <MdUpdate size={26} color="white" className="pr-2" /> Update Music
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className='mb-4 font-weight-bolder'>
                                    {
                                        this.state.session && this.state.session.fileName ? this.state.session.fileName :
                                            this.state.session && this.state.session.defaultAudioURL ? this.state.session.defaultAudioURL.split('music/')[1] : ''
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        <audio src={this.state.playUrl} className="w-100" controls />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.updateAudio.status}>
                    <Modal.Header>Update Audio</Modal.Header>
                    <Modal.Body>
                        <div className="d-flex justify-content-center align-items-center flex-column">
                            <div className='mb-4 font-weight-bolder'>{this.state.updateAudio.selectedAudioName}</div>
                            <audio src={ this.state.updateAudio.selectedAudio ? CDN_URL + '/b' + this.state.updateAudio.selectedAudio : ""} controls />
                            <WindowedSelect
                                name="sessions"
                                className="w-100 mt-4"
                                filterOption={createFilter({ ignoreAccents: false })}
                                onChange={(e) => {
                                    this.setState({ 
                                        updateAudio : {
                                            ...this.state.updateAudio,
                                            selectedAudio : e.value.audio_url,
                                            selectedAudioName : e.value.file_name
                                        }
                                     })
                                }}
                                options={this.state.updateAudio.data}
                                styles={{
                                    option: (base) => ({
                                        ...base,
                                        backgroundColor: 'transparent',
                                        color: 'black',
                                    }),
                                }}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn x-btn x-primary "
                            type="button"
                            onClick={() => {
                                this.setState({
                                    updateAudio : {
                                        ...this.state.updateAudio,
                                        status: false,
                                        selectedAudio: null,
                                        selectedAudioName: ''
                                    }
                                })
                            }}
                        >
                            Close
                        </button>
                        <button
                            className="btn x-btn x-secondary"
                            type="button"
                            disabled={this.state.isProcessing || !this.state.updateAudio.selectedAudio }
                            onClick={() => {
                                this.setState({ isProcessing: true })
                                this.updateSessionAudio()
                            }}
                        >
                            {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2 text-white" /> : <span className="p-2">Update</span>}
                        </button>
                    </Modal.Footer>
                </Modal>
            
            </div>
        );
    }
}

export default SessionMusicDisplay;

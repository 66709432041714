import Axios from "axios";
import clsx from "clsx";
import React, { Component } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { API } from "../../APIAndConfig";
import SessionImages from "../cards/SessionImages";
import Pagination from "react-js-pagination";
import { FiCheckCircle } from "react-icons/fi";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { RiCheckboxMultipleBlankLine, RiCheckboxMultipleLine } from "react-icons/ri";
import SimpleDropZone from "../../v2Sessions/simpleDropzone";
import { BiImageAdd } from "react-icons/bi";
import * as actions from "../../actions";
import { connect } from "react-redux";
import moment from "moment";


export class PersonalizeImages extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteStatus: "view",
            personalized_images: [],
            usedImages: [],
            usedImagesIds: [],
            unusedImages: [],
            selectedDeleteImages: null,
            showMaxImage: false,
            selectedImage: "",
            currentPage: 1,
            imagesPerPage: 30,
            currentImages: [],
            showUploadImageModal: false,
            completedCount: [],
            imageInfo: {},
            display_details: {},
            select_mode: "VIEW_ALL",
            deleteUsedMessage: ""
        }
    }

    async componentDidMount() {
        await this.getImages()
    }

    async getImages() {
        await API.getPersonalizedImages(this.props.auth.id)
            .then((res) => {
                if (res.successful && res.payload) {
                    const indexOfLastImage = 1 * this.state.imagesPerPage;
                    const indexOfFirstImage = indexOfLastImage - this.state.imagesPerPage;
                    this.setState({
                        currentImages: res.payload.final_data.slice(indexOfFirstImage, indexOfLastImage),
                        personalized_images: res.payload.final_data,
                        selectedDeleteImages: null,
                        deleteStatus: "view",
                        isProcessing: false,
                        currentPage: 1,
                        completedCount: [],
                        showUploadImageModal: false,
                        usedImages: res.payload.final_data.filter(obj => obj.sessions_used_names.length),
                        usedImagesIds: res.payload.final_data.filter(obj => obj.sessions_used_names.length).map(obj => obj.id),
                        unusedImages: res.payload.final_data.filter(obj => !obj.sessions_used_names.length)
                    })
                }
            })
    }

    onImageClick = (media_url) => {
        this.setState({
            selectedImage: media_url,
            showMaxImage: true
        })
    }

    onCheck = (id, event) => {
        let data = this.state.selectedDeleteImages ? [...this.state.selectedDeleteImages] : []
        if (event.target.checked) {
            data.push(Number(id))
            this.setState({
                selectedDeleteImages: data
            })
        }
        else {
            const index = data.indexOf(Number(id));
            if (index > -1) {
                data.splice(index, 1);
                if (data.length === 0) {
                    this.setState({
                        selectedDeleteImages: null
                    })
                } else {
                    this.setState({
                        selectedDeleteImages: data
                    })
                }
            }
        }
    }

    openDetailsModal(id, item, size, resolution) {
        let jsxArr = []
        if (item.sessions_used_names.length > 0) {
            Promise.all(
                item.sessions_used_names.map(item => {
                    jsxArr.push(
                        <div>{item}</div>
                    )
                })
            )
        }

        this.setState({
            display_details: {
                status: true,
                fileName: item.name,
                fileSize: size,
                sessions_associated: jsxArr.length > 0 ? jsxArr : 'Not Used',
                image_resolution: resolution,
                upload_date: moment(item.created).format('DD-MM-YYYY')
            }
        })

    }

    renderImages() {
        return this.state.currentImages.map(item => {
            let checkImage = false;
            if (this.state.selectedDeleteImages && this.state.selectedDeleteImages.includes(item.id)) {
                checkImage = true
            }
            return (
                <SessionImages
                    image={item.media_url}
                    id={item.id}
                    onClickImage={this.onImageClick.bind(this)}
                    status={this.state.deleteStatus}
                    onCheck={this.onCheck.bind(this)}
                    checked={checkImage}
                    setImageSize={this.setImageSizes.bind(this)}
                    openDetailsModal={this.openDetailsModal.bind(this)}
                    item={item}
                />
            )
        })
    }

    setImageSizes(id, value, key) {
        this.setState({
            imageInfo: {
                ...this.state.imageInfo,
                [id]: {
                    ...this.state.imageInfo[id],
                    [key]: value
                }
            }
        })
    }

    handlePageNumberClick = (pageNumber) => {
        const indexOfLastImage = Number(pageNumber) * this.state.imagesPerPage;
        const indexOfFirstImage = indexOfLastImage - this.state.imagesPerPage;
        this.setState({
            currentImages: this.getCurrentImages(this.state.deleteStatus, indexOfFirstImage, indexOfLastImage),
            currentPage: Number(pageNumber)
        })
    }

    renderPageNumbers = () => {
        return (
            <div>
                <Pagination
                    itemClass="session_images_pagination_item"
                    linkClass="session_images_pagination_link"
                    activeLinkClass="session_images_pagination_selectedLink"
                    prevPageText='<Prev'
                    nextPageText='Next >'
                    hideFirstLastPages
                    className="session_images_pagination"
                    activePage={this.state.currentPage}
                    itemsCountPerPage={this.state.imagesPerPage}
                    totalItemsCount={
                        this.state.deleteStatus === 'view' || this.state.deleteStatus === 'delete' ? this.state.personalized_images.length :
                            this.state.deleteStatus === 'used' ? this.state.usedImages.length : this.state.unusedImages.length
                    }
                    pageRangeDisplayed={window.innerWidth < 450 ? 3 : 10}
                    onChange={this.handlePageNumberClick.bind(this)}
                />
            </div>
        );
    }

    async deleteImages() {
        await API.deleteMultipleMedia({
            idString: this.state.selectedDeleteImages.toString()
        }).then((res) => {
            if (res.successful) {
                this.setState({
                    isProcessing: false,
                    deleteConfirmationModal: false
                })
                this.getImages()
            }
        })
    }

    successCount(count) {
        let data = [...this.state.completedCount]
        data.push(count)
        this.setState({
            completedCount: data
        })
    }

    onSubmitUploadImageModal = async (files) => {
        if (files.length === 0) {
            this.setState({
                showUploadImageModal: !this.state.showUploadImageModal
            })
        }
        else {
            if (files && files.length > 0) {
                await API.uploadFiles(
                    files,
                    this.props.auth.id,
                    this.successCount.bind(this),
                    "img",
                    "session",
                    null
                ).then((res) => {
                    this.setState({
                        showUploadImageModal: !this.state.showUploadImageModal,
                        completedCount: []
                    })
                    this.getImages()
                });
            }
        }
    }

    getCurrentImages(value, indexOfFirstImage, indexOfLastImage) {
        switch (value) {
            case "view":
                return this.state.personalized_images ? this.state.personalized_images.slice(indexOfFirstImage, indexOfLastImage) : []
            case "used":
                return this.state.usedImages ? this.state.usedImages.slice(indexOfFirstImage, indexOfLastImage) : []
            case "unused":
                return this.state.unusedImages ? this.state.unusedImages.slice(indexOfFirstImage, indexOfLastImage) : []
            default:
                return this.state.personalized_images ? this.state.personalized_images.slice(indexOfFirstImage, indexOfLastImage) : []
        }
    }

    onActionChange(value) {
        const indexOfLastImage = 1 * this.state.imagesPerPage;
        const indexOfFirstImage = indexOfLastImage - this.state.imagesPerPage;

        this.setState({
            currentImages: this.getCurrentImages(value, indexOfFirstImage, indexOfLastImage),
            selectedDeleteImages: value === "delete" && this.state.selectedDeleteImages ? [...this.state.selectedDeleteImages] : null,
            deleteStatus: value,
            currentPage: 1,
        })

    }

    render() {
        if (document.getElementById('dropdown-button-drop-0')) {
            document.getElementById('dropdown-button-drop-0').classList.add('x-secondary', 'text-white', 'border-0')
            document.getElementById('dropdown-button-drop-0').classList.remove('btn-danger')
        }

        return (
            <>
                <div className="row">
                    <div className={clsx('col-12 my-4')}>
                        <h2 className={clsx('main-title')}>
                            My Gallery
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className={clsx('col-12 my-4')}>
                        <div className="card card-blur">
                            <div className="card-header d-flex align-items-center row">
                                <div className="col-md-auto col-12">
                                     Personalize Images - Edit / Delete & Update Your Personal Images Here
                                </div>
                                <div className="ml-auto col-md-auto col-12">
                                    {
                                        this.state.select_mode === "VIEW_ALL" ?
                                            <button className={clsx('btn', 'x-secondary', 'col-md-auto', 'col-12', 'mt-md-0', 'mt-2')} style={{ borderRadius: "10px" }} disabled={this.state.personalized_images.length === 0}
                                                onClick={() => {
                                                    this.setState({
                                                        select_mode: "SELECT_IMAGES",
                                                        deleteStatus: "delete",
                                                    })
                                                }}
                                            >
                                                <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                    <FiCheckCircle size={24} color="white" className="pr-2" /> Select Images
                                                </span></button> :
                                            <button className={clsx('btn', 'x-secondary', 'col-md-auto', 'col-12', 'mt-md-0', 'mt-2')} style={{ borderRadius: "10px" }} disabled={this.state.personalized_images.length === 0}
                                                onClick={() => {
                                                    this.setState({
                                                        select_mode: "VIEW_ALL",
                                                        deleteStatus: "view",
                                                        selectedDeleteImages: null
                                                    })
                                                }}
                                            >
                                                <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                    <FaEye size={24} color="white" className="pr-2" /> View Images
                                                </span></button>
                                    }
                                    {
                                        this.state.select_mode === "VIEW_ALL" ?
                                            <>
                                                <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2"
                                                    disabled={this.state.personalized_images.length === 0}
                                                    style={{ borderRadius: "10px" }}
                                                    onClick={() => { this.onActionChange("view") }}
                                                >
                                                    <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                        View All
                                                    </span>
                                                </button>
                                                <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2"
                                                    disabled={this.state.personalized_images.length === 0}
                                                    style={{ borderRadius: "10px" }}
                                                    onClick={() => { this.onActionChange("used") }}
                                                >
                                                    <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                        Used Images
                                                    </span>
                                                </button>
                                                <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2"
                                                    disabled={this.state.personalized_images.length === 0}
                                                    style={{ borderRadius: "10px" }}
                                                    onClick={() => { this.onActionChange("unused") }}
                                                >
                                                    <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                        Unused Images
                                                    </span>
                                                </button>
                                            </> :
                                            <></>
                                    }
                                    {
                                        this.state.personalized_images.length > 0 && this.state.deleteStatus === "delete" ?
                                            <>

                                                <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }}
                                                    disabled={!this.state.selectedDeleteImages}
                                                    onClick={() => {
                                                        const filteredArray = this.state.selectedDeleteImages.filter(value => this.state.usedImagesIds.includes(value));
                                                        if (filteredArray.length > 0) {
                                                            this.setState({
                                                                deleteUsedMessage: this.state.selectedDeleteImages.length === 1 ? 
                                                                    "Note : This image is associated with a session" :
                                                                    "Note : Some of the selected images are associated with a session"
                                                            })
                                                        }
                                                        else {
                                                            this.setState({
                                                                deleteUsedMessage: ""
                                                            })
                                                        }

                                                        this.setState({
                                                            deleteConfirmationModal: true
                                                        })
                                                    }}
                                                >
                                                    <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                        <FaTrashAlt size={22} color="white" />
                                                    </span></button>

                                                {
                                                    this.state.selectedDeleteImages && this.state.selectedDeleteImages.length === this.state.personalized_images.length ?
                                                        <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedDeleteImages: null
                                                                })
                                                            }}
                                                        >
                                                            <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                                <RiCheckboxMultipleBlankLine size={24} color="white" />
                                                            </span>
                                                        </button>
                                                        :
                                                        <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }}
                                                            onClick={() => {
                                                                this.setState({
                                                                    selectedDeleteImages: this.state.personalized_images.map(obj => obj.id)
                                                                })
                                                            }}
                                                        >
                                                            <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                                                <RiCheckboxMultipleLine size={24} color="white" />
                                                            </span>
                                                        </button>
                                                }
                                            </> : <></>
                                    }
                                    <button className="btn x-secondary ml-md-2 ml-0 col-md-auto col-12 mt-md-0 mt-2" style={{ borderRadius: "10px" }}
                                        onClick={() => {
                                            this.setState({ showUploadImageModal: true })
                                        }}
                                    >
                                        <span className="d-flex justify-content-center align-items-center pt-1 pb-1 text-white">
                                            <BiImageAdd size={24} color="white" />
                                        </span></button>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="form-group row m-0 flex-column">
                                            {
                                                this.state.personalized_images.length > 0 ?
                                                    <>
                                                        <div className="font-weight-bolder pl-3">Total number of images  : {this.state.personalized_images.length}</div>
                                                        {
                                                            this.state.deleteStatus === "delete" ?
                                                                <h6 className="w-100 text-center" style={{ fontSize: "25px", fontWeight: "bolder" }}>Select images to delete</h6>
                                                                : <></>
                                                        }
                                                        <div className={clsx('ps-Modal__selectImages-container', 'justify-content-center')}>
                                                            {this.renderImages()}
                                                        </div>
                                                        <div className="my-4 p-4 d-flex justify-content-center w-100">
                                                            {this.renderPageNumbers()}
                                                        </div>
                                                    </>
                                                    :
                                                    <div className="col-12 p-5"><h3 className="text-center">No personalized images found</h3></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.showMaxImage} onHide={() => { this.setState({ showMaxImage: false }) }} dialogClassName="max_size_modal" centered>
                    <Modal.Header closeButton className="show_images_header"></Modal.Header>
                    <Modal.Body className="d-flex align-items-center justify-content-center">
                        <img
                            src={this.state.selectedImage}
                            style={{
                                maxHeight: "1080px",
                                maxWidth: "1920px"
                            }}
                        />
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.deleteConfirmationModal} onHide={() => { this.setState({ deleteConfirmationModal: true }) }}>
                    <Modal.Header>Delete Images</Modal.Header>
                    <Modal.Body>
                        <div>Are you sure you want to delete images ?</div>
                        <div>{ this.state.deleteUsedMessage }</div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn x-btn x-secondary "
                            type="button"
                            onClick={() => {
                                this.setState({ deleteConfirmationModal: false })
                            }}
                        >
                            Close
                        </button>
                        <button
                            className="btn x-btn x-primary "
                            type="button"
                            disabled={this.state.isProcessing}
                            onClick={() => {
                                this.setState({ isProcessing: true })
                                this.deleteImages()
                            }}
                        >
                            {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2 text-white" /> : <span className="p-2">Delete</span>}
                        </button>

                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.showUploadImageModal} onHide={() => { this.setState({ showUploadImageModal: true }) }}>
                    <Modal.Header>
                        <div className='w-100'>
                            <label>Upload Images</label>
                            <button
                                className="x-secondary"
                                style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                                onClick={() => { this.setState({ showUploadImageModal: false }) }}
                            >X</button>
                        </div>
                    </Modal.Header>
                    <SimpleDropZone
                        onUpload={this.onSubmitUploadImageModal.bind(this)}
                        completedCount={this.state.completedCount.length}
                        acceptTypes="image/jpg, image/gif, image/bmp, image/jpeg, image/tiff, image/png, .heic"
                        message="Click Here To Select Images From Your Device"
                        inputType="Image"
                        maxFiles={50}
                    />
                </Modal>
                <Modal show={this.state.display_details.status} onHide={() => { }}>
                    <Modal.Header>View Details</Modal.Header>
                    <Modal.Body>
                        <div className="my-1">
                            <span className="font-weight-bolder" style={{ color: '#42315b' }}>File Name : </span>
                            <span>{this.state.display_details.fileName}</span>
                        </div>
                        <div className="my-1">
                            <span className="font-weight-bolder" style={{ color: '#42315b' }}>File Size : </span>
                            <span>{this.state.display_details.fileSize}</span>
                        </div>
                        <div className="my-1">
                            <span className="font-weight-bolder" style={{ color: '#42315b' }}>Sessions Associated : </span>
                            <span>{this.state.display_details.sessions_associated}</span>
                        </div>
                        <div className="my-1">
                            <span className="font-weight-bolder" style={{ color: '#42315b' }}>Image Resolution : </span>
                            <span>{this.state.display_details.image_resolution}</span>
                        </div>
                        <div className="my-1">
                            <span className="font-weight-bolder" style={{ color: '#42315b' }}>Upload Date : </span>
                            <span>{this.state.display_details.upload_date}</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn x-btn x-secondary "
                            type="button"
                            onClick={() => {
                                this.setState({ display_details: {} })
                            }}
                        >
                            Close
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps, actions)(PersonalizeImages)
import {Config, API, APIAxios} from "../APIAndConfig";

import axios from "axios";
import {USER_CURRENT_SESSION_SURVEY} from "./types";
import {Firebase} from"../firebase";

import {get} from "lodash";


export const surveyBeforeSession = (
  userId,
  sessionId,
  beforeEmotion
) => async dispatch => {

  const result = await API.createSurvey(sessionId, beforeEmotion);
  const survey = get(result,'payload.survey');
  if (survey) {
      dispatch({type: USER_CURRENT_SESSION_SURVEY, payload: survey});
      return survey;
  }
};

export const surveyAfterSession = (
  userId,
  sessionId,
  surveyId,
  afterEmotion
) => async dispatch => {

    const result = await API.answerSurvey(sessionId, surveyId, afterEmotion);
    const survey = get(result,'payload.survey');
    if (survey) {
        dispatch({type: USER_CURRENT_SESSION_SURVEY, payload: survey});
        return survey;
    }
};

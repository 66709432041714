import React from 'react';

import { Accordion, Card, Button } from "react-bootstrap";
import { faStar } from '@fortawesome/free-regular-svg-icons';
import SidebarLink from './SidebarLink';
import { useLocation } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



import clsx from 'clsx';
import { faTimes } from '@fortawesome/free-solid-svg-icons';



function Sidebar(props) {
    const pathname = useLocation().pathname;
    return (
        <nav className="nav flex-column sidebarSection">
          <div  className={clsx('justify-content-end')}> 
            <div className={clsx('sidebarSection__closeIcon2', 'faIconButton','sideBarSectionWidth')} onClick={props.onCloseSidebar}>
                    <FontAwesomeIcon
                        icon={faTimes}
                    />
                </div>
            </div> 
            <div className={clsx('container', 'logo-section-sidebar')}>
                <img src={Logo} alt={'logo-img'} className={'logo-section-sidebar__img'} />
                <div className={'logo-section-sidebar__text'}>
                    <p className={"logo-section-sidebar__text-normal"}>positive</p>
                    <p className={'logo-section-sidebar__text-bold'}>prime</p>
                </div>

            </div>
            <div className={clsx("list-group list-group-flush overflow-auto", "sidebar-list")}>
                {props.navItems.NAV_ITEMS_COMMON.map((itemProps,index) => {
                    return (
                        <SidebarLink
                            key={index}
                            {...itemProps}
                            activeLink={pathname}
                            onClick={props.onCloseSidebar}
                        />
                    )
                })}
                {!!props.navItems.NAV_ITEMS_COACH.length && renderProArea(props, pathname)}

                {!!props.navItems.NAV_ITEMS_ADMIN.length && renderAdminArea(props, pathname)}

            </div>
        </nav>
    )
}
function renderAdminArea(props, pathname) {
    if (!props.user || props.user.role.id !== 1) return;

    return (
        <>
            <div className={clsx('container')}>
                <div className={clsx('sidebar-seprator row')}></div>
                <div className={clsx('row')}>
                    <p className={clsx('pl-3')}>Admin</p>
                </div>
            </div>
            {
                props.navItems.NAV_ITEMS_ADMIN.map((itemProps,index) => {
                    return (
                        <SidebarLink
                            key={index}
                            {...itemProps}
                            activeLink={pathname}
                            onClick={props.onCloseSidebar}
                        />
                    )
                })
            }
        </>
    );
}
function renderProArea(props, pathname) {
    if (!props.user || [1, 4].indexOf(props.user.role.id) < 0) return;


    return (
        <>        <div className={clsx('container')}>
            <div className={clsx('sidebar-seprator row')}></div>
            <div className={clsx('row')}>
                <p className={clsx('pl-3')}>Coach</p>
            </div>
        </div>
            {props.navItems.NAV_ITEMS_COACH.map((itemProps,index) => {
                return (
                    <SidebarLink
                        key={index}
                        {...itemProps}
                        activeLink={pathname}
                        onClick={props.onCloseSidebar}
                    />
                )
            })}
        </>
    );
}
export default Sidebar;
import React from 'react';
import Cookies from "universal-cookie";

import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Userpilot } from 'userpilot';
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from 'redux';
import reduxThunk from 'redux-thunk';

import App from './components/App';
import reducers from './reducers';

import "./scss/style.scss";
import {Firebase} from"./firebase";
import {Config} from "./APIAndConfig";
// NOTE: For Development only. Trick to use axios in the browser to test things.
// import axios from 'axios';
// window.axios = axios;

// Creating a store

export const cookies = new Cookies();
const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

if (document.location.search) {
    const params = new URLSearchParams(document.location.search);

    if (params.has("referralCode")) {
        const code = params.get("referralCode");
        const expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        cookies.set("referralCode", code, {
            path: '/',
            expires: expires
        });
    }
}

if (Config.SentryDSN)
    Sentry.init({
        dsn: Config.SentryDSN,
        release: Config.Release,
        environment: Config.Environment
    });

if (Config.UserpilotAppToken)
	Userpilot.initialize(Config.UserpilotAppToken);

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('positive-prime')
);
import {AnalyticsRange, getMomentAtMidday} from "./AnalyticsRangePicker";
import moment from "moment";

interface AnalyticsDefaultsInterface {
    collation: {[key:string]: string},
    filters: {[key:string]: string},
    ranges: {[key:string]: AnalyticsRange}
}

export const AnalyticsDefaults = {
    collation: {
        daily: "Daily",
        weekly: "Weekly",
        monthly: "Monthly",
        hourly: "Hourly",
    },
    filters: {
        all:"All"
    },
    ranges: {
        today: {
            label: "Today",
            startDate: getMomentAtMidday(),
            endDate: getMomentAtMidday(),
            collate: "hourly",
        },
        yesterday: {
            label: "Yesterday",
            startDate: getMomentAtMidday(moment().subtract(1, "day")),
            endDate: getMomentAtMidday(moment().subtract(1, "day")),
            collate: "hourly",
        },
        last7days: {
            label: "Last 7 Days",
            startDate: getMomentAtMidday(moment().subtract(6, "days")),
            endDate: getMomentAtMidday(moment()),
            collate: "daily",
        },
        last14days: {
            label: "Last 14 Days",
            startDate: getMomentAtMidday(moment().subtract(13, "days")),
            endDate: getMomentAtMidday(moment()),
            collate: "daily",
        },
        last28days: {
            label: "Last 28 Days",
            startDate: getMomentAtMidday(moment().subtract(27, "days")),
            endDate: getMomentAtMidday(moment()),
            collate: "daily",
        },
        thisWeek: {
            label: "This Week",
            startDate: getMomentAtMidday(moment().startOf("week")),
            endDate: getMomentAtMidday(),
            collate: "daily",
        },
        lastWeek: {
            label: "Last Week",
            startDate: getMomentAtMidday(moment().subtract(1, "week").startOf("week")),
            endDate: getMomentAtMidday(moment().subtract(1, "week").endOf("week")),
            collate: "daily",
        },
        thisMonth: {
            label: "This Month",
            startDate: getMomentAtMidday(moment().startOf("month")),
            endDate: getMomentAtMidday(),
            collate: "daily",
        },
        lastMonth: {
            label: "Last Month",
            startDate: getMomentAtMidday(moment().subtract(1, "month").startOf("month")),
            endDate: getMomentAtMidday(moment().subtract(1, "month").endOf("month")),
            collate: "daily",
        },
        thisYear: {
            label: "This Year",
            startDate: getMomentAtMidday(moment().startOf("year")),
            endDate: getMomentAtMidday(moment().endOf("year")),
            collate: "monthly",
        },
        lastYear: {
            label: "Last Year",
            startDate: getMomentAtMidday(moment().subtract(1, "year").startOf("year")),
            endDate: getMomentAtMidday(moment().subtract(1, "year").endOf("year")),
            collate: "monthly",
        }
    }
}



import React,{useRef} from 'react';
import clsx from 'clsx';
import Chart from 'chart.js';

function Bargraph(props){
    const chartRef = useRef(null);
    let myBarChart;
    React.useEffect(()=>{
            let labels = [];
            let counts = [];
            props.data.map((datum)=>{
                labels.push(datum.label);
                counts.push(datum.count);

            }) 
            Chart.defaults.global.color = '#fff';
            Chart.defaults.global.defaultFontColor = '#fff';
            const ctx = chartRef.current.getContext('2d');
            let fillPattern = ctx.createLinearGradient('0','0','0','-60');
            fillPattern.addColorStop(0, "#857FF1");
            fillPattern.addColorStop(1, "#CDAAB1");
            new Chart(ctx, {
                type: 'bar',
                data: {
                        labels: labels,
                        datasets: [{
                            label: '# of Sessions',
                            data: counts,
                            backgroundColor: fillPattern,
                            // borderColor: [
                            //     'rgba(255, 99, 132, 1)',
                            //     'rgba(54, 162, 235, 1)',
                            //     'rgba(255, 206, 86, 1)',
                            //     'rgba(75, 192, 192, 1)',
                            //     'rgba(153, 102, 255, 1)',
                            //     'rgba(255, 159, 64, 1)'
                            // ],
                            borderWidth: 1
                        }],
                    
                },
                options: {
                    defaultFontColor: '#fff',
                    legend:{
                        display: false
                    },
                    layout: {
                        padding: {
                            left: 0,
                            right: 0,
                            top: 10,
                            bottom: 10
                        }
                    },
                    elements: {
                        line: {
                            borderColor: '#fff'
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                precision: 0,
								beginAtZero: true,
                            }
                        }]
                    }
                }
            });
        

    },[props.data]);
    
    return(
        <div className={clsx('container','bargraph-container')} >
            <div className={'bargraph-container__text'}>
                <p className={clsx('bargraph-container__text-title','x-card-title')}>{props.title}</p>   
                <p className={clsx('bargraph-container__text-subtitle','x-card-subtitle')}>{props.subtitle}</p>      
            </div>
            <div className={'bargraph-container__main'}>
                <canvas 
                    id={props.id}
                    ref={chartRef}
                    style={{maxHeight: "221px"}}
                />
            </div>
        </div>
    )
}

export default Bargraph;
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { API, APIAxios, Config } from "../APIAndConfig";
import { Firebase } from "../firebase";
import { ROUTES } from "../Routers/DashboardRouter";
import { CATEGORY_ROUTES } from "./Category";
import { Media_URL } from "../APIAndConfig.ts";
import axios from "axios";
import Bargraph from '../components/graphs/Bargraph';
import PictureCard from '../components/cards/PictureCard';
import StatsCard from '../components/cards/StatsCard';
import statsIcon from '../assets/statsIcon.png';
import { fetchOwnerSessionForUser, fetchPurchasesByUserId, fetchUserSession, setSelectedSession } from "../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as actions from "../actions";
import { convertToObject } from "typescript";
import { Col, Container, Modal, ModalBody, ModalFooter, Row } from "react-bootstrap";
import { set } from "lodash";
import moment from "moment";
import VaultTile from "../components/AdminDashboard/VaultTile";
import { Capacitor } from '@capacitor/core';

var flag = { id: null };
function Dashboard(props) {
  const state = useSelector(state => state)
  const dispatch = useDispatch()

  const [featuredSessions, setFeaturedSessions] = useState([
    {
      name: 'Null',
      author: 'Null',
      id: null,
      images: [{ media_url: '' }],
      category: null
    },
    {
      name: 'Null',
      author: 'Null',
      id: null,
      images: [{ media_url: '' }],
      category: null
    },
    {
      name: 'Null',
      author: 'Null',
      id: null,
      images: [{ media_url: '' }],
      category: null,
    },
  ]);


  const [lifeMins, setLifeMins] = useState(0)
  const [runtimeMinute, setRunTimeMinute] = useState(15)
  const [bargraphData, setBargraphData] = useState([]);
  const [stats, setStats] = useState({ totalWatched: 0 });
  const [selectedSessionId, setSelectedSessionId] = useState(-1)
  const [selectedSessionTitle, setSelectedSessionTitle] = useState("")
  // const [allSessions, setAllSessions] = useState([])
  const [selectedCategoryId, setSelectedCategoryId] = useState(null)
  const [tutorialModal, setTutorialModal] = useState(false)
  const [tutorialCheck, setTutorialCheck] = useState(false)
  const [currentStreak, setCurrentStreak] = useState(false)
  const [recordStreak, setRecordStreak] = useState(false)
  const networkStatus = useSelector(state => state.networkStatus)

  var categoryArray = [];

  const demoStats = [
    {
      title: "Current Streak",
      subtitle: "Days in a row you’ve been Positive Priming",
      value: currentStreak ? currentStreak.streak : 0,
      unit: "Days",
      icon: statsIcon,
    },
    {
      title: "Record Streak",
      subtitle: "Most days in a row you’ve Positive Primed",
      value: recordStreak ? recordStreak.streak : 0,
      unit: "Days",
      icon: statsIcon,
    },
    {
      title: "Total Positive Prime Sessions",
      subtitle: "Your total number of Sessions watched",
      value: stats.totalWatched,
      unit: "Sessions",
      icon: statsIcon,
    },
    {
      title: "Life Minutes On Positive Prime",
      subtitle: "Your total number of minutes spent Positive Priming",
      value: Math.ceil(lifeMins),
      unit: "Lifetime Minutes",
      icon: statsIcon,
    },
  ];
  const fetchFeaturedSession = async () => {
    if (!networkStatus.connected) {
      return false
    }

    const token = await Firebase.Auth.currentUser.getIdToken();

    const header = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    try {
      const res = await axios.get(`${Config.APIUrl}/featuredSessions`, {
        headers: header,
      });
      if (res.data.status === 200) {
        let resArr = res.data.data
        let secondArr = []
        let j = 0
        while (j < 3) {
          let obj = resArr[j]
          if (resArr.find(obj => obj.promoted === j + 1)) {
            secondArr[j] = resArr.find(obj => obj.promoted === j + 1)
            j++
          }
          else {
            secondArr[j] = resArr.find(obj => obj.promoted === null)
            resArr.splice(resArr.indexOf(secondArr[j]), 1)
            j++
          }
        }



        setFeaturedSessions(secondArr);
      }
    } catch (err) {
      switch (err.response.data) {
        case "SESSION_EXPIRED":
          const token = await Firebase.Auth.currentUser?.getIdToken();
          if (token) {
            await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
            window.location.reload()
          }
          break;
        case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
        case "DATABASE_LIMIT_EXCEEDED":
          localStorage.setItem('service_unavailable_status', "failed");
          await Firebase.Auth.signOut();
          window.location.href = "/error"
          break;
        default:
          console.log("Error Occured While fetching category data ", err);
          break;
      }
    }

    // setProduct(res.data.product)
    // setScreenshots(res.data.screen_shot_list)
    // setSessions(res.data)
  };

  const fetchChartData = async () => {
    if (!networkStatus.connected) {
      return false
    }

    const token = await Firebase.Auth.currentUser.getIdToken();

    const header = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      "x-timezone-offset": new Date().getTimezoneOffset(),
    };
    try {
      const res = await axios.get(`${Config.APIUrl}/dashboardData`, {
        headers: header,
      });
      if (res.data.status === 200) {
        setLifeMins(res.data.life_minutes)
        setBargraphData(
          res.data.mySessions.map((value) => {
            return {
              label: value.Date,
              count: Number(value.watched),
            };
          })
        );
        setStats({
          totalWatched: Number(res.data.total_sessions_watched),
        });
        setCurrentStreak(res.data.currentStreak)
        setRecordStreak(res.data.recordStreak)
      }
    } catch (err) {
      if (err.response) {
        switch (err.response.data) {
          case "SESSION_EXPIRED":
            const token = await Firebase.Auth.currentUser?.getIdToken();
            if (token) {
              await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
              window.location.reload()
            }
            break;
          case "BAD_CONNECTION":
          case "UNCAUGHT_ERROR":
          case "DATABASE_LIMIT_EXCEEDED":
            localStorage.setItem('service_unavailable_status', "failed");
            await Firebase.Auth.signOut();
            window.location.href = "/error"
            break;
          default:
            console.log("Error Occured While fetching category data ", err);
            break;
        }
      }
    }
  };


  const onClickPictureCard = (index) => {
    if (featuredSessions[index].id !== null) {
      props.history.push(
        ROUTES.CATEGORY +
        "/" +
        featuredSessions[index].category +
        CATEGORY_ROUTES.SESSION +
        "/" +
        featuredSessions[index].id
      );
    }
  };

  const onRuntimeChange = (e) => {
    let runtimeValue = parseFloat(e.target.value);
    if (e.target.value.trim().length > 0 && isNaN(runtimeValue)) {
      alert("Must be set as a number");
    }

    setRunTimeMinute(runtimeValue);
  }

  const handleChange = (e) => {
    const sessionId = e.target.value;
    const sessionTitle = e.target.options[e.target.selectedIndex].text;
    setSelectedSessionId(parseInt(sessionId))
    setSelectedSessionTitle(sessionTitle)
    setSelectedCategoryId(categoryArray.find(obj => obj.id === parseInt(e.target.value)).category)

    dispatch(setSelectedSession(parseInt(sessionId), sessionTitle))
    flag.id = "done"
  }

  const renderSelectSession = () => {
    let sessionIds = [];
    let sessionsArray = [];
    if (state.userSessions) {
      if (state.userSessions === null) return <div />;
      sessionsArray = sessionsArray.concat(state.userSessions);
    }

    if (state.ownerSessionForUser) {
      if (state.ownerSessionForUser === null) return <div />;
      sessionsArray = sessionsArray.concat(state.ownerSessionForUser);
    }

    if (state.purchasedSessions) {
      if (state.purchasedSessions === null) return <div />;
      sessionsArray = sessionsArray.concat(state.purchasedSessions);
    }

    // if (allSessions.length > 0) {
    //   sessionsArray = sessionsArray.concat(allSessions);
    // }

    const sessions = Array.from(new Set(sessionsArray.map((a) => a.id))).map(
      (id) => {
        return sessionsArray.find((a) => a.id === id);
      }
    );
    sessions.sort((a, b) => (a.name > b.name ? 1 : -1));
    if (!flag.id) flag.id = sessions[0] && sessions[0].category

    return (
      <select
        id="dashboard-session-select"
        value={selectedSessionId}
        onChange={(e) => handleChange(e)}
        className="custom-select"
      >
        {sessions.map((value, index) => {
          categoryArray.push({ id: value.id, category: value.category })
          sessionIds.push(value.id);
          return (
            <option key={`option-item-${value.id}`} value={value.id}>
              {value.name}
            </option>
          );
        })}
      </select>
    );
  }

  const onWatchNow = (e) => {
    e.preventDefault();

    const select = document.getElementById("dashboard-session-select");
    const input = document.getElementById("dashboard-runtime-input");
    let category = flag.id !== "done" ? flag.id : selectedCategoryId ? selectedCategoryId : 'private'

    if (select && category) {
      props.history.push({
        pathname: ROUTES.CATEGORY + '/' + category + CATEGORY_ROUTES.PLAY + '/' + select.value,
        state: {
          duration: input.value
        }
      });
    }

    //   if (select && selectedCategoryId) {
    // props.history.push(`/images/${select.value}?runtime=${input.value}`);
    //     props.history.push({
    //       pathname: ROUTES.CATEGORY + '/' + selectedCategoryId + CATEGORY_ROUTES.PLAY + '/' + select.value,
    //       state: {
    //         duration: input.value
    //       }
    //     });
    //   }
  }
  const onEditSession = (e) => {
    e.preventDefault();
    if (props.auth.role.id === 2) {
      props.history.push(`/upgradeAccount`);
    }
    else {
      const select = document.getElementById("dashboard-session-select");
      if (select) {
        props.history.push(`/session/edit/${select.value}`);
      }
    }
  }

  // const renderSessions = async () => {
  //   let payload = await API.getAllSessions()
  //   setAllSessions(payload.payload.data || [])
  // }

  const handleClose = () => setTutorialModal(false);

  const setTutorialStatus = async () => {
    if (tutorialCheck) {
      await API.updateTutorialStatus(props.auth.id)
    }
  }

  useEffect(() => {
    if (!networkStatus.connected) {
      props.history.replace('/downloads')
    }

    let propsAuth = props.auth
    if (!propsAuth) {
      propsAuth = { role: {} }
    }

    console.log("Props.auth: ",props.auth);
    
    let daysDifference = moment().diff(moment(propsAuth.created),'days');
    console.log("Days difference: ",daysDifference);
    if(daysDifference >= 30 
      && propsAuth.subscriptionType === "30DayChallengeToUpgrade" 
      && propsAuth.role.id === 5){
      API.day30ChallengeToUpgradeUpdate(propsAuth);
      console.log("30 days completed.");
    }

    if (props.history.location.state && props.history.location.state.path === "from_login") {
      API.updateLastLogin(propsAuth.email);

      if (props.history.location.state.infusion) {
        // infusionsoft part
        let infusionsoftPayload = {
          user: {
            email: propsAuth.email,
            firstName: propsAuth.firstName,
            lastName: propsAuth.lastName
          },
          referralCode: props.history.location.state.referralCode,
          invited: props.history.location.state.fromInvite ? true : false,
          emailPermission: props.history.location.state.emailPermissionStatus
        }
        // API.registerUserInfusionsoft(infusionsoftPayload)

      }

      props.history.replace(props.location.pathname, null);
    }

    if (props.history.location.state === "from_login" && !propsAuth.tutorialStatus) {
      setTutorialModal(true)
      props.history.replace(props.location.pathname, null);
    }

    if (networkStatus.connected) {
      fetchFeaturedSession();
      fetchChartData();
      // renderSessions();
      dispatch(fetchUserSession(state.auth || {}))
      dispatch(fetchOwnerSessionForUser(state.auth || {}))
      dispatch(fetchPurchasesByUserId(state.auth?.id || ""))
    }
  }, []);

  return (
    <div className={clsx("container", "dashboardViewContainer")}>
      <div className={"dashboardViewContainer__header"}>
        <h2
          className={clsx("dashboardViewContainer__header-title", "main-title")}
        >
          Featured Sessions
        </h2>
      </div>
      <div className={clsx("container", "dashboardViewContainer__main")}>
        <div className={"row"}>
          <div className={`dashboardViewCol featured_session ${Capacitor.getPlatform() === 'ios' ? 'col-lg-8' : 'col-lg-4'}`}>
            <PictureCard
              title={featuredSessions[0].name}
              subtitle={featuredSessions[0].author}
              image={
                featuredSessions[0].thumbnail
                  ? process.env.REACT_APP_CDN + '/' + featuredSessions[0].thumbnail
                  : Media_URL + "/" + featuredSessions[0].images[0].media_url
              }
              onClick={() => onClickPictureCard(0)}
            />
          </div>
          <div className={"dashboardViewCol col-lg-4 featured_session"}>
            <PictureCard
              title={featuredSessions[1].name}
              subtitle={featuredSessions[1].author}
              image={
                featuredSessions[1].thumbnail
                  ? process.env.REACT_APP_CDN + '/' + featuredSessions[1].thumbnail
                  : Media_URL + "/" + featuredSessions[1].images[0].media_url
              }
              onClick={() => onClickPictureCard(1)}
            />
          </div>

          {Capacitor.getPlatform() !== "ios" && (
            <div className={"dashboardViewCol col-lg-4 featured_session"}>
              <VaultTile />
            </div>
          )}
        </div>
        <div className={"row"}>
          <div className={"dashboardViewCol col-lg-6 chart_and_sod"}>
            <div className={clsx("container", "chart-and-sod__chart")}>
              <Bargraph
                id={"my-session-bargraph"}
                title={"My Sessions"}
                subtitle={"No. of Sessions viewed past 7 days."}
                data={bargraphData}
              />
            </div>
            <div
              className={clsx(
                "container",
                "chart-and-sod__sod",
                "featured_session_single"
              )}
            >
              <PictureCard
                title={featuredSessions[2].name}
                subtitle={featuredSessions[2].author}
                image={
                  featuredSessions[2].thumbnail
                    ? process.env.REACT_APP_CDN + '/' + featuredSessions[2].thumbnail
                    : Media_URL + "/" + featuredSessions[2].images[0].media_url
                }
                onClick={() => onClickPictureCard(2)}
              />
            </div>
          </div>
          <div className={"dashboardViewCol col-lg-6"}>
            <div className={"statContainer"} style={{ paddingRight: 0 }}>
              <div className={clsx('container', 'stats-card', 'primary-bg')}>
                <div className={'stats-card__text'}>
                  <p className={clsx('stats-card__text-title', 'x-card-title')}>Watch a Session</p>
                  <p className={clsx('stats-card__text-subtitle', 'x-card-subtitle')}>Watch one of your Sessions you created or purchased</p>
                </div>
                <div>
                  <form> { /*onSubmit={this.handleSubmit}> */}
                    <div className="form-group">
                      <p className={clsx('stats-card__text-subtitle', 'x-card-subtitle')}>Select a Session</p>
                      {renderSelectSession()}
                    </div>
                    <div className="form-group">
                      <p className={clsx('stats-card__text-subtitle', 'x-card-subtitle')}>Run Time (Minutes)</p>
                      <input
                        id="dashboard-runtime-input"
                        type="text"
                        className="form-control"
                        defaultValue="15"
                        onChange={e => onRuntimeChange(e)}
                      />
                    </div>
                    <div className="form-group">
                      <div className="row">
                        <div className="col-md-4 mb-3 text-center"> <button
                          className="btn btn-primary"
                          onClick={(e) => onWatchNow(e)}
                        >
                          Watch Now PLAY
                        </button>
                        </div>
                        <div className="col-md-8 mb-3 text-center">
                          <button
                            className="btn btn-primary"

                            onClick={onEditSession.bind(this)}
                          >
                            Personal Uploads / Edit Transform Sessions
                          </button>
                        </div>
                      </div>


                      {/* {this.renderCreateBtn()} */}

                    </div>

                  </form>
                </div>
                <div className={'stats-card__main'}>
                  <span className={'stats-card__main-value'}>{props.value}</span>
                  <span className={'stats-card__main-unit'}>{props.unit}</span>
                </div>
              </div>
            </div>
            {demoStats.map((statsProps,index) => {
              return (
                <div className={"statContainer"} style={{ paddingRight: 0 }} key={index}>
                  <StatsCard {...statsProps} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal show={tutorialModal} onHide={handleClose} size="xl" >
        <ModalBody className="p-0" style={{
          background: 'url(/assets/images/tutorial_back.jpg) no-repeat center center fixed'
        }}>
          <Row className="m-0">
            <Col>
              <Row className="pt-5 pb-5">
                <Col className="d-flex align-items-center flex-column justify-content-center col-md-5 col-12">
                  <h1 style={{
                    fontFamily: 'sofia-pro, sans-serif',
                    fontWeight: 'bolder',
                    fontSize: '62px',
                    lineHeight: '70px',
                    color: 'white'
                  }} >Welcome to the global family of Positive Prime!</h1>
                  <p style={{
                    fontFamily: 'sofia-pro, sans-serif',
                    fontWeight: '600',
                    fontSize: '40px',
                    lineHeight: '50px',
                    color: 'white'
                  }} >We love our Positive Primers and are grateful you are here.</p>
                </Col>
                <Col className="col-md-7 col-12 d-flex justify-content-center align-items-center">
                  <img
                    src="/assets/images/tutorial_circles.gif"
                    style={{ width: "95%", height: "fit-content" }}
                  ></img>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="m-0" >
            <Col>
              <div className="form-group mt-3 d-flex justify-content-center align-items-center">
                <label style={{ fontWeight: '700', fontSize: 'initial', color: 'white' }} className="container-checkbox">I Am Ready To Be Positively Primed! Dont Show This Again
                  <input type="checkbox" defaultChecked={false} onChange={() => setTutorialCheck(!tutorialCheck)} />
                  <span class="checkmark"></span>
                </label>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <button
            className="btn x-btn x-secondary col-md-3 col-12 p-4"
            type="button"
            onClick={() => {
              setTutorialStatus()
              props.history.push("/tutorial")
            }}
          >
            How does Positive Prime work?
          </button>
          <button
            className="btn x-btn x-primary col-md-4 col-12 p-4"
            type="button"
            onClick={() => {
              setTutorialStatus()
              setTutorialModal(false)
            }}
          >
            I'm a Positive Prime expert, lets get started!
          </button>
        </ModalFooter>
      </Modal>
    </div >
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    mode: state.mode,
    userSessions: state.userSessions,
    productsFeatured: state.productsFeatured,
    userClients: state.userClients,
    recentlyWatchSession: state.recentlyWatchSession,
    ownerSessionForUser: state.ownerSessionForUser,
    userOwnersSessionHistory: state.userOwnersSessionHistory,
    userOwnersSessionHistoryYears: state.userOwnersSessionHistoryYears,
    purchasedSessions: state.purchasedSessions,
  };
}


export default connect(mapStateToProps, actions)(withRouter(Dashboard));

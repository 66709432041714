import React, { Component } from "react";
import clsx from 'clsx';
import { Config } from "../../APIAndConfig";
import axios from "axios";
import { Firebase } from '../../firebase';

import SessionCard from '../../components/cards/SessionCard';
import { ROUTES } from '../../Routers/DashboardRouter';
import { useParams } from 'react-router';
import { Media_URL } from '../../APIAndConfig.ts';
import { connect } from "react-redux";
import * as actions from "../../actions";
import PPTable2 from "../common/PPTable2";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row, Spinner } from "react-bootstrap";
import { CATEGORY_ROUTES } from "../../views/Category";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
class SessionsV3 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sessions: [],
            types: ['/sessions/liked', '/sessions/purchased', '/sessions/mysessions', '/sessions/coach']
        }
    }

    componentDidMount() {
        if (!this.state.types.includes(this.props.location.pathname)) {
            this.props.history.push('/sessions/liked')
        }

        this.props.resetError();
        Firebase.Auth.onAuthStateChanged(async authUser => {
            if (authUser) {
                if (this.props.auth) {
                    await this.props.fetchPurchasesByUserId(this.props.auth.id);
                    await this.props.fetchUserSession(this.props.auth);
                    await this.props.fetchOwnerSessionForUser(this.props.auth);
                    await this.props.getLikedSessions(this.props.auth.id)
                }
            } else {
                this.props.history.push("/login");
            }
        });
    }

    async componentDidUpdate() {
        if (this.props.auth) {
            if (!this.props.purchasedSessions) {
                await this.props.fetchPurchasesByUserId(this.props.auth.id);
                await this.props.fetchUserSession(this.props.auth);
                await this.props.fetchOwnerSessionForUser(this.props.auth);
                await this.props.getLikedSessions(this.props.auth.id)
            }
        }
    }

    componentWillUnmount() {
        this.props.resetError();
    }

    handleSessionCardClick(session) {
        if (session.category !== null && session.category !== undefined && session.category !== 'private' ) {
            this.props.history.push(ROUTES.CATEGORY + '/' + session.category + CATEGORY_ROUTES.SESSION + '/' + session.id);
        } else {
            this.props.history.push(ROUTES.CATEGORY + '/private' + CATEGORY_ROUTES.SESSION + '/' + session.id);
        }
    }

    getDistinctSessions(arr) {
        var flags = [], output = [];
        if (arr) {
            for (let i = 0; i < arr.length; i++) {
                if (flags[arr[i].id]) continue;
                flags[arr[i].id] = true;
                output.push(arr[i]);
            }
        }
        return output
    }

    getType() {
        switch (this.props.location.pathname) {
            case '/sessions/purchased':
                return {
                    heading: 'Purchased',
                    data: this.props.purchasedSessions ? this.getDistinctSessions(this.props.purchasedSessions) : null,
                    // data: this.props.purchasedSessions
                }
            case '/sessions/mysessions':
                return {
                    heading: 'Created',
                    data: this.props.userSessions ? this.getDistinctSessions(this.props.userSessions) : null,
                    // data: this.props.userSessions
                }
            case '/sessions/liked':
                return {
                    heading: 'Liked',
                    data: this.props.likedSessions ? this.getDistinctSessions(this.props.likedSessions) : null,
                    // data: this.props.likedSessions
                }
            case '/sessions/coach':
                return {
                    heading: 'Coach',
                    data: this.props.ownerSessionForUser ? this.getDistinctSessions(this.props.ownerSessionForUser) : null,
                    // data: this.props.ownerSessionForUser
                }
            default:
                return { heading: "", data: [] }
        }
    }

    render() {
        let type = { heading: "", data: null }
        type = this.getType()
        if (!type.data) {
            return <div className={"text-center mt-4"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>
        }

        return (
            <div className={clsx('container', 'wellness-collection')}>
                <div className={'wellness-collection__header', 'd-flex align-items-center'}>
                    <Row className='w-100 m-0'>
                        <Col className="col-md-4 col-12">
                            <div style={{ width: 'fit-content' }} >
                                <h2 className={clsx('wellness-collection__header-title', 'main-title')}>
                                    My Sessions
                                </h2>
                                {type.heading ? <p className="m-0 p-0 text-center">{type.heading} Sessions</p> : <></>}
                            </div>
                        </Col>
                        <Col className="col-md-8 col-12 d-flex justify-content-center align-items-center p-0">
                            {/* <div className="ml-auto"> */}
                            {/* <div className="btn-group">
                                    <button type="button" className="btn dropdown-toggle" style={{
                                        background: 'linear-gradient(104.21deg,#ff6624 -27.11%,#cdaab1 114.9%)',
                                        border: '0px',
                                        fontWeight: 600,
                                        color: 'white'
                                    }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        View More
                            </button>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <button type="button" onClick={() => {
                                            this.props.history.push('/sessions/liked')
                                        }}
                                            disabled={this.props.location.pathname === '/sessions/liked'}
                                        >Liked Sessions</button>
                                        <button className="dropdown-item" type="button"
                                            onClick={() => {
                                                this.props.history.push('/sessions/purchased')
                                            }}
                                            disabled={this.props.location.pathname === '/sessions/purchased'}
                                        >Purchased Sessions</button>
                                        <button className="dropdown-item" type="button"
                                            onClick={() => {
                                                this.props.history.push('/sessions/mysessions')
                                            }}
                                            disabled={this.props.location.pathname === '/sessions/mysessions'}
                                        >Created Sessions</button>
                                        <button className="dropdown-item" type="button" onClick={() => {
                                            this.props.history.push('/sessions/coach')
                                        }}
                                            disabled={this.props.location.pathname === '/sessions/coach'}
                                        >Coach Sessions</button>
                                    </div>
                                </div> */}
                            <div className="d-flex w-100 justify-content-end flex-md-row flex-column">
                                <button type="button" className="btn ml-md-3 ml-0 mt-md-0 mt-2" style={{
                                    background: 'linear-gradient(104.21deg,#ff6624 -27.11%,#cdaab1 114.9%)',
                                    border: '0px',
                                    fontWeight: 600,
                                    color: 'white',
                                    minWidth: '120px'
                                }} onClick={() => {
                                    this.props.history.push('/sessions/liked')
                                }}
                                    disabled={this.props.location.pathname === '/sessions/liked'}
                                >
                                    <FontAwesomeIcon
                                        icon={faHeart}
                                        className="favorite_button far fa-heart hidden"
                                        color="white"
                                    />
                                </button>
                                <button type="button" className="btn ml-md-3 ml-0 mt-md-0 mt-2" style={{
                                    background: 'linear-gradient(104.21deg,#ff6624 -27.11%,#cdaab1 114.9%)',
                                    border: '0px',
                                    fontWeight: 600,
                                    color: 'white',
                                    minWidth: '120px'
                                }}
                                    onClick={() => {
                                        this.props.history.push('/sessions/purchased')
                                    }}
                                    disabled={this.props.location.pathname === '/sessions/purchased'}
                                >Purchased</button>
                                <button type="button" className="btn ml-md-3 ml-0 mt-md-0 mt-2" style={{
                                    background: 'linear-gradient(104.21deg,#ff6624 -27.11%,#cdaab1 114.9%)',
                                    border: '0px',
                                    fontWeight: 600,
                                    color: 'white',
                                    minWidth: '120px'
                                }}
                                    onClick={() => {
                                        this.props.history.push('/sessions/mysessions')
                                    }}
                                    disabled={this.props.location.pathname === '/sessions/mysessions'}
                                >Created</button>
                                <button type="button" className="btn ml-md-3 ml-0 mt-md-0 mt-2" style={{
                                    background: 'linear-gradient(104.21deg,#ff6624 -27.11%,#cdaab1 114.9%)',
                                    border: '0px',
                                    fontWeight: 600,
                                    color: 'white',
                                    minWidth: '120px'
                                }} onClick={() => {
                                    this.props.history.push('/sessions/coach')
                                }}
                                    disabled={this.props.location.pathname === '/sessions/coach'}
                                >Coach</button>
                            </div>
                            {/* </div> */}

                        </Col>
                    </Row>
                </div>
                <div className={'wellness-collection__main', 'mt-4'}>
                    <div className={'row'}>
                        {
                            type.data && type.data.length > 0 ?
                                type.data.map((session, index) => {
                                    return (
                                        <div className={clsx('col-lg-3', 'col-sm-12')} key={index}>
                                            <SessionCard
                                                onClick={() => this.handleSessionCardClick(session)}
                                                id={session.id || ""}
                                                title={session.name || ""}
                                                author={session.author_full_name || ""}
                                                img={session.thumbnail ? process.env.REACT_APP_CDN + '/' + session.thumbnail :
                                                    session.placeholder_image ? Media_URL + '/' + session.placeholder_image :
                                                        'https://www.positiveprime.com/wp-content/uploads/2021/04/Positive-Prime-logowhite.png'
                                                }
                                            />
                                        </div>
                                    )
                                }) :
                                <div className='w-100 mt-5'>
                                    <h2 className={clsx('wellness-collection__header-title', 'main-title', 'mt-5', 'pt-5', 'text-center')} style={{ fontWeight: 'bold' }}>
                                        {
                                            type.heading === "Liked" ? 
                                            `This page is where session that you have liked will be displayed. To 'Like' a session, 
                                            simply click the 'heart image' at the bottom of the player while viewing your session.
                                            All liked sessions will then appear here for quick and easy access for you to enjoy.`
                                            :
                                            `${type.heading} sessions not found`  
                                        }
                                    </h2>
                                </div>
                        }
                    </div>

                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        auth: state.auth,
        purchasedSessions: state.purchasedSessions,
        userSessions: state.userSessions,
        ownerSessionForUser: state.ownerSessionForUser,
        likedSessions: state.likedSessions
    };
}

export default connect(mapStateToProps, actions)(SessionsV3);

import React, { Component } from "react";
import { Card, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { API } from "../../APIAndConfig";
import ReactTable from "react-table";
import { AiFillEye } from "react-icons/ai";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

export class CategoryAdmin extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loader: true,
            sessions: [],
            selectedCategory: {
                name: "",
                id: ""
            },
            messageModal: {
                state: false,
                header: '',
                message: ''
            },
            addCategory: {
                status: false,
                name: ""
            },
            editCategory: {
                status: false,
                id: "",
                name: "",
                category_status: "enabled"
            },
            deleteCategory: {
                status: false,
                id: ''
            },
            updateSessionCategory: {
                status: false,
                category: "",
                sessionId: "",
                currentCategory: ""
            },
            displayOrder: {
                status: false
            }
        }
    }

    componentDidMount() {
        this.getAllCategory()
    }

    async getAllCategory() {
        await API.getCategories().then(
            res => {
                if (res.successful) {
                    let displayData = {}
                    res.payload.categories.map((item) => {
                        displayData[item.id] = item.display_order
                    })

                    this.setState({
                        data: res.payload.categories,
                        sessions: res.payload.data,
                        selectedCategory: {
                            name: res.payload.categories[0].name,
                            id: res.payload.categories[0].id
                        },
                        displayOrder: {
                            ...this.state.displayOrder,
                            ...displayData
                        },
                        loader: false
                    })
                } else {
                    console.log("Error in fetching sessions : ", res)
                }
            }
        )
    }

    renderMessageModal() {
        return (
            <Modal show={this.state.messageModal.state} onHide={()=>{}}>
                <Modal.Header> {this.state.messageModal.header}</Modal.Header>
                <Modal.Body>
                    {this.state.messageModal.message}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={() => {
                            this.setState({
                                messageModal: {
                                    header: '',
                                    message: '',
                                    state: false
                                }
                            })
                        }}
                    >
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    async addCategory(payload) {
        await API.addCategories(payload).then(
            res => {
                this.setState({ isProcessing: false })
                if (res.successful) {
                    this.setState({
                        addCategory: {
                            status: false,
                            name: ""
                        },
                        editCategory: {
                            status: false,
                            id: "",
                            name: ""
                        },
                        messageModal: {
                            state: true,
                            header: 'Success',
                            message: payload.category_id ? 'Category has been updated successfully please refresh the window' : 'Category has been created successfully'
                        }
                    })
                    this.getAllCategory()
                } else {
                    this.setState({
                        addCategory: {
                            status: false,
                            name: ""
                        },
                        editCategory: {
                            status: false,
                            id: "",
                            name: ""
                        },
                        messageModal: {
                            state: true,
                            header: 'Failed',
                            message: payload.category_id ? 'Failed to update category' : 'Failed to create category'
                        }
                    })
                }
            }
        )

    }

    async deleteCategory() {
        let payload = {
            category_id: this.state.deleteCategory.id
        }
        await API.deleteCategory(payload).then(
            res => {
                this.setState({ isProcessing: false })
                if (res.successful) {
                    this.setState({
                        deleteCategory: {
                            status: false,
                            id: ''
                        },
                        messageModal: {
                            state: true,
                            header: 'Success',
                            message: 'Category has been deleted successfully'
                        }
                    })
                    this.getAllCategory()
                } else {
                    this.setState({
                        deleteCategory: {
                            status: false,
                            id: ""
                        },
                        messageModal: {
                            state: true,
                            header: 'Failed',
                            message: 'Failed to delete category'
                        }
                    })
                }
            }
        )

    }

    async updateSessionCategory() {
        let payload = {
            session_id: this.state.updateSessionCategory.sessionId,
            category_id: this.state.updateSessionCategory.category
        }
        await API.updateSessionCategory(payload).then(
            res => {
                this.setState({ isProcessing: false })
                if (res.successful) {
                    this.setState({
                        updateSessionCategory: {
                            status: false,
                            category: "",
                            sessionId: ""
                        },
                        messageModal: {
                            state: true,
                            header: 'Success',
                            message: 'Session Category has been updated successfully'
                        }
                    })
                    this.getAllCategory()
                } else {
                    this.setState({
                        updateSessionCategory: {
                            status: false,
                            category: "",
                            sessionId: ""
                        },
                        messageModal: {
                            state: true,
                            header: 'Failed',
                            message: 'Failed to update session category'
                        }
                    })
                }
            }
        )

    }

    renderAddCategoryModal() {
        return (
            <Modal show={this.state.addCategory.status} onHide={()=>{}}>
                <Modal.Header>
                    <div className='w-100'>
                        <label>Add Category</label>
                        <button
                            className="x-secondary"
                            style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                            onClick={() => {
                                this.setState({
                                    addCategory: {
                                        status: false,
                                        name: ""
                                    }
                                })
                            }}
                        >X</button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label>Category Name</label>
                        <input
                            type="text"
                            className='form-control setting-input'
                            name="userId"
                            onChange={(e) => {
                                this.setState({
                                    addCategory: {
                                        ...this.state.addCategory,
                                        name: e.target.value
                                    }
                                })
                            }}
                            value={this.state.addCategory.name}
                            placeholder="Category name"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        disabled={this.state.isProcessing}
                        onClick={() => {
                            this.setState({ isProcessing: true })
                            let payload = {
                                category_name: this.state.addCategory.name
                            }
                            this.addCategory(payload)
                        }}
                    >
                        {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Create</span>}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderEditCategoryModal() {
        return (
            <Modal show={this.state.editCategory.status} onHide={()=>{}}>
                <Modal.Header>
                    <div className='w-100'>
                        <label>Add Category</label>
                        <button
                            className="x-secondary"
                            style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                            onClick={() => {
                                this.setState({
                                    editCategory: {
                                        status: false,
                                        id: "",
                                        name: ""
                                    }
                                })
                            }}
                        >X</button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label>Category id</label>
                        <input
                            type="text"
                            className='form-control setting-input'
                            name="category_id"
                            value={this.state.editCategory.id}
                            placeholder="Category Id"
                            disabled
                        />
                    </div>
                    <div className='form-group'>
                        <label>Category Name</label>
                        <input
                            type="text"
                            className='form-control setting-input'
                            name="category_name"
                            onChange={(e) => {
                                this.setState({
                                    editCategory: {
                                        ...this.state.editCategory,
                                        name: e.target.value
                                    }
                                })
                            }}
                            value={this.state.editCategory.name}
                            placeholder="Category name"
                        />
                    </div>
                    <div className='form-group'>
                        <label>Category Status</label>
                        <select name="category_status" id="category_status" className="form-control" onChange={(e) => {
                            this.setState({
                                editCategory: {
                                    ...this.state.editCategory,
                                    category_status: e.target.value
                                }
                            })
                        }}>
                            <option value="enabled" selected={this.state.editCategory.category_status === "enabled"}>Enabled</option>
                            <option value='disabled' selected={this.state.editCategory.category_status === "disabled"}>Disabled</option>
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        disabled={this.state.editCategory.name === "" || this.state.isProcessing}
                        onClick={() => {
                            this.setState({ isProcessing: true })
                            console.log(this.state.editCategory);
                            let payload = {
                                category_id: this.state.editCategory.id,
                                category_status: this.state.editCategory.category_status,
                                category_name: this.state.editCategory.name
                            }
                            this.addCategory(payload)
                        }}
                    >
                        {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Update</span>}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderDeleteCategoryModal() {
        return (
            <Modal show={this.state.deleteCategory.status} onHide={()=>{}}>
                <Modal.Header>
                    <div className='w-100'>
                        <label>Delete Category</label>
                        <button
                            className="x-secondary"
                            style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                            onClick={() => {
                                this.setState({
                                    deleteCategory: {
                                        status: false,
                                        id: ""
                                    }
                                })
                            }}
                        >X</button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete category ?
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        disabled={this.state.isProcessing}
                        onClick={() => {
                            this.setState({ isProcessing: true })
                            this.deleteCategory()
                        }}
                    >
                        {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Delete</span>}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    renderUpdateSessionCategoryModal() {
        return (
            <Modal show={this.state.updateSessionCategory.status} onHide={()=>{}}>
                <Modal.Header>
                    <div className='w-100'>
                        <label>Update Session Category</label>
                        <button
                            className="x-secondary"
                            style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                            onClick={() => {
                                this.setState({
                                    updateSessionCategory: {
                                        status: false,
                                        category: "",
                                    },
                                })
                            }}
                        >X</button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='form-group'>
                        <label>Session</label>
                        <input
                            type="text"
                            className='form-control setting-input'
                            name="userId"
                            value={this.state.updateSessionCategory.sessioName}
                            placeholder="Category name"
                            disabled
                        />
                    </div>
                    <div className='form-group'>
                        <label>Category Name</label>
                        <select name="category_status" id="category_status" className="form-control" onChange={(e) => {
                            this.setState({
                                updateSessionCategory: {
                                    ...this.state.updateSessionCategory,
                                    category: e.target.value
                                }
                            })
                        }}>
                            {
                                this.state.data.map((item, index) => {
                                    return <option value={item.id} selected={Number(item.id) === Number(this.state.updateSessionCategory.category)}>{item.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='form-group'>
                        <label>Display Order</label>
                        <select name="category_status" id="category_status" className="form-control" onChange={(e) => {
                            this.setState({
                                updateSessionCategory: {
                                    ...this.state.updateSessionCategory,
                                    category: e.target.value
                                }
                            })
                        }}>
                            {
                                this.state.data.map((item, index) => {
                                    return <option value={item.id} selected={Number(item.id) === Number(this.state.updateSessionCategory.category)}>{item.name}</option>
                                })
                            }
                        </select>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        disabled={this.state.isProcessing || Number(this.state.updateSessionCategory.currentCategory) === Number(this.state.updateSessionCategory.category)}
                        onClick={() => {
                            this.setState({ isProcessing: true })
                            this.updateSessionCategory()
                        }}
                    >
                        {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Update</span>}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    async updateDisplayOrder() {
        let arr = Object.values(this.state.displayOrder)
        let sorted_arr = arr.slice().sort();
        let results = [];
        for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] == sorted_arr[i]) {
            results.push(sorted_arr[i]);
            }
        }
        if (results.length > 0) {
            this.setState({
                messageModal : {
                    state: true,
                    header: 'Alert',
                    message: 'Positions for categories are duplicated'
                }
            })
        } else {
            this.setState({ isProcessing : true })
            let payload = { displayOrder : {
                ...this.state.displayOrder
            }}
            delete payload.displayOrder['status']
            console.log(payload);
            await API.setDiplayOrder(payload).then(
                res => {
                    if (res.successful) {
                        this.setState({
                            messageModal : {
                                state: true,
                                header: 'Success',
                                message: 'Display order updated successfully please refresh the window'
                            },
                            isProcessing : false,
                            displayOrder : {
                                status : false
                            }
                        })
                        this.getAllCategory()
                    } else {
                        this.setState({
                            messageModal : {
                                state: true,
                                header: 'Failed',
                                message: 'Failed to update display order please try again'
                            },
                            isProcessing : false,
                            displayOrder : {
                                status : false
                            }
                        })
                    }
                }
            )
        }
    }

    renderDisplayOrder() {
        return (
            <Modal show={this.state.displayOrder.status} onHide={()=>{}}>
                <Modal.Header>
                    <div className='w-100'>
                        <label>Set Display Order</label>
                        <button
                            className="x-secondary"
                            style={{ float: "right", fontWeight: "bolder", color: "white", borderRadius: "25%", border: "none" }}
                            onClick={() => {
                                let displayData = {}
                                this.state.data.map((item) => {
                                    displayData[item.id] = item.display_order
                                })
                                this.setState({
                                    displayOrder: {
                                        ...displayData,
                                        status: false
                                    },
                                })
                            }}
                        >X</button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.data.map((item) => {
                            return (
                                <div className="row my-2">
                                    <div className="col">{item.name}</div>
                                    <div className="col">
                                        <select
                                            id="display_orders"
                                            name={item.id}
                                            className="custom-select custom-select-sm"
                                            onChange={(e) => {
                                                this.setState({
                                                    displayOrder: {
                                                        ...this.state.displayOrder,
                                                        [e.target.name]: Number(e.target.value)
                                                    }
                                                })
                                            }}
                                        >
                                            {
                                                this.state.data.map((obj, index) => {
                                                    return (<option selected={Number(this.state.displayOrder[item.id]) === Number(index + 1)} >{index + 1}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn x-btn x-secondary "
                        type="button"
                        onClick={this.updateDisplayOrder.bind(this)}
                    >
                        {this.state.isProcessing ? <Spinner animation="border" size="sm" className="p-2" /> : <span className="p-2">Set</span>}
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (
            this.state.loader ?
                <div className={"text-center mt-4"}><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div > :
                <>
                    <div className="row">
                        <div className='col-6 my-4'>
                            <h2 className='main-title'>
                                Category Admin
                            </h2>
                        </div>
                        <div className='col-6 my-4'>
                            <button className="btn x-secondary text-white float-right"
                                onClick={() => {
                                    this.setState({
                                        addCategory: {
                                            status: true,
                                            name: ""
                                        }
                                    })
                                }}
                            >
                                Add Category
                            </button>
                        </div>
                    </div>

                    {this.renderCategories()}
                    {this.renderSessions()}
                    {this.renderMessageModal()}
                    {this.renderAddCategoryModal()}
                    {this.renderEditCategoryModal()}
                    {this.renderDeleteCategoryModal()}
                    {this.renderUpdateSessionCategoryModal()}
                    {this.renderDisplayOrder()}

                </>
        )
    }

    renderCategories() {
        return (
            < div >
                <Card className="card-blur">
                    <Card.Header className="text-white row font-weight-bold m-0" style={{ background: "rgb(111 79 136 / 1)" }}>
                        <div className="col p-0 d-flex align-items-center">Categories</div>
                        <div className="col p-0">
                            <button className="btn x-secondary text-white float-right"
                                onClick={() => {
                                    this.setState({
                                        displayOrder: {
                                            ...this.state.displayOrder,
                                            status: true
                                        }
                                    })
                                }}
                            >
                                Set Display Order
                            </button>
                        </div>
                    </Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                            filterable
                            data={this.state.data}
                            columns={[
                                {
                                    id: "category_id",
                                    Header: 'Category id',
                                    accessor: 'id',
                                    maxWidth: 100,
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'category_name',
                                    Header: 'Category Name',
                                    accessor: 'name',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'no_of_sessions',
                                    Header: 'No of Sessions',
                                    accessor: (d) => {
                                        let arr = this.state.sessions.filter(obj => Number(obj.category_id) === Number(d.id))
                                        return arr.length
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'status',
                                    Header: 'Status',
                                    accessor: (d) => {
                                        return d.status.toUpperCase()
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'display_order',
                                    Header: 'Display Order',
                                    accessor: "display_order",
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'action',
                                    Header: 'Action',
                                    accessor: (d) => {
                                        return (
                                            <>
                                                <button className="btn x-secondary text-white"
                                                    onClick={() => {
                                                        this.setState({
                                                            editCategory: {
                                                                status: true,
                                                                name: d.name,
                                                                id: d.id,
                                                                category_status: d.status
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <FaEdit size={18} />
                                                </button>
                                                <button className="btn x-secondary text-white ml-2"
                                                    onClick={() => {
                                                        this.setState({
                                                            selectedCategory: {
                                                                name: d.name,
                                                                id: d.id
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <AiFillEye size={18} />
                                                </button>
                                                <button className="btn x-secondary text-white ml-2"
                                                    disabled={this.state.sessions.filter(obj => Number(obj.category_id) === Number(d.id)).length > 0}
                                                    onClick={() => {
                                                        this.setState({
                                                            deleteCategory: {
                                                                status: true,
                                                                id: d.id
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <FaTrashAlt size={18} />
                                                </button>
                                            </>
                                        )
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                }
                            ]}
                            defaultPageSize={10}
                            noDataText='No Data Found'
                            style={{ background: "rgb(111 79 136 / 1)" }}
                            getTrProps={(state, rowInfo, column, instance) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'transparent',
                                            color: 'white'
                                        },
                                    }
                                }
                            }}
                        />
                    </Card.Body>
                </Card>
            </ div>
        )
    }

    renderSessions() {
        return (
            < div >
                <Card className="card-blur mt-5">
                    <Card.Header className="text-white  font-weight-bold " style={{ background: "rgb(111 79 136 / 1)" }}>{this.state.selectedCategory.name}</Card.Header>
                    <Card.Body className="p-0">
                        <ReactTable
                            filterable
                            data={this.state.sessions.filter(obj => Number(obj.category_id) === Number(this.state.selectedCategory.id))}
                            columns={[
                                {
                                    id: "session_id",
                                    Header: 'Session id',
                                    accessor: 'session_id',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'session_name',
                                    Header: 'Session Name',
                                    accessor: 'name',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'category',
                                    Header: 'Category',
                                    accessor: 'category_id',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'type',
                                    Header: 'Type',
                                    accessor: 'type',
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                },
                                {
                                    id: 'action',
                                    Header: 'Action',
                                    accessor: (d) => {
                                        return (
                                            <>
                                                <button className="btn x-secondary text-white"
                                                    onClick={() => {
                                                        this.setState({
                                                            updateSessionCategory: {
                                                                status: true,
                                                                category: d.category_id,
                                                                sessionId: d.session_id,
                                                                sessioName: d.name,
                                                                currentCategory: d.category_id
                                                            }
                                                        })
                                                    }}
                                                >
                                                    <FaEdit size={18} />
                                                </button>
                                            </>
                                        )
                                    },
                                    headerClassName: 'p-3 text-light',
                                    className: 'text-center'
                                }
                            ]}
                            defaultPageSize={10}
                            noDataText='No Data Found'
                            style={{ background: "rgb(111 79 136 / 1)" }}
                            getTrProps={(state, rowInfo, column, instance) => {
                                if (typeof rowInfo !== "undefined") {
                                    return {
                                        style: {
                                            background: rowInfo.index % 2 === 0 ? 'transparent' : 'rgb(96 64 125 / 1)',
                                            color: rowInfo.index % 2 === 0 ? 'white' : 'white'
                                        },
                                    }
                                }
                                else {
                                    return {
                                        style: {
                                            background: 'transparent',
                                            color: 'white'
                                        },
                                    }
                                }
                            }}
                        />
                    </Card.Body>
                </Card>
            </ div>
        )
    }

}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}


export default connect(mapStateToProps, actions)(CategoryAdmin)
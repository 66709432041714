import { AnalyticsCurrentDateRange, AnalyticsRangePicker } from "./AnalyticsRangePicker";
import { Badge, Card, Col, Container, Row, Table } from "react-bootstrap";
import React from "react";
import { ChartValue, WatchGraph } from "./WatchGraph";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { get, set, omitBy, isNil } from "lodash";
import { connect } from "react-redux";
import * as actions from "../../actions";
import { WatchAnalyticsParams } from "../../api/APIParams";

import querystring from "querystring";
import { WatchBreakdown } from "./WatchBreakdown";
import { API } from "../../APIAndConfig";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface AnalyticsDashboardProps extends RouteComponentProps<any>, React.Props<any> {
    auth?: any;
    createdSessions?: any[]
}

export interface AnalyticsDashboardState {
    config: Partial<AnalyticsCurrentDateRange>,
    user?: string | number,
    session?: string | number,
    userLabel?: string | null,
    sessionLabel?: string | null,
    surveyTable: any
}

class AnalyticsDashboardClassV2 extends React.Component<AnalyticsDashboardProps, AnalyticsDashboardState> {

    constructor(props: AnalyticsDashboardProps) {
        super(props);

        let session: string | number | undefined = undefined;
        let user: string | number | undefined = undefined;
        let config: Partial<AnalyticsCurrentDateRange> = {};

        if (props.location && props.location.search) {
            const queryString = querystring.parse(props.location.search.split('?').pop() || '');

            if (queryString.range)
                config.range = queryString.range as string;

            if (queryString.start)
                config.start = queryString.start as string;

            if (queryString.end)
                config.end = queryString.end as string;

            if (queryString.filter)
                config.filter = queryString.filter as string;

            if (queryString.collate)
                config.collate = queryString.collate as string;

            if (queryString.session)
                session = queryString.session as string;

            if (queryString.user)
                user = queryString.user as string;
        }

        if (!config.range && !config.start && !config.end)
            config.range = "last28days";

        this.state = {
            config: config,
            user: user,
            userLabel: null,
            session: session,
            sessionLabel: null,
            surveyTable: null
        };
    }

    async componentWillMount(): Promise<void> {
        if (this.state.user || this.state.session) {

            const labels = await API.getLabels({
                user: this.state.user,
                session: this.state.session
            });

            this.setState({
                userLabel: get(labels, 'payload.user'),
                sessionLabel: get(labels, 'payload.session'),
                surveyTable: get(labels, 'payload.survey')
            })
        }
    }

    getAllowedFilters() {
        let filters: { [key: string]: string } = {}

        const role = this.props.auth?.role.id || 0;

        if (role === 1)
            set(filters, 'all', 'ALL');

        if (role === 4)
            set(filters, 'clients', 'Clients');

        if (this.props.createdSessions && this.props.createdSessions.length > 1)
            set(filters, 'authored', 'Created Sessions');

        if ([1, 3, 4, 5, 6].indexOf(role) >= 0)
            set(filters, 'self', 'Self');

        return filters;
    }

    onOpenNav(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        const sidebar = document.getElementById('sidebar');
        if (sidebar)
            sidebar.classList.add('sidebarOpen');
    }

    onDailySelect(row: ChartValue, column: ChartValue, value: ChartValue) {

    }

    handleDatePickerChange(config: AnalyticsCurrentDateRange) {
        const afterConfigUpdate = this.afterConfigUpdate.bind(this);
        this.setState({
            config: config
        }, function () {
            afterConfigUpdate();
        })
    }

    afterConfigUpdate() {
        this.props.history.push('?' + this.getQueryString())
        //this.forceUpdate();
    }
    getQueryString(override: any = {}) {
        let params = {};
        if (this.state.config.filter)
            set(params, 'filter', this.state.config.filter);

        if (this.state.config.collate)
            set(params, 'collate', this.state.config.collate);

        if (this.state.config.range) {
            set(params, 'range', this.state.config.range);
        } else {
            if (this.state.config.start)
                set(params, 'start', this.state.config.start);

            if (this.state.config.end)
                set(params, 'end', this.state.config.end);
        }

        if (this.state.user)
            set(params, 'user', this.state.user);

        if (this.state.session)
            set(params, 'session', this.state.session);

        let result = Object.assign(params, override);
        result = omitBy(result, isNil);
        return querystring.stringify(result);
    }

    getGraphParams(): WatchAnalyticsParams {
        const payload: WatchAnalyticsParams = {
            start: this.state.config.start || '',
            end: this.state.config.end || '',
            filter: this.state.config.filter || '',
            collate: this.state.config.collate || '',
            format: "graph",
            user: this.state.user,
            session: this.state.session
        };
        return payload;
    }

    getBreakdownParams(collate: string): WatchAnalyticsParams {
        const payload: WatchAnalyticsParams = {
            start: this.state.config.start || '',
            end: this.state.config.end || '',
            filter: this.state.config.filter || '',
            collate: collate,
            format: "table",
            user: this.state.user,
            session: this.state.session
        };
        return payload;
    }

    render() {


        return (
            <div className="">
                <div>

                    <h2 className="main-title m-0 p-3">Analytics</h2>
                    <hr className="m-0" />

                    <div className="m-5">
                        <Container fluid={true}>
                            <Row className="m-3">
                                <Col className="text-center">
                                    <AnalyticsRangePicker filters={this.getAllowedFilters()} config={this.state.config} onChange={this.handleDatePickerChange.bind(this)} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="filters">
                                    {this.renderSessionFilter()}
                                    {this.renderUserFilter()}
                                </Col>
                            </Row>
                        </Container>

                        <WatchGraph
                            params={this.getGraphParams()}
                            onSelect={this.onDailySelect.bind(this)}
                        />

                        <Container fluid={true} className="mt-5">
                            <Row>
                                {this.renderSurveyTable()}
                            </Row>
                        </Container>

                        <Container fluid={true} className="mt-5">
                            <Row>
                                {this.renderSessionsTable()}
                                {this.renderUsersTable()}
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        )
    }

    renderSurveyTable() {
        return (
            <Col className="text-center">
                <Card className="shadow">
                    <Card.Header className="text-white" style={{ background : "rgb(111 79 136 / 1)" }}>Survey</Card.Header>
                    <Card.Body className="p-0">
                        <WatchBreakdown params={this.getBreakdownParams("survey")} titleClass="text-dark" queryString={this.getQueryString({ survey: undefined })} surveyTableUnique={true} />
                    </Card.Body>
                </Card>
            </Col>
        )

    }

    renderSessionsTable() {
        if (this.state.session)
            return;

        return (
            <Col className="text-center pb-5">
                <Card className="shadow">
                    <Card.Header className="text-white" style={{ background : "rgb(111 79 136 / 1)" }}>Sessions</Card.Header>

                    <WatchBreakdown params={this.getBreakdownParams("session")} titleClass="text-dark" queryString={this.getQueryString({ session: undefined })} surveyTableUnique={false} />

                </Card>
            </Col>
        );
    }

    renderUsersTable() {
        if (this.state.user || this.state.config.filter === "self")
            return;

        const roleId = get(this.props, 'auth.role.id', 0);
        if (roleId !== 1 && roleId !== 4)
            return;

        return (
            <Col className="text-center pb-5">
                <Card className="shadow">
                    <Card.Header className="text-white" style={{ background : "rgb(111 79 136 / 1)" }} >Users / Clients</Card.Header>
                    <WatchBreakdown params={this.getBreakdownParams("user")} titleClass="text-secondary" queryString={this.getQueryString({ user: undefined })} surveyTableUnique={false} />

                </Card>
            </Col>
        )
    }

    renderSessionFilter() {
        if (!this.state.session)
            return;

        let label: string = this.state.sessionLabel ? this.state.sessionLabel : this.state.session.toString();

        return <Badge variant="warning" as="div">
            <small>Session:</small> &nbsp;
            <span>{label}</span>
            <a href={"?" + this.getQueryString({ session: undefined })}>&times;</a>
        </Badge>
    }
    renderUserFilter() {
        if (!this.state.user)
            return;

        let label: string = this.state.userLabel ? this.state.userLabel : this.state.user.toString();

        return <Badge variant="warning" as="div">
            <small>User:</small> &nbsp;
            <span>{label}</span>
            <a href={"?" + this.getQueryString({ user: undefined })}>&times;</a>
        </Badge>
    }
    handleClearSessionFilter() {
        const afterConfigUpdate = this.afterConfigUpdate.bind(this);
        this.setState({
            session: undefined
        }, function () {
            afterConfigUpdate();
        })
    }
    handleClearUserFilter() {
        const afterConfigUpdate = this.afterConfigUpdate.bind(this);
        this.setState({
            user: undefined
        }, function () {
            afterConfigUpdate();
        })
    }
}

function mapStateToProps(state: any) {
    return { auth: state.auth, createdSessions: state.userSessions };
}
export const AnalyticsDashboard = connect(mapStateToProps, actions)(withRouter(AnalyticsDashboardClassV2));

import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";

export interface APIResponse<T> {
    successful: boolean,
    status: number,
    message: string,
    payload: T | object | null
}

export class APIWrapper {
    axios: AxiosInstance;
    constructor(axios: AxiosInstance) {
        this.axios = axios;
    }
    async get<T>(endpoint: string, config?: AxiosRequestConfig): Promise<APIResponse<T>> {
        return await this.makeCall<T>('GET', endpoint,  config);
    }
    async post<T>(endpoint: string, payload?: any, config?: AxiosRequestConfig, ): Promise<APIResponse<T>> {
        return await this.makeCall<T>('POST', endpoint, config, payload);
    }
    async put<T>(endpoint: string, payload: any, config?: AxiosRequestConfig, ): Promise<APIResponse<T>> {
        return await this.makeCall<T>('PUT', endpoint, config, payload);
    }
    async delete<T>(endpoint: string, config?: AxiosRequestConfig, ): Promise<APIResponse<T>> {
        return await this.makeCall<T>('DELETE', endpoint, config);
    }

    async makeCall<T>(method: 'GET' | 'POST' | 'PUT' | 'DELETE', endpoint: string, config?: AxiosRequestConfig, payload?: any): Promise<APIResponse<T>> {

        let response: AxiosResponse | null = null;

        try {
            switch (method) {

                case "DELETE":
                    response = await this.axios.delete(endpoint, config);
                    break;

                case "POST":
                    response = await this.axios.post(endpoint, payload, config);
                    break;

                case "PUT":
                    response = await this.axios.put(endpoint, payload, config);
                    break;

                case "GET":
                default:
                    response = await this.axios.get(endpoint, config);
            }
        } catch(err) {
            return {
                successful: false,
                status: err.response ? err.response.status || 500 : 500,
                message: err.response ? err.response.statusText || 'Unknown Error' : 'Unknown Error',
                payload: err.response ? err.response.data || null : null
            }
        }

        if (!response)
            return {successful: false, status: 500, message: 'Unknown Error', payload: null};

        return {
            successful: true,
            status: response.status,
            message: response.statusText,
            payload: response.data
        }

    }
}

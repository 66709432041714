import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  endUserWatchSession,
  fetchDefaultImages,
  fetchImages,
  fetchImageSessionById,
  fetchSessionDetailById,
  fetchUser,
  fetchUserSession,
  resetMediaSessions,
  startUserWatchSession,
  surveyAfterSession,
  surveyBeforeSession
} from "../../actions/index";
import ImageDetail from "./ImageDetail";
import ModalImageDetail from "./ModalImageDetail";
import SurveyBefore from "../surveys/SurveyBefore";
import SurveyAfter from "../surveys/SurveyAfter";

import { withRouter } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleDown, faAngleDoubleUp,
  faBars,
  faCompress,
  faExpand,
  faPause,
  faPlay,
  faVolumeMute,
  faVolumeUp
} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

import "./image.css";
import { Firebase } from "../../firebase";
import { makeBucketUrl, makeLegacyCDNUrl, makePlayerImageUrl } from "../../APIAndConfig";

library.add(faVolumeMute);

const START_PLAYER = "START_PLAYER";
const PAUSE_PLAYER = "PAUSE_PLAYER";
const END_PLAYER = "END_PLAYER";
const FASTER_PLAYER = "FASTER_PLAYER";
const SLOWER_PLAYER = "SLOWER_PLAYER";

const AUDIO_PLAY = "AUDIO_PLAY";
const AUDIO_PAUSE = "AUDIO_PAUSE";
const BUFFER_SECONDS = 10;
const BUFFER_BATCH_SIZE = 10;
//const timerInterval = 1000;
//const timerIntervalSet = [250, 500, 1000, 2000, 3000, 4000, 5000, 6000];

// TODO: 1. Add check the toggle button since it forces the thing to play again.
//       2. Add logic to reset the internal component state.
//       3. Add logic to update the timerIntervalSet to insert in between...
//       4. Merge logic to randomize images and splice in the text images.

class ImagePlayer extends Component {
  constructor(props) {
    super(props);


    this.state = {
      imageData: null,
      index: 0,
      timer: 0,
      action: START_PLAYER,
      audioAction: AUDIO_PLAY,
      timerInterval: 0,
      timerIntervalIdx: -1,
      timerIntervalSet: [],
      imageDataArray: null,
      timerArray: [],
      originalIdxArray: [],
      loaded: false,
      currentEmotion: "skip",
      token: null,
      speedLabel: "",
      userNameCount: 1,
      submittedEndTime: false,
      nextLocation: null,
      userWatchHistory: null,
      completedSession: false,
      defaultAudioUrl: "/default/music/default_music_3.mp3",
      progressBar: 0,
      beforeSurveyClosed: false
    };

    this.nextImageToBuffer = 0;

    //Bind this to the functions
    this.nextProperty = this.nextProperty.bind(this);
    this.startAnimation = this.startAnimation.bind(this);
    this.stopAnimation = this.stopAnimation.bind(this);
    this.fasterAnimation = this.fasterAnimation.bind(this);
    this.slowerAnimation = this.slowerAnimation.bind(this);
    this.audioChange = this.audioChange.bind(this);
    this.openModalImage = this.openModalImage.bind(this);
    this.toggleBtn = this.toggleBtn.bind(this);
    this.toggleDisabled = this.toggleDisabled.bind(this);
    this.printRemainingTime = this.printRemainingTime.bind(this);

    this.startImagePlayer = this.startImagePlayer.bind(this);
    this.setupTimerIntervalSet = this.setupTimerIntervalSet.bind(this);
    this.setupDefaultArray = this.setupDefaultArray.bind(this);
    this.onRouteChange = this.onRouteChange.bind(this);
    this.onClickAfterSurvey = this.onClickAfterSurvey.bind(this);
    this.addImageProcess = this.addImageProcess.bind(this);
    this.addAudioProcess = this.addAudioProcess.bind(this);
  }

  async componentDidMount() {

    this.unblock = this.props.history.block(targetLocation => {
      this.targetLocation = targetLocation;
      this.onRouteChange();
      return false;
    });

    let session_id = parseInt(this.props.match.params._id);

    if (isNaN(session_id)) {
      this.props.history.push("/notFound");
      return;
    }

    Firebase.Auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        const token = await authUser.getIdToken();
        this.setState({ token });
        if (!this.props.auth) {
          return;
        }

        if (!this.state.loaded) {
          this.state.loaded = true;

          let data = await this.props.fetchSessionDetailById(
            this.props.match.params._id
          );

          let audioPlayer = document.getElementById("audioPlayer");
          if (audioPlayer) {
            let source = document.createElement("source");
            source.id = "audioSource";
            source.type = 'audio/mpeg';
            source.src = makeLegacyCDNUrl('b/' + data.defaultAudioURL);

            audioPlayer.appendChild(source);

            audioPlayer.src = makeLegacyCDNUrl('b/' + data.defaultAudioURL);
            audioPlayer.preload = "auto";

          }

          this.state.defaultAudioUrl = data.defaultAudioURL;

          await this.props.fetchImageSessionById(
            this.props.auth,
            this.props.match.params._id
          );

          // Doesn't get called on component mount, only component update
          await this.props.fetchDefaultImages();

          this.openBeforeSurveyModal();
          await this.startImagePlayer();

          // Only start playing if the before survey is closed
          if (this.state.beforeSurveyClosed === true)
            this.startAnimation();
          else
            this.stopAnimation();
        }
      } else {
        this.props.history.push("/login");
      }
    });
  }

  async componentDidUpdate() {


    if (this.state.loaded) {
      return;
    }

    if (this.props.auth && this.props.mediaSession.length <= 0) {
      this.state.loaded = true;

      let data = await this.props.fetchSessionDetailById(
        this.props.match.params._id
      );

      let audioPlayer = document.getElementById("audioPlayer");
      if (audioPlayer) {
        let source = document.createElement("source");
        source.id = "audioSource";
        source.src = makeLegacyCDNUrl('b/' + data.defaultAudioURL);
        source.src = makeLegacyCDNUrl('b/' + data.defaultAudioURL);
        audioPlayer.preload = "auto";
        audioPlayer.appendChild(source);
      }


      this.state.defaultAudioUrl = data.defaultAudioURL;
      await this.props.fetchImageSessionById(
        this.props.auth,
        this.props.match.params._id
      );

      await this.props.fetchDefaultImages();

      this.openBeforeSurveyModal();
      await this.startImagePlayer();

      // Only start playing if the before survey is closed
      if (this.state.beforeSurveyClosed === true)
        this.startAnimation();
      else
        this.stopAnimation();
    }
  }

  componentWillUnmount() {
    let audioPlayer = document.getElementById("audioPlayer");
    if (audioPlayer && !audioPlayer.paused) {
      audioPlayer.remove();
    }

    if (this.unblock) {
      this.unblock();
      this.props.history.push(this.targetLocation);
    }
  }

  setupDefaultArray() {
    if (this.state.timerIntervalSet && this.state.timerIntervalSet.length > 0) {
      return;
    }

    let initalInterval = 3000; // milliseconds
    this.state.timerIntervalSet.unshift({
      interval: initalInterval,
      label: "1x"
    });

    // 2x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.5,
      label: "2x"
    });

    // 3x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.3333333333,
      label: "3x"
    });

    // 4x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.25,
      label: "4x"
    });

    // 5x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.2,
      label: "5x"
    });

    // 6x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.1666666667,
      label: "6x"
    });

    // 7x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.14285714,
      label: "7x"
    });

    // 8x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.125,
      label: "8x"
    });

    // 9x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.11111111111,
      label: "9x"
    });

    // 10x
    this.state.timerIntervalSet.unshift({
      interval: initalInterval * 0.1,
      label: "Max"
    });
  }

  
  setupTimerIntervalSet(totalNumberOfImgs) {
    if (totalNumberOfImgs > 0) {
      let runtime = this.getRuntime();
      this.setupDefaultArray();

      let seconds = runtime * 60;
      let interval = seconds / totalNumberOfImgs;
      interval *= 1000;

      const { timerIntervalSet } = this.state;

      for (let i = timerIntervalSet.length - 1; i >= 0; --i) {
        if (timerIntervalSet[i].interval <= interval) {
          this.state.timerIntervalSet[i].interval = interval;
          this.state.timerIntervalIdx = i;
          this.state.timerInterval = interval;

          return;
        } else if (i === 0 && timerIntervalSet[i].interval > interval) {
          this.state.timerIntervalSet[i].interval = interval;
          this.state.timerIntervalIdx = i;
          this.state.timerInterval = interval;
        }
      }
      this.toggleDisabled();
    }
  }

  async onRouteChange() {
    if (this.state.timer !== 0) {
      clearInterval(this.state.timer);
      this.state.timer = 0;
    }

    this.stopAnimation();
    this.props.resetMediaSessions();

    if (this.state.userWatchHistory && this.props.auth) {
      if (this.state.submittedEndTime === false) {
        let history = await this.props.endUserWatchSession(
          this.props.auth,
          this.props.match.params._id,
          this.state.userWatchHistory,
          false
        );

        if (history.earnCredits) {
//          alert(
//            `Congratulations! You earn a bonus of ${history.earnCredits} credits for this session!`
//          );

          if (this.state.token) {
            await this.props.fetchUser(this.state.token);
          }
        }
      }
    }

    let survey = document.getElementById("ppAfterSurvey");
    if (survey) {
      survey.click();
    }
  }
  getRuntime() {
    let runtime = new URLSearchParams(this.props.location.search).get("runtime");

    runtime = parseInt(runtime);
    if (isNaN(runtime)) {
      // Default to 15 minutes
      runtime = 15;
    } else if (runtime < 3) {
      // Min runtime is 3 minutes
      runtime = 3;
    } else if (runtime > 15) {
      // Max runtime is 3 minutes
      runtime = 15;
    }

    return runtime;
  }

  imagesToBuffer(totalImages) {
    let runtimeSec = this.getRuntime() * 60;
    let imagesPerSec = totalImages / runtimeSec;
    let imagesBuffer = Math.round(imagesPerSec * BUFFER_SECONDS);

    return imagesBuffer;
  }

  bufferNextImage() {
    if (this.nextImageToBuffer === null)
      return;

    this.setState((state) => {
      const imageArray = state.imageDataArray;
      // console.log(imageArray)

      if (imageArray[this.nextImageToBuffer].image_element) {
        this.nextImageToBuffer++;
        return {};
      }

      let img = document.createElement("img");
      img.src = makePlayerImageUrl(imageArray[this.nextImageToBuffer].media_url);
      imageArray[this.nextImageToBuffer].image_element = img;

      if (this.nextImageToBuffer < imageArray.length - 1)
        this.nextImageToBuffer++;
      else
        this.nextImageToBuffer = null;

      return { imageDataArray: imageArray }
    });
  }

  addImageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => {
        reject(img);
      };
      img.src = src;
    });
  }

  addAudioProcess(src) {
    return new Promise((resolve, reject) => {
      let audioPlayer = document.getElementById("audioPlayer");

      if (audioPlayer) {
        audioPlayer.onload = () => {
          resolve(audioPlayer);
        };
        audioPlayer.onerror = reject;
        audioPlayer.src = src;
      }
    });
  }

  async startImagePlayer() {


    let imageArray = this.props.mediaSession;
    // Add in the beginning images if it exist
    for (let i = this.props.defaultImages.length - 1; i >= 0; --i) {
      const defaultImage = this.props.defaultImages[i];

      if (defaultImage.default_image_type_id === 1) {
        imageArray.unshift(defaultImage);
      }
    }

    // Add in the ending images if it exist
    for (let i = 0; i < this.props.defaultImages.length; ++i) {
      const defaultImage = this.props.defaultImages[i];

      if (defaultImage.default_image_type_id === 2) {
        imageArray.push(defaultImage);
      }
    }

    // Loading the images first
    let imageArrayLength = imageArray.length;
    let bufferLength = this.imagesToBuffer(imageArrayLength);

    // Buffer initial set of images before play
    let promises = [];
    let batch = [];
    var that = this;
    for (this.nextImageToBuffer; this.nextImageToBuffer < bufferLength; ++this.nextImageToBuffer) {
      batch.push(this.nextImageToBuffer);

      promises.push(
        this.addImageProcess(
          makePlayerImageUrl(imageArray[this.nextImageToBuffer].media_url)
        ).catch(e => {
          console.error(e.message);
        })
      );

      if (batch.length === BUFFER_BATCH_SIZE || this.nextImageToBuffer === (bufferLength - 1)) {
        await Promise.all(promises)
          .then(function (response) {
            for (var key in response) {
              that.setState({ progressBar: (batch[key] / (bufferLength - 1) * 100) });
              imageArray[batch[key]].image_element = response[key];
            }
          })
          .catch(e => console.log(`Error in promises ${e}`));

        promises.length = 0;
        batch.length = 0;
      }
    }

    // Add in the user name images...
    let canvas = document.getElementById("ppCanvas");
    if (canvas) {
      let dataURL = canvas.toDataURL("image/jpeg");
      let img = document.createElement("img");
      img.src = dataURL;
      let userNameImg = {
        media_url: dataURL,
        name: "userName.jpeg",
        auto_generated: true,
        image_element: img
      };

      if (imageArrayLength < 21) {
        // Add user image in the middle
        let middle = Math.floor(imageArrayLength * 0.5);
        imageArray.splice(middle, 0, userNameImg);
      } else if (imageArrayLength < 101) {
        // Add user image every 20 images
        let i = 20
        while (i < imageArrayLength) {
          imageArray.splice(i, 0, userNameImg);
          i += 20;
        }
      } else {
        // Add user image every 50 images
        let i = 50
        while (i < imageArrayLength) {
          imageArray.splice(i, 0, userNameImg);
          i += 50;
        }
      }
    }

    this.setupTimerIntervalSet(imageArray.length);

    let label = this.state.timerIntervalSet[this.state.timerIntervalIdx].label;
    this.setState({
      action: START_PLAYER,
      imageData: imageArray[0],
      imageDataArray: imageArray,
      speedLabel: label
    });

    let imgContainer = document.getElementById("mainImagePlayerImage");
    let modalImgContainer = document.getElementById("modalImagePlayerImage");
    let children = imgContainer.getElementsByTagName("img");
    let childrenModal = modalImgContainer.getElementsByTagName("img");

    if (imgContainer) {
      for (let i = 0; i < children.length; ++i) {
        let child = children[i];
        let childModal = childrenModal[i];

        if (child.hasAttribute("img-idx")) {
          let value = parseInt(child.getAttribute("img-idx"));
          if (value < imageArray.length) {
            // Update previous elements!
            if (imageArray[value].auto_generated) {
              if (!imageArray[value].image_element) {
                child.src = imageArray[value].media_url;
                childModal.src = imageArray[value].media_url;
              } else {
                imageArray[value].image_element.setAttribute(
                  "img-idx",
                  child.getAttribute("img-idx")
                );
                let disable = child.classList.contains("pp-img-disable");

                if (disable) {
                  imageArray[value].image_element.classList.add(
                    "pp-img-disable"
                  );
                }

                child.parentNode.replaceChild(
                  imageArray[value].image_element,
                  child
                );

                childModal.parentNode.replaceChild(
                  imageArray[value].image_element.cloneNode(true),
                  childModal
                );
              }
            } else {
              if (!imageArray[value].image_element) {
                child.src = makePlayerImageUrl(imageArray[value].media_url);
                childModal.src = makePlayerImageUrl(imageArray[value].media_url);
              } else {
                imageArray[value].image_element.setAttribute(
                  "img-idx",
                  child.getAttribute("img-idx")
                );
                let disable = child.classList.contains("pp-img-disable");

                if (disable) {
                  imageArray[value].image_element.classList.add(
                    "pp-img-disable"
                  );
                }

                child.parentNode.replaceChild(
                  imageArray[value].image_element,
                  child
                );

                childModal.parentNode.replaceChild(
                  imageArray[value].image_element.cloneNode(true),
                  childModal
                );
              }
            }
          }
        }
      }
    }
  }

  // TODO: Use the carousel way instead
  async nextProperty(event) {
    const imageArray = this.state.imageDataArray;
    if (!this.state.imageData) {
      // STOP ANIMATION HERE...
      this.state.action = END_PLAYER;
      this.setState({
        imageData: imageArray[0],
        action: END_PLAYER
      });
      this.stopAnimation();
      this.state.completedSession = true;

      if (this.state.userWatchHistory) {
        this.state.submittedEndTime = true;

        let history = await this.props.endUserWatchSession(
          this.props.auth,
          this.props.match.params._id,
          this.state.userWatchHistory,
          true
        );

        if (history.earnCredits) {
//          alert(
//            `Congratulations! You earn a bonus of ${history.earnCredits} credits for this session!`
//          );

          if (this.state.token) {
            await this.props.fetchUser(this.state.token);
          }
        }
      }
      this.openAfterSurveyModal();

      return;
    } else {
      let newIndex = 0;
      if (this.state.index < imageArray.length - 1) {
        if (
          this.state.action !== FASTER_PLAYER &&
          this.state.action !== SLOWER_PLAYER
        ) {
          // Going to show next image so continue buffer
          this.bufferNextImage();

          newIndex = this.state.index + 1;

          let imgContainer = document.getElementById("mainImagePlayerImage");

          let modalImgContainer = document.getElementById(
            "modalImagePlayerImage"
          );

          let ppIdx = 0;
          if (newIndex > 0) {
            ppIdx = newIndex % 5;
          }

          let children = imgContainer.getElementsByTagName("img");
          let childrenModal = modalImgContainer.getElementsByTagName("img");

          for (let i = 0; i < children.length; ++i) {
            let child = children[i];
            let childModal = childrenModal[i];

            child.classList.add("pp-img-disable");
            childModal.classList.add("pp-img-disable");

            if (child.hasAttribute("img-idx")) {
              let nextIdx = this.state.index + 5;
              let value = parseInt(child.getAttribute("img-idx"));

              let previous = ppIdx - 1;

              if (previous === -1)
                previous = 4;

              if (value === previous && nextIdx < this.state.imageDataArray.length) {
                if (this.state.imageDataArray[nextIdx].auto_generated) {
                  if (!this.state.imageDataArray[nextIdx].image_element) {
                    child.src = this.state.imageDataArray[nextIdx].media_url;
                    childModal.src = this.state.imageDataArray[
                      nextIdx
                    ].media_url;
                  } else {
                    this.state.imageDataArray[
                      nextIdx
                    ].image_element.setAttribute(
                      "img-idx",
                      child.getAttribute("img-idx")
                    );

                    let disable = child.classList.contains("pp-img-disable");
                    if (disable) {
                      this.state.imageDataArray[
                        nextIdx
                      ].image_element.classList.add("pp-img-disable");
                    }

                    child.parentNode.replaceChild(
                      this.state.imageDataArray[nextIdx].image_element,
                      child
                    );

                    childModal.parentNode.replaceChild(
                      this.state.imageDataArray[
                        nextIdx
                      ].image_element.cloneNode(true),
                      childModal
                    );
                  }
                } else {
                  if (!this.state.imageDataArray[nextIdx].image_element) {
                    // Update previous elements!
                    child.src = makePlayerImageUrl(this.state.imageDataArray[nextIdx].media_url);
                    childModal.src = makePlayerImageUrl(this.state.imageDataArray[nextIdx].media_url);

                  } else {
                    this.state.imageDataArray[
                      nextIdx
                    ].image_element.setAttribute(
                      "img-idx",
                      child.getAttribute("img-idx")
                    );

                    let disable = child.classList.contains("pp-img-disable");
                    if (disable) {
                      this.state.imageDataArray[
                        nextIdx
                      ].image_element.classList.add("pp-img-disable");
                    }

                    child.parentNode.replaceChild(
                      this.state.imageDataArray[nextIdx].image_element,
                      child
                    );

                    childModal.parentNode.replaceChild(
                      this.state.imageDataArray[
                        nextIdx
                      ].image_element.cloneNode(true),
                      childModal
                    );
                  }
                }
              }

              if (value === ppIdx) {
                child.classList.remove("pp-img-disable");
                childModal.classList.remove("pp-img-disable");
              }
            }
          }
        } else {
          newIndex = this.state.index;
          this.state.action = PAUSE_PLAYER;
        }
      } else {
        // STOP ANIMATION HERE...
        this.state.action = END_PLAYER;
        this.setState({
          imageData: imageArray[0],
          action: END_PLAYER
        });
        this.stopAnimation();
        this.state.completedSession = true;

        if (this.state.userWatchHistory) {
          this.state.submittedEndTime = true;
          let history = await this.props.endUserWatchSession(
            this.props.auth,
            this.props.match.params._id,
            this.state.userWatchHistory,
            true
          );

          if (history.earnCredits) {
//            alert(
//              `Congratulations! You earn a bonus of ${history.earnCredits} credits for this!`
//            );

            if (this.state.token) {
              await this.props.fetchUser(this.state.token);
            }
          }
        }
        this.openAfterSurveyModal();

        return;
      }

      this.setState({ imageData: imageArray[newIndex], index: newIndex });
    }
  }

  stopAnimation() {
    if (this.state.action !== END_PLAYER) {
      this.state.action = PAUSE_PLAYER;
    }

    // NOTE: Funkie way of clearing the timer for now
    // TODO: Remove this after adding in a structure to keep track of timer creation.
    for (let i = 0; i < 1000; i++) {
      clearInterval(i);
    }

    if (this.state.timer !== 0) {
      clearInterval(this.state.timer);
      this.state.timer = 0;
    }

    let audioPlayer = document.getElementById("audioPlayer");
    if (audioPlayer && !audioPlayer.paused) {
      audioPlayer.pause();
    }

    let playBtns = document.querySelectorAll(".fa-play");
    playBtns.forEach(function (element) {
      element.classList.remove("hidden");
    });

    let pauseBtns = document.querySelectorAll(".fa-pause");
    pauseBtns.forEach(function (element) {
      element.classList.add("hidden");
    });
  }

  startAnimation() {
    // Don't start player if image array isn't set
    if (this.props.defaultImages) {
      if (this.state.imageDataArray === null)
        return;

      document.querySelector(".next-click").click();
      this.state.action = START_PLAYER;
      let l_timerInterval = this.state.timerInterval;
      this.state.timer = setInterval(function () {
        document.querySelector(".next-click").click();
      }, l_timerInterval);

      let audioPlayer = document.getElementById("audioPlayer");
      if (
        audioPlayer &&
        audioPlayer.paused &&
        this.state.audioAction === AUDIO_PLAY
      ) {
        const playPromise = audioPlayer.play();
        if (playPromise !== null) {
          playPromise.catch(error => {
            audioPlayer.play();
          });
        }
      }

      let playBtns = document.querySelectorAll(".fa-play");
      playBtns.forEach(function (element) {
        element.classList.add("hidden");
      });

      let pauseBtns = document.querySelectorAll(".fa-pause");
      pauseBtns.forEach(function (element) {
        element.classList.remove("hidden");
      });
    }
    // Don't start player if image array isn't set
  }

  slowerAnimation() {
    if (this.state.action === END_PLAYER) {
      this.stopAnimation();
      return;
    }

    if (this.state.timerIntervalIdx < this.state.timerIntervalSet.length - 1) {
      this.state.timerIntervalIdx++;
      this.state.timerInterval = this.state.timerIntervalSet[
        this.state.timerIntervalIdx
      ].interval;

      let label = this.state.timerIntervalSet[this.state.timerIntervalIdx]
        .label;
      this.setState({ speedLabel: label });
    }
    this.toggleDisabled();

    if (this.state.action === START_PLAYER) {
      this.state.action = SLOWER_PLAYER;
      this.stopAnimation();
      this.startAnimation();
    }
  }

  fasterAnimation() {
    if (this.state.action === END_PLAYER) {
      this.stopAnimation();
      return;
    }

    if (this.state.timerIntervalIdx !== 0) {
      this.state.timerIntervalIdx--;
      this.state.timerInterval = this.state.timerIntervalSet[
        this.state.timerIntervalIdx
      ].interval;

      let label = this.state.timerIntervalSet[this.state.timerIntervalIdx]
        .label;
      this.setState({
        speedLabel: label
      });
    }

    this.toggleDisabled();

    if (this.state.action === START_PLAYER) {
      this.state.action = FASTER_PLAYER;
      this.stopAnimation();
      this.startAnimation();
    }
  }

  audioChange() {
    let volumeUpBtns = document.querySelectorAll(".fa-volume-up");
    let volumeMuteBtns = document.querySelectorAll(".fa-volume-mute");
    let audioPlayer = document.getElementById("audioPlayer");

    if (volumeUpBtns[0].classList.contains("hidden")) {
      this.setState({ audioAction: AUDIO_PLAY });
      volumeUpBtns.forEach(function (element) {
        element.classList.remove("hidden");
      });

      volumeMuteBtns.forEach(function (element) {
        element.classList.add("hidden");
      });

      if (
        audioPlayer &&
        audioPlayer.paused &&
        this.state.action === START_PLAYER
      ) {
        const playPromise = audioPlayer.play();
        if (playPromise !== null) {
          playPromise.catch(error => {
            audioPlayer.play();
          });
        }
      }
    } else {
      this.setState({ audioAction: AUDIO_PAUSE });
      volumeUpBtns.forEach(function (element) {
        element.classList.add("hidden");
      });

      volumeMuteBtns.forEach(function (element) {
        element.classList.remove("hidden");
      });

      if (audioPlayer && !audioPlayer.paused) {
        audioPlayer.pause();
      }
    }
  }

  openModalImage() {
    if (this.state.timer !== 0) {
      let audioPlayer = document.getElementById("audioPlayer");
      if (
        audioPlayer &&
        audioPlayer.paused &&
        this.state.audioAction === AUDIO_PLAY
      ) {
        const playPromise = audioPlayer.play();
        if (playPromise !== null) {
          playPromise.catch(error => {
            audioPlayer.play();
          });
        }
      }
    }

    let modalImgPlayer = document.getElementById("modalImagePlayer");
    modalImgPlayer.style.display = "block";
    let mainImgPlayer = document.getElementById("mainImagePlayer");
    mainImgPlayer.style.display = "none";
  }

  closeModalImage() {
    let modalImgPlayer = document.getElementById("modalImagePlayer");
    modalImgPlayer.style.display = "none";
    let mainImgPlayer = document.getElementById("mainImagePlayer");
    mainImgPlayer.style.display = "block";
  }

  toggleBtn() {
    if (
      this.state.action === PAUSE_PLAYER ||
      this.state.action === END_PLAYER
    ) {
      this.startAnimation();
    } else {
      this.stopAnimation();
    }
  }

  toggleDisabled() {
    const downBtns = document.querySelectorAll(".fa-angle-double-down");
    if (
      this.state.timerIntervalIdx ===
      this.state.timerIntervalSet.length - 1
    ) {
      downBtns.forEach(function (element) {
        element.classList.add("disabled");
      });
    } else {
      downBtns.forEach(function (element) {
        element.classList.remove("disabled");
      });
    }

    const upBtns = document.querySelectorAll(".fa-angle-double-up");
    if (this.state.timerIntervalIdx === 0) {
      upBtns.forEach(function (element) {
        element.classList.add("disabled");
      });
    } else {
      upBtns.forEach(function (element) {
        element.classList.remove("disabled");
      });
    }
  }

  printRemainingTime() {
    const {
      timerIntervalSet,
      timerIntervalIdx,
      imageDataArray,
      index
    } = this.state;

    if (
      !timerIntervalSet ||
      timerIntervalSet.length <= 0 ||
      !imageDataArray ||
      imageDataArray.length <= 0
    ) {
      return;
    }

    let count = imageDataArray.length - 1 - index;
    let timeRemainMS = count * timerIntervalSet[timerIntervalIdx].interval;

    return this.formatMS(timeRemainMS);
  }

  formatMS(ms) {
    // 1- Convert to seconds:
    let seconds = ms / 1000;
    // 2- Extract hours:
    let hours = parseInt(seconds / 3600); // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    let minutes = parseInt(seconds / 60); // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;

    return (
      this.formatNumber(hours) +
      ":" +
      this.formatNumber(minutes) +
      ":" +
      this.formatNumber(Math.ceil(seconds))
    );
  }

  formatNumber(num) {
    let format = num;
    if (num < 10) {
      format = `0${num}`;
    }

    return format;
  }

  /* Begin Survey Helper logic */ // -------------------------------------------------------------------------------------------------------

  openBeforeSurveyModal() {
    const modal = document.getElementById("beforeModal");
    modal.style.display = "block";
    this.clearSurveyActiveState();
    this.setState({ beforeSurveyClosed: false });
  }

  openAfterSurveyModal() {
    const modal = document.getElementById("afterModal");
    modal.style.display = "block";
    this.clearSurveyActiveState();
  }

  onClickAfterSurvey(e) {
    e.preventDefault();
    this.openAfterSurveyModal();
  }

  async onBeforeModalClose(e) {
    e.preventDefault();
    let survey = await this.props.surveyBeforeSession(
      this.props.auth.id,
      this.props.match.params._id,
      this.state.currentEmotion
    );
    const modal = document.getElementById("beforeModal");
    modal.style.display = "none";

    // reset the currentEmotion to skip
    this.state.currentEmotion = "skip";

    // Don't use startImagePlayer(survey). Just send survey details and only start if images are ready

    // Send to server starting watch session
    let userWatchHistory = await this.props.startUserWatchSession(
      this.props.auth,
      this.props.match.params._id,
      survey
    );

    if (userWatchHistory)
      this.setState({ userWatchHistory: userWatchHistory });

    // Only if the player is loaded and is in paused state then start playing
    if (this.state.action == PAUSE_PLAYER && this.state.imageDataArray !== null)
      this.startAnimation();

    this.setState({ beforeSurveyClosed: true });
  }

  async onAfterModalClose(e) {
    e.preventDefault();

    if (this.props.sessionSurvey) {
      await this.props.surveyAfterSession(
        this.props.auth.id,
        this.props.match.params._id,
        this.props.sessionSurvey.id,
        this.state.currentEmotion
      );
      const modal = document.getElementById("afterModal");
      modal.style.display = "none";

      this.state.currentEmotion = "skip";
    }

    if (this.unblock) {
      this.unblock();
      this.props.history.push(this.targetLocation);
    }

    if (this.state.completedSession === true) {
      this.props.history.push("/dashboard");
    }
  }

  clearSurveyActiveState() {
    const collection = document.getElementsByClassName("ppEmojiButton");
    for (let i = 0; i < collection.length; ++i) {
      const element = collection[i];

      if (element.classList.contains("ppEmoActive")) {
        element.classList.remove("ppEmoActive");
      }
    }
  }

  onSelectEmotion(e) {
    e.preventDefault();
    let mainTarget = e.target;

    let value = e.target.getAttribute("name");
    if (e.target.nodeName === "path") {
      value = e.target.parentElement.parentElement.parentElement.getAttribute(
        "name"
      );

      mainTarget = e.target.parentElement.parentElement.parentElement;
    }

    if (e.target.nodeName === "svg") {
      value = e.target.parentElement.parentElement.getAttribute("name");
      mainTarget = e.target.parentElement.parentElement;
    }

    if (e.target.nodeName === "p") {
      value = e.target.parentElement.getAttribute("name");
      mainTarget = e.target.parentElement;
    }

    this.state.currentEmotion = value;

    this.clearSurveyActiveState();
    mainTarget.classList.add("ppEmoActive");
  }

  onBeforeSurveyContinue(e) {
    // Send data to server
    e.preventDefault();

    this.onBeforeModalClose(e);
  }

  onAfterSurveyDone(e) {
    // Send data to server
    e.preventDefault();

    this.onAfterModalClose(e);
  }
  /* End Survey Helper logic */

  onOpenNav(e) {
    document.getElementById("sidebar").classList.add("sidebarOpen");
  }

  /* Begin Name Template */
  calculateY(text, maxWidth, lineHeight) {
    let numOfCharacters = maxWidth / lineHeight; // number of character on 1 line
    numOfCharacters *= 2;
    return (text.length / numOfCharacters) * lineHeight;
  }

  wrapText(context, text, x, y, maxWidth, lineHeight) {
    let words = text.split(" "),
      line = "",
      lineCount = 0,
      i,
      test,
      metrics;

    for (i = 0; i < words.length; i++) {
      test = words[i];
      metrics = context.measureText(test);
      while (metrics.width > maxWidth) {
        // Determine how much of the word will fit
        test = test.substring(0, test.length - 1);
        metrics = context.measureText(test);
      }
      if (words[i] != test) {
        words.splice(i + 1, 0, words[i].substr(test.length));
        words[i] = test;
      }

      test = line + words[i] + " ";
      metrics = context.measureText(test);

      if (metrics.width > maxWidth && i > 0) {
        context.fillText(line, x, y);
        line = words[i] + " ";
        y += lineHeight;
        lineCount++;
      } else {
        line = test;
      }
    }

    context.fillText(line, x, y);
  }

  onTemplate1Load(e) {
    e.preventDefault();
    this.state.template1Loaded = true;

    let fullName = this.props.auth.firstName + " " + this.props.auth.lastName;

    let canvas = document.getElementById("ppCanvas");
    let context = canvas.getContext("2d");

    canvas.width = e.target.naturalWidth;
    canvas.height = e.target.naturalHeight;

    let x = canvas.width * 0.5;

    let endY = this.calculateY(fullName, canvas.width - 30, 150);
    let y = canvas.height * 0.5 - endY * 0.5;

    context.drawImage(e.target, 0, 0, canvas.width, canvas.height);

    context.font = "bolder 60pt Helvetica Neue";

    context.fillStyle = "#3D5478";
    context.textAlign = "center";

    this.wrapText(context, fullName, x, y, canvas.width - 30, 150);
  }

  renderTemplateImages() {
    if (this.state.template1Loaded) {
      return;
    }
    const fullpath = makeLegacyCDNUrl("/b/default/sessions/background_3_default.jpeg?t=" + new Date().getTime());
    return (
      <img
        crossOrigin="anonymous"
        id="pp-img-template-1"
        src={fullpath}
        style={{ display: "none" }}
        className="pp-stmt-img-card"
        onLoad={this.onTemplate1Load.bind(this)}
      />
    );
  }

  /* End Name Template */

  render() {
    if (!this.props.auth || !this.props.mediaSession) {
      return <div />;
    }
    let session_id = parseInt(this.props.match.params._id);

    // Don't render the rest of the page if the session_id is not a number.
    if (isNaN(session_id) || !this.props.mediaSession) {
      return <div />;
    }

    return (
      <div className="mainWidth">
        <div className="d-flex">
          <button
            className="btn bg-transparent d-md-none"
            onClick={this.onOpenNav.bind(this)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <p className="text-secondary m-0 px-0 py-3 px-md-3">Image Player</p>
        </div>
        <hr className="m-0" />
        <div id="mainImagePlayer" className="image-player">
          <div className="image-title">
            {this.state.userWatchHistory && this.state.userWatchHistory.title
              ? this.state.userWatchHistory.title
              : ""}
          </div>
          <div>
            <div className="next-click" onClick={this.nextProperty} />
            <div id="ppAfterSurvey" onClick={this.onClickAfterSurvey} />
          </div>

          <canvas id="ppCanvas" style={{ display: "none" }} />
          <div>{this.renderTemplateImages()}</div>

          <div className="d-flex justify-content-center">
            <div className="bg-white p-2 shadow">
              <div className="image-container position-relative">
                <ImageDetail
                  imageData={this.state.imageData}
                  imageIndex={this.state.originalIdxArray[this.state.index] + 1}
                  progressBar={this.state.progressBar}
                />
                <div
                  id="imagePlayerControls"
                  className="player-control d-flex align-items-center p-2"
                >
                  <span
                    id="playBtn"
                    className="md-font p-2"
                    onClick={this.toggleBtn}
                  >
                    <FontAwesomeIcon
                      icon={faPlay}
                      className="hidden"
                      color="white"
                    />
                    <FontAwesomeIcon icon={faPause} color="white" />
                  </span>

                  <div
                    id="slowBtn"
                    className="d-flex align-items-center sm-font p-2"
                    onClick={this.slowerAnimation}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Slower"
                  >
                    <p className="sm-font text-white m-0">slower</p>
                    <FontAwesomeIcon
                      icon={faAngleDoubleDown}
                      className="ml-2"
                      color="white"
                    />
                  </div>

                  <div
                    id="fastBtn"
                    className="d-flex align-items-center sm-font p-2"
                    onClick={this.fasterAnimation}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Faster"
                  >
                    <p className="sm-font text-white m-0">faster</p>
                    <FontAwesomeIcon
                      icon={faAngleDoubleUp}
                      className="ml-2"
                      color="white"
                    />
                  </div>

                  <span
                    id="speedLabel"
                    className="sm-font p-2"
                    style={{ fontWeight: "bold" }}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Speed of Animation"
                  >
                    <div className="white-text">{this.state.speedLabel}</div>
                  </span>

                  <span
                    id="timeRemainLabel"
                    className="ml-auto sm-font p-2"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Time Remaining"
                  >
                    <div className="white-text">
                      {this.printRemainingTime()}
                    </div>
                  </span>

                  <span
                    id="audioBtn"
                    className="ml-auto md-font p-2"
                    onClick={this.audioChange}
                  >
                    <FontAwesomeIcon
                      icon={faVolumeMute}
                      className="hidden"
                      color="white"
                    />
                    <FontAwesomeIcon icon={faVolumeUp} color="white" />
                  </span>

                  <span
                    id="expandBtn"
                    className="md-font p-2"
                    onClick={this.openModalImage}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Expand Player"
                  >
                    <FontAwesomeIcon icon={faExpand} color="white" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalImagePlayer">
          <div id="modalImagePlayerContent">
            <ModalImageDetail
              imageData={this.state.imageData}
              imageIndex={this.state.originalIdxArray[this.state.index] + 1}
            />

            <div
              id="modalImagePlayerControls"
              className="modal-player-control d-flex align-items-center p-2"
            >
              <span
                id="modalPlayBtn"
                className="md-font p-2"
                onClick={this.toggleBtn}
              >
                <FontAwesomeIcon icon={faPlay} className="hidden" color="white" />
                <FontAwesomeIcon icon={faPause} color="white" />
              </span>

              <span
                id="modalSlowBtn"
                className="sm-font d-flex align-items-center p-2"
                onClick={this.slowerAnimation}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Slower"
              >
                <p className="sm-font text-white m-0">slower</p>
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faAngleDoubleDown}
                  color="white"
                />
              </span>

              <span
                id="modalFastBtn"
                className="sm-font d-flex align-items-center p-2"
                onClick={this.fasterAnimation}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Faster"
              >
                <p className="sm-font text-white m-0">faster</p>
                <FontAwesomeIcon
                  className="ml-2"
                  icon={faAngleDoubleUp}
                  color="white"
                />
              </span>

              <span
                id="speedLabel"
                className="sm-font p-2"
                style={{ fontWeight: "bold" }}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Speed of Animation"
              >
                <div className="white-text">{this.state.speedLabel}</div>
              </span>

              <span
                id="timeRemainLabel"
                className="sm-font ml-auto"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Time Remaining"
              >
                <div className="white-text">{this.printRemainingTime()}</div>
              </span>

              <span
                id="modalAudioBtn"
                className="md-font ml-auto p-2"
                onClick={this.audioChange}
              >
                <FontAwesomeIcon
                  icon={faVolumeMute}
                  className="hidden"
                  color="white"
                />
                <FontAwesomeIcon icon={faVolumeUp} color="white" />
              </span>

              <span
                id="compressBtn"
                className="md-font p-2"
                onClick={this.closeModalImage}
                data-toggle="tooltip"
                data-placement="bottom"
                title="Exit Fullscreen"
              >
                {" "}
                <FontAwesomeIcon icon={faCompress} color="white" />
              </span>
            </div>
          </div>
          <span className="modal-close cursor" onClick={this.closeModalImage}>
            &times;
          </span>
        </div>

        <div id="audioContent">
          <audio id="audioPlayer" loop preload="auto" autoPlay={false}>

            <p>If you're reading this, audio isn't supported.</p>
          </audio>
        </div>


        <SurveyBefore
          onBeforeModalClose={this.onBeforeModalClose.bind(this)}
          onSelect={this.onSelectEmotion.bind(this)}
          onContinue={this.onBeforeSurveyContinue.bind(this)}
          onSkip={this.onBeforeModalClose.bind(this)}
        />

        <SurveyAfter
          onAfterModalClose={this.onAfterModalClose.bind(this)}
          onSelect={this.onSelectEmotion.bind(this)}
          onDone={this.onAfterSurveyDone.bind(this)}
          onSkip={this.onAfterModalClose.bind(this)}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchUser,
      fetchImages,
      fetchDefaultImages,
      fetchUserSession,
      fetchImageSessionById,
      resetMediaSessions,
      startUserWatchSession,
      endUserWatchSession,
      surveyBeforeSession,
      surveyAfterSession,
      fetchSessionDetailById
    },
    dispatch
  );
}

function mapStateToProps({
  auth,
  images,
  authFirebase,
  userSelectedCurrentSession,
  mediaSession,
  userCurrentWatchSession,
  sessionSurvey,
  defaultImages
}) {
  return {
    auth,
    images,
    authFirebase,
    userSelectedCurrentSession,
    mediaSession,
    userCurrentWatchSession,
    sessionSurvey,
    defaultImages
  }; // ES6 - identical to { images: images }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ImagePlayer));

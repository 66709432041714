import React from 'react';
import './survey.css';
import {faAngry, faFrown, faLaugh, faMeh} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFrownOpen, faSmile} from "@fortawesome/free-solid-svg-icons";

const SurveyBefore = ({ onBeforeModalClose, onSelect, onContinue, onSkip }) => {
   return (
      <div id="beforeModal" className="surveyModal">
         <div className="d-flex align-items-center justify-content-center h-100">
            <div className="surveyModalContent pp-animate-bottom bg-white p-4 shadow">
               <p
                  className="modalClose text-right m-0"
                  onClick={onBeforeModalClose}
               >
                  &times;
               </p>
               <div className="py-4">
                  <p className="h4 m-0 px-2 text-dark">How are you feeling now?</p>
                  <p className="fontRegular text-secondary m-0 px-2">
                     Recording your emotions at the...
                  </p>
               </div>
               <div className="row m-0">
                  <div className="col-4 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded border"
                        name="angry"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faAngry} className="ppEmoji ppRed" />
                        </p>
                     </div>
                     <p className="fontSmall fontBold text-center m-0 text-dark">Angry</p>
                  </div>
                  <div className="col-4 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded border"
                        name="meh"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faMeh} className="ppEmoji ppGray" />
                        </p>
                     </div>
                     <p className="fontSmall fontBold text-center m-0 text-dark">Neutral</p>
                  </div>
                  <div className="col-4 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded border"
                        name="happy"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faSmile} className="ppEmoji ppGreen" />
                        </p>
                     </div>
                     <p className="fontSmall fontBold text-center m-0 text-dark">Happy</p>
                  </div>
               </div>
               <div className="row m-0 pb-4">
                  <div className="col-4 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded border"
                        name="powerful"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faLaugh} className="ppEmoji ppPink" />
                        </p>
                     </div>
                     <p className="fontSmall fontBold text-center m-0 text-dark">
                        Powerful
                     </p>
                  </div>
                  <div className="col-4 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded border"
                        name="sad"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faFrown} className="ppEmoji ppBlue" />
                        </p>
                     </div>
                     <p className="fontSmall fontBold text-center m-0 text-dark">Sad</p>
                  </div>
                  <div className="col-4 p-2">
                     <div
                        className="p-2 ppEmojiButton rounded border"
                        name="anxious"
                        onClick={onSelect}
                     >
                        <p className="m-0">
                           <FontAwesomeIcon icon={faFrownOpen} className="ppEmoji ppOrange" />
                        </p>
                     </div>
                     <p className="fontSmall fontBold text-center m-0 text-dark">
                        Anxious
                     </p>
                  </div>
               </div>
               <div className="pb-4" align="center">
                  <button className="btn btn-primary" onClick={onContinue}>
                     <p>Let's see if we can improve that!</p>
					 <p className="mb-0">Let's get your Positive Prime on the way</p>
                  </button>
               </div>
               <div className="pb-4" align="center">
                  <a href="#" onClick={onSkip}>
                     Skip
                  </a>
               </div>
               {/* <center>
                        <button
                           id="addBtn"
                           className="btn btn-primary"
                           style={{ margin: '10px 0px' }}
                           type="submit"
                        >
                           Ok
                        </button>
                     </center> */}
            </div>
         </div>
      </div>
   );
};

export default SurveyBefore;

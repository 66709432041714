import React, { useEffect, useState } from "react";
import { Config, API, APIAxios } from "../../APIAndConfig";
import clsx from "clsx";
import SessionCover from "../../components/cards/SessionCover";
import { useParams } from "react-router-dom";
import { ROUTES } from "../../Routers/DashboardRouter";
import { CATEGORY_ROUTES } from "../Category";
import SessionCard from "../../components/cards/SessionCard";
import axios from "axios";
import { Firebase } from "../../firebase";
import {
  makeAssetsUrl,
  makeLegacyCDNUrl,
  makePlayerImageUrl,
  makeProfileImageUrl,
} from "../../APIAndConfig";
import { Media_URL } from "../../APIAndConfig.ts";
import PersonalizeSessions from "../../components/Modals/PersonalizeSession";
import * as actions from "../../actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { set } from "lodash";

function Session(props) {
  const [personalizedSessionModal, setPersonalizedSessionModal] =
    useState(false);
  const dispatch = useDispatch();
  const sessionId = useParams().sessionId;
  const categoryId = useParams().categoryId;
  const onClickPlayModal = () => {
    props.history.replace(
      ROUTES.CATEGORY +
        "/" +
        categoryId +
        CATEGORY_ROUTES.PLAY +
        "/" +
        sessionId
    );
  };
  const onClickPlaySessionCover = () => {
    props.history.push(
      ROUTES.CATEGORY +
        "/" +
        categoryId +
        CATEGORY_ROUTES.PLAY +
        "/" +
        sessionId
    );
  };

  const onClickPersonalize = () => {
    // setPersonalizedSessionModal(true);
    console.log();
    if (props.auth.role.id === 2) {
      props.history.push("/upgradeAccount");
    } else {
      props.history.push("/session/edit/" + sessionId);
    }
  };

  const onCloseSessionModal = () => {
    setPersonalizedSessionModal(false);
  };
  const onClickSessionCard = (session) => {
    let category = session.category ? session.category : "private";
    props.history.push(
      ROUTES.CATEGORY +
        "/" +
        category +
        CATEGORY_ROUTES.SESSION +
        "/" +
        session.id
    );
    document
      .getElementById("header-session")
      .scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const [product, setProduct] = useState({});
  const [sessions, setSessions] = useState([]);

  const [author_name, setAuthor_name] = useState();
  const [author_desc, setAuthor_desc] = useState();
  const [profileImg, setProfileImg] = useState();
  const [short_desc, setShort_desc] = useState();
  const [bgImg, setBgImg] = useState();
  const networkStatus = useSelector(state => state.networkStatus)

  const fetchProduct = async () => {
    const token = await Firebase.Auth.currentUser.getIdToken();

    const header = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    await axios
      .get(`${Config.APIUrl}/session/${sessionId}`, { headers: header })
      .then((res) => {
        let result = res.data;
        setAuthor_name(
          result.data.author
            ? result.data.author.firstName + " " + result.data.author.lastName
            : ""
        );
        setAuthor_desc(
          result.data.author ? result.data.author.description : ""
        );
        setProfileImg(
          result.data.author ? result.data.author.profileImageMediaPurpose ? result.data.author.profileImageMediaPurpose : result.data.author.profileImageUrl : ""
        );
        setShort_desc(
          result.data.descriptions
            ? result.data.descriptions.short_description
            : ""
        );
        setBgImg(
          result.data.coverphoto
            ? process.env.REACT_APP_CDN + "/" + result.data.coverphoto
            : result.data.images[1]
            ? Media_URL + "/" + result.data.images[1].media_url
            : "https://www.positiveprime.com/wp-content/uploads/2021/04/Positive-Prime-logowhite.png"
        );

        setProduct(result.data);
      })
      .catch(async (err) => {
        switch (err.response.data) {
          case "SESSION_EXPIRED":
            const token = await Firebase.Auth.currentUser?.getIdToken();
            if (token) {
              await set(
                APIAxios.defaults.headers,
                "authorization",
                `Bearer ${token}`
              );
              window.location.reload();
            }
            break;
          case "BAD_CONNECTION":
          case "UNCAUGHT_ERROR":
          case "DATABASE_LIMIT_EXCEEDED":
            localStorage.setItem("service_unavailable_status", "failed");
            await Firebase.Auth.signOut();
            window.location.href = "/error";
            break;
          default:
            console.log(err);
            break;
        }
      });
  };

  const randomSessions = async (arr) => {
    var n = 8;
    var result = [],
      len = arr.length,
      taken = [];
    if (n > len) n = len;
    while (n--) {
      var x = Math.floor(Math.random() * len);
      result[n] = arr[x in taken ? taken[x] : x];
      taken[x] = --len in taken ? taken[len] : len;
    }

    var flags = [],
      output = [],
      l = result.length,
      i;
    for (i = 0; i < l; i++) {
      if (flags[result[i].id]) continue;
      flags[result[i].id] = true;
      output.push(result[i]);
    }

    return output;

    // if (arr.length < 8) { n = arr.length }
    // var object = {}
    // var taken = []
    // while (Object.keys(object).length < n) {
    //     var x = Math.floor(Math.random() * arr.length);
    //     if (taken.includes(x)) continue
    //     if (Object.keys(object).includes(arr[x].id)) continue
    //     taken.push(x)
    //     object[arr[x].id] = arr[x]
    // }
    // return Object.values(object)
  };

  const fetchSessions = async () => {
    const token = await Firebase.Auth.currentUser.getIdToken();

    const header = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    try {
      const res = await axios.get(`${Config.APIUrl}/category/` + categoryId, {
        headers: header,
      });
      if (res.data.status === 200) {
        setSessions(await randomSessions(res.data.data));
      }
    } catch (err) {
      switch (err.response.data) {
        case "SESSION_EXPIRED":
          const token = await Firebase.Auth.currentUser?.getIdToken();
          if (token) {
            await set(
              APIAxios.defaults.headers,
              "authorization",
              `Bearer ${token}`
            );
            window.location.reload();
          }
          break;
        case "BAD_CONNECTION":
        case "UNCAUGHT_ERROR":
        case "DATABASE_LIMIT_EXCEEDED":
          localStorage.setItem("service_unavailable_status", "failed");
          await Firebase.Auth.signOut();
          window.location.href = "/error";
          break;
        default:
          console.log(err);
          break;
      }
    }
  };

  useEffect(() => {
    if (!networkStatus.connected) {
      return false
    }

    fetchProduct();
    if (
      categoryId === "private" ||
      categoryId === null ||
      categoryId === undefined
    ) {
      dispatch(actions.fetchPurchasesByUserId(props.auth.id));
      dispatch(actions.fetchUserSession(props.auth));
      dispatch(actions.fetchOwnerSessionForUser(props.auth));
    } else {
      fetchSessions();
    }
  }, [sessionId, categoryId]);

  const setPrivateSessions = async () => {
    if (
      props.purchasedSessions &&
      props.userSessions &&
      props.ownerSessionForUser
    ) {
      let arr = props.purchasedSessions
        .concat(props.userSessions)
        .concat(props.ownerSessionForUser);
      arr = await randomSessions(arr);
      setSessions(arr);
    }
  };
  useEffect(() => {
    if (
      props.purchasedSessions &&
      props.userSessions &&
      props.ownerSessionForUser
    ) {
      setPrivateSessions();
    }
  }, [props.purchasedSessions, props.userSessions, props.ownerSessionForUser]);

  return (
    <>
      <PersonalizeSessions
        onClickPlay={onClickPlayModal}
        show={personalizedSessionModal}
        onClose={onCloseSessionModal}
      />
      <div className={clsx("container", "wellness-session")}>
        <div className={"wellness-session__header"}>
          <h2
            id="header-session"
            className={clsx("wellness-session__header-title", "main-title")}
          >
            Session
          </h2>
        </div>
        <div className={clsx("container", "wellness-session__cover")}>
          <SessionCover
            title={product.name}
            desc={short_desc}
            img={bgImg}
            onClickPlay={onClickPlaySessionCover}
            onClickPersonalize={onClickPersonalize}
            id={sessionId}
          />
        </div>
        <div className={clsx("container", "wellness-session__author")}>
          <img
            src={profileImg ? profileImg : makeAssetsUrl("user_default.png")}
            alt={author_name}
            className={"wellness-session__author-img"}
          />
          <div className={clsx("wellness-session__author-text")}>
            <h4
              className={clsx(
                "wellness-session__author-title",
                "main-title",
                "main-title-3"
              )}
            >
              About {author_name}
            </h4>
            <p
              className={clsx("wellness-session__author-description", "p-text")}
            >
              {author_desc}
            </p>
          </div>
        </div>
        <div className={clsx("container", "wellness-session__moreLikeThese")}>
          <h2
            className={clsx(
              "wellness-session__moreLikeThese-title",
              "main-title"
            )}
          >
            More Like These
          </h2>
          <div className={clsx("wellness-session__moreLikeThese-cards", "row")}>
            {sessions.map((session, index) => {
              return (
                <div className={clsx("col-lg-3", "col-sm-12")} key={index}>
                  <SessionCard
                    onClick={() => onClickSessionCard(session)}
                    id={session.id}
                    title={session.name}
                    author={
                      session.author
                        ? session.author.fullName
                        : session.author_full_name
                    }
                    img={
                      session.thumbnail
                        ? process.env.REACT_APP_CDN + "/" + session.thumbnail
                        : session.placeholder_image
                        ? Media_URL + "/" + session.placeholder_image
                        : session.images &&
                          session.images[0] &&
                          session.images[0].media_url
                        ? Media_URL + "/" + session.images[0].media_url
                        : "https://www.positiveprime.com/wp-content/uploads/2021/04/Positive-Prime-logowhite.png"
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    purchasedSessions: state.purchasedSessions,
    userSessions: state.userSessions,
    ownerSessionForUser: state.ownerSessionForUser,
  };
}

export default connect(mapStateToProps, actions)(Session);

import React, {CSSProperties} from 'react';

// TODO: Refactor logic
//       1. Take in id for props since there maybe more than one spinner
//       2. Rename this file Spinner and css to spinner.
//       3. Custom spinner css class name will have pp-spinner as a name.
//             -> rename loader to pp-spinner-sm, pp-spinner-md, pp-spinner-lg, etc...

export interface LoaderProps {
   size?: "small" | "medium" | "large",
   id?: string,
   style?: CSSProperties
}
export class Loader extends React.PureComponent<LoaderProps> {
   getClassName() {
      switch (this.props.size) {
         case 'small':
         case 'medium':
            return 'loader-' + this.props.size;
         default:
            return 'loader';
      }
   }
   render() {
      return <div id={this.props.id} className={this.getClassName()} style={this.props.style}/>;
   }
}
export default Loader;

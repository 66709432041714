import { set } from "lodash";
import moment from "moment";
import React from "react";
import { Container, Spinner, Card, Row, Col, Form } from "react-bootstrap";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { APIActions } from "../../api/APIActions";
import { API } from "../../APIAndConfig";
import { FormInput } from "../FormInput";

interface stateinterface {
  tableData: any;
  paidLedgerData: any;
  userID: any;
  description: any;
  amount: any;
  userIdList: any;
  type: any;
  errAmount: any;
  errDesp: any;
  amountMessage: any;
  descriptionMessage: any;
  payoutData: any;
  searchInput: string;
  searchInput1: string;
  filteredData1: any;
  filteredData: any;
}

export class PayoutLedger extends React.Component<any, stateinterface> {
  constructor(props: any) {
    super(props);

    this.state = {
      tableData: null,
      paidLedgerData: null,
      userID: null,
      description: "",
      amount: null,
      errAmount: null,
      errDesp: null,
      userIdList: [],
      type: "Generic (default)",
      amountMessage: null,
      descriptionMessage: null,
      payoutData: null,
      searchInput: "",
      searchInput1: "",
      filteredData1: [],
      filteredData: [],
    };
  }

  async componentWillMount() {
    const ledgerRecord: any = await API.getLedgerPayoutData();
    this.setState({
      tableData: ledgerRecord.payload.data || [],
      userIdList: ledgerRecord.payload.userId || [],
      userID: ledgerRecord.payload.userId[0].id,
      paidLedgerData: ledgerRecord.payload.paidLedger || [],
    });
  }

  getColumnWidth(rows: any, accessor: any, headerText: any) {
    const maxWidth = 400;
    const magicSpacing = 10;
    const cellLength = Math.max(
      ...rows.map((row: any) => (`${row[accessor]}` || "").length),
      headerText.length
    );
    return Math.min(maxWidth, cellLength * magicSpacing);
  }

  handleChange(e: any) {
    let state = { ...this.state };

    if (e.target.name === "userid") {
      state.userID = e.target.value;
    } else if (e.target.name === "description") {
      state.description = e.target.value;
    } else if (e.target.name === "amount") {
      state.amount = e.target.value;
    } else if (e.target.name === "type") {
      state.type = e.target.value;
    }
    this.setState(state);
  }
  async setPayout(e: any) {
    this.setState({
      payoutData: e.original,
      userID: e.original.id,
      description: "",
      amount: e.original.amount,
    });
    //   e.preventDefault()
  }
  async viewTrans(e: any) {
    let queryString =
      "view_payout_user=" +
      e.original.id +
      "&payoutdate=" +
      moment(e.original.payoutdate).local().format("YYYY-DD-MM");
    this.props.history.push(`/ledger?${queryString}`);
    //   e.preventDefault()
  }
  async handleClick(e: any) {
    e.preventDefault();

    let num = new RegExp("[-]?d+(.)?(d+)?");

    if (this.state.amount !== null && this.state.amount.match(num) !== null) {
      this.setState({
        errAmount: false,
        amountMessage: "amount can only be number",
      });
    }

    if (
      this.state.userID !== null &&
      this.state.type != null &&
      this.state.amount !== null &&
      this.state.description !== null
    ) {
      await API.postLedgerPayoutData({
        id: this.state.userID,
        description: this.state.description,
        amount: this.state.amount,
        type: this.state.type,
        payoutdate: this.state.payoutData.payoutdate,
      })
        .then(async (res: any) => {
          if (res.payload.message === "Successfully saved") {
            this.setState({
              tableData: null,
              userIdList: [],
              userID: null,
              paidLedgerData: null,
            });
            const ledgerRecord: any = await API.getLedgerPayoutData();
            alert("Saved Successfully");

            this.setState({
              tableData: ledgerRecord.payload.data || [],
              userIdList: ledgerRecord.payload.userId || [],
              userID: ledgerRecord.payload.userId[0].id,
              paidLedgerData: ledgerRecord.payload.paidLedger || [],
            });
            let data = { ...this.state };
            data.userID = data.userIdList[0].id;
            data.type = "Generic (default)";
            data.description = null;
            data.amount = null;
            data.errAmount = null;
            data.errDesp = null;
            data.amountMessage = null;
            data.descriptionMessage = null;
            this.setState(data);
          }
        })
        .catch((err) => {});
    } else {
      if (this.state.amount === null) {
        this.setState({
          errAmount: false,
          amountMessage: "amount cannot be empty",
        });
      }

      if (this.state.description === null) {
        this.setState({
          errDesp: false,
          descriptionMessage: "description cannot be empty",
        });
      }
    }
  }
  searchChange1 = (event: any) => {
    this.setState({ searchInput1: event.target.value }, () => {
      this.globalSearch1();
    });
  };

  globalSearch1 = () => {
    let { searchInput1, tableData } = this.state;
    let filteredData1 = tableData.filter((value: any) => {
      return (
        value.id
          .toString()
          .toLowerCase()
          .includes(searchInput1.toLowerCase()) ||
        value.first_name.toLowerCase().includes(searchInput1.toLowerCase()) ||
        value.last_name.toLowerCase().includes(searchInput1.toLowerCase()) ||
        value.email.toLowerCase().includes(searchInput1.toLowerCase())
      );
    });
    this.setState({ filteredData1 });
  };

  searchChange = (event: any) => {
    this.setState({ searchInput: event.target.value }, () => {
      this.globalSearch();
    });
  };

  globalSearch = () => {
    let { searchInput, paidLedgerData } = this.state;
    let filteredData = paidLedgerData.filter((value: any) => {
      return (
        value.id.toString().toLowerCase().includes(searchInput.toLowerCase()) ||
        value.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
        value.email.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
    this.setState({ filteredData });
  };

  showLedger() {
    if (!this.state.tableData)
      return (
        <div className={"text-center"}>
          <Spinner
            animation={"grow"}
            variant={"primary"}
            style={{ width: "5em", height: "5em" }}
          />
        </div>
      );

    return (
      <div>
        <Card border="dark">
          <Card.Header className="text-white bg-dark font-weight-bold ">
            {" "}
            <div>
              <span>Pending Payouts</span>
              <input
                style={{ float: "right" }}
                name="searchInput"
                value={this.state.searchInput1 || ""}
                onChange={this.searchChange1}
                placeholder="Search"
              />
            </div>
          </Card.Header>
          <Card.Body className="p-0">
            <ReactTable
              data={
                this.state.filteredData1 && this.state.filteredData1.length
                  ? this.state.filteredData1
                  : this.state.tableData
              }
              columns={[
                {
                  Header: "User Id",
                  accessor: "id",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "First Name",
                  accessor: "first_name",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "Last Name",
                  accessor: "last_name",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                },
                {
                  id: "email",
                  Header: "Email",
                  accessor: "email",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                  width: this.getColumnWidth(
                    this.state.tableData,
                    "email",
                    "Email"
                  ),
                },
                {
                  id: "payoutdate",
                  Header: "Payout Date",
                  accessor: (d: any) => {
                    return moment(d.payoutdate).local().format("YYYY-DD-MM");
                  },
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "Payout",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                  Cell: (data: any) => (
                    <button
                      className="btn btn-primary btn-block p-auto m-auto"
                      onClick={() => this.setPayout(data)}
                    >
                      Payout
                    </button>
                  ),
                },
                {
                  Header: "Transaction",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                  Cell: (data: any) => (
                    <button
                      className="btn btn-primary btn-block p-auto m-auto"
                      onClick={() => this.viewTrans(data)}
                    >
                      View
                    </button>
                  ),
                },
              ]}
              defaultPageSize={10}
              noDataText="No Data Found"
              getTrProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    style: {
                      background: rowInfo.index % 2 === 0 ? "white" : "#f9f9f9",
                      color: rowInfo.index % 2 === 0 ? "#000000" : "#000000",
                    },
                  };
                } else {
                  return {
                    style: {
                      background: "white",
                      color: "black",
                    },
                  };
                }
              }}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
  paidLedger() {
    if (!this.state.paidLedgerData)
      return (
        <div className={"text-center"}>
          <Spinner
            animation={"grow"}
            variant={"primary"}
            style={{ width: "5em", height: "5em" }}
          />
        </div>
      );

    return (
      <div>
        <Card border="dark">
          <Card.Header className="text-white bg-dark font-weight-bold ">
            <div>
              <span>Payout Ledger</span>
              <input
                style={{ float: "right" }}
                name="searchInput"
                value={this.state.searchInput || ""}
                onChange={this.searchChange}
                placeholder="Search"
              />
            </div>
          </Card.Header>
          <Card.Body className="p-0">
            <ReactTable
              data={
                this.state.filteredData && this.state.filteredData.length
                  ? this.state.filteredData
                  : this.state.paidLedgerData
              }
              columns={[
                {
                  Header: "User Id",
                  accessor: "id",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "First Name",
                  accessor: "first_name",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "Last Name",
                  accessor: "last_name",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                },
                {
                  id: "email",
                  Header: "Email",
                  accessor: "email",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                  width: this.getColumnWidth(
                    this.state.paidLedgerData,
                    "email",
                    "Email"
                  ),
                },
                {
                  id: "entrystamp",
                  Header: "Payment Date",
                  accessor: (d: any) => {
                    return moment(d.entrytimestamp)
                      .local()
                      .format("YYYY-DD-MM");
                  },
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                  headerClassName: "bg-primary p-3 text-light",
                  className: "text-center",
                },
              ]}
              defaultPageSize={10}
              noDataText="No Data Found"
              getTrProps={(
                state: any,
                rowInfo: any,
                column: any,
                instance: any
              ) => {
                if (typeof rowInfo !== "undefined") {
                  return {
                    style: {
                      background: rowInfo.index % 2 === 0 ? "white" : "#f9f9f9",
                      color: rowInfo.index % 2 === 0 ? "#000000" : "#000000",
                    },
                  };
                } else {
                  return {
                    style: {
                      background: "white",
                      color: "black",
                    },
                  };
                }
              }}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }

  renderSelectUser() {
    return (
      <select
        id="dashboard-session-select"
        value={this.state.userID || ""}
        onChange={this.handleChange.bind(this)}
        className="custom-select"
        name="userid"
        disabled={true}
      >
        {this.state.userIdList.map((obj: any) => {
          return (
            <option key={obj.id} value={obj.id}>
              {obj.id} - {obj.first_name} {obj.last_name}
            </option>
          );
        })}
      </select>
    );
  }

  render() {
    return (
      <div className="mainWidth mb-5 pb-5">
        <p className="text-secondary m-0 p-3">Payout Ledger</p>
        <hr className="m-0" />
        <Row className="ml-3 mr-3 mb-3 mt-5">
          <Col>{this.paidLedger()}</Col>
        </Row>
        <Row className="ml-3 mr-3 mb-3 mt-5">
          <Col>{this.showLedger()}</Col>
        </Row>

        <div className="row m-0">
          <div className="col-12 pt-5 px-4 pb-0 px-lg-5">
            <p className="h4 m-0">Add Ledger Entry</p>
          </div>
        </div>

        <Row className="mt-3 ml-3 mr-3 mb-5">
          <Col>
            <div className="bg-white shadow p-4 mb-5">
              <form>
                <div className="form-group">
                  <label htmlFor="inputSession">User ID</label>
                  {this.renderSelectUser()}
                </div>

                <FormInput
                  name="description"
                  type="text"
                  label="Description"
                  placeholder="Description"
                  value={this.state.description}
                  valid={this.state.errDesp}
                  feedback={this.state.descriptionMessage}
                  onChange={(e) => this.handleChange(e)}
                ></FormInput>

                <div className="form-group">
                  <label htmlFor="inputSession">Type</label>
                  <select
                    id="dashboard-session-select"
                    value={this.state.type}
                    onChange={this.handleChange.bind(this)}
                    className="custom-select"
                    name="type"
                    disabled={true}
                  >
                    {[
                      "Generic (default)",
                      "Product Sale with Affiliates",
                      "Product Sale No Affiliates",
                    ].map((obj: any, value: any) => {
                      return (
                        <option key={`option-item-${value}`} value={obj}>
                          {obj}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <FormInput
                  name="amount"
                  type="text"
                  label="Amount"
                  placeholder="Amount"
                  disabled={true}
                  value={this.state.amount}
                  valid={this.state.errAmount}
                  feedback={this.state.amountMessage}
                  onChange={(e) => this.handleChange(e)}
                ></FormInput>

                <div className="mt-4">
                  <button
                    className="btn btn-primary btn-block p-auto m-auto"
                    onClick={(e) => {
                      this.handleClick(e);
                    }}
                    disabled={
                      this.state.payoutData == null ||
                      this.state.description === ""
                        ? true
                        : false
                    }
                  >
                    Add Ledger Entry
                  </button>
                </div>
              </form>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

import React from 'react'
import PPTable2 from '../common/PPTable2'
import {
    Row,
    Col, Card,
    Spinner
} from 'react-bootstrap'
import { API } from '../../APIAndConfig'

interface stateInterface {
    users: any
}

export class UserManagement extends React.Component<any, stateInterface> {

    constructor(props: any) {
        super(props)
        this.state = {
            users: null
        }
    }

    async componentWillMount() {
        const usersInfo = await API.getAllUsers().then(
            res => { return res }
        ).catch(
            err => { return err }
        )

        this.setState({
            users: usersInfo.payload.data || []
        })

    }

    renderUsersTable() {
        const columns = [{
            Header: 'ID',
            accessor: 'id',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Role ID',
            accessor: 'role_id',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'First Name',
            accessor: 'first_name',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Last Name',
            accessor: 'last_name',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Email',
            accessor: 'email',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Credits',
            accessor: 'credits',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Referral Code',
            accessor: 'referral_code',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Subscribed',
            accessor: 'subscribed',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Subscribed Membership Type',
            accessor: 'subscribed_membership_type',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Subscribed Membership Id',
            accessor: 'subscription_membership_id',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Stripe Customer Id',
            accessor: 'stripe_customer_id',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Created',
            accessor: 'created',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Modified',
            accessor: 'modified',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }, {
            Header: 'Referred By',
            accessor: 'referrer',
            headerClassName: 'bg-primary p-3 text-light',
            className: 'text-center'
        }]

        if ( this.state.users === null )
            return (<div className="text-center m-5 "><Spinner animation={"grow"} variant={"primary"} style={{ width: "5em", height: "5em" }} /></div>)

        return (
            <PPTable2
                columns={columns}
                list={this.state.users}
            ></PPTable2>
        )
    }

    // renderUpdateUserForm() {
    //     return (
    //         <div>
    //             Hello world
    //         </div>
    //     )
    // }

    render() {


        return <div className='mainWidth'>
            <p className="text-secondary m-0 p-3">User Management</p>
            <hr className="m-0" />

            <Row className="ml-3 mr-3 mb-3 mt-5">
                <Col>
                    <Card border="dark">
                        <Card.Header className="text-white bg-dark font-weight-bold ">User Management</Card.Header>
                        <Card.Body className="p-0">
                            {this.renderUsersTable()}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* {this.renderUpdateUserForm()} */}

        </div>
    }
}
import { Config, API, APIAxios } from "../APIAndConfig";
import axios from "axios";
import { ERROR_MESSAGE, FETCH_USER_IMAGES } from "./types";
import { Firebase } from "../firebase";
import set from "date-fns/set";

export const submitStatementImage = (
  auth,
  file,
  fileName
) => async dispatch => {
  const token = await Firebase.Auth.currentUser.getIdToken();

  const header = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token
  };

  try {
    const res = fileName.split(".");
    const fileExtension = res.reverse()[0];

    const upload = await axios.post(
      Config.APIUrl + "/upload",
      {
        type: 'img',
        purpose: "session",
        mime: file.type,
        filename: fileName,
        user: auth.id
      },
      { headers: header }
    );
    const uploaded = await axios.put(upload.data.upload_url, file, {
      headers: { "Content-Type": file.type }
    })
    console.log('Uploaded', uploaded);

    //console.log('Done uploading', uploadConfig.data.url);
    //console.log('filepath', uploadConfig.data.url);

    // Fetch all images that the user uploaded.
    const userUploads = await axios.get(
      `${Config.APIUrl}/media?user=${auth.id}&purpose=session&variant=thumb`,
      {
        headers: header
      }
    );

    // Tell all components listening that there are new images.
    dispatch({
      type: FETCH_USER_IMAGES,
      payload: userUploads.data.data
    });

    return userUploads.data.data;
  } catch (err) {
    if (err.response) {
      switch (err.response.data) {
        case "SESSION_EXPIRED":
          const token = await Firebase.Auth.currentUser?.getIdToken();
          if (token) {
            await set(APIAxios.defaults.headers, 'authorization', `Bearer ${token}`);
            window.location.reload()
        }
          break;
        case "BAD_CONNECTION":
          case "UNCAUGHT_ERROR":
        case "DATABASE_LIMIT_EXCEEDED":
          localStorage.setItem('service_unavailable_status', "failed");
          await Firebase.Auth.signOut();
          window.location.href = "/error"
          break;
        default:
          dispatch({ type: ERROR_MESSAGE, payload: err.message });
          break;
      } 
    } else {
      dispatch({ type: ERROR_MESSAGE, payload: err.message });
    }
  }
};

import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../actions';

import './UserDetails.css';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import './survey.css';

class UserDetails extends Component {
   constructor(props) {
      super(props);
      this.state = {
         error: null
      };
   }

   componentDidMount() {
      this.props.resetError();
   }

   componentWillUnmount() {
      this.props.resetError();
   }

   onOpenNav(e) {
      document.getElementById('sidebar').classList.add('sidebarOpen');
   }

   render() {
      return (
         <div className="mainWidth">
            <div className="d-flex">
               <button
                  className="btn bg-transparent d-md-none"
                  onClick={this.onOpenNav.bind(this)}
               >
                   <FontAwesomeIcon icon={faBars} />
               </button>
               <p className="text-secondary m-0 px-0 py-3 px-md-3">
                  User Details
               </p>
            </div>
            <hr className="m-0" />
            <div className="bg-white m-3 p-3 shadow">
                <form id="detailsForm" className="px-0 pt-3 pb-0">
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">User&nbsp;Id</label>
                        <div className="col-9">
                            <input className="form-control-plaintext form-control-sm" placeholder="12345" readonly />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Email</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Alt&nbsp;Email</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">First&nbsp;Name</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Last&nbsp;Name</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Nickname</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Join&nbsp;Date</label>
                        <div className="col-9 d-flex">
                            <select className="form-control form-control-sm mr-3">
                                <option>Jan</option>
                            </select>
                            <select className="form-control form-control-sm mr-3">
                                <option>01</option>
                            </select>
                            <select className="form-control form-control-sm">
                                <option>2019</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Website</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Password</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Last&nbsp;Active</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Last&nbsp;Login</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">User&nbsp;Type</label>
                        <div className="col-9">
                            <select className="form-control form-control-sm">
                                <option>Professional</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Gold&nbsp;Pass</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">App&nbsp;Access</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Afilliate</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">First&nbsp;Login</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Heard</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Registered</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Referrer</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Source</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Created</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Default&nbsp;Time</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Goals</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Sub&nbsp;Exp</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Paid</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Mail&nbsp;Lists</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">User&nbsp;Role</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                    <div className="form-group row mx-0">
                        <label className="col-3 col-form-label col-form-label-sm">Sessions</label>
                        <div className="col-9">
                            <input className="form-control form-control-sm" />
                        </div>
                    </div>
                </form>
                <button className="btn btn-primary m-3">Save Changes</button>
            </div>
         </div>
      );
   }
}

function mapStateToProps(state) {
   return { auth: state.auth };
}

export default connect(
   mapStateToProps,
   actions
)(UserDetails);

import {Col, Row, Spinner} from "react-bootstrap";
import React from "react";

export interface IntroWrapProps {
    processing: boolean;
    heading: string,
    text: string
}

export class IntroWrap extends React.Component<IntroWrapProps> {
    static defaultProps = {
        processing: false,
        heading: "",
        text: ""
    }
    render() {
        return <Row className="loginWrap m-0 p-0 h-100 min-vh-100 bg-white">
            <Col className="d-flex flex-column justify-content-center align-items-center">
                <a className="loginForm mb-auto text-center pt-2" href="https://positiveprime.com">
                    <img src="/logo.gif" alt="Positive Prime" style={{maxWidth:"250px"}}/>
                </a>

                <div className="text-center mb-5">
                    <h1 className="h2">{this.props.heading}</h1>
                    <p>{this.props.text}</p>
                </div>

                {this.props.children}
                {this.renderOverlay()}

                <div className="u-login-form text-muted py-3 mt-auto">
                    If you are having trouble please contact <a href="mailto:support@positiveprime.com">support@positiveprime.com</a>
                </div>
            </Col>
            <Col className="d-none d-lg-flex flex-column align-items-center justify-content-center bg-gradient-primary text-white loginSplash" />
        </Row>
    }
    renderOverlay() {
        if (this.props.processing)
            return (
                <div className="processingOverlay">
                    <Spinner animation="border" style={{margin:"0 auto"}} />
                </div>
            )
    }
}

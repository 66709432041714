import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API, makeLegacyCDNUrl, makePlayerImageUrl } from "../APIAndConfig";
import { useImmer } from "use-immer";
import {
  createOfflineSession,
  DOWNLOAD_STATE,
  getOfflineSession,
  getOfflineSessionDefaultRequiredImage,
  getOfflineSessionImageIdsForSession,
  getOfflineSessions,
  removeOfflineSession,
  setOfflineDefaultRequiredImages,
  setOfflineImageData,
  setOfflineSession,
  setOfflineSessionDefaultRequiredImages,
  setOfflineSessionMedia,
} from "../helpers/offlineSession";
import _ from "lodash";

type ContextProps = {
  download: (id: number) => void;
  pause: (id: number) => void;
  remove: (id: number) => void;
  downloads: SessionDownloadState;
  refresh: number;
};

type SessionDownloadState = {
  [id: string]: {
    session: any;
    saved_images: any[];
    progress: number;
    total: number;
    state: string;
  };
};

type SessionDownloadProps = React.FC & {
  Context: React.Context<ContextProps>;
};

const Context = React.createContext<ContextProps>({
  download: (id: number) => null,
  pause: (id: number) => null,
  remove: (id: number) => null,
  downloads: {},
  refresh: 1,
});

// reads an image from a url returns a base64 version
const read = async (url: any): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();

  return await convert(blob);
};

// converts a blob to base64
const convert = (blob: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result as any);
    };

    reader.readAsDataURL(blob);
  });

const setOfflineSessionState = async (
  session: any,
  state: string
): Promise<void> => {
  session.downloadState = state;
  await setOfflineSession(session);
};

let pausedID: number[] = [0];

export const SessionDownload: SessionDownloadProps = ({ children }) => {
  // @ts-ignore
  const auth = useSelector((state) => state.auth);
  const [downloads, setDownloads] = useImmer<SessionDownloadState>({});
  const [downloadID, setDownloadID] = useState(0);
  const [refresh, setRefresh] = useState(1);
  const [_session, _setSession] = useState(null);
  const sessionRef = React.useRef(_session);
  const networkStatus = useSelector((state: any) => state.networkStatus)
  const setSession = async (data: any) => {
    sessionRef.current = data;
    await _setSession({ ...data });
    await setOfflineSession(sessionRef.current);
  };

  useEffect(() => {
    start_download(downloadID);
  }, [downloadID]);

  useEffect(() => {
    if (networkStatus.connected) {
      initializeExistingSessions();
    }
  }, []);

  const initializeExistingSessions = async (): Promise<void> => {
    const existing_sessions = await getOfflineSessions(true);
    console.log("Existing Sessions : ", existing_sessions);
    await existing_sessions.map(async (existing_session): Promise<void> => {
      console.log(
        "Initialized Existing Sessions : ",
        existing_session.id,
        existing_session.downloadState
      );
      if (existing_session.id && existing_session.downloadState) {
        const { payload: session } = await API.getSessionById(
          existing_session.id
        );
        const { payload: images } = (await API.getImageSession(
          auth.id,
          existing_session.id
        )) as any;
        // get already saved images
        const saved = await getOfflineSessionImageIdsForSession(
          existing_session.id
        );
        // initialize downloads state
        setDownloads((draft) => {
          draft[existing_session.id] = {
            session,
            saved_images: saved,
            total: images.length,
            progress: (saved.length / images.length) * 100,
            state: existing_session.downloadState,
          };
        });
      }
    });
    // await checkStates();
  };

  const download = async (id: number): Promise<void> => {
    // get session and list of all images for the session
    const { payload: session } = await API.getSessionById(id);
    const { payload: images } = (await API.getImageSession(auth.id, id)) as any;

    // @ts-ignore
    const audio64 = await read(
      // @ts-ignore
      makeLegacyCDNUrl("b/" + session.data.defaultAudioURL)
    );

    // creating Offline Session
    await createOfflineSession({
      session: {
        // @ts-ignore
        ...session.data,
        audio64,
        downloadState: DOWNLOAD_STATE.DOWNLOADING,
        // @ts-ignore
        suggestedData: session.suggestedData,
      },
      images,
    });

    // get the created offline session so we can update the state on it
    const offline = await getOfflineSession(id);
    await setSession(offline);
    setOfflineSessionState(offline, DOWNLOAD_STATE.DOWNLOADING);

    // configuring Offline Session
    const { payload: defaultImages } = await API.getDefaultRequiredImages();
    const { payload: medias } = await API.getMedia({
      purpose: "session",
      variant: "thumb",
      user: "current",
      session: id,
    });

    // @ts-ignore
    await setOfflineSessionMedia(id, medias.data);
    await setOfflineDefaultRequiredImages(defaultImages);

    const mapOfflineDefaultRequiredImageData = async (defaultImages: any = []) => {
      if (!!defaultImages.length) {
        for (let index = 0; index < defaultImages.length; index++) {
          const defaultImage = defaultImages[index];

          // proceed only if default required image is not yet saved
          const saved = await getOfflineSessionDefaultRequiredImage(defaultImage.id)
          if (!!Object.keys(saved).length) continue
          
          const url = makePlayerImageUrl(defaultImage.media_url);
          const base64 = await read(url);

          await setOfflineSessionDefaultRequiredImages(defaultImage.id, base64)
        }
      }
    }

    await mapOfflineDefaultRequiredImageData(defaultImages)

    // get already saved images
    const saved = await getOfflineSessionImageIdsForSession(id);

    // initialize downloads state
    setDownloads((draft) => {
      draft[id] = {
        session,
        saved_images: saved,
        total: images.length,
        progress: (saved.length / images.length) * 100,
        state: DOWNLOAD_STATE.DOWNLOADING,
      };
    });

    setDownloadID(id);
    setDownloadID(0);
  };

  const start_download = async (id: number): Promise<void> => {
    if (id) {
      console.log("Download ID change detected for : ", id, downloads[id]);
      const { payload: images } = (await API.getImageSession(
        auth.id,
        id
      )) as any;
      const offline = await getOfflineSession(id);

      if (pausedID.includes(id)) {
        pausedID = _.without(pausedID, id);
      }
      if (downloads[id]?.state === DOWNLOAD_STATE.DOWNLOADING) {
        console.log("START DOWNLOADING SESSION ", id);

        // get already saved images for the session so we don't download them again
        const saved = await getOfflineSessionImageIdsForSession(id);
        const unsaved = images.filter((i: any) => !saved.includes(i.id));

        // loop over all the unsaved images and add them into the offline session
        for (let index = 0; index < unsaved.length; index++) {
          const image = unsaved[index];
          const url = makePlayerImageUrl(image.media_url);
          const base64 = await read(url);

          if (pausedID.includes(id)) {
            // set offline session downloadState to finished
            await setOfflineSessionState(offline, DOWNLOAD_STATE.PAUSED);
            // set context state
            if (downloads[id]) {
              setDownloads((draft) => {
                draft[id].state = DOWNLOAD_STATE.PAUSED;
              });
            }
            console.log("DOWNLOAD PAUSED");
            break;
          }

          await setOfflineImageData(image.id, base64);
          const savedImageIds = await getOfflineSessionImageIdsForSession(id);
          // @ts-ignore
          sessionRef!.current!.downloadPercentage = Math.floor(
            (savedImageIds.length / images.length) * 100
          );
          // @ts-ignore
          sessionRef.current.downloadState =
            // @ts-ignore
            sessionRef.current.downloadPercentage === 100
              ? DOWNLOAD_STATE.FINISHED
              : DOWNLOAD_STATE.DOWNLOADING;
          // update session state
          await setSession(sessionRef.current);
          if (downloads[id]) {
            setDownloads((draft) => {
              draft[id].saved_images.push(image);
              draft[id].progress = (savedImageIds.length / images.length) * 100;
            });
          }
        }

        if (!pausedID.includes(id)) {
          // set offline session downloadState to finished & progress to 100
          await setOfflineSessionState(offline, DOWNLOAD_STATE.FINISHED);
          // set context state
          if (downloads[id]) {
            setDownloads((draft) => {
              draft[id].state = DOWNLOAD_STATE.FINISHED;
            });
          }
          console.log("DOWNLOAD COMPLETE");
          // offline.downloadPercent = 100;
          // setDownloads((draft) => {
          //   draft[id].progress = 100;
          // });
        }
      } else {
        console.log("Invalid ID");
      }
    }
  };

  const pause = async (id: number): Promise<void> => {
    console.log("PAUSED ATTEMPTED FOR ID : ", id);
    // get the created offline session so we can update the state on it
    if (id) {
      const offline = await getOfflineSession(id);
      setOfflineSessionState(offline, DOWNLOAD_STATE.PAUSED);
      // change context state
      if (downloads[id]) {
        setDownloads((draft) => {
          draft[id].state = DOWNLOAD_STATE.PAUSED;
        });
      }
      pausedID.push(id);
    }
  };

  const remove = async (id: number): Promise<void> => {
    console.log("REMOVE ATTEMPTED FOR ID : ", id);
    // get the created offline session so we can update the state on it
    if (id) {
      const offline = await getOfflineSession(id);
      setOfflineSessionState(offline, DOWNLOAD_STATE.REMOVING);
      // set context state
      if (downloads[id]) {
        setDownloads((draft) => {
          draft[id].state = DOWNLOAD_STATE.REMOVING;
        });
      }
      await removeOfflineSession(id);
      removeComplete(id);
    }
  };

  const removeComplete = async (id: number): Promise<void> => {
    if (id) {
      const savedImageIds = await getOfflineSessionImageIdsForSession(id);
      console.log("Image Left : ", savedImageIds.length);
      console.log("REMOVE COMPLETE");
      const offline = await getOfflineSession(id);
      setOfflineSessionState(offline, DOWNLOAD_STATE.NONE);
      if (downloads[id]) {
        setDownloads((draft) => {
          draft[id].saved_images = [];
          draft[id].total = 0;
          draft[id].progress = 0;
          draft[id].state = DOWNLOAD_STATE.NONE;
        });
      }
      setRefresh(-refresh);
    }
  };

  const checkStates = async (): Promise<void> => {
    const existing_sessions = await getOfflineSessions(true);
    console.log("Existing Sessions : ", existing_sessions);
    existing_sessions.map(async (existing_session): Promise<void> => {
      const { payload: images } = (await API.getImageSession(
        auth.id,
        existing_session.id
      )) as any;
      const savedImageIds = await getOfflineSessionImageIdsForSession(
        existing_session.id
      );
      console.log(
        "Check Existing Sessions : ",
        existing_session.id,
        existing_session.downloadState,
        "Progress: ",
        Math.floor((savedImageIds.length / images.length) * 100)
      );
    });
  };

  return (
    <Context.Provider value={{ download, pause, remove, downloads, refresh }}>
      {children}
    </Context.Provider>
  );
};

SessionDownload.Context = Context;

export default SessionDownload;
